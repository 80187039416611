
import Order from "../models/orderModel";

import * as fflate from 'fflate';
import { BaseConfig } from "../baseConfig";
import { DateTime } from "luxon";
import axios from 'axios';
export const getOrder = async (orderId) => {
  const order = new Order();
  let orderObj = [];
  try {
    // API object call.
    const url = BaseConfig.ApiEndPoint + "/orders/" + orderId;
    const orderApiData = await axios.get(url);
    orderObj = order.parseApiOrderObject(orderApiData.data.data);
  } catch (err) {
    return Promise.reject(
      "There is a problem on retrieving Order data. Please try again!"
    );
  }

  return Promise.resolve(orderObj);
}

export const getAllOrders = async (reload) => {
  const order = new Order();
  let orderObj = [];
  try {
    // if (reload) {
    // API object call.
    const url = BaseConfig.ApiEndPoint + "/orders";
    const orderApiData = await axios.get(url);
    orderObj = order.parseApiOrderObject(orderApiData.data.data);

    // Put the object into storage
    // localStorage.setItem('OrderListObject', JSON.stringify(orderObj));
    // }

    // Retrieve the object from storage
    // const retrievedOrderObject = localStorage.getItem('OrderListObject');
    // orderObj = JSON.parse(orderObj);
  } catch (err) {
    return Promise.reject(
      "There is a problem on retrieving Order data. Please try again!"
    );
  }

  return Promise.resolve(orderObj);
}

//   ## -> BaseConfig is a axios for getting all orders by date.
export const getOrdersByDate = async (searchData) => {
  const order = new Order();
  let orderObj = [];
  try {
    const data = {
      orderDate: searchData.date,
      terminals: searchData.terminal
    }
    const url = BaseConfig.ApiEndPoint + "/ordersbydate";
    const orderApiData = await axios.post(url, data);
    let withDotString = JSON.stringify(orderApiData.data.data)
    let withoutDotString = withDotString.replaceAll('.', '');
    let OrderTableObject = JSON.parse(withoutDotString);
    return Promise.resolve(OrderTableObject);
  } catch (err) {
    return Promise.reject(
      "There is a problem on retrieving Order data. Please try again!"
    );
  }

}

export const getordertabinplanners = async (filterData) => {

  const order = new Order();
  let orderObject = []
  try {

    let data = {
      date: filterData.date,
      terminal_id: filterData.terminals,
      daysOut: filterData.daysOut
    }
    const url = BaseConfig.ApiEndPoint + "/getorderstabdata";

    const orderApiData = await axios.post(url, data);

    const strData = atob(orderApiData.data.data);
    // Convert binary string to character-number array
    const charData = strData.split("").map((x) => { return x.charCodeAt(0); });
    // Turn number array into byte-array
    const binData = new Uint8Array(charData);
    // Use BaseConfig Code to Decode the String

    //Uncompress API Call//
    let orderApiDataUnCompressed = fflate.unzlibSync(binData);
    let orderApiDataUnCompressedString = fflate.strFromU8(orderApiDataUnCompressed)
    let orderIntermediateObject = JSON.parse(orderApiDataUnCompressedString)
    orderObject = orderIntermediateObject.data;
  } catch (error) {
  }
  return Promise.resolve(orderObject);
}
///end

export const getAllOrderByFilter = async (filterObj, reload) => {
  const order = new Order();
  let orderObj = [];
  try {
    // if (reload) {
    // API object call.
    const url = BaseConfig.ApiEndPoint + "/plannerbydate";
    const orderApiData = await axios.post(url, filterObj);
    orderObj = order.parseApiOrderObject(orderApiData.data.data);

    // Put the object into storage
    // localStorage.setItem('OrderListObject', JSON.stringify(orderObj));
    // }

    // Retrieve the object from storage
    // const retrievedOrderObject = localStorage.getItem('OrderListObject');
    // orderObj = JSON.parse(orderObj);
  } catch (err) {
    return Promise.reject(
      "There is a problem on retrieving Order data. Please try again!"
    );
  }

  return Promise.resolve(orderObj);
}


export const getordertabinunplanners = async (filterData) => {

 

  const order = new Order();

  let orderObject = []

  try {

 

    let data = {

      date: filterData.date,

      // terminal_id: filterData.terminals,

      daysOut: filterData.daysOut

    }

    const url = BaseConfig.ApiEndPoint + "/getunplanedorderstabdata";

 

    const orderApiData = await axios.post(url, data);

 

    const strData = atob(orderApiData.data.data);

    // Convert binary string to character-number array

    const charData = strData.split("").map((x) => { return x.charCodeAt(0); });

    // Turn number array into byte-array

    const binData = new Uint8Array(charData);

    // Use BaseConfig Code to Decode the String

 

    //Uncompress API Call//

    let orderApiDataUnCompressed = fflate.unzlibSync(binData);

    let orderApiDataUnCompressedString = fflate.strFromU8(orderApiDataUnCompressed)

    let orderIntermediateObject = JSON.parse(orderApiDataUnCompressedString)

    orderObject = orderIntermediateObject.data;

  } catch (error) {

  }

  return Promise.resolve(orderObject);

}


export const searchUnplanAndPlandOrder = async (tabname,searchTerms,sortTerms, page, size, date, daysout) => {

  let decompressedObject;

  let payload_data = {
    tabname:tabname,
    date: date,
    daysOut: daysout,
    searchdata: [],
    shortData:sortTerms
  };
  searchTerms?.forEach((term) => {
    const { field, value, operator } = term;
      payload_data.searchdata.push({
        [field]: value,
        operator: operator,
      });
  });
  const url = `${BaseConfig.ApiEndPoint}/searchorderstab?page=${page}&size=${size}`;
  try {
    const response = await axios.post(url, payload_data);
    if (response?.data && response?.data?.compressedbase64string) {
      const strData = atob(response.data.compressedbase64string);
      const charData = strData.split("").map((x) => {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      let apiDataUnCompressed = fflate.unzlibSync(binData);
      let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
      decompressedObject = JSON.parse(apiDataUnCompressedString);
    } else {
      throw new Error("Invalid response format: response.data is undefined or null");
    }

  } catch (error) {
    console.error("An error occurred while performing  search:", error);
    throw error;
  }

  return decompressedObject;

};