class Order {
    constructor() {


    }
    
    parseApiOrderObject(order) {
        const orderObject = {...order};
        //Define your Data Model based on the UI Requirement here.
        //Implementation should continue bu creating demo model.

        return orderObject ;
    }
}

export default Order;