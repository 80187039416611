import React, { useState, useEffect } from "react";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  IntlProvider,
  LocalizationProvider,
  IntlService,
} from "@progress/kendo-react-intl";
import { process } from "@progress/kendo-data-query";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import "@progress/kendo-theme-default/dist/all.css";
import getRowDraggable from "../rowDraggable/rowDraggable";
import { tankWashDragContext } from "../../pages/tankWash/tankWash";
import { DateTime } from "luxon";
import { Grid as MGrid } from '@mui/material';
import { ToggleButtonGroup, ToggleButton } from '@mui/lab';

const DATE_FORMAT = "MM-dd-yyyy HH:mm a ZZZZ ";
const intl = new IntlService("en");

const DetailComponent = () => {
  return null;
};

const TankWashOrdersTable = (props) => {
  const {  data, location, handleToggle, selectedValue, setSelectedValue } = props;
  const context = React.useContext(tankWashDragContext);
  const [isDataloaded, setIsDataLoaded] = useState(false);

  const locales = [
    {
      language: "en-US",
      locale: "en",
    },
    {
      language: "es-ES",
      locale: "es",
    },
  ];

  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 20,
    sort: [
      {
        field: "",
        dir: "asc",
      },
    ],
  
  });

  const [currentLocale, setCurrentLocale] = React.useState(locales[0]);
  const [orders, setorders] = useState([]);
  const [orderArr, setOrderArr] = useState(data);
  const [dataResult, setDataResult] = React.useState(
    process(orders, dataState)
  );
  
  let columns = [
    { id: 0, name: "expanded", title: "" },
    {id: 1,
      name: "wash_location_id",
      title: "Wash Location Id",
      width: "280px"},
    { id: 2, name: "wash_id", title: "Wash Id", width: "280px" },
    { id: 3, name: "trailer_id", title: "Trailer Id", width: "280px" },
    { id: 4, name: "commodity_id", title: "Commodity Id", width: "280px" },
    {
      id: 5,
      name: "int_wash_code",
      title: "Int Wash Code",
      width: "280px",
    },
    {
      id: 6,
      name: "ext_wash_code",
      title: "Ext Wash Code",
      width: "280px",
    },
    { id: 7, name: "needed_date_utc", title: "Needed Date", width: "280px" },
    { id: 8, name: "in_date_utc", title: "In Date Utc", width: "280px" },
    { id: 9, name: "out_date_utc", title: "Out Date Utc", width: "280px" },
    { id: 10, name: "company_id", title: "Company Id", width: "280px" },
    { id: 11, name: "charge_type", title: "Charge Type", width: "280px" },
    { id: 12, name: "customer_id", title: "Customer Id", width: "280px" },
    { id: 13, name: "driver_id", title: "Driver Id", width: "280px" },
    {
      id: 14,
      name: "entered_user_id",
      title: "Entered User Id",
      width: "280px",
    },
    { id: 15, name: "equip_type", title: "Equip Type", width: "280px" },
    {
      id: 16,
      name: "ext_wash_amount",
      title: "Ext Wash Amount",
      width: "280px",
    },
    {
      id: 17,
      name: "ext_wash_amount_c",
      title: "Ext_Wash_Amount C",
      width: "280px",
    },
    {
      id: 18,
      name: "ext_wash_amount_d",
      title: "Ext wash Amount D",
      width: "280px",
    },
    {
      id: 19,
      name: "ext_wash_amount_n",
      title: "Ext wash Amount N",
      width: "280px",
    },
    {
      id: 20,
      name: "ext_wash_amount_r",
      title: "Ext wash Amount R",
      width: "280px",
    },
    // {
    //   id: 15,
    //   name: "ext_wash_code",
    //   title: "Ext Wash Code",
    //   width: "280px",
    // },
    { id: 21, name: "hazmat_code", title: "Hazmat Code", width: "280px" },
    { id: 22, name: "heel", title: "Heel", width: "280px" },
    {
      id: 23,
      name: "heel_unit_type",
      title: "Heel Unit Type",
      width: "280px",
    },
    // { id: 19, name: "in_date", title: "In Date", width: "280px" },
    {
      id: 24,
      name: "int_wash_amount",
      title: "Int Wash Amount",
      width: "280px",
    },
    {
      id: 25,
      name: "int_wash_amount_c",
      title: "Int Wash Amount C",
      width: "280px",
    },
    {
      id: 26,
      name: "int_wash_amount_d",
      title: "Int Wash Amount D",
      width: "280px",
    },
    {
      id: 27,
      name: "int_wash_amount_n",
      title: "Int Wash Amount N",
      width: "280px",
    },
    {
      id: 28,
      name: "int_wash_amount_r",
      title: "Int Wash Amount R",
      width: "280px",
    },
    // {
    //   id: 25,
    //   name: "int_wash_code",
    //   title: "Int Wash Code",
    //   width: "280px",
    // },
    {
      id: 29,
      name: "intra_co_seg_code",
      title: "Intra Co Seg Code",
      width: "280px",
    },
    {
      id: 30,
      name: "invoice_po_date",
      title: "Invoice Po Date",
      width: "280px",
    },
    {
      id: 31,
      name: "invoice_po_num",
      title: "Invoice Po Num",
      width: "280px",
    },
    { id: 32, name: "movement_id", title: "Movement Id", width: "280px" },
    { id: 33, name: "order_id", title: "Order Id", width: "280px" },
    { id: 34, name: "other_equip", title: "Other Equip", width: "280px" },
    // { id: 32, name: "out_date", title: "Out Date", width: "280px" },
    {
      id: 35,
      name: "ready_to_xfer",
      title: "Ready To Xfer",
      width: "280px",
    },
    {
      id: 36,
      name: "seg_alloc_code",
      title: "Seg Alloc Code",
      width: "280px",
    },
    { id: 37, name: "tractor_id", title: "Tractor Id", width: "280px" },
    // { id: 35, name: "trailer_id", title: "Trailer Id", width: "280px" },
    { id: 38, name: "vendor_id", title: "Vendor Id", width: "280px" },
    { id: 39, name: "void", title: "Void", width: "280px" },
    { id: 40, name: "voucher_id", title: "Voucher Id", width: "280px" },
    { id: 41, name: "wash_date_utc", title: "Wash Date", width: "280px" },
    {
      id: 42,
      name: "wash_location_id",
      title: "Wash Location Id",
      width: "280px",
    },
    { id: 43, name: "washed_for", title: "Washed For", width: "280px" },
    {
      id: 44,
      name: "tank_wash_assigned",
      title: "Tank Wash Assigned",
      width: "280px",
    },
    {
      id: 45,
      name: "trailer_wash_assigned",
      title: "Trailer Wash Assigned",
      width: "280px",
    },
    // { id: 44, name: "needed_date", title: "Needed Date", width: "280px" },
    {
      id: 46,
      name: "commodity_desc2",
      title: "Commodity Desc2",
      width: "280px",
    },
    { id: 47, name: "text", title: "Text", width: "280px" },
    { id: 48, name: "bay", title: "Bay", width: "280px" },
    { id: 49, name: "source", title: "Source", width: "280px" },
    { id: 50, name: "resource", title: "Resource", width: "280px" },
    { id: 51, name: "history", title: "History", width: "280px" },
    { id: 52, name: "start_time_utc", title: "Start Time Utc", width: "280px" },
    { id: 53, name: "end_time_utc", title: "End Time Utc", width: "280px" },
    // { id: 54, name: "in_date_utc", title: "In Date Utc", width: "280px" },
    // { id: 55, name: "out_date_utc", title: "Out Date Utc", width: "280px" },
  ];

  const columnReorderChange = function () {};

  const dataStateChange = (event) => {
    setDataResult(process(orders, event.dataState));
    setDataState(event.dataState);
  };

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setDataResult({
      ...dataResult,
    });
  };

  const convertmilisecondtimeformat = (duration) => {
    if (duration) {
      let date = Date.parse(duration);
 
      return (
        DateTime.fromMillis(parseInt(date))
          .setZone(location?.timezone)
          .toFormat("MM-dd-yyyy HH:mm a ZZZZ ")
          .toString()
      );
    }
  }



  const convertdatetime = (epoch_date, tzone) => {

    if (!tzone || tzone === "Eastern Standard Time" || tzone === "EST" || tzone === "EDT" || tzone === "America/New_York") {
      tzone = "America/New_York"
    }
    if (!tzone || tzone === "Central Standard Time" || tzone === "CST" || tzone === "CDT" || tzone === "America/Chicago") {
      tzone = "America/Chicago"
    }
    if (epoch_date > 0) {
      return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
    }
    else {
      return ""
    }
  }

  useEffect(() => {
    const deepClonedAllTankwashData = JSON.parse(JSON.stringify(data));
    let newDataValue = [];

    for (let index = 0; index < deepClonedAllTankwashData.length; index++) {
      const temp = deepClonedAllTankwashData[index];

      if (temp.charge_type) {
        temp.charge_type = temp?.charge_type === "I" ? "Intra-Company" : temp?.charge_type === "O" ? "Outside Carrier" : temp.charge_type === "P" ? "Vendor Payble" : null;
      }

      if (temp.equip_type) {
        temp.equip_type = temp.equip_type === "L" ? "Lifting Equipment" : temp.equip_type === "O" ? "Stationary Equipment" : null;
      }

      if (
        temp.ext_wash_amount_d === null ||
        isNaN(temp.ext_wash_amount_d)
      ) {
        temp.ext_wash_amount_d
      } else {
        temp.ext_wash_amount_d = convertdatetime(
          temp.ext_wash_amount_d, props.location?.timezone);

      }
      if (temp.invoice_po_date) {
        temp.invoice_po_date = convertmilisecondtimeformat(temp.invoice_po_date);
      }


      //  if(temp.in_date){
      //   temp.in_date= convertmilisecondtimeformat(temp.in_date) 
      //  }
      if (
        temp.in_date_utc === null ||
        isNaN(temp.in_date_utc)
      ) {
        temp.in_date_utc
      } else {
        temp.in_date_utc = convertdatetime(
          temp.in_date_utc, props.location?.timezone);

      }
      //  if(temp.int_wash_amount_d){
      //   temp.int_wash_amount_d= convertmilisecondtimeformat(temp.int_wash_amount_d) 
      //  }
      if (
        temp.int_wash_amount_d === null ||
        isNaN(temp.int_wash_amount_d)
      ) {
        temp.int_wash_amount_d
      } else {
        temp.int_wash_amount_d = convertdatetime(
          temp.int_wash_amount_d, props.location?.timezone);

      }
      //  if(temp.out_date){
      //   temp.out_date= convertmilisecondtimeformat(temp.out_date) 
      //  }
      if (
        temp.out_date_utc === null ||
        isNaN(temp.out_date_utc)
      ) {
        temp.out_date_utc
      } else {
        temp.out_date_utc = convertdatetime(
          temp.out_date_utc, props.location?.timezone);

      }

      if (
        temp.wash_date_utc === null ||
        isNaN(temp.wash_date_utc)
      ) {
        temp.wash_date_utc
      } else {
        temp.wash_date_utc = convertdatetime(
          temp.wash_date_utc, props.location?.timezone);

      }

      if (
        temp.needed_date_utc === null ||
        isNaN(temp.needed_date_utc)
      ) {
        temp.needed_date_utc;
      } else {
        temp.needed_date_utc = convertdatetime(
          temp.needed_date_utc, props.location?.timezone);

      }


      //  if(temp.start_time_utc){
      //   temp.start_time_utc= convertdatetime(temp?.start_time_utc,props.location?.timezone) 

      //  }
      if (
        temp.start_time_utc === null ||
        isNaN(temp.start_time_utc)
      ) {
        temp.start_time_utc;
      } else {
        temp.start_time_utc = convertdatetime(
          temp.start_time_utc, props.location?.timezone);

      }
      //  if(temp.end_time_utc){
      //   temp.end_time_utc= convertdatetime(temp?.end_time_utc) 

      //  }
      if (
        temp.end_time_utc === null ||
        isNaN(temp.end_time_utc)
      ) {
        temp.end_time_utc;
      } else {
        temp.end_time_utc = convertdatetime(
          temp.end_time_utc, props.location?.timezone);

      }
      newDataValue.push(temp);
    }

    setorders(newDataValue);
    setDataResult(newDataValue)
    setIsDataLoaded(true);

  }, [data, selectedValue]);

  return (
    <div className='tab_inside_section'>
      <div className='tab_inside_section_top'>
        <div className='tab_inside_section_top_left'></div>
        <div className='tab_inside_section_top_right'>
          <div className="planning_board_tab_toggle">
            <MGrid
              container
              spacing={2}
              direction='column'
              alignItems='right'
            >
              <MGrid item>
                <ToggleButtonGroup
                  size='small'
                  value={selectedValue}
                  exclusive
                  onChange={handleToggle}
                >
                  <ToggleButton value='left'>PLANNED</ToggleButton>
                  <ToggleButton value='center'>UNPLANNED</ToggleButton>
                </ToggleButtonGroup>
              </MGrid>
            </MGrid>
          </div>
          {isDataloaded === "true" ? (
            ""
          ) : (
            <Link
              to=''
              component={RouterLink}
              onClick={() => {
                setIsDataLoaded("false");
                window.open(`/orders`, "_blank");
              }}
            >
              <button variant='contained' type='button' className='btn_signout'>
                <i className='fa fa-external-link'></i>
              </button>
            </Link>
          )}
          <i
            className='fa fa-times'
            aria-hidden='true'
            onClick={(e) => props.settabSelected("")}
          ></i>
        </div>
      </div>
      <div className='tab_inside_section_bottom'>
      <div className="bottomright-with-nw-resize-outer">
        {isDataloaded ? (
          <LocalizationProvider language={currentLocale.language}>
            <IntlProvider locale={currentLocale.locale}>           
                <Grid
                  sortable={true}
                  filterable={true}
                  groupable={true}
                  reorderable={true}
                  pageable={{
                    buttonCount: 4,
                    pageSizes: true,
                  }}
                  resizable={true}
                  data={process(orders, dataState)}
                  skip={dataState.skip}
                  take={dataState.take}
                  onDataStateChange={dataStateChange}
                  {...dataState}
                  detail={DetailComponent}
                  expandField='expanded'
                  onExpandChange={expandChange}
                  onColumnReorder={columnReorderChange}
                  onRowClick={(e) => props.parentcallback(true, e.dataItem)}
                  rowRender={getRowDraggable(
                    selectedValue === "left" ? () => {} : (e, dataItem) => {
                      context.setDragFrom("ordersTable");
                      context.setDragData(dataItem);
                    },
                    selectedValue === "left" ? () => {} : (e, dataItem) => {
                      context.setDragFrom(null);
                      context.setDragData(-1);
                    }
                  )}
                >
                  <GridToolbar></GridToolbar>
                  {columns.slice(1).map((it) => (
                    <GridColumn
                      key={it.id}
                      field={it.name}
                      title={it.title}
                      width={it.width}
                    />
                  ))}
                </Grid>     
            </IntlProvider>
          </LocalizationProvider>
        ) : (
          <ProgressBar animated now={100} />
        )}
        </div>
      </div>
    </div>
  );
};
export default TankWashOrdersTable;
