const appConfig = require("./Config.json");

let environment = appConfig.environment;
let dynamoDbPrefix = "";
let ApiEndPoint = "http://localhost:4000/api";
let PlanningApiEndPoint = "http://localhost:3200";
let ApiAdminEndPoint = "http://localhost:4000/admin-api"
let ApiEndForSamsara = "http://localhost:4000/external/api"
let SamsaraDriverPayLink = "https://cloud.samsara.com/o/21800/fleet/reports/hos/driver/"
let ApiKey = "";
let Mapkey = "EC4C17331DA1244E83DF701D69E9636D";

switch (environment.toUpperCase()) {
    case "LOCAL":
        dynamoDbPrefix = "";
        ApiEndPoint = appConfig.local.apiUrl + "/api";
        PlanningApiEndPoint = appConfig.local.planningBaseApiUrl + "/plan";
        ApiAdminEndPoint = appConfig.local.apiUrl + "/admin-api"
        ApiKey = appConfig.local.key;
        Mapkey = Mapkey;

        break;
    case "DEV":
        dynamoDbPrefix = "";
        ApiEndPoint = appConfig.dev.apiUrl + "/api";
        ApiAdminEndPoint = appConfig.dev.apiUrl + "/admin-api";
        PlanningApiEndPoint = appConfig.dev.planningBaseApiUrl + "/plan";
        ApiEndForSamsara = appConfig.dev.apiUrl + "/external/api"
        // driverpayapipdfurl=appConfig.dev.driverpaypdfurl
        PlanningApiEndPoint=appConfig.dev.planningBaseApiUrl + "/plan";
        ApiKey = appConfig.dev.key
        Mapkey = Mapkey
        break;
    case "QA":
        dynamoDbPrefix = "";
        ApiEndPoint = appConfig.qa.apiUrl + "/api";
        PlanningApiEndPoint = appConfig.qa.planningBaseApiUrl + "/plan";
        ApiAdminEndPoint = appConfig.qa.apiUrl + "/admin-api"
        ApiEndForSamsara = appConfig.qa.apiUrl + "/external/api"
        ApiKey = appConfig.qa.key
        Mapkey = Mapkey 
        break;
    case "ACC":
        dynamoDbPrefix = "";
        ApiEndPoint = appConfig.acc.apiUrl + "/api";
        ApiAdminEndPoint = appConfig.acc.apiUrl + "/admin-api";
        PlanningApiEndPoint = appConfig.acc.planningBaseApiUrl + "/plan";
        ApiEndForSamsara = appConfig.acc.apiUrl + "/external/api"
        ApiKey = appConfig.acc.key
        Mapkey = Mapkey
        break;
    case "PROD":
        dynamoDbPrefix = "";
        ApiEndPoint = appConfig.prod.apiUrl + "/api";
        ApiEndForSamsara = appConfig.prod.apiUrl + "/external/api"
        PlanningApiEndPoint = appConfig.prod.planningBaseApiUrl + "/plan";
        ApiAdminEndPoint = appConfig.prod.apiUrl + "/admin-api"
        // driverpayapipdfurl=appConfig.prod.driverpaypdfurl
        PlanningApiEndPoint=appConfig.prod.planningBaseApiUrl + "/plan";
        ApiKey = appConfig.prod.key
        Mapkey = Mapkey
        break;
}

export const BaseConfig = {
    dynamoDbPrefix: "",
    ApiEndPoint: ApiEndPoint,
    PlanningApiEndPoint: PlanningApiEndPoint,
    ApiAdminEndPoint:ApiAdminEndPoint,
    ApiEndForSamsara: ApiEndForSamsara,
    ApiKey: ApiKey,
    Mapkey : Mapkey,
    SamsaraDriverPayLink,
};
