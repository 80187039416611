import React, { useState, useEffect, useContext, createContext } from "react";
import Header from "../../components/header";
import PlannerHeader from "../../components/plannerHeader/plannerHeader";
import { gettrailerstabbydate } from "../../services/trailerService";
import {
  getBoardByDriverFormatted,
  getDriverbyTerminalIDs,
  getmovementstopbyterminalid,
  updatemultiplestops,
  getBoardByTrailerFormatted,
  getallcarrierview,
  addTrailer,
  changePlanTimebystartshift,
  scheduleSingleOrder,
  scheduleStackedOrders
} from "../../services/planningBoardService";
import { DateTime } from "luxon";
import { ContextData } from "../../components/appsession";
import OrdersTab from "../../components/ordersTab/ordersTab";
import ProgressBar from "react-bootstrap/ProgressBar";
import PlannerBoardTrailersTable from "../../components/plannerBoardTrailersTable/plannerBoardTrailersTable";
import PlannerBoardOrdersTable from "../../components/plannerBoardOrdersTable/plannerBoardOrdersTable";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { makeStyles } from "@mui/styles";
import "../../components/planningboardRow/available.css";
import "../../components/planningboardRow/unavailable.css";
import { getPlanningProfileById, getAllPlanningProfiles } from "../../services/userService";
import "../plannerboard/plannermainsectionboard.css";
import PlannerBoardDriversection from "../../components/plannerboarddriversection/plannerboarddriversection";
import PlannerBoardTrailersection from "../../components/plannerboardtrailersection/plannerboardtrailersection";
import PlannerBoardCarrierViewsection from "../../components/plannerboardcarrierviewsection/plannerboardcarrierviewsection";
import { getLoadunloadstopsInformation } from "../../services/loadunloadruleService";
import PlannerBoardTopSection from "../../components/plannerboardtopsection/plannerboardtopsection";
import { useHistory,useLocation } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import { getorderdragdrop } from '../../utils/plannerutils'
import Autocomplete from "@mui/lab/Autocomplete";
import AppBar from "../../components/appbar";
import { getDecodedURI, getEncodedURI } from "../../utils/urlEncodeandDecode";
import WarningModal from '../../components/warningModal'

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth: 500,
  },
  customTooltip: {
    maxWidth: "none",
    backgroundColor: "#4267B2",
    padding: "12px",
    borderRadius: "10px",
  },
  customArrow: {
    color: "#4267B2",
    fontSize: "20px",
  },
}));

const DropContext = createContext();
export { DropContext };

const Planner = () => {
  const [userData] = useContext(ContextData);
  const [dragData, setDragData] = useState({});
  const [dropData, setDropData] = useState({});
  const [isPlannerBoardClicked, setisPlannerBoardClicked] = useState(false);
  const [selectedTab, setselectedTab] = useState("");
  const [allOrders, setAllOrders] = useState([]);
  const [allTrailers, setAllTrailers] = useState([]);
  const [PlannerBoardLoadingPage, setPlannerBoardLoadingPage] = useState(false);
  const [PlanningBoardDriversResponse, setallPlanningBoardDriversView] = useState([]);
  const [driverPageNumber, setDriverPageNumber] = useState(1);
  const history = useHistory();
  const location= useLocation();
  const [date, setDate] = useState(
    DateTime.now().toUTC().startOf("day").toMillis()
  );
  const [movementData, setMovementData] = useState([]);
  const [allTrailersArray, setAllTrailersArray] = useState([]);
  const [tabSelected, settabSelected] = useState("");
  const [plannerTimezone, setPlannerTimezone] = useState("utc");
  const [selectedTimezoneDate, setSelectedTimezoneDate] = useState(date);
  const [daysout, setDaysout] = useState(1);
  const [planningTimeout, setPlanningTimeout] = useState(null);
  const [datarefresh, setdatarefresh] = useState(false);
  const [driverViewPlannedMode, setDriverViewPlannedMode] = useState(true);
  const [oderTabClickC, setOderTabClickC] = useState(0);
  const [trailerTabClickC, setTrailerTabClickC] = useState(0);
  const [trailerTabLoading, setTrailerTabLoading] = useState(true)
  const [profile, setProfile] = useState();
  const [allPlanningProfile, setallPlanningProfile] = useState([])
  const [usersPlanningProfile, setusersPlanningProfile] = useState([])
  const [selectedPlanningProfile, setselectedPlanningProfile] = useState([]);
  const [palnningBoardLoading, setpalnningBoardLoading] = useState(true)
  const [plannedAcctualToggle, setplannedAcctualToggle] = useState("")
  const [payloadForOrders, setpayloadForOrders] = useState({});
  const [planningBoardData, setPlanningBoardData] = useState({ data: [] });
  const [driverData, setDriverData] = useState([]);
  const [payloadForRefreshData, setPayloadForRefreshData] = useState(null);
  const [plannedOrUnplanned, setPlannedOrUnplanned] = useState("center");
  const [trailerViewPageNumber, settrailerViewPageNumber] = useState(1)
  const [tarilerViewData, settarilerViewData] = useState([])
  const [carrierviewData, setcarrierviewData] = useState([])
  const [carrierPageNumber, setCarrierPageNumber] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [washDetails, setWashDetails] = useState({});
  const hourArr = Array.from(Array(24).keys());
  const dragset = Array.from(Array(96).keys());
  const [isEditPermission, setisEditPermission] = useState(false);
  const [isViewPermission, setViewPermission] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [daysOutLoader, setdaysOutLoader] = useState(false)
  const [PlannedTab, setplannedTab] = useState(false);
  const [selectedCommodityGroup, setSelectedCommodityGroup] = useState("");
  const [findTrailersBasedOnCommodity, setFindTrailersBasedOnCommodity] = useState(false);
  const [driverMessageDetails, setDriverMessageDetails] = useState({});
  const [driverLoadUnloadFlag, setDriverLoadUnloadFlag] = useState(false)
  const [loadchange, setloadChange] = useState({})
  const [unloadChange, setunloadChange] = useState({})
  const [puloadflag, setpuloadflag] = useState([{
    driver_load_unload: "Y",
  },
  {
    driver_load_unload: "N"
  }])
  const [orderRefreshState,setOrderRefreshState]=useState(false);
  const [successResponseMssg, setSuccessResponseMssg ] = useState(null);
  const [failureResponseMssg, setFailureResponseMssg ] = useState(null);
  const [afterRefreshshowmsg,setAfterRefreshshowmsg]=useState(false)
  const [delloadflag, setdelloadflag] = useState([{
    driver_load_unload: "Y",
  },
  {
    driver_load_unload: "N"
  }])
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
 const [warningModalOpen, setWarningModalOpen] =  useState(null);
  const max_rest_duration_of_driver = 10 * 60 * 60 * 1000;

  useStyles();

  useEffect(()=>{
    let params=getDecodedURI(location.search);
     if(params?.view){
        setselectedTab(params.view)
        }else{
          setselectedTab('driver')
        }
     if(params?.mode){
      setplannedAcctualToggle(params?.mode==='planned' ? 'left' : 'center');
     }else{
      setplannedAcctualToggle('left');
     }   
   },[])

  useEffect(() => {
    if (tabSelected === "orders") {
      setOderTabClickC(prev => prev + 1)
    }
    if (tabSelected === "trailers") {
      setTrailerTabClickC(prev => prev + 1)
    }

  }, [tabSelected])

  const getTrailerTabData = async (movementData, driverData, trailerinfo, plannedAcctualToggle, trailerViewPageNumber) => {

    let BoardByTrailerFormatted = await getBoardByTrailerFormatted(
      movementData,
      driverData,
      trailerinfo.slice((trailerViewPageNumber - 1) * 25,
        trailerViewPageNumber * 25),
      plannedAcctualToggle
    );

    if (BoardByTrailerFormatted) {
      settarilerViewData(BoardByTrailerFormatted)
      return BoardByTrailerFormatted;
    }
  };

  useEffect(() => {
    refreshData(payloadForRefreshData, movementData, driverData, tarilerViewData, true)
  }, [trailerViewPageNumber]);

  const quickRefresh = async (mvmtData, plannedMode = true) => {
    setDriverViewPlannedMode(plannedMode);
    let BoardByDriverFormatted = await getBoardByDriverFormatted(
      mvmtData,
      allTrailers,
      driverData,
      plannedMode
    );

    setallPlanningBoardDriversView(BoardByDriverFormatted);
    setTimeout(() => {
      setpalnningBoardLoading(false);
    }, 100);

    return BoardByDriverFormatted;
  };

  useEffect(() => {
    if (selectedTab === "carrier") {
      setpalnningBoardLoading(true)
      let payload = {
        "date": selectedTimezoneDate,
        "terminals": [selectedPlanningProfile.terminal_id]
      }
      carrierviewmovementdata(payload)
    } else {
      let payload = {
        "date": selectedTimezoneDate,
        "terminals": [selectedPlanningProfile.terminal_id]
      };
      setpalnningBoardLoading(true);
      refreshDataFromAPI(payload);
      setpayloadForOrders({
        "date": selectedTimezoneDate,
        "terminals": [selectedPlanningProfile.terminal_id],
        "daysOut": daysout
      });
    }
  }, [
    selectedPlanningProfile,
    selectedTimezoneDate,
    selectedTab
  ]);

  useEffect(() => {
    setTrailerTabLoading(PlannerBoardLoadingPage)
  }, [PlannerBoardLoadingPage]);

  useEffect(() => {
    setpayloadForOrders({
      "date": selectedTimezoneDate,
      "terminals": [selectedPlanningProfile.terminal_id],
      "daysOut": daysout
    })
  }, [selectedPlanningProfile.terminal_id]);
  useEffect(()=>{
    if(successResponseMssg!==null){
      NotificationManager.success(successResponseMssg,'Success')
      setSuccessResponseMssg(null);
    }else if(failureResponseMssg!==null){
      if(failureResponseMssg?.split(" ")?.length>15){
        toast.error(failureResponseMssg, { autoClose: false, position: "top-center"});
      }else{
        toast.error(failureResponseMssg, { autoClose: false })
      }
      setFailureResponseMssg(null);
    } 
  },[afterRefreshshowmsg])

  const refreshDataFromAPI = async (payload) => {
    if (planningTimeout) clearTimeout(planningTimeout);
    setPlanningTimeout(
      setTimeout(async () => {
        let api_res = null;
        setTrailerTabLoading(true)

        if (payload?.terminals?.length > 0 && payload?.terminals[0]) {
          api_res = await Promise.all([getmovementstopbyterminalid(payload,selectedPlanningProfile?.terminal?.timezone), getDriverbyTerminalIDs(payload)]);
          setMovementData(api_res[0]);
          setDriverData(api_res[1]);
          setTrailerTabLoading(false);
          setAfterRefreshshowmsg(!afterRefreshshowmsg)
        }
        if (!api_res || !api_res[0] || !api_res[1]) return;

        setPayloadForRefreshData(payload);
        refreshData(payload, api_res[0], api_res[1]);
      }, 1500)
    );
  };

  useEffect(() => {
    setAllTrailersArray(allTrailers);
  }, [allTrailers.length]);

  const refreshData = async (searchparams, movementDataForRefresh, driData, noreload = false) => {
    setTrailerTabLoading(true);

    //Load Planning Board View From Service
    //Pull and Send The Correct Date Here
    if (searchparams?.terminals?.length > 0) {
      let tab_res = [allTrailers];
      console.log("tab_res", tab_res)

      if ((selectedTab !== 'trailer')) {
        const planningBoardDriverResponse = await getBoardByDriverFormatted(
          movementDataForRefresh,
          tab_res[0],
          [...driData].slice(
            (driverPageNumber - 1) * 25,
            driverPageNumber * 25
          ),
          driverViewPlannedMode
        ); //Move Into Drivers Logic

        setallPlanningBoardDriversView(planningBoardDriverResponse);
      }
      else {
        const trailerResponse = await gettrailerstabbydate(searchparams.terminals);
        const sortTrailerRes=trailerResponse?.sort((a,b)=>parseInt(a.trailer_id,36)-parseInt(b.trailer_id,36))
        setAllTrailers(sortTrailerRes);
        getTrailerTabData(movementDataForRefresh, driData, sortTrailerRes, driverViewPlannedMode, trailerViewPageNumber);
      }
      if (!noreload) {
        setOrderRefreshState(!orderRefreshState)
      }

      setpalnningBoardLoading(false)
      setPlannerBoardLoadingPage(false);
      setTrailerTabLoading(false);
    } else {
      setAllOrders([]);
      setAllTrailers([]);
      setPlannerBoardLoadingPage(false);
      setallPlanningBoardDriversView([]);
    }
  };

  const handelcallbackFromHeader = async (childdata) => {
    setisPlannerBoardClicked(childdata);
    setselectedTab("");
  };

  const parrentCallBackForTab = (data) => {
    setselectedTab(data);
  };

  useEffect(() => {
    refreshData(payloadForRefreshData, movementData, driverData, true);
  }, [driverPageNumber]);

  const checkAccess = () => {
    const permission = userData?.roles?.rolespermission
      .map(permit => {
        if (permit?.permissiondetail.code === "Planning") {
          setisEditPermission(permit?.can_edit);
          setViewPermission(permit?.can_view);
        }
      });
  }

  useEffect(() => {
    checkAccess()
  }, [userData]);

  // For calculating drop index times
  const calculateIndexTime = function (drop_data, selected_tz_date) {
    const miliInhour = 60 * 60 * 1000;
    const hour = drop_data.index / 4;
    const splitHourData = (hour - Math.floor(hour)) * 60;
    const dropIndexCalculatedTime = selected_tz_date + (parseInt(Math.floor(hour)) * miliInhour) + (splitHourData * 60 * 1000);

    let dropTimeObj = {
      dropTimeInISO: DateTime.fromMillis(parseInt(dropIndexCalculatedTime)).toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'"),
      dropTimeInMillis: dropIndexCalculatedTime,
    };

    return dropTimeObj;
  };

  async function scheduleSingleAndStackOrder(allMovementsOfSelectedDriver, dropTime, dragDataItems, driver, driver_load, driver_unload) {
    let lastEndShiftOfDriver = {};
    let diffBetweenDropIndexToLastEndShift = null;

    // Finding if any pre scheduled orders or not.
    if (allMovementsOfSelectedDriver.length > 0) {
      // All end shifts of driver
      const allEndShiftsOfDriver = allMovementsOfSelectedDriver.filter((mov) => mov.movementData.delivery.stop_type === "ES" && mov.stopData === null && mov.movementData.delivery.lm_location_id !== "UNKNOWN" && parseInt(mov?.movementData?.delivery?.planned_depart_utc) <= dropTime.dropTimeInMillis).sort((a, b) => a.planned_arrive_utc - b.planned_arrive_utc);

      // Getting exact last end shift before dropIndex
      lastEndShiftOfDriver = allEndShiftsOfDriver?.length > 0 ? allEndShiftsOfDriver[allEndShiftsOfDriver.length - 1] : null;

      // Difference between dropIndex to lastEndShift
      diffBetweenDropIndexToLastEndShift = lastEndShiftOfDriver ? Math.abs(dropTime.dropTimeInMillis - lastEndShiftOfDriver?.movementData.delivery.planned_depart_utc) : null;
    };

    if (diffBetweenDropIndexToLastEndShift === null || diffBetweenDropIndexToLastEndShift >= max_rest_duration_of_driver) {
      try {
        // If there is some pre scheduled orders and difference between drop index time and last end shift is less than 10hrs then order will be stacked.
        let payload = {
          movement_id: dragDataItems.Move_ID,
          planning_date: dropTime.dropTimeInISO,
          trailer_id: dragDataItems.Trailer_ID,
        };

        if (driver_load) {
          payload.loadFlag = driver_load;
        }

        if (driver_unload) {
          payload.unloadFlag = driver_unload;
        }

        // Calling scheduleSingleOrder API for scheduling an order.
        const data = await scheduleSingleOrder(driver.driverId, payload);
        if(data?.data?.status=='warning'){
          let str=data?.data?.errors?.map((el)=>`${el}`)?.join(",") ?? "";
          setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}  warningMasterData={data?.data?.options ?? []}  payload={payload} driver={driver} ApiName={'scheduleSingleOrder'} setSuccessResponseMssg={setSuccessResponseMssg} setFailureResponseMssg={setFailureResponseMssg} errormssg={str}/></div>)});
           return
        }else if(data?.data?.status=='failure'){
         let str = data?.data?.errors?.map((el) => `${el}`);
         setFailureResponseMssg(`${str[0].charAt(0).toUpperCase() + str[0].slice(1)}.`);
        }else{
       setSuccessResponseMssg("Order scheduled successfully.")
        }
      } catch (err) {
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          let str = err?.response?.data?.errors?.map((el) => `${el}`);
          setFailureResponseMssg(`${str[0].charAt(0).toUpperCase() + str[0].slice(1)}.`)
        } else {
          setFailureResponseMssg(`${err?.response?.data}` ?? "Order cannot be scheduled. Please try again.")
        }
        throw err;
      }
    } else {
      try {
        // If there is some pre scheduled orders and difference between drop index time to last end shift is less than 10hrs then order will be stacked.
        let payload = {
          movement_id: dragDataItems.Move_ID,
          trailer_id: dragDataItems.Trailer_ID
        };

        if (driver_load) {
          payload.loadFlag = driver_load;
        }

        if (driver_unload) {
          payload.driver_unload = driver_unload;
        }

        const planId = lastEndShiftOfDriver.movementData.delivery.plan_id;

        // Calling scheduleSingleOrder API for scheduling an order.

        const data = await scheduleStackedOrders(driver.driverId, planId, payload);
        if(data?.data?.status=='warning'){
          let str=data?.data?.errors?.map((el)=>`${el}`)?.join(",") ?? "";
          setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}  warningMasterData={data?.data?.options ?? []}  payload={payload} planId={planId} driver={driver} ApiName={'scheduleStackedOrders'} setSuccessResponseMssg={setSuccessResponseMssg} setFailureResponseMssg={setFailureResponseMssg} errormssg={str}/></div>)});
          return
       }else if(data?.data?.status=='failure'){
        let str = data?.data?.errors?.map((el) => `${el}`);
        setFailureResponseMssg(`${str[0].charAt(0).toUpperCase() + str[0].slice(1)}.`);
       }else{
        setSuccessResponseMssg("Order scheduled successfully.")
       }
      } catch (err) {
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          let str = err?.response?.data?.errors?.map((el) => `${el}`);
          setFailureResponseMssg(`${str[0].charAt(0).toUpperCase() + str[0].slice(1)}.`)
        } else {
          setFailureResponseMssg(`${err?.response?.data}` ?? "Order cannot be scheduled. Please try again.")
        }
        throw err;
      }
    }
    refreshDataFromAPI({
      "date": selectedTimezoneDate,
      "terminals": [selectedPlanningProfile.terminal_id]
    });
  };

  const requiredDataForDragAndDrop = (dropData, dragData, selectedTimezoneDate, PlanningBoardDriversResponse) => {
    const { drop_to, driver_id } = dropData;
    const dragDataItems = dragData.dragDataItem;
    const dropTime = calculateIndexTime(dropData, selectedTimezoneDate);
    const driver = PlanningBoardDriversResponse.filter((driver) => driver.driverId === driver_id)[0];
    const allMovementsOfSelectedDriver = driver.movements;

    return { drop_to, driver_id, dragDataItems, dropTime, driver, allMovementsOfSelectedDriver };
  };

  // New system's codes
  useEffect(() => {
    (async () => {
      if (isInitialRender) {
        setIsInitialRender(false);
        return;
      } else {
        if (isEditPermission) {
          if ((plannedOrUnplanned === "center" && dragData?.dragFrom === "orders") || dragData?.dragFrom === "trailers" || dragData?.dragFrom === "movements" || dragData?.dragFrom === "Shift") {
            if (!driverViewPlannedMode) {
              refreshData(payloadForRefreshData, movementData, driverData);
              return;
            };
            try {
              if (Object.keys(dropData).length > 0) {
                 // Required Variables
                 const { drop_to, driver_id, dragDataItems, dropTime, driver, allMovementsOfSelectedDriver } = requiredDataForDragAndDrop(dropData, dragData, selectedTimezoneDate, PlanningBoardDriversResponse);  

                if (dragData?.dragDataItem?.Driver_Load === 'M ' || dragData?.dragDataItem?.Driver_Unload === 'M') {
                  setDriverLoadUnloadFlag(true);
                  setloadChange({ driver_load_unload: dragData?.dragDataItem?.Driver_Load.trim() });
                  setunloadChange({ driver_load_unload: dragData?.dragDataItem?.Driver_Unload.trim() });
                } else {
                  // Orders drag and drop
                  if (dragData?.dragFrom === "orders" && drop_to === "planner_tick_container") {
                    await scheduleSingleAndStackOrder(allMovementsOfSelectedDriver, dropTime, dragDataItems, driver);
                  } else if (
                    dragData?.dragFrom === "Shift" &&
                    (drop_to === "planner_tick_container" ||
                      (dropData?.movement?.movementData?.delivery?.stop_type === "ES")) &&
                    dragDataItems?.movement?.movementData?.pickup?.stop_type === "SS"
                  ){
                    try{
                      let planId=dragDataItems?.movement?.movementData?.delivery?.plan_id
                    let response= await changePlanTimebystartshift(dropTime?.dropTimeInISO,planId);
                    if(response?.data?.status=='warning'){
                      let str=data?.data?.errors?.map((el)=>`${el}`)?.join(",") ?? "";
                      setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}  warningMasterData={response?.data?.options ?? []}  payload={dropTime?.dropTimeInISO} planId={planId} driver={driver} ApiName={'changePlanTimebystartshift'} setSuccessResponseMssg={setSuccessResponseMssg} setFailureResponseMssg={setFailureResponseMssg} errormssg={str}/></div>)});
                      return;
                   }else if(response?.data?.status=='failure' && response?.data?.errors && response?.data?.errors?.length>0 ){
                      let str=response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
                     setFailureResponseMssg(`${str}`);
                     }else{
                     setSuccessResponseMssg("Change Plan Time successfull."); 
                     }                   
                    }
                    catch(err){                      
                      if(err?.response?.data && err?.response?.data?.errors?.length>0){
                        let str=err?.response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
                       setFailureResponseMssg(`${str}`); 
                      }else{
                     setFailureResponseMssg("Something Went Wrong While Changing Plan Time."); 
                      }
                    }
                    refreshDataFromAPI({
                      "date": selectedTimezoneDate,
                      "terminals": [selectedPlanningProfile.terminal_id]
                    });
                  }

                  // Trailers drag and drop
                  if (dragData?.dragFrom === "trailers" && drop_to === "trailer_row") {
                    try {
                      // const trailerData = driver.trailers.find((trailer) => dropTime.dropTimeInMillis >= trailer.trailerStartTime && dropTime.dropTimeInMillis <= trailer.trailerEndTime);        

                      const allMovsBtwTrailerStartToEnd = allMovementsOfSelectedDriver.filter((mov) => mov.stopData === null && (mov.movementData.pickup.planned_arrive_utc >= dropData.trailer.trailerStartTime || mov.movementData.pickup.planned_depart_utc >= dropData.trailer.trailerStartTime) && (mov.movementData.delivery.planned_arrive_utc <= dropData.trailer.trailerEndTime || mov.movementData.delivery.planned_depart_utc <= dropData.trailer.trailerEndTime));

                      const planId = allMovsBtwTrailerStartToEnd[0].movementData.pickup.plan_id;

                      const allMovsIDs = allMovsBtwTrailerStartToEnd.map((move) => move.load_id);
                      const payload = {
                        movement_id: [...new Set(allMovsIDs)],
                        trailer_id: dragDataItems.Trailer_ID
                      };

                      const response = await addTrailer(driver_id, planId, payload);
                      if(response?.data?.status=='warning'){
                        let str=data?.data?.errors?.map((el)=>`${el}`)?.join(",") ?? "";
                        setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}  warningMasterData={response?.data?.options ?? []}  payload={payload} planId={planId} driver={driver} ApiName={'addTrailer'} setSuccessResponseMssg={setSuccessResponseMssg} setFailureResponseMssg={setFailureResponseMssg} errormssg={str}/></div>)});
                        return
                     }else if(response?.data?.status=='failure' && response?.data?.errors && response?.data?.errors?.length>0 ){
                      let str=response?.data?.errors?.map((el) => `${el}`);
                      setFailureResponseMssg(`${str[0].charAt(0).toUpperCase() + str[0].slice(1)}.`);
                     }else{
                      setSuccessResponseMssg("Trailer assigned successfully.");   
                     }             
                    } catch (err) {              
                      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
                        let str = err?.response?.data?.errors?.map((el) => `${el}`);
                       setFailureResponseMssg(`${str[0].charAt(0).toUpperCase() + str[0].slice(1)}.`);
                      } else {
                       setFailureResponseMssg(`${err?.response?.data}` ?? "Trailer cannot be assigned. Please try again.");
                      }
                      throw err;
                    }
                    refreshDataFromAPI({
                      "date": selectedTimezoneDate,
                      "terminals": [selectedPlanningProfile.terminal_id]
                    });
                  }

                  setDragData({});
                }
              }
            } catch (err) {
              console.trace(err);

              setDragData({});
              refreshDataFromAPI({
                "date": selectedTimezoneDate,
                "terminals": [selectedPlanningProfile.terminal_id]
              });
            }
          }

        } else {
          setDragData({});
          refreshData(payloadForRefreshData, movementData, driverData);
          return NotificationManager.info("Sorry,You don't have the necessary permissions to perform this action.", "Information", 3000)
        }
      };
    })();
  }, [dropData, datarefresh]);


  const setPlanningProfileHelperFunction = async(data,planning_profiles) => {
    let params=getDecodedURI(location.search);
    if(params?.planningProfile){
      let selectPlanningProfiles = planning_profiles?.find((el) => el?.terminal_id === params?.planningProfile);
      setselectedPlanningProfile(selectPlanningProfiles);
      setPlannerTimezone(getTimeZone(selectPlanningProfiles?.terminal?.timezone));

      if (params?.planningProfile && params?.driverId && params?.driverName) {
        setDriverMessageDetails({driverId: params?.driverId, driver_full_name: params?.driverName});
      }

      if(params?.selectedDate){
        setSelectedTimezoneDate(DateTime.fromISO(params?.selectedDate).setZone(getTimeZone(selectPlanningProfiles?.terminal?.timezone),{keepLocalTime : true}).startOf("day").toMillis());
        setPlannerTimezone(getTimeZone(selectPlanningProfiles.terminal.timezone));
      }else{
        if (data !== undefined) {
          setPlannerTimezone(getTimeZone(selectPlanningProfiles.terminal.timezone));
          setSelectedTimezoneDate(DateTime.now().setZone(getTimeZone(selectPlanningProfiles.terminal.timezone)).startOf("day").toMillis());
        }
      }
    }else{
    if (data !== undefined) {
      let params=getDecodedURI(location.search);
      params.planningProfile=data?.terminal_id
      history.push({pathname:location.pathname,search:`${getEncodedURI(params)}`});
      setselectedPlanningProfile(data);
      setPlannerTimezone(getTimeZone(data.terminal.timezone));
      setSelectedTimezoneDate(DateTime.now().setZone(getTimeZone(data.terminal.timezone)).startOf("day").toMillis());
    }
  }
  }

  const getPlanningProfileOfUser = async (planningProfilesData) => {
    let planningProfileData = await getPlanningProfileById(userData.id);
    setusersPlanningProfile(planningProfileData);

    let isCurrentlySelectedProfileEnable = false;

    if (userData?.userpreferences && userData?.userpreferences?.currentlySelectedPlanningProfile?.length > 0) {
      isCurrentlySelectedProfileEnable = userData?.userpreferences?.currentlySelectedPlanningProfile[0]?.terminal.use_planning;
    }

    if (userData.userpreferences &&
      userData?.userpreferences?.currentlySelectedPlanningProfile &&
      userData?.userpreferences?.currentlySelectedPlanningProfile.length > 0 && isCurrentlySelectedProfileEnable) {

      setPlanningProfileHelperFunction(userData?.userpreferences?.currentlySelectedPlanningProfile[0],planningProfilesData);
    } else {
      let assignedProfile = [...planningProfilesData].filter((profile) =>
        planningProfileData.map((assign) => assign.planningprofile_id)
          .includes(profile.id)
      );

      const unassignedProfile = planningProfilesData.filter(el => {
        return !planningProfileData.find(element => {
          return element.planningprofile_id === el.id;
        });
      });

      if (assignedProfile.length > 0) {
        const firstEnableAssignPlanningProfile = assignedProfile.filter((profile) => profile?.terminal?.use_planning === true);

        if (firstEnableAssignPlanningProfile.length > 0) {
          setPlanningProfileHelperFunction(firstEnableAssignPlanningProfile[0],planningProfilesData);
        } else {
          const firstEnableUnassignPlanningProfile = unassignedProfile.filter((profile) => profile?.terminal?.use_planning === true);

          setPlanningProfileHelperFunction(firstEnableUnassignPlanningProfile[0],planningProfilesData);
        }
      } else {
        const firstEnableUnassignPlanningProfile = unassignedProfile.filter((profile) => profile?.terminal?.use_planning === true);

        setPlanningProfileHelperFunction(firstEnableUnassignPlanningProfile[0],planningProfilesData);
      }
    }
  };

  const getTimeZone = (zone) => {
    let Timezone
    if (
      zone === "Eastern Standard Time" || zone === "America/New_York"
    ) {
      Timezone = "America/New_York";
    }
    if (
      zone === "Central Standard Time" || zone === "America/Chicago"
    ) {
      Timezone = "America/Chicago";
    }
    return Timezone
  };

  const getallPlanningProfile = async () => {
    let allPlanningProfile = await getAllPlanningProfiles();
    setallPlanningProfile(allPlanningProfile);
    return allPlanningProfile
  };

  const getAllPlanningProfilesFunction=async()=>{
    let planningProfiledata=await  getallPlanningProfile()
    getPlanningProfileOfUser(planningProfiledata)
  }

  useEffect(() => {
    if (userData.id) {
      getAllPlanningProfilesFunction()
    }
  }, [userData.id]);

  const carrierviewmovementdata = async (payload) => {
    const carrierviewmovementdaa = await getallcarrierview(payload);
    setcarrierviewData(carrierviewmovementdaa.data)
    setpalnningBoardLoading(false)
  };

  const createWashOrderHandler = () => {
    history.push({
      pathname: '/tankwash',
      state: { washDetails }
    })
  };

  const cancelWashCreateHandler = () => {
    setIsModalOpen(false)
    return NotificationManager.info("You Need to take the trailer somewhere else.")
  };

  const handlepuloadChange = (e, value) => {
    if (value) {
      setloadChange((prev) => ({
        ...prev,
        driver_load_unload: value.driver_load_unload,
      }));
    }
    // updateButtonState();
  };

  const handledelunloadChange = (e, value) => {

    if (value) {
      setunloadChange((prev) => ({
        ...prev,
        driver_load_unload: value.driver_load_unload,
      }));
    }
    // updateButtonState();
  };

  useEffect(() => {
    if (loadchange.driver_load_unload && unloadChange.driver_load_unload) {
      if (loadchange.driver_load_unload?.trim() !== 'M' && unloadChange.driver_load_unload?.trim() !== 'M') {
        setSaveButtonDisabled(false);
      }
    }
  }, [loadchange, unloadChange]);

  const updateloadunloaddata = async (e) => {
    let loadPayload = { loadFlag: loadchange.driver_load_unload };
    let unloadPayload = { unloadFlag: unloadChange.driver_load_unload };
 
    if (loadPayload.loadFlag === "M" || unloadPayload.unloadFlag === "M") {
      toast.error("load unload should not save with flag M", "Error");
 
    } else {
      const { drop_to, driver_id, dragDataItems, dropTime, driver, allMovementsOfSelectedDriver } = requiredDataForDragAndDrop(dropData, dragData, selectedTimezoneDate, PlanningBoardDriversResponse);
 
      let updateStopResponse = await scheduleSingleAndStackOrder(allMovementsOfSelectedDriver, dropTime, dragDataItems, driver, loadPayload.loadFlag, unloadPayload.unloadFlag);
 
 
      setDriverLoadUnloadFlag(false);
      setSaveButtonDisabled(true);
      setDragData({});
      refreshDataFromAPI({
        "date": selectedTimezoneDate,
        "terminals": [selectedPlanningProfile.terminal_id]
      });
    }
  };

  const closeModal = () => {
    setDriverLoadUnloadFlag(false);
    refreshDataFromAPI(
      {
        "date": selectedTimezoneDate,
        "terminals": [selectedPlanningProfile.terminal_id]
      }
    );
    setSaveButtonDisabled(true);
  };



  return (
    <DropContext.Provider
      value={{
        dragData,
        setDragData,
        dropData,
        setDropData,
        planningBoardData,
        setPlanningBoardData,
        payloadForOrders,
        quickRefresh,
        planningTimeout,
        setPlanningTimeout,
        refreshDataFromAPI,
        driverPageNumber,
        setDriverPageNumber,
        plannerTimezone,
        setPlannerTimezone,
        movementData,
        setMovementData,
        setdatarefresh,
        datarefresh,
        payloadForRefreshData,
        driverViewPlannedMode,
        selectedTimezoneDate,
        setSelectedTimezoneDate,
        trailerTabLoading,
        PlannerBoardLoadingPage,
        setPlannerBoardLoadingPage,
        plannedOrUnplanned,
        setPlannedOrUnplanned,
        selectedPlanningProfile,
        settrailerViewPageNumber,
        trailerViewPageNumber,
        selectedCommodityGroup,
        setSelectedCommodityGroup,
        findTrailersBasedOnCommodity,
        setFindTrailersBasedOnCommodity,
        settabSelected,
        driverMessageDetails,
        setDriverMessageDetails
      }}
    >
      <div>
        <div id='wrapper'>
          <Header
            userclicked={isPlannerBoardClicked}
            parentcallback={handelcallbackFromHeader}
          ></Header>
          <AppBar></AppBar>
          <div
            className={`content-page_yardcheck ${tabSelected != "" ? "planner_board_extra_bottom_space" : ""
              }`}
          >
            <div className='content'>
              <div className='container-fluid'>
                {isPlannerBoardClicked ? (
                  <></>
                ) : (
                  <>
                    <PlannerHeader
                      parrentCallBackForTab={parrentCallBackForTab}
                    />
                    <div className='row mt_30'>
                      <div className='col-xl-12 planner_inner_card'>
                        <div className='card card_shadow'>

                          {
                            !palnningBoardLoading ?
                              <PlannerBoardTopSection
                                allPlanningProfile={allPlanningProfile}
                                setallPlanningProfile={setallPlanningProfile}
                                usersPlanningProfile={usersPlanningProfile}
                                setusersPlanningProfile={setusersPlanningProfile}
                                setselectedPlanningProfile={setselectedPlanningProfile}
                                selectedPlanningProfile={selectedPlanningProfile}
                                selectedTimezoneDate={selectedTimezoneDate}
                                setSelectedTimezoneDate={setSelectedTimezoneDate}
                                setpalnningBoardLoading={setpalnningBoardLoading}
                                palnningBoardLoading={palnningBoardLoading}
                                setplannedAcctualToggle={setplannedAcctualToggle}
                                plannedAcctualToggle={plannedAcctualToggle}
                                plannerTimezone={plannerTimezone}
                                setPlannerTimezone={setPlannerTimezone}
                                isEditPermission={isEditPermission}
                              />
                              : ""
                          }

                          {selectedTab === "carrier" ? (
                            <>
                              {
                                !palnningBoardLoading ?
                                  <PlannerBoardCarrierViewsection
                                    hourArr={hourArr}
                                    carrierviewData={carrierviewData}
                                    carrierPageNumber={carrierPageNumber}
                                    setCarrierPageNumber={setCarrierPageNumber}
                                  />
                                  : <ProgressBar animated now={100} />
                              }
                            </>
                          ) : selectedTab === "trailer" ? (
                            <>
                              {
                                !palnningBoardLoading ?
                                  <PlannerBoardTrailersection
                                    hourArr={hourArr}
                                    dragset={dragset}
                                    allTrailers={allTrailers}
                                    selectedTimezoneDate={selectedTimezoneDate}
                                    tarilerViewData={tarilerViewData}
                                    settrailerViewPageNumber={settrailerViewPageNumber}
                                    trailerViewPageNumber={trailerViewPageNumber}

                                  />
                                  : <ProgressBar animated now={100} />
                              }
                            </>
                          ) : selectedTab === "order" ? (
                            <OrdersTab startdate={payloadForOrders.date} />
                          ) : (
                            <>
                              {
                                !palnningBoardLoading ?
                                  <PlannerBoardDriversection
                                    type='1'
                                    hourArr={hourArr}
                                    dragset={dragset}
                                    plannerDriverData={PlanningBoardDriversResponse}
                                    selectedTimezoneDate={selectedTimezoneDate}
                                    allTrailers={allTrailers}
                                    allDrivers={driverData}
                                    profile={profile}
                                    plannerBoardLoadingPage={PlannerBoardLoadingPage}
                                    setPlannerBoardLoadingPage={setPlannerBoardLoadingPage}
                                    isEditPermission={isEditPermission}
                                    selectedPlanningProfile={selectedPlanningProfile}
                                    plannerTimezone={plannerTimezone}
                                    setFailureResponseMssg={setFailureResponseMssg}
                                    setSuccessResponseMssg={setSuccessResponseMssg}

                                  />
                                  : <ProgressBar animated now={100} />
                              }
                              <div className='bottom_tab_wrapper'>
                                <div className='tab_button_section '>
                                  <button
                                    className={`tab_button ${tabSelected === "orders" ? "active_tab" : ""
                                      }`}
                                    onClick={(e) => {
                                      if (allOrders.length > 0) {
                                        settabSelected("orders")
                                      } else {
                                        settabSelected("orders")
                                      }
                                    }}
                                  >
                                    ORDERS
                                  </button>
                                  <button
                                    className={`tab_button ${tabSelected === "trailers" ? "active_tab" : ""
                                      }`}
                                    onClick={(e) => {
                                      if (allTrailers.length >= 0) {
                                        settabSelected("trailers");
                                      }
                                    }}
                                  >
                                    TRAILERS
                                  </button>
                                </div>
                                {palnningBoardLoading ? (
                                  <div className='oredertab_loader_nomargin'>
                                    <ProgressBar animated now={100} />
                                  </div>
                                ) : PlanningBoardDriversResponse.length === 0 ? (
                                  ""
                                ) : tabSelected === "" ? null : tabSelected ===
                                  "orders" ? (
                                  !daysOutLoader ? (
                                    <PlannerBoardOrdersTable
                                      settabSelected={settabSelected}
                                      startdate={payloadForOrders.date}
                                      tabSelected={tabSelected}
                                      oderTabClickC={oderTabClickC}
                                      payloadForOrders={payloadForOrders}
                                      selectedPlanningProfile={selectedPlanningProfile}
                                      setplannedTab={setplannedTab}
                                      PlannedTab={PlannedTab}
                                      refreshDataFromAPI={refreshDataFromAPI}
                                      orderRefreshState={orderRefreshState}
                                    />) : (
                                    <div className='oredertab_loader_nomargin'>
                                      <ProgressBar animated now={100} />
                                    </div>
                                  )
                                ) : allTrailersArray.length >= 0 ? (
                                    <PlannerBoardTrailersTable
                                      settabSelected={settabSelected}
                                      allTrailersArray={allTrailers}
                                      tabSelected={tabSelected}
                                      trailerTabClickC={trailerTabClickC}
                                      selectedTimezoneDate={selectedTimezoneDate}
                                      refreshDataFromAPI={refreshDataFromAPI}
                                      selectedPlanningProfile={selectedPlanningProfile}
                                    />
                                  ) : ""}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <NotificationContainer />
        </div>

        <Modal
          show={driverLoadUnloadFlag}
          onHide={() => closeModal()}
          // onShow={driverLoadUnloadFlag}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Update Load Unload
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(dragData?.dragDataItem?.Driver_Load === 'M ') && (
              <>
                <div className="col-md-12 six_pad">
                  <label for="typeHeadCommodityGroup">
                    PU Load Flag
                  </label>
                  <div className="meterial_autocomplete">

                    <Autocomplete
                      id="combo-box-demo"
                      options={puloadflag}
                      getOptionLabel={(option) => `${option.driver_load_unload}`}
                      value={loadchange}
                      onChange={handlepuloadChange}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Choose RedTag..."
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </div>
              </>
            )}
            {(dragData?.dragDataItem?.Driver_Unload === 'M ') && (
              <>
                <div className="col-md-12 six_pad">
                  <label for="typeHeadCommodityGroup">
                    Del UnLoad Flag
                  </label>
                  <div className="meterial_autocomplete">
                    <Autocomplete
                      id="combo-box-demo"
                      options={delloadflag}
                      getOptionLabel={(option) => `${option.driver_load_unload}`}
                      value={unloadChange}
                      onChange={handledelunloadChange}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Choose RedTag..."
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => closeModal()}>Cancel</Button>
            <Button onClick={updateloadunloaddata} disabled={saveButtonDisabled} >Save</Button>
          </Modal.Footer>

        </Modal>


        <Modal
          show={isModalOpen}
          onHide={() => setIsModalOpen(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Is Wash Required?</p>
          </Modal.Body>
          <Modal.Footer>

            <Button onClick={createWashOrderHandler}>Create Wash</Button>
            <Button onClick={cancelWashCreateHandler}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal> 

        {/* modal will open if api resonse is warning */}
        {
          warningModalOpen ? warningModalOpen : <></>
        }
      </div>
    </DropContext.Provider>
  );
};

export default Planner;
