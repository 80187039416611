

// Application Specific
import Trailer from '../models/trailerModel';
import Driver from '../models/driverModel';
import { BaseConfig } from "../baseConfig";
import * as fflate from 'fflate';

import axios from 'axios';

  export const getTrailer = async(trailer_id) => {
    const trailer = new Trailer();
    let trailerObject;
    try {
      const url = BaseConfig.ApiEndPoint + "/trailers/" + trailer_id;
      const trailerApiData = await axios.get(url);
      trailerObject = trailerApiData.data.data[0];
    } catch (err) {
      return Promise.resolve(
        "There is a problem on retrieving trailer data. Please try again!"
      );
    }

    return Promise.resolve(trailerObject);
  }

  export const getAllTrailers = async() => {
    const trailer = new Trailer();
    let trailerObject = [];
    try {
      const url = BaseConfig.ApiEndPoint + "/trailers";
        const trailerApiData = await axios.get(url);
        trailerObject = trailerApiData.data.data.map((data) =>
          trailer.parseApiTrailerObject(data)
        );
    } catch (err) {
      return Promise.resolve(
        "There is a problem on retrieving trailer data. Please try again!"
      );
    }

    return await Promise.resolve(trailerObject);
  }

  export const getAllActiveTrailers = async() => {
    const trailer = new Trailer();
    let trailerObject = [];
    try {
      const url = BaseConfig.ApiEndPoint + "/activetrailers";
        const trailerApiData = await axios.get(url);
        trailerObject = trailerApiData.data.data.map((data) =>
          trailer.parseApiTrailerObject(data)
        );
    } catch (err) {
      return Promise.resolve(
        "There is a problem on retrieving trailer data. Please try again!"
      );
    }

    return await Promise.resolve(trailerObject);
  }

  export const getAllTrailersbyTerminalID = async(filterData) => {
    const trailer = new Trailer();
    let trailerObject = [];
    let Ids = filterData?.terminal_id?.filter(id => id)
    try {
      let data = {
        terminal_id: Ids,
      };
      const url = BaseConfig.ApiEndPoint + "/gettrailerbyterminalid";
        const trailerApiData = await axios.post(url, data);
        trailerObject = trailerApiData.data.data.map((data) =>
          trailer.parseApiTrailerObject(data)
        );
    } catch (err) {
      return Promise.resolve(
        "There is a problem on retrieving trailer data. Please try again!"
      );
    }

    return await Promise.resolve(trailerObject);
  }
  export const getTrailersInPlanner = async(filterData) => {
    const trailer = new Trailer();
    let trailerObject = [];
    try {
      const url = BaseConfig.ApiEndPoint + "/gettrailerbyterminalid";
        const trailerApiData = await axios.post(url, data);
        trailerObject = trailerApiData.data.data.map((data) =>
          trailer.parseApiTrailerObject(data)
        );
    } catch (err) {
      return Promise.resolve(
        "There is a problem on retrieving trailer data. Please try again!"
      );
    }

    return await Promise.resolve(trailerObject);
  }
////Trailers Tab in Planner
export const gettrailerstabbydate = async (filterData) => {
  const trailer = new Trailer();
  let trailerObject = [];
  try {
    let data = {
      terminal_id: filterData,
    };
    // API object call.
    const url = BaseConfig.ApiEndPoint + "/gettrailerstabdata";
    const trailerApiData = await axios.post(url, data);
    // trailerObject = trailerApiData.data.data;

    //Uncompress API Call//
    const strData = atob(trailerApiData.data.data);
    // Convert binary string to character-number array
    const charData = strData.split("").map((x) => { return x.charCodeAt(0); });
    // Turn number array into byte-array
    const binData = new Uint8Array(charData);
    // Use BaseConfig Code to Decode the String

    //Uncompress API Call//
    let trailersApiDataUnCompressed = fflate.unzlibSync(binData);
    let trailersApiDataUnCompressedString = fflate.strFromU8(trailersApiDataUnCompressed)
    let trailerIntermediateObject = JSON.parse(trailersApiDataUnCompressedString)
   
    trailerObject=trailerIntermediateObject.data

  } catch (error) {
    return Promise.resolve(
      "There is a problem on retrieving trailer data. Please try again!"
    );
  }
  return Promise.resolve(trailerObject);
};
  ////////end


  export const getTrailersTabData = async (payload, page, limit, sortData) => {
    const pageNumber = page || 1;
    const limitCount = limit || 25;
    const sortDataArr = sortData;

    let data = {
      searchData: payload,
      shortData: sortDataArr
    }
   
    try {
      // API object call.
      const url = `${BaseConfig.ApiEndPoint}/gettrailerstabdatanew?page=${pageNumber}&limit=${limitCount}`;
      const response = await axios.post(url, data);
   
      return response.data.data;
   
    } catch (error) {
      throw error;
    }
  };


  export const getTrailerHistorybyTrailerid = async(trailer_id) => {
    let trailerHistoryData = [];
    try {
      // API object call.
      let data = {
        trailer_id: trailer_id,
      };
      const url = BaseConfig.ApiEndPoint + "/gettrailerhistorybytrailerid";
      try {
        trailerHistoryData = await axios.post(url, data);
      } catch (error) {}
    } catch (err) {
      return Promise.resolve(
        "There is a problem on retrieving the Trailer History. Please try again!"
      );
    }
    return await Promise.resolve(trailerHistoryData.data.data);
  }
  export const getLatestTrailerhistorybytrailerid = async(trailer_id) => {
    let trailerHistoryData = [];
    try {
      // API object call.
      let data = {
        trailer_id: trailer_id,
      };
      const url = BaseConfig.ApiEndPoint + "/getlatesttrailerhistorybytrailerid";
      try {
        trailerHistoryData = await axios.post(url, data);
      } catch (error) {}
    } catch (err) {
      return Promise.resolve(
        "There is a problem on retrieving the Trailer History. Please try again!"
      );
    }getLatestTrailerhistorybytrailerid
    return await Promise.resolve(trailerHistoryData.data.data);
  }

  export const getTrailerhistorybytrailerandOrderId = async(trailer_id,order_id) => {
    let trailerHistoryData = [];
    try {
      // API object call.
      let data = {
        trailer_id: trailer_id,
        order_id:order_id
      };
      const url = BaseConfig.ApiEndPoint + "/gettrailerhistorybytrailerandorderId";
      try {
        trailerHistoryData = await axios.post(url, data);
      } catch (error) {}
    } catch (err) {
      return Promise.resolve(
        "There is a problem on retrieving the Trailer History. Please try again!"
      );
    }getTrailerhistorybytrailerandOrderId
    return await Promise.resolve(trailerHistoryData.data.data);
  }

  export const gettrailerSpecificationsbytrailerid = async(trailer_id) => {
    let trailerSpecificationData = [];
    try {
      // API object call.
      let data = {
        trailer_id: trailer_id,
      };
      const url = BaseConfig.ApiEndPoint + "/gettrailerSpecificationsbytrailerid";
      try {
        trailerSpecificationData = await axios.post(url, data);
      } catch (error) {
      }
    } catch (err) {
      return Promise.resolve(
        "There is a problem on retrieving the Trailer Specifications. Please try again!"
      );
    }
    return await Promise.resolve(trailerSpecificationData.data.data);
  }

  

  export const getTrailersByTerminalId = async(filterData) => {
    const trailer = new Trailer();
    let trailerObject = [];
    try {
      let data = {
        terminal_id: filterData,
      };
      const url = BaseConfig.ApiEndPoint + "/gettrailerbyterminalid";
        const trailerApiData = await axios.post(url, data);
        trailerObject = trailerApiData.data.data.map((data) =>
          trailer.parseApiTrailerObject(data)
        );
    } catch (err) {
      return Promise.resolve(
        "There is a problem on retrieving trailer data. Please try again!"
      );
    }

    return await Promise.resolve(trailerObject);
  }