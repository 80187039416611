import React, { useState, useEffect, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Calendar } from "@progress/kendo-react-dateinputs";
import { ContextData } from "../../components/appsession";
import {
  getPlanningProfileById,
  getAllPlanningProfiles,
} from "../../services/userService";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import { DropContext } from "../../pages/planner/planner";
import { FormControl, InputLabel, Select } from "@mui/material";
import { useLocation,useHistory } from "react-router-dom";
import { getDecodedURI, getEncodedURI } from "../../utils/urlEncodeandDecode";



const PlannerHeader = (props) => {
  const [userData, setuserData] = useContext(ContextData);
  const [commodityTypeDrpdownClicked, setcommodityTypeDrpdownClicked] =
    useState(false);
  const [terminalDrpdownClicked, setterminalDrpdownClicked] = useState(false);
  const [calenderDrpdownClicked, setcalenderDrpdownClicked] = useState(false);
  const [selectedCommodityTypeOptions, setSelectedCommodityTypeOptions] =
    useState([]);
  const [searchData, setSearchData] = useState("");
  const location= useLocation();
  const history = useHistory();





  const [tabSelected, setTabSelected] = useState({
    planner: true,
    driver: true,
    trailer: false,
    carrier: false,
  });

  useEffect(()=>{
    let params=getDecodedURI(location.search);
    let selectedTabFRomParams=tabSelected
     if(params?.view){
       for(let key in tabSelected){
        if(key===params?.view){
          selectedTabFRomParams[key]=true
        }else if(key!=='planner'){
          selectedTabFRomParams[key]=false
        }
       }
       setTabSelected(selectedTabFRomParams)
        }
   },[])

  const tabClickHandler = (tabname) => {
    if (tabname === "driver") {
      setTabSelected({
        driver: true,
        trailer: false,
        carrier: false,
      });
    }
    if (tabname === "trailer") {
      setTabSelected({
        driver: false,
        trailer: true,
        carrier: false,
      });
    }
    if (tabname === "carrier") {
      setTabSelected({
        driver: false,
        trailer: false,
        carrier: true,
      });
    }
    let params=getDecodedURI(location.search);
    params.view=tabname
 history.push({pathname:location.pathname,search:`${getEncodedURI(params)}`});
    props.parrentCallBackForTab(tabname);
  };

  const searchHandler = (filterData) => {
    // props.parentCallBackForPlannerHeader(filterData);
  };

  const SearchHandlerInput = (event) => {
    setSearchData(event.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchHandler(e);
    }
  };
 

  return (
    <div className='row df mt_30'>
      <div className='tabs'>
        <input
          type='radio'
          name='tabs'
          id='tabone'
          checked={tabSelected.planner}
          onChange={() => tabClickHandler("planner")}
        />
        <label htmlFor='tabone'>Planning Board</label>
        <div className='tab planner_top'>
          <div className='card-body'>
            <div className='row top_adjust'>
              <div className='df'>
                <div
                  className={
                    tabSelected.driver
                      ? "panner_top_tab panner_top_tab_active "
                      : "panner_top_tab"
                  }
                  onClick={() => tabClickHandler("driver")}
                >
                  Driver View
                </div>
                <div
                  className={
                    tabSelected.trailer
                      ? "panner_top_tab ml_25 panner_top_tab_active"
                      : "panner_top_tab ml_25"
                  }
                  onClick={() => tabClickHandler("trailer")}
                >
                  Trailer View
                </div>
                <div
                  className={
                    tabSelected.carrier
                      ? "panner_top_tab ml_25 panner_top_tab_active"
                      : "panner_top_tab ml_25"
                  }
                  onClick={() => tabClickHandler("carrier")}
                >
                  Carrier View
                </div>
              </div>
            </div>
            <div className='row top_adjust planneretasection'>
              <div className='input-group w-30 mt_26'>
                <input
                  type='search'
                  className='form-control place_back'
                  placeholder='Search...'
                  id='top-search'
                  style={{ zIndex: "1" }}
                  onChange={(e) => SearchHandlerInput(e)}
                  onKeyDown={handleKeyPress}
                />

                <button
                  className='btn input-group-text search_btn'
                  type='submit'
                  onClick={(e) => searchHandler(e)}
                >
                  <i
                    className='fa fa-search f_color_white'
                    aria-hidden='true'
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default PlannerHeader;
