import React, { useState, useEffect, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { getUserFilter } from "../../services/appFilterService";
import { Calendar } from "@progress/kendo-react-dateinputs";
import { ContextData } from "../../components/appsession";

import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { getPlanningProfileById, getAllPlanningProfiles } from "../../services/userService";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import { useLocation,useHistory } from "react-router-dom";
import { getDecodedURI, getEncodedURI } from "../../utils/urlEncodeandDecode";


const PlannerBoardHeader = (props) => {
    const { calenderDropdownSelectHandler, selectedDate, setcalenderDrpdownClicked, calenderDrpdownClicked } = props
    const [userData, setuserData] = useContext(ContextData);

    // const [calenderDrpdownClicked, setcalenderDrpdownClicked] = useState(false);

    const [searchData, setSearchData] = useState('')
    const [planningProfile, setPlanningProfile] = useState([])
    const [planningProfileOption, setPlanningProfileOption] = useState([]);
    const [selectedPlanningProfileOptions, setselectedPlanningProfileOptions] = useState([]);
    const [assignedPlanningProfile, setAssignedPlanningProfile] = useState([]);
    const location= useLocation();
    const history = useHistory();

    useEffect(() => {
        let profiles = []
        let selected = []
        planningProfile.map((profile) => {
            let profileObj = {
                label: profile.name,
                value: profile.terminal_id

            }
            profiles.push(profileObj)
        })
        setPlanningProfileOption(profiles)
        // setselectedPlanningProfileOptions(profiles)
        let selectedProfile = planningProfile.filter(profile =>
            assignedPlanningProfile.map(assign => assign.planningprofile_id).includes(profile.id))
        selectedProfile.map((select) => {
            let selectedObj = {
                label: select.name,
                value: select.terminal_id
            }
            selected.push(selectedObj)
        })

        setselectedPlanningProfileOptions(selected)
    }, [planningProfile.length, assignedPlanningProfile.length])

    const [tabSelected, setTabSelected] = useState({
        planner: true,
        driver: true,
        trailer: false,
        carrier: false,
    });

    useEffect(()=>{
        let params=getDecodedURI(location.search);
        let selectedTabFRomParams=tabSelected
         if(params?.view){
           for(let key in tabSelected){
            if(key===params?.view){
              selectedTabFRomParams[key]=true
            }else if(key!=='planner'){
              selectedTabFRomParams[key]=false
            }
           }
           setTabSelected(selectedTabFRomParams)
            }
       },[])

    const tabClickHandler = (tabname) => {
        if (tabname === "driver") {
            setTabSelected({

                driver: true,
                trailer: false,
                carrier: false,
            });
        }
        if (tabname === "trailer") {
            setTabSelected({
                driver: false,
                trailer: true,
                carrier: false,
            });
        }
        if (tabname === "carrier") {
            setTabSelected({
                driver: false,
                trailer: false,
                carrier: true,
            });
        }
          let params=getDecodedURI(location.search);
        params.view=tabname
     history.push({pathname:location.pathname,search:`${getEncodedURI(params)}`});
        props.parrentCallBackForTab(tabname);
    };

    const searchHandler = () => {
        //props.parentCallBackForPlannerHeader(filterData);
    };

    const SearchHandlerInput = (event) => {
        setSearchData(event.target.value);
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            searchHandler();
        }
    };

    useEffect(async () => {
        if (userData !== undefined) {
            const planningProfileData = await getPlanningProfileById(userData.id);
            setAssignedPlanningProfile(planningProfileData);
            const allPlanningProfile = await getAllPlanningProfiles();
            setPlanningProfile(allPlanningProfile)
        }
    }, [userData]);

    let filterData = {
        terminal: selectedPlanningProfileOptions.map((o) => o.value),
        date: selectedDate,
        search: searchData,
    }

    useEffect(() => {
        // props.parentCallBackForPlannerHeader(filterData);
    }, [

        selectedPlanningProfileOptions,
        // selectedDate,
    ]);



    return (
        <div className="row df mt_30">
            <div className="tabs">
                <input
                    type="radio"
                    name="tabs"
                    id="tabone"
                    checked={tabSelected.planner}
                    onChange={() => tabClickHandler("planner")}
                />
                <label htmlFor="tabone">Planning Board</label>
                <div className="tab">
                    <div className="card-body">
                        <div className="row top_adjust">
                            <div className="df">
                                <div
                                    className={
                                        tabSelected.driver
                                            ? "panner_top_tab panner_top_tab_active "
                                            : "panner_top_tab"
                                    }
                                    onClick={() => tabClickHandler("driver")}
                                >
                                    Driver View
                                </div>
                                <div
                                    className={
                                        tabSelected.trailer
                                            ? "panner_top_tab ml_25 panner_top_tab_active"
                                            : "panner_top_tab ml_25"
                                    }
                                    onClick={() => tabClickHandler("trailer")}
                                >
                                    Trailer View
                                </div>
                                <div
                                    className={
                                        tabSelected.carrier
                                            ? "panner_top_tab ml_25 panner_top_tab_active"
                                            : "panner_top_tab ml_25"
                                    }
                                    onClick={() => tabClickHandler("carrier")}
                                >
                                    Carrier View
                                </div>
                            </div>
                        </div>
                        <div className="row top_adjust df">
                            <div className="input-group w-30 mt_26">
                                <input
                                    type="search"
                                    className="form-control place_back"
                                    placeholder="Search..."
                                    id="top-search"
                                    style={{ zIndex: "1" }}
                                    onChange={(e) => SearchHandlerInput(e)}
                                    onKeyPress={handleKeyPress}
                                />

                                <button
                                    className="btn input-group-text search_btn"
                                    type="submit"
                                    onClick={(e) => searchHandler(e)}
                                >
                                    <i
                                        className="fa fa-search f_color_white"
                                        aria-hidden="true"
                                    ></i>
                                </button>
                            </div>
                            <>
                                <div className="multiselect ml_30 w-25 mt_26 pr terminal_drop_fixed">

                                    <div className="dropdownadjust">
                                        <Picky
                                            options={planningProfileOption}
                                            labelKey="label"
                                            valueKey="value"
                                            placeholder="Planning Profile"
                                            multiple={true}
                                            includeFilter
                                            includeSelectAll
                                            value={selectedPlanningProfileOptions}
                                            numberDisplayed={1}
                                            manySelectedPlaceholder="Planning Profile : %s "
                                            allSelectedPlaceholder="Planning Profile : All"
                                            onChange={setselectedPlanningProfileOptions}
                                            renderSelectAll={({
                                                filtered,
                                                tabIndex,
                                                allSelected,
                                                toggleSelectAll,
                                                multiple,
                                            }) => {
                                                // Don't show if single select or items have been filtered.
                                                if (multiple && !filtered) {
                                                    return (
                                                        <div className="select_multiple_container">
                                                            <div
                                                                tabIndex={tabIndex}
                                                                role="option"

                                                                className="option"
                                                                onClick={toggleSelectAll}
                                                                onKeyPress={toggleSelectAll}
                                                            >
                                                                <button className="selectall_btn">
                                                                    Select all
                                                                </button>
                                                            </div>
                                                            <div
                                                                tabIndex={tabIndex}
                                                                role="option"
                                                                className="option"
                                                                onClick={() => {
                                                                    setselectedPlanningProfileOptions([]);
                                                                }}
                                                                onKeyPress={toggleSelectAll}
                                                            >
                                                                <button className="selectall_btn">
                                                                    Clear All
                                                                </button>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <>
                                </>

                            </>

                            <div className="vl mt_32"></div>
                            <div className="multiselect ml_30 mt_32 pr date_width_adjust">
                                <div
                                    className="selectBox"
                                    onClick={() =>
                                        setcalenderDrpdownClicked(!calenderDrpdownClicked)
                                    }
                                >
                                    <select>
                                        <option>Calendar</option>
                                    </select>
                                    <div className="overSelect"></div>
                                </div>
                                {calenderDrpdownClicked ? (
                                    <div className="multiselect_select">
                                        <Calendar
                                            onChange={calenderDropdownSelectHandler}
                                            value={selectedDate}
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlannerBoardHeader;