import React, { useEffect, useState } from "react";
import {getAllTrailers, gettrailerstabbydate} from "../../services/trailerService";
import TrailerspageTable from "../TrailerspageTable/TrailerspageTable";


const TrailersTab = (props) => {

    const [tabSelected, setTabSelected] = useState("");
    const [allTrailers, setAllTrailers] = useState([]);
    const [trailerLoading,setisTrilerLoading]=useState(false)

    useEffect(async () => {
        setisTrilerLoading(true)
        try {
            await gettrailerstabbydate([props.terminalid]).then((trailers) => {
                setAllTrailers(trailers);
            });
        } catch (error) {
            
        }
        setisTrilerLoading(false)
    }, [props.terminalid]);

    return (
        <div>
            <TrailerspageTable allTrailersArray={allTrailers}trailerLoading={trailerLoading} />
        </div>
    );
};

export default TrailersTab;
