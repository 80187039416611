import React, { useState, useEffect, useContext, useCallback } from 'react';
import "./plannerBoardTrailersTable.css";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { IntlProvider, load, LocalizationProvider, loadMessages, IntlService } from '@progress/kendo-react-intl';
import likelySubtags from './dt/likelySubtags.json';
import currencyData from './dt/currencyData.json';
import weekData from './dt/weekData.json';
import numbers from './dt/numbers.json';
import currencies from './dt/currencies.json';
import caGregorian from './dt/ca-gregorian.json';
import dateFields from './dt/dateFields.json';
import timeZoneNames from './dt/timeZoneNames.json';
import '@progress/kendo-theme-default/dist/all.css';
load(likelySubtags, currencyData, weekData, numbers, currencies, caGregorian, dateFields, timeZoneNames);
import esMessages from './dt/es.json';
loadMessages(esMessages, 'es-ES');
import { ContextData } from '../../components/appsession';
import { DropContext } from '../../pages/planner/planner';
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "@mui/material";
import getRowDraggable from "../rowDraggable/rowDraggable";
import { updateSelectedPlanningProfileOfUser } from "../../services/userService";
import { getTrailersTabData } from '../../services/trailerService';
import debounce from 'lodash/debounce';
import filterOperators from '../../utils/filterOperators';


const PlannerBoardTrailersTable = (props) => {
  const { settabSelected, tabSelected, trailerTabClickC } = props;
  const [userData, setuserData, getUserInfoMSGraph, userUniqueId] = useContext(ContextData);
  const { selectedPlanningProfile, selectedCommodityGroup, setSelectedCommodityGroup, findTrailersBasedOnCommodity, setFindTrailersBasedOnCommodity, dragData, setDragData, } = useContext(DropContext);
  const [columnorder, setcolumnorder] = useState([]);
  const [columnorderfordetail, setcolumnorderfordetail] = useState([])
  const [totalResult, setTotalResult] = useState(0);
  const [searchTerms, setSearchTerms] = useState(JSON.parse(localStorage.getItem('userpreferenceTrailerstab'))?.filter || [{
    "field": "Terminal",
    "operator": "contains",
    "value": selectedPlanningProfile.terminal_id
  }]);
  const [takeNumberOfResult, setTakeNumberOfResult] = useState(25);
  const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [dataResult, setDataResult] = useState([]);
  const [sortData, setSortData] = useState([{ field: "Trailer_ID", dir: "asc" }]);

  const locales = [{
    language: 'en-US',
    locale: 'en'
  }, {
    language: 'es-ES',
    locale: 'es'
  }];

  const [currentLocale, setCurrentLocale] = React.useState(locales[0]);

  console.log(selectedCommodityGroup, "selectedCommodityGroup");

  const [dataState, setDataState] = useState({
    skip: skipNumberOfResult,
    take: takeNumberOfResult,
    filter: {
      logic: "and",
      filters: JSON.parse(localStorage.getItem('userpreferenceTrailerstab'))?.filter || [{
        "field": "Terminal",
        "operator": "contains",
        "value": selectedPlanningProfile.terminal_id
      }],
    },
    sort: sortData,
  });

  let columns = [
    { id: 0, name: "Status", title: "Status" },
    { id: 1, name: "Trailer_ID", title: "Trailer ID" },
    { id: 2, name: "Commodity_Group", title: "Commodity Group" },
    { id: 3, name: "Terminal", title: "Terminal" },
    { id: 4, name: "Last_Order", title: "Last Order" },
    { id: 5, name: "Last_Commodity", title: "Last Commodity" },
    { id: 6, name: "Last_Product", title: "Last Product" },
    { id: 7, name: "Loads_Since_Last_Wash", title: "Loads Since Last Wash" },
    { id: 8, name: "Loads_to_Next_Wash", title: "Loads to Next Wash" },
    { id: 9, name: "Days_to_Next_Wash", title: "Days to Next Wash" },
    { id: 10, name: "Days_Since_Last_Wash", title: "Days Since Last Wash" },
    { id: 11, name: "Last_Washed", title: "Last Washed" },
    { id: 12, name: "Last_Wash_W/O", title: "Last Wash W/O" },
    { id: 13, name: "PM_Due_Date", title: "PM Due Date" },
    { id: 14, name: "Make", title: "Make" },
    { id: 15, name: "Model", title: "Model" },
    { id: 16, name: "Year", title: "Year" },
    { id: 17, name: "Length", title: "Length" },
    { id: 18, name: "Width", title: "Width" },
    { id: 19, name: "Height", title: "Height" },
    { id: 20, name: "Volume", title: "Volume" },
    { id: 21, name: "Tare_Weight", title: "Tare Weight" },
    { id: 22, name: "Shipper_Pool", title: "Shipper Pool" },
    { id: 23, name: "Dedicated", title: "Dedicated" },
    { id: 24, name: "License_State", title: "License State" },
    { id: 25, name: "License_Plate", title: "License Plate" },
    { id: 26, name: "QR_Code", title: "QR Code" },
  ];  

  useEffect(async () => {
    if (trailerTabClickC >= 0 && tabSelected === "trailers") {
      if (userData?.userpreferences?.trailertabsequence) {
        setcolumnorder(userData?.userpreferences?.trailertabsequence);
        setcolumnorderfordetail(userData?.userpreferences?.trailertabsequence)
      }
      else {
        setcolumnorder(columns);
        setcolumnorderfordetail(columns)
      }
    }
  }, [tabSelected,dataResult]);


  const dataStateChange = (event) => {
    const sortTerms = event?.dataState?.sort || [];
    const sorts = sortTerms && sortTerms.length > 0 ? sortTerms.map(({ field, dir }) => ({ field, dir })) : [];
    setSortData(sorts);
  
    const filters = event?.dataState?.filter?.filters || [];
    const previousCommodityGroup = dataState?.filter?.filters.find((el) => el.field === "Commodity_Group");
    const currentCommodityGroup = filters.find((el) => el.field === "Commodity_Group");

    if (previousCommodityGroup?.value !== currentCommodityGroup?.value && findTrailersBasedOnCommodity) {
      setFindTrailersBasedOnCommodity(false);
    }

    const terms = filters && filters.length > 0 ? filters.map(({ field, value, operator }) => ({ field, value, operator })) : [];
    setSearchTerms(terms);

    const take = event?.dataState?.take;
    const skip = event?.dataState?.skip;
    const page = Math.floor(skip / take) + 1;
    setTakeNumberOfResult(take);
    setSkipNumberOfResult(skip);
    setPageNumber(page);
    setDataState(event.dataState);
    let payload = {
      filter: filters ? filters.filter((e) => Object.keys(e).length !== 0) : [],
    }
    localStorage.setItem('userpreferenceTrailerstab', JSON.stringify(payload));
  };

  const debouncedSearch = useCallback(
    debounce(async (newsearchTerms, page, size, sort) => {
      setIsDataLoaded(false);

      let arr = newsearchTerms?.map((el) => {
        let obj = {};
        obj[el?.field] = el?.value
        obj.operator = el?.operator;
        return obj
      });


      const search = await getTrailersTabData(arr, page, size, sort);

      if (search.data.length <= 0) {
        setDataResult(search.data);
        setTotalResult(Number(search?.count[0]?.count));
      }
      if (search.data.length > 0) {
        setDataResult(search.data);
      }
      setTotalResult(Number(search?.count[0]?.count));
      setIsDataLoaded(true);
    }, 1000),
    []
  );

  useEffect(() => {    
    if (findTrailersBasedOnCommodity ) {
      const payload = {
        field: "Commodity_Group",
        value: selectedCommodityGroup,
        operator: "eq"
      }

      let newsearchTerms = [];      
      newsearchTerms.push(payload);

      if (searchTerms.length > 0) {
        searchTerms.forEach((el) => {
          if (el.field !== payload.field && el.value !== payload.value && el.operator !== payload.operator) {
            newsearchTerms.push(el);  
          }
        })
      }

      setDataState({
        skip: skipNumberOfResult,
        take: takeNumberOfResult,
        filter: {
          logic: "and",
          filters: newsearchTerms
        },
        sort: sortData            
      });

      debouncedSearch(newsearchTerms, pageNumber, takeNumberOfResult, sortData);
    } else {
      if (searchTerms.length <= 0) {

        let newsearchTerms = [];
        debouncedSearch(newsearchTerms, pageNumber, takeNumberOfResult, sortData);
      }

      // it will run when there is filter in kendo
      if (searchTerms.length > 0) {
        let searchTerm;
        searchTerm = searchTerms.filter(item => item.value !== "");    

        setDataState({
          skip: skipNumberOfResult,
          take: takeNumberOfResult,
          filter: {
            logic: "and",
            filters: searchTerm
          },
          sort: sortData  
        })
        debouncedSearch(searchTerm, pageNumber, takeNumberOfResult, sortData);
      }
    }

  }, [searchTerms, pageNumber, takeNumberOfResult, findTrailersBasedOnCommodity, sortData, selectedCommodityGroup]);

  const columnReorderChange = async (event) => {
    const newTrailers =[];
     event.target._columns.forEach((it) => {
      if(it.field!=="expanded"){
        newTrailers.push({
        id: it.index,
        name: it.field,
        title: it.title,
        width: it.width,
      });
    }
    });

    // Storing selected planning profile in user's table for user
    let payload = {
      id: userData.id,
      userpreferences: {}
    };

    // If userpreferences has already some data
    if (userData.userpreferences) {
      if (userData.userpreferences.orderstabsequence) {
        payload.userpreferences.orderstabsequence = userData.userpreferences.orderstabsequence;
      }

      // If userpreferences has already trailertabsequence data and we are changing sequence.
      if (userData.userpreferences.trailertabsequence) {
        payload.userpreferences.trailertabsequence = newTrailers;
      } else {
        // If userpreferences has already some data, but not trailertabsequence data.
        payload.userpreferences.trailertabsequence = newTrailers;
      }

      if (userData.userpreferences.currentlySelectedPlanningProfile) {
        payload.userpreferences.currentlySelectedPlanningProfile = userData.userpreferences.currentlySelectedPlanningProfile;
      }
    } else {
      // If userpreferences has not any data.
      payload.userpreferences.trailertabsequence = newTrailers;
    }

    try {
      setcolumnorderfordetail(newTrailers)
      const updateOrdersResponse = await updateSelectedPlanningProfileOfUser(payload);
      await getUserInfoMSGraph(userUniqueId);
    } catch (err) {
      NotificationManager.error(err, 'Error');
    }
  };

  const expandChange = (event) => {
    let newData = dataResult.map((item) => {
      if (item.Trailer_ID === event.dataItem.Trailer_ID) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setDataResult(newData);
  };
  const DetailComponent = props => {
    const dataItem =props.dataItem
    return(
      <div style={{display:"flex",gap:"0.5em"}} >
        {
          columnorderfordetail?.map((el)=><div style={{fontWeight:"600",width:"200px"}}>{`${el?.title} : ${dataItem[el?.name] ?? ""}`}</div>)
        }
      </div>
    )
  };

  const handleClear = () => {
    let userPrefrenceData=[{
      "field": "Terminal",
      "operator": "contains",
      "value": selectedPlanningProfile.terminal_id
    }];
    setFindTrailersBasedOnCommodity(false);
    localStorage.removeItem('userpreferenceTrailerstab');
    setSearchTerms(userPrefrenceData)

    setDataState({
      skip: 0,
      take: 20,
      filter: {
        logic: "and",
        filters: userPrefrenceData,
      }, 
      sort: sortData
    });

    setSortData([]);
    debouncedSearch(userPrefrenceData, pageNumber, takeNumberOfResult, []);
  };

  return (
    <div className="tab_inside_section">
      <div className="tab_inside_section_top">
        <div className="tab_inside_section_top_left">
        </div>
        <div className="tab_inside_section_top_right">
          <div>
            <button
              type='button'
              className='btn_white_sm ml_10 '
              onClick={handleClear}
            >
              Clear
            </button>

          </div>
          {isDataLoaded === "true" ? (
            ""
          ) : (
            <Link
              onClick={() => {
                setIsDataLoaded("false");
                window.open(`/TrailersPage?terminal_id=${selectedPlanningProfile.terminal_id}`, "_blank");

              }}
            >
              <button variant="contained" type="button" className="btn_signout"><i className="fa fa-external-link"></i></button>
            </Link>
          )}
          <i
            className="fa fa-times"
            aria-hidden="true"
            onClick={(e) => settabSelected("")}
          ></i>
        </div>
      </div>

      <div className="tab_inside_section_bottom">
        <div className="bottomright-with-nw-resize-outer">
          {
            (isDataLoaded) ?
              <LocalizationProvider language={currentLocale.language}>
                <IntlProvider locale={currentLocale.locale}>
                  <Grid
                    detail={DetailComponent}
                    filter={dataState.filter}
                    filterable={true}
                    groupable={true}
                    reorderable={true}
                    filterOperators={filterOperators}
                    expandField="expanded"
                    sort={dataState.sort}
                    sortable={true}
                    pageable={{
                      pageSizes: [25, 50, 100],
                      info: true,
                      previousNext: true,
                      buttonCount: 10,
                    }}

                    resizable={true}
                    skip={dataState.skip}
                    take={dataState.take}
                    data={dataResult}
                    onDataStateChange={dataStateChange}
                    onColumnReorder={columnReorderChange}
                    rowRender={getRowDraggable(
                      (e, dataItem) => {
                        setDragData({
                          dragFrom: "trailers",
                          dragDataItem: dataItem,
                        })
                      },
                      (e, dataItem) => { setDragData({}) }
                    )}
                    onExpandChange={expandChange}
                    total={totalResult}
                  >
                    {
                      columnorder.map(it =>
                        (<GridColumn  key={it.id} field={it.name} title={it.title} width="200px" />))
                    }
                  </Grid>
                </IntlProvider>
              </LocalizationProvider>
              :
              <ProgressBar animated now={100} />
          }
        </div>
      </div>
    </div>
  )
}

export default PlannerBoardTrailersTable;