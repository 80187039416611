import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/header";
import AppBar from "../../components/appbar";
import PlannerBoardHeader from "../../components/plannerBoardHeader/plannerBoardHeader";
import PlannerBoardDriversection from "../../components/plannerboarddriversection/plannerboarddriversection";
import { DateTime } from "luxon";
import "./plannermainsectionboard.css";
import ProgressBar from "react-bootstrap/ProgressBar";

import PlannerBoardTrailersTable from "../../components/plannerBoardTrailersTable/plannerBoardTrailersTable";
import PlannerBoardOrdersTable from "../../components/plannerBoardOrdersTable/plannerBoardOrdersTable";

function Plannerboard() {
  const [plannerboardClicked, setplannerboardClicked] = useState(false);
  const [selectedTab, setselectedTab] = useState("driver");

  const [tabSelected, settabSelected] = useState("");
  const [PlannerBoardLoadingPage, setPlannerBoardLoadingPage] = useState(true);
  const [plannerboardById, setplannerboardById] = useState({});
  const [hourArr, sethourArr] = useState(Array.from(Array(24).keys()));
  const [dragset, setdragset] = useState(Array.from(Array(48).keys()));
  const [calenderDrpdownClicked, setcalenderDrpdownClicked] = useState(false);
  const [selectedDate, setselectedDate] = useState(() => {
    return new Date();
  });
  const [allOrdersArray, setAllOrdersArray] = useState([]);

  const handelcallback = (childdata, plannerboarddata) => {
    setplannerboardById(plannerboarddata);
    setplannerboardClicked(childdata);
  };
  const selectedDateInToMilliSeconds =
    DateTime.fromJSDate(selectedDate).toMillis();
  const calenderDropdownSelectHandler = (event) => {
    setselectedDate(event.value);
    setcalenderDrpdownClicked(false);
  };
  const handelcallbackFromHeader = async (childdata) => {
    setplannerboardClicked(childdata);
    setselectedTab("");
  };
  const parrentCallBackForTab = (data) => {
    //  let payload = payloadForPlanningBoard;
    setselectedTab(data);
    // refreshData(payload);
  };

  return (
    <div id='wrapper'>
      <Header
        userclicked={plannerboardClicked}
        parentcallback={handelcallbackFromHeader}
      ></Header>

      <AppBar></AppBar>
      <div className='content-page_yardcheck'>
        <div className='content'>
          <div className='container-fluid'>
            {!plannerboardClicked ? (
              <>
                <PlannerBoardHeader
                  parentcallback={handelcallback}
                  calenderDropdownSelectHandler={calenderDropdownSelectHandler}
                  selectedDate={selectedDate}
                  setcalenderDrpdownClicked={setcalenderDrpdownClicked}
                  calenderDrpdownClicked={calenderDrpdownClicked}
                  parrentCallBackForTab={parrentCallBackForTab}
                />

                {selectedTab === "carrier" ? (
                  <>
                    <div className='row mt_30'>
                      <div className='col-xl-12'>
                        <div className='card card_shadow'>
                          <div className='card-body '>
                            <h1>Carrieer details</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : selectedTab === "trailer" ? (
                  <>
                    <div className='row mt_30'>
                      <div className='col-xl-12'>
                        <div className='card card_shadow'>
                          <div className='card-body '>
                            <div className='planner_top_date'>
                              <div className='planner_date_text'></div>
                            </div>

                            <h1>Trailer details</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <PlannerBoardDriversection
                      
                      hourArr={hourArr}
                      dragset={dragset}
                      selectedDateInToMilliSeconds={
                        selectedDateInToMilliSeconds
                      }
                    />
                  </>
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Plannerboard;
