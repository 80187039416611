

// Application Specific
import Tractor from '../models/tractorModel';
import { BaseConfig } from "../baseConfig";
import axios from 'axios';

    export const getAllTractors = async(filterData) => {
        const tractor = new Tractor();
        let tractorObject = []
        try {
            const url = BaseConfig.ApiEndPoint + "/gettractorbyterminalid";
            const tractorApiData = await axios.post(url,filterData);
            var tracDt = tractorApiData.data.data;
            tractorObject = tracDt.map(data => tractor.parseApiTractorObject(data));
        } catch (err){
            return Promise.resolve("There is a problem on retrieving Tractor data. Please try again!");
        }       

        return Promise.resolve(tractorObject);
    }   
    
    export const getTractor = async(tractor_id) => {
     
        const tractor = new Tractor();
        let tractorObject 
        try {
            const url = BaseConfig.ApiEndPoint + "/tractors/" + tractor_id;
            const tractorApiData = await axios.get(url);
            tractorObject = tractorApiData.data.data[0]   
        } catch (err){
            return Promise.resolve("There is a problem on retrieving Tractor data. Please try again!");
        }       

        return Promise.resolve(tractorObject);
    }

    export const getidfromvin = async(vin) => {
     
        const tractor = new Tractor();
        let tractorObject 
        try {
            const url = this.ApiEndForSamsara + "/getlatlongfromvin?vin=" + vin;
             const tractorApiData = await axios.get(url);
             tractorObject = tractorApiData.data.data.data[0]  
        } catch (err){
            return Promise.resolve("There is a problem on retrieving Tractor data. Please try again!");
        }       
        return Promise.resolve(tractorObject);
    }
