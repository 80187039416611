import React, { useState, useEffect, useContext } from 'react';
import * as ReactDOM from 'react-dom';
import "./trailespage.css";
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { IntlProvider, load, LocalizationProvider, loadMessages, IntlService } from '@progress/kendo-react-intl';

import '@progress/kendo-theme-default/dist/all.css';

import { process } from '@progress/kendo-data-query';
import {getUser, updatePUser, updateUser} from '../../services/userService';
import { ContextData } from '../../components/appsession';
import ProgressBar from "react-bootstrap/ProgressBar";
import { Box, Button, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { DateTime } from "luxon";

const DATE_FORMAT = 'yyyy-mm-dd hh:mm:ss.SSS';
const intl = new IntlService('en');


const DetailComponent = props => {
    const dataItem = props.dataItem;
    return null;
};

const TrailerspageTable = (props) => {
    const { settabSelected, allTrailersArray ,trailerLoading} = props;
    const [columnorder, setcolumnorder] = useState([])
    let columns = [
      {id: 0, name: 'expanded', title: ' '},
      {id:1 , name:"Status", title:"Status"}, 
      {id:2 , name:"Trailer_ID", title:"Trailer ID"},
      {id:3, name:"Commodity_Group", title:"Commodity Group"}, 
      {id:4 , name:"Terminal", title:"Terminal"},
      {id:5 , name:"Last_Order", title:"Last Order"},  
      {id:6 , name:"Last_Commodity", title:"Last Commodity"},  
      {id:7 , name:"Last_Product", title:"Last Product"},
      {id:8 , name:"Loads_Since_Last_Wash", title:"Loads Since Last Wash"},
      {id:9, name:"Loads_to_Next_Wash", title:"Loads to Next Wash"},
      {id:10 , name:"Days_to_Next_Wash", title:"Days to Next Wash"},
      {id:11 , name:"Days_Since_Last_Wash", title:"Days Since Last Wash"},
      {id:12 , name:"Last_Washed", title:"Last Washed"},
      {id:13 , name:"Last_Wash_W/O", title:"Last Wash W/O"},
      {id:14 , name:"PM_Due_Date", title:"PM Due Date"},
      {id:15 , name:"Make", title:"Make"},
      {id:16 , name:"Model", title:"Model"},
      {id:17 , name:"Year", title:"Year"},
      {id:20 , name:"Length", title:"Length"},
      {id:21 , name:"Width", title:"Width"},
      {id:22 , name:"Height", title:"Height"},
      {id:23 , name:"Volume", title:"Volume"},
      {id:24 , name:"Tare_Weight", title:"Tare Weight"}, 
      {id:25 , name:"Shipper_Pool", title:"Shipper Pool"},   
      {id:26, name:"Dedicated", title:"Dedicated"},
      {id:26, name:"License_State", title:"License State"}, 
      {id:26, name:"License_Plate", title:"License Plate"}, 
      {id:26, name:"QR_Code", title:"QR Code"}, 
    ];

    const [userData, setuserData] = useContext(ContextData);

    if (userData.hasOwnProperty("trailerColumns")) {
        columns = userData.trailerColumns;
    }

    const locales = [{
        language: 'en-US',
        locale: 'en'
    }, {
        language: 'es-ES',
        locale: 'es'
    }];
    const [dataState, setDataState] = React.useState({
        skip: 0,
        take: 20,
        sort: [{
            field: 'orderDate',
            dir: 'desc'
        }],
        // group: [{
        //   field: 'customerID'
        // }]
    });
    const [currentLocale, setCurrentLocale] = React.useState(locales[0]);
    const [orders, setorders] = useState([]);
    const [dataResult, setDataResult] = React.useState(process(orders, dataState));
    const [isDataloaded, setIsDataLoaded] = useState(false);

    useEffect(()=>{
        if(userData?.userpreferences?.trailertabsequence){
            setcolumnorder(userData?.userpreferences?.trailertabsequence)
          }
          else{
            setcolumnorder(columns)
          }
    },[userData])
    
    const expandChange = event => {
        const isExpanded = event.dataItem.expanded === undefined ? event.dataItem.aggregates : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
        setDataResult({
            ...dataResult
        });
    };

    let _pdfExport;

    const exportExcel = () => {
        _export.save();
    };

    let _export;

    const exportPDF = () => {
        _pdfExport.save();
    };

    const dataStateChange = event => {
        setDataResult(process(orders, event.dataState));
        setDataState(event.dataState);
    };


    const columnReorderChange = async(event) =>{
      const newTrailers = event.target._columns.map(it => {
        return {
          id: it.index,
          name: it.field,
          title: it.title,
          width: it.width
        }
      });
      
      setorders(newTrailers)
      let payload = userData;
      payload.trailertabsequence = newTrailers;
  
      const updateOrdersRespons = await updatePUser(userData);
      const usrData = await getUser(userData.id);
      // setorders();
      // updateUser(userData);
    }



    useEffect(() => {
        var arrayOfObj = [];
        let newTrailerArr = allTrailersArray
    
        if (newTrailerArr && Object.keys(newTrailerArr)) {
          arrayOfObj = Object.keys(newTrailerArr).map((i) => newTrailerArr[i]);
          let newDataValue = [];
          for (let index = 0; index < arrayOfObj.length; index++) {
            const temp = arrayOfObj[index];
    
            let tempCopy = {...temp, Last_Washed_Copy: temp.Last_Washed};
            
            if (isNaN(temp.Last_Washed) || temp.Last_Washed === null || temp.Last_Washed === "") {
              temp.Days_Since_Last_Wash = ""
            } else {
              const now = DateTime.now();
              const todaysEpochMillis = now.toMillis();
              const lastWashedMillis = tempCopy.Last_Washed_Copy;
              const diffMillis = Math.floor((todaysEpochMillis - lastWashedMillis) / (1000 * 60 * 60 * 24));
              temp.Days_Since_Last_Wash = diffMillis.toString();
            }
    
            if (
              temp.Last_Washed === null ||
              isNaN(temp.Last_Washed)
            ) {
              temp.Last_Washed;
            } else {
              const millis_Last_Washed = parseInt(temp.Last_Washed);
    
              temp.Last_Washed = DateTime.fromMillis(
                millis_Last_Washed,
                {
                  zone: "America/New_York",
                  setZone: "yes",
                  locale: "en-US",
                }
              ).toFormat(DATE_FORMAT);
            }
            newDataValue.push(temp);
          }
          setorders(newDataValue);
          setIsDataLoaded(true);
        }
    
        if (!userData.hasOwnProperty("trailerDataState")) {
          setDataResult(process(arrayOfObj, dataState));
        } else {
          setDataResult(process(arrayOfObj, userData.trailerDataState));
        }
      }, [allTrailersArray]);

    return (
        <div className="tab_inside_section">


            <div className="tab_content">

                {
                    !trailerLoading?
                    <LocalizationProvider language={currentLocale.language}>
                    <IntlProvider locale={currentLocale.locale}>
          
                      <ExcelExport data={orders} ref={exporter => {
                        _export = exporter;
                      }}>
                        <Grid sortable={true} filterable={true} groupable={true} reorderable={true} pageable={{
                          buttonCount: 4,
                          pageSizes: true
                        }}resizable={true} data={dataResult} {...dataState} onDataStateChange={dataStateChange} 
                          detail={DetailComponent} expandField="expanded" 
                          onExpandChange={expandChange}
                          onColumnReorder = {columnReorderChange}
                          >
                          <GridToolbar>
          
                          </GridToolbar>
                            {
                              columnorder.slice(1).map(it => 
                                    (<GridColumn field= { it.name } title= { it.title } width="200px" />))
                            }
                        </Grid>
                      </ExcelExport>
          
                    </IntlProvider>
                  </LocalizationProvider>:<ProgressBar animated now={100} />
                }


            </div>

        </div>
    )
}

export default TrailerspageTable
