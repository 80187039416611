import React, { useEffect, useState, useContext, useRef } from "react";
import { DateTime } from "luxon";
import BaselineShiping from "../../assets/images/baseline_local_shipping.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { withStyles } from '@mui/styles';
import Menu from '@mui/material/Menu';
import { BaseConfig } from "../.././baseConfig"
import SendIcon from '@mui/icons-material/Send';
import ForumIcon from '@mui/icons-material/Forum';
import ListItemText from '@mui/material/ListItemText';
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import pcMilerAPI from "../../utils/pcmilerRouteReports";
import ProgressBar from "react-bootstrap/ProgressBar";
import { getActivelocations } from "../../services/locationService";
import { ContextData } from "../../components/appsession";
import { getUser } from "../../services/userService";
import RvHookupIcon from '@mui/icons-material/RvHookup';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import AuditLogModalComponent from "../../components/driverPay/auditLogModal";
import { process } from "@progress/kendo-data-query";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { DropContext } from "../../pages/planner/planner";
import { Autocomplete } from "@mui/lab";
import { TextField, Chip } from "@mui/material";
import { getAllDrivers} from "../../services/driverService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import Skeleton from '@mui/material/Skeleton';
import { createDriverMessage, getDriverMessage, updateDriverMessageseen } from "../../services/messageService";
import { NotificationManager } from "react-notifications";
import { getDecodedURI } from "../../utils/urlEncodeandDecode";
import {
  getTerminalbylatlong,
  getImapctView,
  getAllTerminals,
  removemultiplemovements,
  updatemultiplemovements,
  updatemultiplemovementsforxmitted2driver_utc,
  updateETA,
  updatemultiplestops,
  createSplit,
  createBobtailOrEmptyMovements,
  createUnSplit,
  editMovements,
  editStops,
  removeTrailer,
  deleteMovementFromPlan,
  getDriverHosClock
} from "../../services/planningBoardService";

import PlannerBoardTooltip, {
  SamsaraPBToolTip,
  OrderTooltip,
  OrderPBToolTip,
  Order2PBToolTip,
  TrailerPBToolTip,
  ShiftToolTip
} from "./plannerboardtooltip";

import {
  Grid,
  GridColumn,
} from "@progress/kendo-react-grid";
import { gettrailerstabbydate } from "../../services/trailerService";
import { useHistory, useLocation } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import WarningModal from "../warningModal";

// Initial values
const DATE_FORMAT = "MM-dd-yyyy HH:mm a ZZZZ ";
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",

  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",

    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",

    }}
    {...props}
  />
));

const PlannerBoardDriversection = (props) => {
  const [columnorder, setcolumnorder] = useState([])
  const [userData] = useContext(ContextData);
  let columns = [
    { id: 0, name: "expanded", title: " " },
    { id: 1, name: "terminal_fullname", title: "Terminal", width: "280px" },
    { id: 2, name: "Order_ID", title: "Order ID", width: "200px" },
    { id: 3, name: "Order_Status", title: "Order Status", width: "200px" },
    { id: 4, name: "Move_ID", title: "Move ID", width: "200px" },
    { id: 21, name: "pcmiler_distance", title: "Distance", width: "200px" },

    { id: 5, name: "Move_Status", title: "Move Status", width: "200px" },
    { id: 6, name: "pcmilerduration", title: 'Duration', width: '200px' },

    { id: 7, name: "Customer", title: "Customer", width: "280px" },
    { id: 8, name: "BOL", title: "BOL", width: "200px" },
    { id: 9, name: "Consignee_Ref", title: "Consignee Ref", width: "200px" },
    { id: 10, name: "PO_Lot", title: "PO Lot", width: "280px" },
    {
      id: 11,
      name: "Commodity_Group",
      title: "Commodity Group",
      width: "280px",
    },
    { id: 12, name: "Commodity", title: "Commodity", width: "280px" },
    { id: 13, name: "Product", title: "Product", width: "280px" },
    { id: 14, name: "HazMat", title: "HazMat", width: "200px" },
    { id: 15, name: "HazMat_Code", title: "HazMat Code", width: "200px" },
    { id: 16, name: "Ordered_Wgt", title: "Ordered Weight", width: "200px" },
    { id: 17, name: "ActualWgt", title: "Actual Weight", width: "200px" },
    { id: 18, name: "Driver_ID", title: "Driver ID", width: "200px" },
    { id: 19, name: "Trailer_ID", title: "Trailer ID", width: "200px" },
    { id: 20, name: "Tractor_ID", title: "Tractor ID", width: "200px" },
    { id: 22, name: "Sent_To_Driver", title: "Sent to Driver", width: "200px" },
    { id: 23, name: "Tankwash_WO", title: "Tankwash WO", width: "200px" },

    { id: 24, name: "Driver_Load", title: "Driver Load", width: "200px" },
    { id: 25, name: "Pickup_Type", title: "Pickup Type", width: "200px" },
    { id: 26, name: "Pickup_Name", title: "Pickup Location", width: "280px" },
    { id: 27, name: "Pickup_Address", title: "Pickup Address", width: "280px" },

    { id: 28, name: "Pickup_City", title: "Pickup City", width: "280px" },
    { id: 29, name: "Pickup_State", title: "Pickup State", width: "280px" },
    { id: 30, name: "Pickup_Zip", title: "Pickup Zip", width: "200px" },

    {
      id: 31,
      name: "Pickup_Timezone",
      title: "Pickup Timezone",
      width: "280px",
    },
    { id: 32, name: "Driver_Unload", title: "Driver Unload", width: "200px" },
    { id: 33, name: "Delivery_Type", title: "Delivery Type", width: "200px" },
    { id: 34, name: "Delivery_Name", title: "Delivery Location", width: "280px" },
    {
      id: 35,
      name: "Delivery_Address",
      title: "Delivery Address",
      width: "280px",
    },
    { id: 36, name: "Delivery_City", title: "Delivery City", width: "280px" },
    { id: 37, name: "Delivery_State", title: "Delivery State", width: "280px" },
    {
      id: 38,
      name: "sched_arrive_late_utc  ",
      title: "Late Pickup Appt",
      width: "280px",
    },
    { id: 39, name: "Delivery_Zip", title: "Delivery Zip", width: "200px" },
    {
      id: 40,
      name: "Delivery_Timezone",
      title: "Delivery Timezone",
      width: "280px",
    },
    { id: 41, name: "Drop_Flag", title: "Drop Flag", width: "200px" },
    {
      id: 42,
      name: "Early_Pickup_Appt",
      title: "Early Pickup Appt",
      width: "280px",
    },

    {
      id: 43,
      name: "Late_Pickup_Appt",
      title: "Late Pickup Appt",
      width: "280px",
    },
    {
      id: 44,
      name: "Pickup_Planned_Arrive",
      title: "Pickup Planned Arrive",
      width: "280px",
    },
    {
      id: 45,
      name: "Pickup_Planned_Depart",
      title: "Pickup Planned Depart",
      width: "280px",
    },

    { id: 46, name: "Pickup_ETA", title: "Pickup ETA", width: "280px" },
    {
      id: 47,
      name: "Pickup_Actual_Arrive",
      title: "Pickup Actual Arrive",
      width: "280px",
    },
    {
      id: 48,
      name: "Pickup_Actual_Depart",
      title: "Pickup Actual Depart",
      width: "280px",
    },

    {
      id: 49,
      name: "Early_Delivery_Appt",
      title: "Early Delivery Appt",
      width: "280px",
    },
    {
      id: 50,
      name: "Late_Delivery_Appt",
      title: "Late Delivery Appt",
      width: "280px",
    },
    {
      id: 51,
      name: "Delivery_Planned_Arrive",
      title: "Delivery Planned Arrive",
      width: "280px",
    },
    {
      id: 52,
      name: "Delivery_Planned_Depart",
      title: "Delivery Planned Depart",
      width: "280px",
    },
    { id: 53, name: "Delivery_ETA", title: "Delivery ETA", width: "280px" },
    {
      id: 54,
      name: "Delivery_Actual_Arrive",
      title: "Delivery Actual Arrive",
      width: "280px",
    },
    {
      id: 55,
      name: "Delivery_Actual_Depart",
      title: "Delivery Actual Depart",
      width: "280px",
    },
    { id: 56, name: "On_Hold", title: "On Hold", width: "280px" },
    { id: 57, name: "On_Hold_Reason", title: "On Hold Reason", width: "280px" },
    { id: 58, name: "planningstatus", title: "Planning Status", width: "200px" },

  ];
  const movementType = [{ Type: "BT", movement: "Bobtail" }, { Type: "E", movement: "Empty" }];
  const context = React.useContext(DropContext);
  const {
    selectedTimezoneDate,
    hourArr,
    dragset,
    plannerDriverData,
    allDrivers,
    selectedPlanningProfile,
    isEditPermission,
    setSuccessResponseMssg,
    setFailureResponseMssg
  } = props;

  const [driverData, setDriverData] = useState([]);
  const [driverState, setDriverState] = useState([]);
  const [locationViewModal, setlocationViewModal] = useState(false);
  const [selectedLocationLatitude, setSelectedLocationLatitude] = useState("");
  const [selectedLocationLongitude, setSelectedLocationLongitude] = useState("");
  const [locationsDataFromMap, setLocationsDataFromMap] = useState([]);
  const [impactViewModal, setImpactViewModal] = useState(false);
  const [UserDataUPdate, setUserDataUPdate] = useState([]);
  const [splitviewmodal, setsplitviewmodal] = useState(false);
  const [allimpact, setallimpact] = useState([]);
  const [terminalname, setterminalname] = useState([]);
  const [selectedDriverOnMovement, setSelectedDriverOnMovement] = useState([]);
  const [terminalData, setterminalData] = useState([]);
  const [defaultterminal, setdefaultterminal] = useState([]);
  const [terminalHandelardata, setterminalHandelardata] = useState([]);
  const [driverHandelardata, setdriverHandelardata] = useState([]);
  const [movementlocationname, setmovementlocationName] = useState([]);
  const [durationsettime, setdurationsettime] = useState([]);
  const [page, setPage] = useState(context.driverPageNumber);
  const [driverviewmodal, setdriverviewmodal] = useState(false);
  const [driverdropdownData, setdriverdropdownData] = useState([]);
  const [locationcodeData, setlocationcodeData] = useState([]);
  const [defaultdriver, setdefaultdriver] = useState([]);
  const [driverIddropdown, setdriverIddropdown] = useState([]);
  const [orderData, setorderData] = useState([]);
  const [searchLocationData, setSearchLocationData] = useState("");
  const [selectedDistance, setSelectedDistance] = useState(5);
  const [startTime, setStartTime] = useState();
  const [handlesplitButton, sethandlesplitButton] = useState(false);
  const [destiLocation, setDestiLocation] = useState("");
  const [selectedTrailer, setSelectedTrailer] = useState({});
  const [driverMessage, setDriverMessage] = useState("");
  const [commodityGroupId, setCommodityGroupId] = useState("");
  const [isFindTrailerButtonVisible, setIsFindTrailerButtonVisible] = useState(null);
  const [allTrailers, setAllTrailers] = useState([]);
  const dayToMillis = 86400000;

  // Kendo states for impact views
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 5,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });
  const [dataResult, setDataResult] = useState(process(allimpact, dataState));
  const [clickedDriver, setClickedDriver] = useState("");
  const miles = [10, 15, 20];
  const [openMovementDialog, setOpenMovementDialog] = useState(false);
  const [openTrailerDialog, setOpenTrailerDialog] = useState(false);
  const [openXmitDialog, setOpenXmitDialog] = useState(false);
  const [removeTrailerBlock, setRemoveTrailerBlock] = useState({});
  const [removeMovementBlock, setRemoveMovementBlock] = useState({});
  const [driverID, setDriverID] = useState("");

  // fordroptrailer chnage locatioin
  const [isDropTrailerTrimbleVisible, setIsDropTrailerTrimbleVisible] = useState(false);
  const [isAddMovTrimbleVisible, setIsAddMovTrimbleVisible] = useState(false);
  const [dropTrailerData, setDropTrailerData] = useState({});
  const [dropTraileranddrophookData, setDropTraileranddrophookData] = useState({});
  const [destChangeDHandDT, setDestChangeDHandDT] = useState(false);
  const [modalShowDropTrailer, setmodalShowDropTrailer] = useState(false);
  const [selectedTrailerOnDropTrailer, setSelectedTrailerOnDropTrailer] = useState(null);
  const [movementforlocmapForDropTrailer, setmovementforlocmapDropTrailer] = useState([]);
  const [startTimeDropTrailer, setStartTimeDropTrailer] = useState();
  const [EndTimeDropTrailer, setEndTimeDropTrailer] = useState();
  const [destinationViewModal, setdestinationViewModal] = useState(false);
  const [movementforlocmap, setmovementforlocmap] = useState([]);
  const [alllocationdata, setalllocationdata] = useState([]);
  const [selectedDriverData, setSelectedDriverData] = useState({});
  const [isLocationLoading, setIsLocationLoading] = useState(false);

  // Picky State
  const [trailerRemoveOptions, setTrailerRemoveOptions] = useState([]);
  const [selectedOrderForTrailer, setSelectedOrderForTrailer] = useState([]);
  const [destinationadd, setdestinationadd] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [addMovements, setAddMovements] = React.useState(null);
  const [selectedMoveType, setSelectedMoveType] = useState(null);
  const [pickupdestinationlong, setpickupdestinationlong] = React.useState();
  const [deldestinationlat, setdeldestinationlat] = React.useState();
  const [deldestinationlong, setdeldestinationlong] = React.useState();
  const [movementformodal, setmovementformodal] = useState(false);
  const [movementdata, setmovementdata] = useState({});
  const [orderremovemodal, setorderremovemodal] = useState(false);
  const [driverdetails, setdriverdetails] = useState({});
  const [orderdetails, setorderdetails] = useState({});

  // Buttons states
  const [editActualSSandES, setEditActualSSandES] = React.useState(null);
  const [editActualsExceptSSandES, setEditActualsExceptSSandES] = React.useState(null);
  const [addEmptyMovement, setAddEmptyMovement] = React.useState(null);
  const [showButtons, setShowButtons] = React.useState(null);

  const [changeMoveTypeOnEmptyMov, setChangeMoveTypeOnEmptyMov] = React.useState(null);
  const [changeMoveTypeOnBobtailMov, setChangeMoveTypeOnBobtailMov] = React.useState(null);
  const [isSplitButton, setIsSplitButton] = useState(null);
  const [isUnsplitButton, setIsUnsplitButton] = useState(null);

  // states for unsplit modals
  const [unsplitModaldel, setunsplitmodaldel] = useState(null);
  const [unsplitdataDel, setUnsplitDatadel] = useState([]);
  const [unsplitModalpu, setunsplitmodalpu] = useState(null);
  const [unsplitdatapu, setUnsplitDatapu] = useState([]);
  const [loadedduration, setloadedduration] = useState(false);

  const [actualeditData, setactualeditData] = useState(false);
  const [actualeditdatamodal, setactualeditdatamodal] = useState({});
  const [selectedactualDate, setselectedactualDate] = useState();
  const [actual_arrivaldate, setactual_arrivaldate] = useState();
  const [selecteddepartDate, setselecteddepartDate] = useState();
  const [actual_arrivaldate1, setactual_arrivaldate1] = useState();
  const [actual_departdate, setactual_departdate] = useState();
  const [actual_departdate1, setactual_departdate1] = useState();
  const [stopsviewmodal, setstopsviewmodal] = useState(false);
  const [actualeditstopsdata, setactualeditstopsdata] = useState({});
  const [selectedarrival_stops_Date, setselectedarrival_stops_Date] =
    useState();
  const [actualarrival, setactualarrival] = useState();
  const [selecteddepart_stops_Date, setselecteddepart_stops_Date] = useState();
  const [actualdepart, setactualdepart] = useState();
  const [driverDOM, setDriverDOM] = useState(<></>);
  const [refreshComponent, setRefreshComponent] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [xmitDriver, setXmitDriver] = useState({});
  const [dataLoaded, setDataLoaded] = useState(true);
  const [driverChatMessageOpen, setDriverChatMessageOpen] = useState(false);
  const [driverToSendMessage, setDriverToSendMessage] = useState(null);
  const [driverEnteredMessage, setDriverEnteredMessage] = useState("");
  const [orders, setorders] = useState([]);

  // Audit log states
  const [clickedMovOrStopData, setClickedMovOrStopData] = useState({ type: "", movement: {} });
  const [isTrailerHistoryNeeded, setIsTrailerHistoryNeeded] = useState(false);

  //pickup and delivery stops data for Stops
  const [isModalOpen, setIsModalOpen] = useState(false, null);
  const [driverid, setdriverid] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longuitude, setlonguitude] = useState("");
  const [splitConfirmBtn,setSplitConfirmbtn] = useState(false);

  const stop_and_movs_buttons = {
    display: "inline-block",
    marginRight: "10px"
  };

  // adding popup content where driver load unload flag option edited 
  const [selectedOption, setSelectedOption] = useState('');
  const [actualeditmovedata, setactualeditmovedata] = useState({});
  const [stopClickFlag, setStopClickFlag] = useState(false);
  const [loadunloadflag, setLoadUnloadflag] = useState(false);
  const [movementsEditModal, setMOvementsEditModal] = useState(false);
  const [editStopChangeflag,setEditStopChangeFlag] = useState(true);
  const [movementChangeTypeText,setMovementChangeTypeText]=useState("");
  const [extraMoveModal,setExtraMoveModal]=useState(false);
  const [extraMoveDetails,setExtramoveDetails]=useState(null);
  const [selectedDriverHosData,setSelectedDriverHosData]=useState(null);
  const [driverToolTipModal,setDriverToolTipModal] = useState(false);
  const [changedestconfirmbtn,setChangedestconfirmbtn] = useState(false);
  const [changemovetypeconfirmbtn,setChangemovetypeconfirmbtn] = useState(false);
  const [addmovconfirmbtn,setaddmovconfirmbtn] = useState(false);
  const [editstopconfirmbtn,seteditstopconfirmbtn] = useState(false);
  const [deletmovconfirmbtn,setdeletmovconfirmbtn] = useState(false);
  const [warningModalOpen, setWarningModalOpen] =  useState(null);

  const location = useLocation();
  const history = useHistory();

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleMouseEnter = () => {
    if (isDragging) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const renderTooltip = (movement) => {
    if (isHovered && !isDragging) {
      return (
        <PlannerBoardTooltip tooltip={<OrderPBToolTip it={movement} />}>
          <div className='tooltip_row_block'>{movement.type}</div>
        </PlannerBoardTooltip>
      );
    } else if (isDragging) {
      return (
        <div className='tooltip_row_block'>{movement.type}</div>
      );
    } else {
      return (
        <PlannerBoardTooltip tooltip={<OrderPBToolTip it={movement} />}>
          <div className='tooltip_row_block'>{movement.type}</div>
        </PlannerBoardTooltip>
      );
    }
  };

  const handleLoadingBarOnDriver = (driverId, isLoad) => {
    setDriverState(driverState.map((el) => {
      if (el.driverId === driverId)
        return {
          ...el,
          isloading: isLoad,
        };
      return el;
    }));
  }

  useEffect(async () => {
    if (UserDataUPdate[0]?.userpreferences) {
      setcolumnorder(UserDataUPdate[0]?.userpreferences.orderstabsequence)
    }
    else {
      if (UserDataUPdate[0]?.userpreferences?.orderstabsequence) {
        setcolumnorder(UserDataUPdate[0]?.userpreferences?.orderstabsequence)
      } else {
        setcolumnorder(columns)
      }
    }
  }, [UserDataUPdate[0]?.userpreferences?.orderstabsequence, UserDataUPdate[0]?.userpreferences]);

  useEffect(() => {
    var arrayOfObj = [];
    let newOrderArr = allimpact;

    if (newOrderArr && Object.keys(newOrderArr)) {
      arrayOfObj = Object.keys(newOrderArr).map((i) => newOrderArr[i]);
      let newDataValue = [];
      for (let index = 0; index < arrayOfObj.length; index++) {
        const temp = arrayOfObj[index];
        const duration = temp.pcmilerduration;

        if (!duration || isNaN(duration)) {
          temp.pcmilerduration = "";
        } else {
          const durationFormatted = Duration.fromObject({ hours: duration }).toFormat("hh:mm:ss");
          temp.pcmilerduration = durationFormatted;
        }

        if (
          temp.Delivery_Actual_Arrive === null ||
          isNaN(temp.Delivery_Actual_Arrive)
        ) {
          temp.Delivery_Actual_Arrive;
        } else {
          const millis_Delivery_Actual_Arrive = parseInt(
            temp.Delivery_Actual_Arrive * 1000
          );

          const Timezone_Delivery_Actual_Arrive = temp.Delivery_Timezone;

          temp.Delivery_Actual_Arrive = DateTime.fromMillis(
            millis_Delivery_Actual_Arrive,
            {
              zone: Timezone_Delivery_Actual_Arrive,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Delivery_Actual_Depart === null ||
          isNaN(temp.Delivery_Actual_Depart)
        ) {
          temp.Delivery_Actual_Depart;
        } else {
          const millis_Delivery_Actual_Depart = parseInt(
            temp.Delivery_Actual_Depart * 1000
          );
          const Timezone_Delivery_Actual_Depart = temp.Delivery_Timezone;

          temp.Delivery_Actual_Depart = DateTime.fromMillis(
            millis_Delivery_Actual_Depart,
            {
              zone: Timezone_Delivery_Actual_Depart,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Delivery_Planned_Arrive === null ||
          isNaN(temp.Delivery_Planned_Arrive)
        ) {
          temp.Delivery_Planned_Arrive;
        } else {
          const millis_Delivery_Planned_Arrive = parseInt(
            temp.Delivery_Planned_Arrive * 1000
          );
          const Timezone_Delivery_Planned_Arrive = temp.Delivery_Timezone;

          temp.Delivery_Planned_Arrive = DateTime.fromMillis(
            millis_Delivery_Planned_Arrive,
            {
              zone: Timezone_Delivery_Planned_Arrive,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Delivery_Planned_Depart === null ||
          isNaN(temp.Delivery_Planned_Depart)
        ) {
          temp.Delivery_Planned_Depart;
        } else {
          const millis_Delivery_Planned_Depart = parseInt(
            temp.Delivery_Planned_Depart * 1000
          );
          const Timezone_Delivery_Planned_Depart = temp.Delivery_Timezone;

          temp.Delivery_Planned_Depart = DateTime.fromMillis(
            millis_Delivery_Planned_Depart,
            {
              zone: Timezone_Delivery_Planned_Depart,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Early_Delivery_Appt === null ||
          isNaN(temp.Early_Delivery_Appt)
        ) {
          temp.Early_Delivery_Appt;
        } else {
          const millis_Early_Delivery_Appt = parseInt(
            temp.Early_Delivery_Appt * 1000
          );
          const Timezone_Early_Delivery_Appt = temp.Delivery_Timezone;

          temp.Early_Delivery_Appt = DateTime.fromMillis(
            millis_Early_Delivery_Appt,
            {
              zone: Timezone_Early_Delivery_Appt,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (temp.Early_Pickup_Appt === null || isNaN(temp.Early_Pickup_Appt)) {
          temp.Early_Pickup_Appt;
        } else {
          const millis_Early_Pickup_Appt = parseInt(
            temp.Early_Pickup_Appt * 1000
          );
          const Timezone_Early_Pickup_Appt = temp.Delivery_Timezone;

          temp.Early_Pickup_Appt = DateTime.fromMillis(
            millis_Early_Pickup_Appt,
            {
              zone: Timezone_Early_Pickup_Appt,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Late_Delivery_Appt === null ||
          isNaN(temp.Late_Delivery_Appt)
        ) {
          temp.Late_Delivery_Appt;
        } else {
          const millis_Late_Delivery_Appt = parseInt(
            temp.Late_Delivery_Appt * 1000
          );
          const Timezone_Late_Delivery_Appt = temp.Delivery_Timezone;

          temp.Late_Delivery_Appt = DateTime.fromMillis(
            millis_Late_Delivery_Appt,
            {
              zone: Timezone_Late_Delivery_Appt,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (temp.Late_Pickup_Appt === null || isNaN(temp.Late_Pickup_Appt)) {
          temp.Late_Pickup_Appt;
        } else {
          const millis_movementpickupactual_departure = parseInt(
            temp.movementpickupactual_departure * 1000
          );
          const Timezone_movementpickupactual_departure =
            temp.movementpickuptimezone;
          const millis_Late_Pickup_Appt = parseInt(
            temp.Late_Pickup_Appt * 1000
          );
          const Timezone_Late_Pickup_Appt = temp.Pickup_Timezone;

          temp.movementpickupactual_departure = DateTime.fromMillis(
            millis_movementpickupactual_departure,
            {
              zone: Timezone_movementpickupactual_departure,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);

          temp.Late_Pickup_Appt = DateTime.fromMillis(millis_Late_Pickup_Appt, {
            zone: Timezone_Late_Pickup_Appt,
            setZone: "yes",
            locale: "en-US",
            outputCalendar: "gregory",
            numberingSystem: "buddhist",
          }).toFormat(DATE_FORMAT);
        }

        if (
          temp.movementpickupsched_arrive_early === null ||
          isNaN(temp.movementpickupsched_arrive_early)
        ) {
          temp.movementpickupsched_arrive_early;
        } else {
          const millis_movementpickupsched_arrive_early = parseInt(
            temp.movementpickupsched_arrive_early * 1000
          );
          const Timezone_movementpickupsched_arrive_early =
            temp.movementpickuptimezone;

          temp.movementpickupsched_arrive_early = DateTime.fromMillis(
            millis_movementpickupsched_arrive_early,
            {
              zone: Timezone_movementpickupsched_arrive_early,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Pickup_Actual_Arrive === null ||
          isNaN(temp.Pickup_Actual_Arrive)
        ) {
          temp.Pickup_Actual_Arrive;
        } else {
          const millis_Pickup_Actual_Arrive = parseInt(
            temp.Pickup_Actual_Arrive * 1000
          );
          const Timezone_Pickup_Actual_Arrive = temp.Pickup_Timezone;

          temp.Pickup_Actual_Arrive = DateTime.fromMillis(
            millis_Pickup_Actual_Arrive,
            {
              zone: Timezone_Pickup_Actual_Arrive,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Pickup_Actual_Depart === null ||
          isNaN(temp.Pickup_Actual_Depart)
        ) {
          temp.Pickup_Actual_Depart;
        } else {
          const millis_Pickup_Actual_Depart = parseInt(
            temp.Pickup_Actual_Depart * 1000
          );
          const Timezone_Pickup_Actual_Depart = temp.Pickup_Timezone;

          temp.Pickup_Actual_Depart = DateTime.fromMillis(
            millis_Pickup_Actual_Depart,
            {
              zone: Timezone_Pickup_Actual_Depart,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (temp.Pickup_ETA === null || isNaN(temp.Pickup_ETA)) {
          temp.Pickup_ETA;
        } else {
          const millis_Pickup_ETA = parseInt(temp.Pickup_ETA * 1000);
          const Timezone_Pickup_ETA = temp.Pickup_Timezone;

          temp.Pickup_ETA = DateTime.fromMillis(millis_Pickup_ETA, {
            zone: Timezone_Pickup_ETA,
            setZone: "yes",
            locale: "en-US",
            outputCalendar: "gregory",
            numberingSystem: "buddhist",
          }).toFormat(DATE_FORMAT);
        }

        if (temp.Delivery_ETA === null || isNaN(temp.Delivery_ETA)) {
          temp.Delivery_ETA;
        } else {
          const millis_Delivery_ETA = parseInt(temp.Delivery_ETA * 1000);
          const Timezone_Delivery_ETA = temp.Delivery_Timezone;

          temp.Delivery_ETA = DateTime.fromMillis(millis_Delivery_ETA, {
            zone: Timezone_Delivery_ETA,
            setZone: "yes",
            locale: "en-US",
            outputCalendar: "gregory",
            numberingSystem: "buddhist",
          }).toFormat(DATE_FORMAT);
        }

        if (
          temp.Pickup_Planned_Arrive === null ||
          isNaN(temp.Pickup_Planned_Arrive)
        ) {
          temp.Pickup_Planned_Arrive;
        } else {
          const millis_Pickup_Planned_Arrive = parseInt(
            temp.Pickup_Planned_Arrive * 1000
          );
          const Timezone_Pickup_Planned_Arrive = temp.Pickup_Timezone;

          temp.Pickup_Planned_Arrive = DateTime.fromMillis(
            millis_Pickup_Planned_Arrive,
            {
              zone: Timezone_Pickup_Planned_Arrive,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Pickup_Planned_Depart === null ||
          isNaN(temp.Pickup_Planned_Depart)
        ) {
          temp.Pickup_Planned_Depart;
        } else {
          const millis_Pickup_Planned_Depart = parseInt(
            temp.Pickup_Planned_Depart * 1000
          );
          const Timezone_Pickup_Planned_Depart = temp.Pickup_Timezone;

          temp.Pickup_Planned_Depart = DateTime.fromMillis(
            millis_Pickup_Planned_Depart,
            {
              zone: Timezone_Pickup_Planned_Depart,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        newDataValue.push(temp);
      }
      setorders(newDataValue);
      // setIsDataLoaded(true);
    }
  }, [allimpact]);

  const handleCloseDialogXmit = async (sendOrNot, xmitDriver) => {
    handleLoadingBarOnDriver(xmitDriver.driverId, true);

    try {
      if (sendOrNot === "send") {
        let load_ids = [...new Set(xmitDriver.movements.map(
          movement => movement.load_id))];
        await updatemultiplemovementsforxmitted2driver_utc(load_ids.map(load_id => ({
          load_id, xmitted2driver_utc: DateTime.now().toMillis()
        })));
      }

      setSuccessResponseMssg("Plan Sent to Driver.");
      context.refreshDataFromAPI(context.payloadForRefreshData);

    } catch (e) {
      toast.error("Unable to send xmit.", { autoClose: false });
    }
    setOpenXmitDialog(false);
    setXmitDriver({});
  };

  const sendToXmit = (driver) => {
    setXmitDriver(driver);
    setOpenXmitDialog(true);
  };

  useEffect(async () => {
    if (driverChatMessageOpen === true) {
      await updateDriverMessageseen(driverToSendMessage?.driverId);
    }
  }, [driverChatMessageOpen]);

  useEffect(() => {
    if (Object.keys(context?.driverMessageDetails).length > 0) {
      driverChatModalHandler(context?.driverMessageDetails);
    }
  }, [context?.driverMessageDetails]) 


  const resetURLAfterClosingMessageModal = () => { 
    let params = getDecodedURI(location.search);
    history.push(`${location.pathname}?planningProfile=${params.planningProfile}`);
    context?.setDriverMessageDetails({});
    setDriverChatMessageOpen(false);
  }

  const driverChatModalHandler = async (driver) => {
    const response = await getDriverMessage(driver?.driverId);
    setDriverMessage(response);
    setDriverToSendMessage(driver);
    setDriverChatMessageOpen(true);
    setDriverEnteredMessage("");
  };

  const sendDriverChatMessageHandler = async () => {
    try {
      let payload = {
        driverId: driverToSendMessage?.driverId,
        messageText: driverEnteredMessage,
        source: "BPA"
      }
      await createDriverMessage(payload);      
      setDriverEnteredMessage("");

      // Resetting the url
      resetURLAfterClosingMessageModal();
      NotificationManager.success("Message Sent!", "Success")
    } catch (error) {
      toast.error("Message could not be sent!", { autoClose: false })
    }
  };

  // THIS WILL BE CALLED AFTER PRESSING DELETE BUTTON FOR MOVEMENT REMOVING
  const handleCloseDialogMovement = (saveOrNot) => {
    setOpenMovementDialog(false);
    if (saveOrNot === "save") {
      deleteOrdersplanningBoard(removeMovementBlock, driverID);
    }
    setRemoveMovementBlock({});
    setDriverID("");
  };

  // IT WILL BE CALLED WHEN WE WILL SELECT ORDER ID USING PICKY
  useEffect(() => { }, [
    trailerRemoveOptions.length,
    selectedOrderForTrailer.length,
  ]);

  // THIS FUNCTION WILL BE CALLED AFTER PRESSING CLOSE ICON FOR TRAILER REMOVING
  const handleOpenDialogTrailer = (trailer, driverID, driver) => {
    setSelectedDriverOnMovement(driver);
    setOpenTrailerDialog(true);
    let trailerId = trailer.trailer_id
    let arr = []
    let driver_movements_details = driver.movements
    for (let i = 0; i < driver_movements_details.length; i++) {
      if (driver_movements_details[i].trailerData.trailer_id === trailerId && driver_movements_details[i].movementData.movement_type === 'L'
        && driver_movements_details[i].movementData.pickup.planned_arrive_utc >= selectedTimezoneDate
        && driver_movements_details[i].movementData.pickup.planned_arrive_utc <= (selectedTimezoneDate + 24 * 60 * 60 * 1000)
        && driver_movements_details[i].movementData.movement_type === 'L' &&
        driver_movements_details[i].stopData === null) {
        let orderObj = {
          label: `ORDER ID - ${driver_movements_details[i].movementData.order_id2}`,
          value: driver_movements_details[i].movementData.order_id2,
        };
        // arr.push(orderObj);
        arr.push(orderObj)
      }
      // Remove duplicates based on order_id2
      arr = arr.filter((item, index, self) => {
        return (index === self.findIndex((i) => i.value === item.value)
        );
      });
    }

    setTrailerRemoveOptions(arr);
    setSelectedOrderForTrailer(arr);
    setRemoveTrailerBlock(trailer);
    setDriverID(driverID);
  };

  const resetMapRelatedData = function () {
    setSelectedDistance(5);
    setSearchLocationData("");
    setSelectedLocationLongitude("");
    setSelectedLocationLatitude("");
  };

  const handleAddMovementViewModalDropTrailer = () => {
    setIsDropTrailerTrimbleVisible(false);
    setStartTimeDropTrailer();
    resetMapRelatedData();
    setLocationsDataFromMap([]);
  };

  // FOR CLOSING ADD MOVEMENT MODAL
  const handleAddMovementViewModal = () => {
    handleLoadingBarOnDriver(selectedDriverData.driverId, false);
    setSelectedDriverData({});
    setIsAddMovTrimbleVisible(false);
    resetMapRelatedData();
  };

  useEffect(() => setDriverData(plannerDriverData), [plannerDriverData]);

  useEffect(() => {
    setDriverState(
      driverData
    );
  }, [driverData, page]);

  // This function is for Impact view
  const dataStateChange = (event) => {
    setDataResult(process(orders, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {
    setDataResult(process(allimpact, dataState));
  }, [allimpact]);
  const [routeData, setrouteData] = useState([]);

  const currentDate = DateTime.fromObject({ hour: 0 }).toMillis();

  const getAllTrailers = async () => {
    const trailerResponse = await gettrailerstabbydate([selectedPlanningProfile?.terminal_id]);
    setAllTrailers(trailerResponse);
  };

  // This function is running on clicking right button on movements
  const handleRightClickOnMovements = async (
    event,
    type,
    driverid,
    order,
    driver,
    movement
  ) => {
    event.preventDefault();
    setIsUnsplitButton(null);
    setunsplitmodaldel(null);
    setunsplitmodalpu(null);
    setEditStopChangeFlag(true);
    setDestChangeDHandDT(false);
    setAddEmptyMovement(null);

    const nextMovForBobtailOrEmpty = driver.movements.find((mov) => movement.movementData.delivery.lm_stop_id === mov.movementData.pickup.lm_stop_id);

    if (showButtons) {
      setShowButtons(null);
    } else {
      if (movement.movementData.pickup.actual_arrival_utc === 0 || movement.movementData.pickup.actual_arrival_utc === null) {
        sethandlesplitButton(true);
      } else {
        sethandlesplitButton(false);
      }
      if (type === 'PU' || type === 'DEL' || type === 'SP' || type === 'SD') {
        setLoadUnloadflag(true);
        setStopClickFlag(true);
      }

      // Storing clicked mov or stop data for Audit Log
      if (type === "BT" || type === "L" || type === "E") {
        setClickedMovOrStopData({ type: type, movement: movement });
      } else {
        if (movement.movementData.pickup.stop_type === type) {
          setClickedMovOrStopData({ type: type, movement: movement.movementData.pickup });
        } else {
          setClickedMovOrStopData({ type: type, movement: movement.movementData.delivery });
        }
      };

      // Storing selected driver object
      setClickedDriver(driverid);
      setSelectedDriverData(driver);

      // It is showing all buttons 
      setShowButtons(event.currentTarget);

      const currentDate = DateTime.now().setZone(context.planningBoardData).ts;

      const getloadedmovementsforSD = driver.movements.filter((mvts) => mvts.stopData == null && mvts.movementData.delivery?.lm_stop_id == movement.stopData?.lm_stop_id);

      const getloadedmovementsforSp = driver.movements.filter((mvts) => mvts.stopData == null && mvts.movementData.pickup?.lm_stop_id == movement.stopData?.lm_stop_id);
      let stop_data = movement.stopData?.planned_arrive_utc;

      let filterstopArray = driver.movements?.filter((e) => e.movementData.pickup.planned_arrive_utc < stop_data);

      let last_stops_data_other = filterstopArray.find(o => o.movementData.pickup.planned_depart_utc === Math.max(...filterstopArray.map(i => i.movementData.pickup.planned_depart_utc)));

      // Only Unsplit Delivery button will be shown
      if (type === "SD" && last_stops_data_other.movementData.pickup.actual_arrival_utc === 0 && last_stops_data_other.movementData.pickup.actual_departure_utc === 0) {
          setunsplitmodaldel(event.currentTarget);
          setUnsplitDatadel(movement);
      };

      // Only Unsplit Pickup button will be shown
      if (type === "SP" && last_stops_data_other.movementData.pickup.actual_arrival_utc == 0 && last_stops_data_other.movementData.pickup.actual_departure_utc === 0) {
          setunsplitmodalpu(true);
          setUnsplitDatapu(movement);
      };
      // Edit Actuals and Unsplit Pickup both button will be shown
      if (type === "SP" && (last_stops_data_other.movementData.pickup.actual_arrival_utc !== 0 ||
        last_stops_data_other.movementData.pickup.actual_departure_utc !== 0)) {
        let loadedmovementduration = getloadedmovementsforSp[0].movementData.delivery.planned_arrive_utc - getloadedmovementsforSp[0].movementData.pickup.planned_depart_utc;

        if (loadedmovementduration > 10) {
          setloadedduration(true);
        }
        setIsUnsplitButton(true);
        setEditActualsExceptSSandES(true);
        setStopClickFlag(true)
        setactualeditstopsdata(movement);
        setUnsplitDatapu(movement);
      };

      // Edit Actuals and Unsplit Delivery both button will be shown
      if (type === "SD" && (last_stops_data_other.movementData.pickup.actual_arrival_utc !== 0 ||
        last_stops_data_other.movementData.pickup.actual_departure_utc !== 0)) {
        let loadedmovementduration = getloadedmovementsforSD[0].movementData.delivery.planned_arrive_utc - getloadedmovementsforSD[0].movementData.pickup.planned_depart_utc;

        if (loadedmovementduration > 10) {
          setloadedduration(true);
        }
        setIsUnsplitButton(true);
        setEditActualsExceptSSandES(true);
        setStopClickFlag(true)
        setactualeditstopsdata(movement);
        setUnsplitDatadel(movement);
      };

      // Split button will be shown 
      if (
        type === "L" && movement.movementData.delivery.actual_arrival_utc === 0 && movement.movementData.delivery.actual_departure_utc === 0
      ) {
        setIsSplitButton(true);
        setrouteData(movement);
        setdriverIddropdown(driverid);
        let hookstarttimeduration1 = movement.movementData.pickup.planned_depart_utc;
        let unhookingendtimeduration1 = movement.movementData.delivery.planned_arrive_utc;
        let duration = [];
        duration.push(unhookingendtimeduration1 - hookstarttimeduration1);
        setdurationsettime(duration);
      };

      // Change movement type button will be shown
      if (
        (type === "E" && nextMovForBobtailOrEmpty?.movement_type === "BT")
      ) {
        setChangeMoveTypeOnEmptyMov(true);
        setpickupdestinationlong(movement.movementData.pickup);
        setdeldestinationlat(movement.movementData.delivery);
        setdeldestinationlong(movement.movementData.delivery);
        setrouteData(movement);
      };

      // Change move type button will be shown
      if (type === "BT" && nextMovForBobtailOrEmpty?.movement_type === "E") {
        setChangeMoveTypeOnBobtailMov(true);
        setpickupdestinationlong(movement.movementData.pickup.longitude);
        setdeldestinationlat(movement.movementData.delivery.latitude);
        setdeldestinationlong(movement.movementData.delivery.longitude);

        const allResponse = await getActivelocations();
        setalllocationdata(allResponse);
      };

            //change destination address for DT AND DH
      if ((type === "DT" || type==="DH") && movement.movementData.delivery.actual_arrival_utc === 0 && movement.movementData.delivery.actual_departure_utc === 0) {
        const movedataofDT = driver.movements.filter((mvts) =>  mvts.stopData == null && mvts.movementData.delivery?.lm_stop_id == movement.stopData?.lm_stop_id);
        setDestChangeDHandDT(true);
        setDropTraileranddrophookData(movement);
        setpickupdestinationlong(movedataofDT[0]?.movementData?.pickup);
        setdeldestinationlat(movedataofDT[0]?.movementData?.delivery);
        setdeldestinationlong(movedataofDT[0]?.movementData?.delivery);
        setrouteData(movedataofDT[0]);
      };

      // Add empty movement will be shown
      if (type === "DT" && movement.movementData.delivery.actual_arrival_utc === 0 && movement.movementData.delivery.actual_departure_utc === 0) {
        setAddEmptyMovement(event.currentTarget);
        setactualeditstopsdata(movement);
        setDropTrailerData(movement);
        setSelectedLocationLatitude(movement?.movementData?.pickup?.latitude);
        setSelectedLocationLongitude(movement?.movementData?.pickup?.longitude);
        await getAllTrailers();
      };

      // Edit Actuals except SS and ES, if previous stops have actual
      if (
        type === "PU" && last_stops_data_other.movementData.pickup.actual_arrival_utc != 0 &&
        last_stops_data_other.movementData.pickup.actual_departure_utc != 0 ||
        type === "DEL" && last_stops_data_other.movementData.pickup.actual_arrival_utc != 0 &&
        last_stops_data_other.movementData.pickup.actual_departure_utc != 0 ||
        type === "HT" && last_stops_data_other.movementData.pickup.actual_arrival_utc != 0 &&
        last_stops_data_other.movementData.pickup.actual_departure_utc != 0 ||
        type === "DH" && last_stops_data_other.movementData.pickup.actual_arrival_utc != 0 &&
        last_stops_data_other.movementData.pickup.actual_departure_utc != 0 ||
        type === "DT" && last_stops_data_other.movementData.pickup.actual_arrival_utc != 0 &&
        last_stops_data_other.movementData.pickup.actual_departure_utc != 0
      ) {
        setactualeditstopsdata(movement);
        setEditActualsExceptSSandES(true);
        setStopClickFlag(true)
      };
    }
  };

  const Stopsmodal_open = async (i, actualeditstopsdata) => {
    setShowButtons(null);
    setSelectedOption(actualeditmovedata?.movementData?.pickup?.driver_load_unload?.trim());
    if (editActualsExceptSSandES) {
      let pu_timestamp = null;
      if (
        ![0, null, undefined, ""].includes(
          actualeditstopsdata?.stopData?.actual_arrival_utc
        )
      ) {
        pu_timestamp = DateTime.fromMillis(
          parseInt(actualeditstopsdata?.stopData?.actual_arrival_utc)
        )
          .setZone(
            actualeditstopsdata.stopData.pickup_timezone
              ? actualeditstopsdata.stopData.pickup_timezone
              : actualeditstopsdata.stopData.delivery_timezone
          )
          .toFormat("yyyy-MM-dd HH:mm")
          .toString();
        setselectedarrival_stops_Date(pu_timestamp);
      }
      let pu_timestamp1 = null;

      if (
        ![0, null, undefined, ""].includes(
          actualeditstopsdata?.stopData?.actual_departure_utc
        )
      ) {
        pu_timestamp1 = DateTime.fromMillis(
          parseInt(actualeditstopsdata?.stopData?.actual_departure_utc)
        )
          .setZone(
            actualeditstopsdata.stopData.pickup_timezone
              ? actualeditstopsdata.stopData.pickup_timezone
              : actualeditstopsdata.stopData.delivery_timezone
          )
          .toFormat("yyyy-MM-dd HH:mm")
          .toString();
        setselecteddepart_stops_Date(pu_timestamp1);
      }
    }
    setstopsviewmodal(true);
  };

  const handleactual_stops_DateChange = (e) => {
    setselectedarrival_stops_Date(e.target.value);
    let actualedit_time_pu = DateTime.fromISO(e.target.value)
      .setZone(
        actualeditstopsdata.stopData.pickup_timezone
          ? actualeditstopsdata.stopData.pickup_timezone
          : actualeditstopsdata.stopData.delivery_timezone,
        { keepLocalTime: true }
      )
      .toMillis();
    setactualarrival(actualedit_time_pu);
    setEditStopChangeFlag(false)
  };

  const handledepart_stops_DateChange = (e) => {
    setselecteddepart_stops_Date(e.target.value);

    let departedit_time_pu = DateTime.fromISO(e.target.value)
      .setZone(
        actualeditstopsdata.stopData.pickup_timezone
          ? actualeditstopsdata.stopData.pickup_timezone
          : actualeditstopsdata.stopData.delivery_timezone,
        { keepLocalTime: true }
      )
      .toMillis();
    setactualdepart(departedit_time_pu);
    setEditStopChangeFlag(false)
  };

  const convertEpochToISO = (dateTime, data) => {
    let IsoDateTime = DateTime.fromMillis(parseInt(dateTime))
      .setZone(
        data?.stopData?.pickup_timezone ? data?.stopData?.pickup_timezone : data?.stopData?.delivery_timezone
      ).toISO();
    return IsoDateTime
  };
  let errormessageDisplay=(arr)=>{
    if(arr?.split(" ")?.length>15){
      toast.error(`${arr}`, { autoClose: false, position: "top-center" });
    }else{
      toast.error(`${arr}`, { autoClose: false } );
    }
  } 

  const stopclosehandeler = () => {
    setactualarrival();
    setactualdepart();
    setselecteddepart_stops_Date();
    setselectedarrival_stops_Date();
    setstopsviewmodal(false);
    setSelectedOption('');
    setEditActualsExceptSSandES(null);
    setStopClickFlag(false);
    setLoadUnloadflag(false);
    setAddEmptyMovement(null);
    seteditstopconfirmbtn(false);
  };

  const stop_ChangeClick = async (warningDropdownValue) => {
    if (!isEditPermission) {
      return toast.error("Sorry You Dont Have Permission To Edit Stops", { autoClose: false });
    }
    seteditstopconfirmbtn(true)

    let payload = {};

    if (selectedOption && loadunloadflag) {
      let originalLoadUnload = actualeditmovedata?.stopData?.driver_load_unload?.trim();
      if (originalLoadUnload !== selectedOption) {
        payload.load_unload_flag = selectedOption
      }
    }
    if (editActualsExceptSSandES) {
      let allmovementoncurrentdate = driverState.filter((driver) => driver.driverId == actualeditstopsdata.driverId)[0].movements.filter((mvts) => mvts.startTime > selectedTimezoneDate && mvts.stopData == null)
      let previousStopInfo = allmovementoncurrentdate.filter((mvts) => mvts.movementData.delivery.lm_stop_id == actualeditstopsdata.stopData.lm_stop_id);
      if (previousStopInfo[0].movementData.pickup.actual_departure_utc) {
        if (actualarrival && previousStopInfo[0].movementData.pickup.actual_departure_utc > actualarrival) {
          seteditstopconfirmbtn(false)
          return toast.error("Actual arrival must be greater then previous stops depart", { autoClose: false });
        }
      }
      let originalActualArrival = actualeditmovedata?.stopData?.actual_arrival_utc ?? null;
      let originalActualDepart = actualeditmovedata?.stopData?.actual_departure_utc ?? null;
      if (actualarrival && actualdepart) {
        if (actualarrival > actualdepart) {
          seteditstopconfirmbtn(false)
          return toast.error("Actual depart should be greater than actaul arrival", { autoClose: false });
        }
        payload.actual_arrival_datetime = convertEpochToISO(actualarrival, actualeditmovedata)
        payload.actual_departure_datetime = convertEpochToISO(actualdepart, actualeditmovedata)
      } else if (actualarrival && !actualdepart) {
        if (originalActualDepart && actualarrival > originalActualDepart) {
          seteditstopconfirmbtn(false)
          return toast.error("Actual depart should be greater than actaul arrival", { autoClose: false });
        } else {
          payload.actual_arrival_datetime = convertEpochToISO(actualarrival, actualeditmovedata);
        }
      } else if (actualdepart && !actualarrival) {
        if (originalActualArrival && originalActualArrival > actualdepart) {
          seteditstopconfirmbtn(false)
          return toast.error("Actual depart should be greater than actaul arrival", { autoClose: false });
        } else if (originalActualArrival && originalActualArrival < actualdepart) {
          payload.actual_departure_datetime = convertEpochToISO(actualdepart, actualeditmovedata);
        } else {
          seteditstopconfirmbtn(false)
          return toast.error("Actual arrival need to be filled before saving actual depart", { autoClose: false });
        }
      }
    }
    if(warningDropdownValue){
     payload['option']=warningDropdownValue
    }
    try {
      let response= await editStops(payload, actualeditmovedata?.stopData?.lm_stop_id);
      if(response?.data?.status=='warning'){
        let str=data?.data?.errors?.map((el)=>`${el}`)?.join(",") ?? "";
      setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}   warningMasterData={response?.data?.options ?? []}  parentCallback={stop_ChangeClick} errormssg={str}/></div>)});
     return
    }else if(response?.data?.status=='failure' && response?.data?.errors && response?.data?.errors?.length>0 ){
      let str=response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
      errormessageDisplay(str);
     }else{
     setSuccessResponseMssg("Stop Data Updated successfully.");
     }
     setWarningModalOpen(null);
     seteditstopconfirmbtn(false)
    }
    catch (err) {
      seteditstopconfirmbtn(false);
      setWarningModalOpen(null);
      if(err?.response?.data && err?.response?.data?.errors?.length>0){
        let str=err?.response?.data?.errors?.map((el)=>`${el}\n`)?.join(",")
        errormessageDisplay(str); 
      }else{
        toast.error("Something Went Wrong While Updating Stops", { autoClose: false });
      }
    }

    handleLoadingBarOnDriver(selectedDriverData.driverId, true);
    context.refreshDataFromAPI(context.payloadForRefreshData);
    setEditActualsExceptSSandES(null);
    setSelectedOption("");
    setactualarrival(null);
    setactualdepart(null)
    setStopClickFlag(false);
    setLoadUnloadflag(false);
    setselectedarrival_stops_Date(null);
    setselecteddepart_stops_Date(null);
    setstopsviewmodal(false);
  };

  // using for SS OR ES stops tooltip//
  const handlerightClose4 = () => {
    setShowButtons(null);
    setEditActualSSandES(null);
    setChangeMoveTypeOnBobtailMov(null);
    setAddEmptyMovement(null);
    setIsUnsplitButton(null);
    setunsplitmodaldel(null);
    setunsplitmodalpu(null);
    setChangeMoveTypeOnEmptyMov(null)
    setIsSplitButton(null);
    setStopClickFlag(false);
    setLoadUnloadflag(false);
    setDestChangeDHandDT(false);
  };

  // droptrailermodalhandler
  const openDroptrailerModal = async (location) => {
    try {
      // DT
      let dh_stop_payload = {
        latitude: dropTrailerData.latitude ? dropTrailerData.latitude : dropTrailerData.latitude,
        longitude: dropTrailerData.longitude ? dropTrailerData.longitude : dropTrailerData.longitude,
      };

      // DH 
      let new_dt_stop_payload = {
        latitude: location.latitude,
        longitude: location.longitude,
      };

      const Eresult = await pcMilerAPI(dh_stop_payload, new_dt_stop_payload);

      setmovementforlocmapDropTrailer(location);
      setStartTimeDropTrailer(movementTime(dropTrailerData.stopData.planned_depart_utc, context.plannerTimezone));
      setEndTimeDropTrailer(movementTime(dropTrailerData.stopData.planned_depart_utc + Eresult.duration, context.plannerTimezone));

      setmodalShowDropTrailer(true);
      setIsDropTrailerTrimbleVisible(false);
      setLocationsDataFromMap([]);
    } catch (err) {
      toast.error(`${err?.response?.data}` ?? "Something went wrong. Please try again.");
    }
  };

  // After clicking on Add Empty Movement button
  const movementModalOpenforDT = () => {
    setShowButtons(null);
    setAddEmptyMovement(null);
    setIsDropTrailerTrimbleVisible(true);
  };

  const resetStateValuesforDropTrailer = async () => {
    await context.refreshDataFromAPI(context.payloadForRefreshData);
    // reset all the states
    onModalCloseDropTrailer();
    setIsDropTrailerTrimbleVisible(false);
    setDropTrailerData({});    
    setStartTimeDropTrailer();
    setLocationsDataFromMap([]);
  };

  // After clicking on create button for Drop Trailer 
  const dropTrailerHandler = async (warningDropdownValue) => {    
    handleLoadingBarOnDriver(selectedDriverOnMovement.driverId, true);

    try {
      setaddmovconfirmbtn(true);
      const allMovementsOfSelectedDriver = selectedDriverData.movements;
      let lastEndShiftOfDriver = {};
      let planId = "";

      // Finding if any pre scheduled orders or not.
      if (allMovementsOfSelectedDriver.length > 0) {
        // All end shifts of driver
        const allEndShiftsOfDriver = allMovementsOfSelectedDriver.filter((mov) => mov.movementData.delivery.stop_type === "ES" && mov.stopData === null && mov.movementData.delivery.lm_location_id !== "UNKNOWN").sort((a, b) => a.planned_arrive_utc - b.planned_arrive_utc);

        // Getting exact last end shift
        lastEndShiftOfDriver = allEndShiftsOfDriver[allEndShiftsOfDriver.length - 1];
        planId = lastEndShiftOfDriver.movementData.delivery.plan_id;
      };

      const payload = {
        plan_id: planId,
        movement_type: "empty",
        trailer_id: selectedTrailerOnDropTrailer?.Trailer_ID || "",
        destination_location_id: movementforlocmapForDropTrailer?.code || "",
        stop_id: dropTrailerData.movementData.pickup.lm_stop_id,
      };
      if(warningDropdownValue){
        payload['option']=warningDropdownValue
       }

      const data = await createBobtailOrEmptyMovements(selectedDriverData.driverId, payload);
      if(data?.data?.status=='warning'){
        let str=data?.data?.errors?.map((el)=>`${el}`)?.join(",") ?? "";
        setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}   warningMasterData={data?.data?.options ?? []}  parentCallback={dropTrailerHandler} errormssg={str}/></div>)});
       return
      }else if(data?.data?.status=='failure' && data?.data?.errors && data?.data?.errors?.length>0 ){
        let str=data?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
        errormessageDisplay(str);
       }else{
         setSuccessResponseMssg("Movement created successfully.");
       }
      setWarningModalOpen(null);
      setaddmovconfirmbtn(false);
      // Reset state values
      await resetStateValuesforDropTrailer();
      await context.refreshDataFromAPI(context.payloadForRefreshData);
      
    } catch (err) {
      setaddmovconfirmbtn(false);
      setWarningModalOpen(null);
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        let str = err?.response?.data?.errors?.map((el) => `${el}`);
        toast.error(`${str[0].charAt(0).toUpperCase() + str[0].slice(1)}.`, { autoClose: false });
      } else {
        toast.error(`${err?.response?.data}` ?? "Movements cannot be created successfully. Please try again.",{ autoClose: false });
      }

      handleLoadingBarOnDriver(selectedDriverOnMovement.driverId, false);
    };
  };

  // Create a ref to hold the child component instance
  const auditLogComponentRef = useRef();

  // Audit log modal open handler
  const auditLogModalOpenHandler = (props) => {
    setShowButtons(null);
    const payload = {
      primary_key_value: clickedMovOrStopData?.movement?.id,
      table_name: (clickedMovOrStopData.type === "L" || clickedMovOrStopData.type === "E" || clickedMovOrStopData.type === "BT" ? "movements" : "stops")
    };

    // Access the child component's function using the ref
    auditLogComponentRef.current.fetchAllAuditLogData(payload.primary_key_value, payload.table_name);
  };

  const onModalCloseDropTrailer = () => {
    setmodalShowDropTrailer(false);
    setIsDropTrailerTrimbleVisible(false);  
    setLocationsDataFromMap([]);
    setSelectedTrailerOnDropTrailer();
    resetMapRelatedData();
    setaddmovconfirmbtn(false)
  };

  const handleDropTrailerDropTrailer = (e, value) => {
    if (value) {
      setSelectedTrailerOnDropTrailer(value);
    }
  };

  // This function will be called on clicking right button on Start and End Shift
  const handleRightClickOnShifts = (e, shift, driver) => {
    e.preventDefault();

    if (showButtons) {
      setShowButtons(null);
    } else {
      if (shift.shiftType === "start") {
        setClickedMovOrStopData({ type: shift, movement: shift.movementData.pickup });
      } else {
        setClickedMovOrStopData({ type: shift, movement: shift.movementData.delivery });
      }

      let stop_data_shift = shift.movementData.delivery.planned_arrive_utc
      let filterstop_shift_Array = driver.movements?.filter((e) => e.movementData.pickup.planned_arrive_utc < stop_data_shift)
      let last_stops_shiftdata_other = filterstop_shift_Array.find(o => o.movementData.pickup.planned_depart_utc === Math.max(...filterstop_shift_Array.map(i => i.movementData.pickup.planned_depart_utc)));

      if (shift.shiftType === "start" ||
        shift.shiftType === "end" && last_stops_shiftdata_other.movementData.pickup.actual_arrival_utc != 0 &&
        last_stops_shiftdata_other.movementData.pickup.actual_departure_utc != 0) {
        setactualeditdatamodal(shift);
        setEditActualSSandES(e.currentTarget);
      }
      // This state is responsible for showing Edit Actuals buttons for Shift
      if (shift.shiftType === "start") {
        setEditActualSSandES(e.currentTarget);
      }
      setShowButtons(e.currentTarget);
    }
  };

  const actualeditModal = async (i, shift) => {
    setShowButtons(null);
    let timestamp = null;
    if (shift.shiftType === "start") {
      if (![0, null, undefined, ""].includes(shift.movementData.pickup.actual_arrival_utc)) {
        timestamp = DateTime.fromMillis(parseInt(shift.movementData.pickup.actual_arrival_utc
        )).setZone(shift.movementData.pickup.pickup_timezone ? shift.movementData.pickup.pickup_timezone : shift.movementData.delivery.delivery_timezone).toFormat("yyyy-MM-dd HH:mm").toString();
        setselectedactualDate(timestamp);
      }
      let timestamp2 = null;
      if (![0, null, undefined, ""].includes(shift.movementData.pickup.actual_departure_utc)) {
        timestamp2 = DateTime.fromMillis(parseInt(shift.movementData.pickup.actual_departure_utc
        )).setZone(shift.movementData.pickup.pickup_timezone ? shift.movementData.pickup.pickup_timezone : shift.movementData.delivery.delivery_timezone).toFormat("yyyy-MM-dd HH:mm").toString();
        setselecteddepartDate(timestamp2);
      }
    } else if (shift.shiftType === "end") {
      let timestamp1 = null;
      if (![0, null, undefined, ""].includes(shift.movementData.delivery.actual_arrival_utc)) {
        timestamp1 = DateTime.fromMillis(parseInt(shift.movementData.delivery.actual_arrival_utc
        )).setZone(shift.movementData.pickup.pickup_timezone ? shift.movementData.pickup.pickup_timezone : shift.movementData.delivery.delivery_timezone).toFormat("yyyy-MM-dd HH:mm").toString();

        setselectedactualDate(timestamp1);
      }
      let timestamp3 = null;
      if (![0, null, undefined, ""].includes(shift.movementData.delivery.actual_departure_utc)) {
        timestamp3 = DateTime.fromMillis(parseInt(shift.movementData.delivery.actual_departure_utc
        )).setZone(shift.movementData.pickup.pickup_timezone ? shift.movementData.pickup.pickup_timezone : shift.movementData.delivery.delivery_timezone).toFormat("yyyy-MM-dd HH:mm").toString();

        setselecteddepartDate(timestamp3);
      }
    }

    setactualeditData(true)
    setEditActualSSandES(null);
  };

  const handleactualDateChange = (e) => {
    setselectedactualDate(e.target.value)
    if (actualeditdatamodal.shiftType === "start") {
      let actualedit_time = DateTime.fromISO(e.target.value).setZone(`${actualeditdatamodal.movementData.pickup.pickup_timezone ? actualeditdatamodal.movementData.pickup.pickup_timezone : actualeditdatamodal.movementData.delivery.delivery_timezone}`, { keepLocalTime: true }).toMillis();
      setactual_arrivaldate(actualedit_time);
    }
    if (actualeditdatamodal.shiftType === "end") {
      let actualedit_time1 = DateTime.fromISO(e.target.value)
        .setZone(
          `${actualeditdatamodal.movementData.pickup.pickup_timezone
            ? actualeditdatamodal.movementData.pickup.pickup_timezone
            : actualeditdatamodal.movementData.delivery.delivery_timezone
          }`,
          { keepLocalTime: true }
        )
        .toMillis();
      setactual_arrivaldate1(actualedit_time1);
    }
    setEditStopChangeFlag(false)
  };

  const handledepartDateChange = (e) => {
    setselecteddepartDate(e.target.value)
    if (actualeditdatamodal.shiftType === "start") {
      let actualedit_time2 = DateTime.fromISO(e.target.value).setZone(`${actualeditdatamodal.movementData.pickup.pickup_timezone ? actualeditdatamodal.movementData.pickup.pickup_timezone : actualeditdatamodal.movementData.delivery.delivery_timezone}`, { keepLocalTime: true }).toMillis();
      setactual_departdate(actualedit_time2)
    }
    if (actualeditdatamodal.shiftType === "end") {
      let actualedit_time3 = DateTime.fromISO(e.target.value).setZone(`${actualeditdatamodal.movementData.pickup.pickup_timezone ? actualeditdatamodal.movementData.pickup.pickup_timezone : actualeditdatamodal.movementData.delivery.delivery_timezone}`, { keepLocalTime: true }).toMillis();
      setactual_departdate1(actualedit_time3)
    }
    setEditStopChangeFlag(false)
  };

  const ss_es_modalclosehandelar = () => {
    setactual_arrivaldate()
    setactual_arrivaldate1()
    setactual_departdate()
    setactual_departdate1()
    setselecteddepartDate()
    setselectedactualDate()
    setactualeditData(false);
    setEditStopChangeFlag(true);
    seteditstopconfirmbtn(false);
  }

  const converEpochToIsoForShifts = (dateTime, data) => {
    let IsoDateTime = DateTime.fromMillis(parseInt(dateTime))
      .setZone(
        data?.movementData?.pickup?.pickup_timezone ? data?.movementData?.pickup?.pickup_timezone : data?.movementData?.delivery?.delivery_timezone
      ).toISO();
    return IsoDateTime
  }

  const saveDate = async (warningDropdownValue) => {
    if (!isEditPermission) {
      return toast.error("Sorry You Dont Have Permission To Edit Stops", { autoClose: false });
    }

    let payload = {};
    seteditstopconfirmbtn(true)
    if (actualeditdatamodal.shiftType === "start") {
      let originalActualArrival = actualeditdatamodal?.movementData?.pickup?.actual_arrival_utc ?? null;
      let originalActualDepart = actualeditdatamodal?.movementData?.pickup?.actual_departure_utc ?? null;
      if (actual_arrivaldate && actual_departdate) {
        if (actual_arrivaldate > actual_departdate) {
          seteditstopconfirmbtn(false)
          return toast.error("Actual depart should be greater than actaul arrival", { autoClose: false });
        }
        payload.actual_arrival_datetime = converEpochToIsoForShifts(actual_arrivaldate, actualeditdatamodal);
        payload.actual_departure_datetime = converEpochToIsoForShifts(actual_departdate, actualeditdatamodal);
      } else if (actual_arrivaldate && !actual_departdate) {
        if (originalActualDepart && actual_arrivaldate > originalActualDepart) {
          seteditstopconfirmbtn(false)
          return toast.error("Actual depart should be greater than actaul arrival", { autoClose: false });
        } else {
          payload.actual_arrival_datetime = converEpochToIsoForShifts(actual_arrivaldate, actualeditdatamodal);
        }
      } else if (actual_departdate && !actual_arrivaldate) {
        if (originalActualArrival && originalActualArrival > actual_departdate) {
          seteditstopconfirmbtn(false)
          return toast.error("Actual depart should be greater than actaul arrival", { autoClose: false });
        } else if (originalActualArrival && originalActualArrival < actual_departdate) {
          payload.actual_departure_datetime = converEpochToIsoForShifts(actual_departdate, actualeditdatamodal);;
        } else {
          seteditstopconfirmbtn(false)
          return toast.error("Actual arrival need to be filled before saving actual depart", { autoClose: false });
        }
      }
      if(warningDropdownValue){
        payload['option']=warningDropdownValue
       }

      try {
       let response= await editStops(payload, actualeditdatamodal?.movementData?.pickup?.lm_stop_id);
       if(response?.data?.status=='warning'){
        let str=data?.data?.errors?.map((el)=>`${el}`)?.join(",") ?? "";
        setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}   warningMasterData={response?.data?.options ?? []}  parentCallback={saveDate} errormssg={str}/></div>)});
       return
      }else if(response?.data?.status=='failure' && response?.data?.errors && response?.data?.errors?.length>0 ){
        let str=response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
        errormessageDisplay(str);
       }else{
         setSuccessResponseMssg("Stop Data Updated Successfully.");
       }
       setWarningModalOpen(null);
       seteditstopconfirmbtn(false)
      }
      catch (err) {
        seteditstopconfirmbtn(false)
        setWarningModalOpen(null);
        if(err?.response?.data && err?.response?.data?.errors?.length>0){
          let str=err?.response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
           errormessageDisplay(str); 
        }else{
            toast.error("Something Went Wrong While Updating Stops", { autoClose: false });
        }
        
      }
    } else if (actualeditdatamodal.shiftType === "end") {
      let originalActualArrival = actualeditdatamodal?.movementData?.delivery?.actual_arrival_utc ?? null;
      let originalActualDepart = actualeditdatamodal?.movementData?.delivery?.actual_departure_utc ?? null;
      if (actual_arrivaldate1 && actual_departdate1) {
        if (actual_arrivaldate1 > actual_departdate1) {
          seteditstopconfirmbtn(false)
          return toast.error("Actual depart should be greater than actaul arrival", { autoClose: false });
        }
        payload.actual_arrival_datetime = converEpochToIsoForShifts(actual_arrivaldate1, actualeditdatamodal);
        payload.actual_departure_datetime = converEpochToIsoForShifts(actual_departdate1, actualeditdatamodal);
      } else if (actual_arrivaldate1 && !actual_departdate1) {
        if (originalActualDepart && actual_arrivaldate1 > originalActualDepart) {
          seteditstopconfirmbtn(false)
          return toast.error("Actual depart should be greater than actaul arrival", { autoClose: false });
        } else {
          payload.actual_arrival_datetime = converEpochToIsoForShifts(actual_arrivaldate1, actualeditdatamodal);;
        }
      } else if (actual_departdate1 && !actual_arrivaldate1) {
        if (originalActualArrival && originalActualArrival > actual_departdate1) {
          seteditstopconfirmbtn(false)
          return toast.error("Actual depart should be greater than actaul arrival", { autoClose: false });
        } else if (originalActualArrival && originalActualArrival < actual_departdate1) {
          payload.actual_departure_datetime = converEpochToIsoForShifts(actual_departdate1, actualeditdatamodal);;
        } else {
          seteditstopconfirmbtn(false)
          return toast.error("Actual arrival need to be filled before saving actual depart", { autoClose: false });
        }
      }
      if(warningDropdownValue){
        payload['option']=warningDropdownValue
       }
      try {
      let response=await editStops(payload, actualeditdatamodal?.movementData?.delivery?.lm_stop_id);
      if(response?.data?.status=='warning'){
        let str=data?.data?.errors?.map((el)=>`${el}`)?.join(",") ?? "";
        setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}   warningMasterData={response?.data?.options ?? []}  parentCallback={saveDate} errormssg={str}/></div>)});
       return
      }else if(response?.data?.status=='failure' && response?.data?.errors && response?.data?.errors?.length>0 ){
        let str=response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
        errormessageDisplay(str);
       }else{
         setSuccessResponseMssg("Stop Data Updated Successfully.");
       }
        setWarningModalOpen(null);
        seteditstopconfirmbtn(false);
      }
      catch (err) {
        setWarningModalOpen(null);
        seteditstopconfirmbtn(false)
        if(err?.response?.data && err?.response?.data?.errors?.length>0){
          let str=err?.response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
           errormessageDisplay(str); 
        }else{
            toast.error("Something Went Wrong While Updating Stops", { autoClose: false });
        }
       
      }
    }
    handleLoadingBarOnDriver(selectedDriverData.driverId, true);
    setactualeditData(false);
    setactual_arrivaldate(null);
    setactual_arrivaldate1(null);
    setactual_departdate(null);
    setactual_departdate1(null);
    setselectedactualDate(null);
    setselecteddepartDate(null);
    await context.refreshDataFromAPI(context.payloadForRefreshData);
  }

  // movement for E //
  const Movementformodaldata = async (i, terminalname) => {
     setShowButtons(null)
    if(actualeditmovedata?.type?.trim()=="E"){
      setMovementChangeTypeText("Empty To Bobtail")
    }else if(actualeditmovedata?.type?.trim()=="BT"){
      setMovementChangeTypeText("Bobtail To Empty")
    }
    setmovementformodal(true);
    setmovementdata(terminalname);
  };

  const movement_E_ChangeClick = async (warningDropdownValue) => {
    setChangemovetypeconfirmbtn(true)
    const payload = {
      type: actualeditmovedata?.movementData?.movement_type === 'E' ? "bobtail" : "Empty",
    };
    if(warningDropdownValue){
      payload['option']=warningDropdownValue
     }

    try {
    let response=await editMovements(payload, actualeditmovedata?.movementData?.load_id);
    if(response?.data?.status=='warning'){
      let str=data?.data?.errors?.map((el)=>`${el}`)?.join(",") ?? "";
      setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}   warningMasterData={response?.data?.options ?? []}  parentCallback={movement_E_ChangeClick} errormssg={str}/></div>)});
     return
    }else if(response?.data?.status=='failure' && response?.data?.errors && response?.data?.errors?.length>0 ){
      let str=response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
      errormessageDisplay(str);
     }else{ 
       setSuccessResponseMssg("Movement Type changed successfully");
     }
     setWarningModalOpen(null);
    setChangemovetypeconfirmbtn(false)
      await context.refreshDataFromAPI({
        "date": selectedTimezoneDate,
        "terminals": [selectedPlanningProfile.terminal_id]
      });
      setmovementformodal(false);
      setMOvementsEditModal(false);
      setMovementChangeTypeText("");
      setChangeMoveTypeOnBobtailMov(false);
      setChangeMoveTypeOnEmptyMov(false);
    }
    catch (err) {
      setWarningModalOpen(null);
      setChangemovetypeconfirmbtn(false)
      if(err?.response?.data && err?.response?.data?.errors?.length>0){
        let str=err?.response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
        errormessageDisplay(str); 
      }else{
      toast.error("Something Went Wrong While Updating Movements", { autoClose: false }); 
      }
    }

  };

  // unsplit handler
  const unsplitdelhandler = async (warningDropdownValue) => {
    setShowButtons(null);
    setIsUnsplitButton(null);
    setStopClickFlag(false);
    let final_driver_pb_data = null;
    handleLoadingBarOnDriver(selectedDriverData.driverId, true);     
    try {
      let driverMovements = driverState.filter(it2 => it2.driverId === clickedDriver)[0]?.movements;
      let movements = driverMovements.filter((el) => el.stopData === null);
      let loadedMovement = movements?.filter((el) => ((el.movementData.pickup.lm_stop_id === unsplitdataDel?.stopData?.lm_stop_id) || (el.movementData.delivery.lm_stop_id === unsplitdataDel?.stopData?.lm_stop_id)) && el.movementData.movement_type === 'L');

      const payload = {
        movement_id: loadedMovement[0]?.movementData?.load_id,
        unsplitType: unsplitdataDel?.movementData?.pickup?.type
      };
      if(warningDropdownValue){
        payload['option']=warningDropdownValue
       }

      let response=await createUnSplit(payload);
      if(response?.data?.status=='warning'){
        let str=data?.data?.errors?.map((el)=>`${el}`)?.join(",") ?? "";
        setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}   warningMasterData={response?.data?.options ?? []}  parentCallback={unsplitdelhandler} errormssg={str}/></div>)});
       return
      }else if(response?.data?.status=='failure' && response?.data?.errors && response?.data?.errors?.length>0 ){
        let str=response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
        errormessageDisplay(str);
       }else{
         setSuccessResponseMssg("Unsplit Done Successfully");
       }
       setWarningModalOpen(null);
      setunsplitmodaldel(null);
      setUnsplitDatadel([]);
      setactualeditstopsdata([]);
      await context.refreshDataFromAPI(context.payloadForRefreshData);
    } catch (err) {
      setunsplitmodaldel(null);
      setWarningModalOpen(null);
      setUnsplitDatadel([]);
      setactualeditstopsdata([]);
      await context.refreshDataFromAPI(context.payloadForRefreshData);
      setDriverState(prevDriverState => prevDriverState.map(it2 => {
        if (it2.driverId === final_driver_pb_data?.driverId || it2.driverId === unsplitdataDel?.driverId) {
          return {
            ...it2,
            isloading: false, // Set isLoading back to false
          };
        }
        return it2;
      }));
      if(err?.response?.data && err?.response?.data?.errors?.length>0){
        let str=err?.response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
        errormessageDisplay(str); 
      }else{
        toast.error("Something Went Wrong While unsplit", { autoClose: false }); 
      }
    }
  };

  const unsplitpuhandler = async (warningDropdownValue) => {
    setShowButtons(null);
    setIsUnsplitButton(null);
    setunsplitmodalpu(null);
    handleLoadingBarOnDriver(selectedDriverData.driverId, true);  
    setStopClickFlag(false);   
    let final_driver_pb_data = null;
    try {

      let driverMovements = driverState.filter(it2 => it2.driverId === clickedDriver)[0]?.movements;
      let movements = driverMovements.filter((el) => el.stopData === null);
      let loadedMovement = movements?.filter((el) => ((el.movementData.pickup.lm_stop_id === unsplitdatapu?.stopData?.lm_stop_id) || (el.movementData.delivery.lm_stop_id === unsplitdatapu?.stopData?.lm_stop_id)) && el.movementData.movement_type === 'L');

      const payload = {
        movement_id: loadedMovement[0]?.movementData?.load_id,
        unsplitType: unsplitdatapu?.movementData?.pickup?.type
      }
      if(warningDropdownValue){
        payload['option']=warningDropdownValue
       }

     let response= await createUnSplit(payload);  
     if(response?.data?.status=='warning'){
      let str=data?.data?.errors?.map((el)=>`${el}`)?.join(",") ?? "";
      setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}   warningMasterData={response?.data?.options ?? []}  parentCallback={unsplitpuhandler} errormssg={str}/></div>)});
     return
    }else if(response?.data?.status=='failure' && response?.data?.errors && response?.data?.errors?.length>0 ){
      let str=response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
      errormessageDisplay(str);
     }else{
       setSuccessResponseMssg("Unsplit Done Successfully");
     }
     setWarningModalOpen(null);    
      setunsplitmodalpu(null);
      setUnsplitDatapu([]);
      setactualeditstopsdata([]);
      await context.refreshDataFromAPI(context.payloadForRefreshData);
      handleLoadingBarOnDriver(unsplitdatapu.driverId, false);      
    } catch (error) {
      setunsplitmodalpu(null);
      setWarningModalOpen(null);    
      setUnsplitDatapu([]);
      setactualeditstopsdata([]);
      await context.refreshDataFromAPI(context.payloadForRefreshData);
      setDriverState(prevDriverState => prevDriverState.map(it2 => {
        if (it2.driverId === final_driver_pb_data?.driverId || it2.driverId === unsplitdatapu?.driverId) {
          return {
            ...it2,
            isloading: false, // Set isLoading back to false
          };
        }
        return it2;
      }));
      if(err?.response?.data && err?.response?.data?.errors?.length>0){
        let str=err?.response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
        errormessageDisplay(str); 
      }else{
        toast.error("Something Went Wrong While unsplit", { autoClose: false }); 
      }
    }
  };

  // Destination Handler //
  const destinationviewModalopen = async () => {
    setShowButtons(null);
    let payload = {
      longitude: deldestinationlong?.longitude,
      latitude: deldestinationlat?.latitude,
      searchtext: searchLocationData,
      distance: selectedDistance
    };
    setdestinationViewModal(true);
    try {
      setIsLocationLoading(true);
      const latlanglocation = await getTerminalbylatlong(payload);
      if (latlanglocation) {
        setIsLocationLoading(false);
        setLocationsDataFromMap(latlanglocation);
      }
    } catch (error) {
      toast.error("There is a problem in retrieving the location data.", { autoClose: false });
     }
  };

  const destinationchangeClick = async (latlonlocationdata,warningDropdownValue) => {
    setChangedestconfirmbtn(true);
    handleLoadingBarOnDriver(selectedDriverData.driverId, true);      
    if(destChangeDHandDT){
      try {
        let payload={
          location_id:latlonlocationdata.code
        }
        if(warningDropdownValue){
          payload['option']=warningDropdownValue
         }
  
        let response= await editStops(payload, dropTraileranddrophookData?.stopData?.lm_stop_id);
        setChangedestconfirmbtn(false);
        if(response?.data?.status=='warning'){
          let str=data?.data?.errors?.map((el)=>`${el}`)?.join(",") ?? "";
          setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}   warningMasterData={response?.data?.options ?? []} payload={latlonlocationdata} parentCallback={destinationchangeClick} errormssg={str}/></div>)});
         return
        }else if(response?.data?.status=='failure' && response?.data?.errors && response?.data?.errors?.length>0 ){
          let str=response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
          errormessageDisplay(str);
         }else{
         setSuccessResponseMssg("Stop Data Updated successfully.");
         }
         setWarningModalOpen(null);
        setDestChangeDHandDT(false);
        setdestinationViewModal(false);
        setDropTraileranddrophookData(null);
       }
       catch (err) {
        setChangedestconfirmbtn(false);
        setWarningModalOpen(null);
         if(err?.response?.data && err?.response?.data?.errors?.length>0){
           let str=err?.response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");    
           return toast.error(`${str}`); 
         }else{    
           return toast.error("Something Went Wrong While Updating Stops", "Error");
         }
       }
    }
  await context.refreshDataFromAPI({
    "date": selectedTimezoneDate,
    "terminals": [selectedPlanningProfile.terminal_id]
  });
  };

  const splitviewclick = async (e, data, location, driverIddropdown) => {
    setlocationcodeData(location);
    setorderData(data.lm_order_id);
    setdriverIddropdown(driverIddropdown);

    const terminallocationdata = await getAllTerminals();
    setterminalData(terminallocationdata);

    let terminaldefaultdata = terminallocationdata.filter(
      (item) => item.terminal_id === data.movementData.terminal_id
    );
    setterminalHandelardata((item) => ({
      ...item,
      terminal_id: data.movementData.terminal_id,
    }));
    setdefaultterminal(terminaldefaultdata);
    setsplitviewmodal(true);
    setMOvementsEditModal(false);
  };

  const handeDriverViewPagination = async (event, value) => {
    context.setDriverPageNumber(value);
    setPage(value);
  };

  const movementTime = (epoch_date, tzone) => {
    return DateTime.fromMillis(parseInt(epoch_date))
      .setZone(tzone)
      .toFormat("yyyy-MM-dd HH:mm")
      .toString();
  };

  const impactviewclick = async (e, data, data1) => {
    const usrData = await getUser(userData.id);
    setUserDataUPdate(usrData);

    if (data1.movementData.pickup.eta_utc > data1.movementData.pickup.planned_arrive_utc) {
      if (data == "PU" || "SP") {
        setDataLoaded(false)
        setImpactViewModal(true);
        const impactViewData = getImapctView(
          data1.movementData.pickup.planned_arrive_utc,
          data1.driverId,
          data1.trailer_id
        )
          .then((res) => {
            const impactdata = res.data.filter(obj => obj.Move_ID !== data1.movementData.load_id);
            setallimpact(impactdata);
            setDataLoaded(true)
          })
          .catch((err) => {
            setDataLoaded(false)
          });
      }

    }

    if (data1.movementData.delivery.eta_utc > data1.movementData.delivery.planned_arrive_utc) {
      if (data == "DEL" || "SD") {
        setDataLoaded(false)
        setImpactViewModal(true);
        const impactViewData = getImapctView(
          data1.movementData.delivery.planned_arrive_utc,
          data1.driverId,
          data1.trailer_id
        )
          .then((res) => {
            const impactdata = res.data.filter(obj => obj.Move_ID !== data1.movementData.load_id);
            setallimpact(impactdata);
            setDataLoaded(true)
            // setImpactViewModal(true);
          })
          .catch((err) => {
            setDataLoaded(false)
          });
      }
    }
  };

  const Blockmodalopen = async (    
    terminalname,
    driverIddropdown,
    movementlocationname
  ) => {
    setShowButtons(null);
    setterminalname(terminalname);
    setorderData(terminalname);
    setmovementlocationName(movementlocationname);
    setLocationsDataFromMap([]);

    // driver api call for driver dropdown outside modal //
    let filterData = {
      terminalId: [terminalname.terminal_id],
    };
    const driverdropdowndata = await getAllDrivers(filterData);
    let driverdefaultdata = driverdropdowndata.filter(
      (e) => e.driver_id === driverIddropdown
    );
    setdriverHandelardata((item) => ({
      ...item,
      driver_id: driverIddropdown,
    }));
    setdefaultdriver(driverdefaultdata);
    setdriverdropdownData(driverdropdowndata);

    setlocationViewModal(true);
  };

  // Trimble map utility function
  async function showTrimbleMap(isMapVisible, BaseConfig, intialLatLong1, intialLatLong2, type) {
    if (isMapVisible) {
      TrimbleMaps.APIKey = BaseConfig.Mapkey;

      const map = new TrimbleMaps.Map({
        container: "map",
        style: TrimbleMaps.Common.Style.TRANSPORTATION,
        center: new TrimbleMaps.LngLat(
          intialLatLong1.longitude,
          intialLatLong1.latitude
        ),
        zoom: 12,
      });

      // It will be dynamically generated
      let myRoute;
      if (type === "dropTrailer" || type === "addMovement") {
        myRoute = new TrimbleMaps.Route({
          container: "map",
          routeId: "myRoute",
          style: TrimbleMaps.Common.Style.TRANSPORTATION,
          stops: [
            new TrimbleMaps.LngLat(
              intialLatLong1.longitude,
              intialLatLong1.latitude
            ),
            new TrimbleMaps.LngLat(
              intialLatLong1.longitude,
              intialLatLong1.latitude
            ),
          ],
        });
      } else {
        myRoute = new TrimbleMaps.Route({
          container: "map",
          routeId: "myRoute",
          style: TrimbleMaps.Common.Style.TRANSPORTATION,
          stops: [
            new TrimbleMaps.LngLat(
              intialLatLong1.longitude,
              intialLatLong1.latitude
            ),
            new TrimbleMaps.LngLat(
              intialLatLong2.longitude,
              intialLatLong2.latitude
            ),
          ],
        });
      }

      // It will be dynamically generated
      let marker;
      if (type === "dropTrailer" || type === "addMovement" || type === "locationViewModal") {
        marker = new TrimbleMaps.Marker({
          draggable: true,
        }).setLngLat([
          intialLatLong1.longitude,
          intialLatLong1.latitude
        ]).addTo(map);
      } else {
        marker = new TrimbleMaps.Marker({
          draggable: true,
        }).setLngLat([
          intialLatLong2.longitude,
          intialLatLong2.latitude
        ]).addTo(map);
      }

      map.addControl(new TrimbleMaps.NavigationControl());
      const scale = new TrimbleMaps.ScaleControl({
        maxWidth: 80,
        unit: "imperial",
      });

      map.on("load", function () {
        myRoute.addTo(map);
      });

      map.addControl(scale);

      marker.on("dragend", async function (e) {
        const lngLat = e.target.getLngLat();
        setSelectedLocationLongitude(lngLat.lng.toFixed(4));
        setSelectedLocationLatitude(lngLat.lat.toFixed(4));

        let payload = {
          longitude: lngLat.lng.toFixed(4),
          latitude: lngLat.lat.toFixed(4),
          searchtext: searchLocationData,
          distance: selectedDistance,
        };

        try {
          setIsLocationLoading(true);
          const allLocationsBasedOnLatLong = await getTerminalbylatlong(payload);
          if (allLocationsBasedOnLatLong) {
            setIsLocationLoading(false);      
            setLocationsDataFromMap(allLocationsBasedOnLatLong);
          }
        } catch (error) {
          toast.error("There is a problem in retrieving the location data.", { autoClose: false });
        }
      });


      // Initial loading
      if (selectedLocationLongitude && selectedLocationLatitude) {
        let payload = {
          longitude: selectedLocationLongitude,
          latitude: selectedLocationLatitude,
          searchtext: searchLocationData,
          distance: selectedDistance,
        };
  
        try {
          setIsLocationLoading(true);
          const allLocationsBasedOnLatLong = await getTerminalbylatlong(payload);
          if (allLocationsBasedOnLatLong) {
            setIsLocationLoading(false);      
            setLocationsDataFromMap(allLocationsBasedOnLatLong);
          }
        } catch (error) {
          toast.error("There is a problem in retrieving the location data.", { autoClose: false });
        }
      }
    }
  };

  // useEffect for showing map for different scenarios
  useEffect(() => {
    if (isDropTrailerTrimbleVisible) {
      (async () => {
        await showTrimbleMap(isDropTrailerTrimbleVisible, BaseConfig, dropTrailerData, dropTrailerData, "dropTrailer");
      })();

    } else if (isAddMovTrimbleVisible) {
      (async () => {
        await showTrimbleMap(isAddMovTrimbleVisible, BaseConfig, selectedDriverData?.terminal, selectedDriverData?.terminal, "addMovement");
      })();
    } else if (locationViewModal) {
      (async () => {
        await showTrimbleMap(locationViewModal, BaseConfig, routeData?.movementData?.pickup, routeData?.movementData?.delivery, "locationViewModal");

      })();
    } else if (destinationViewModal) {
      (async () => {
        await showTrimbleMap(destinationViewModal, BaseConfig, pickupdestinationlong, deldestinationlong, "destinationViewModal");
      })();
    }
  }, [isDropTrailerTrimbleVisible, isAddMovTrimbleVisible, locationViewModal, destinationViewModal]);

  const terminalIdHandler = (e, terminalname) => {
    if (terminalname) {
      setterminalHandelardata((item) => ({
        ...item,
        terminal_id: terminalname.terminal_id,
      }));
    }
  };

  const driverIdHandler = (e, driverIddropdown) => {
    if (driverIddropdown) {
      setdriverHandelardata((item) => ({
        ...item,
        driver_id: driverIddropdown.driver_id,
      }));
    }
  };

  const splitAssignDriverviewclick = async (warningDropdownValue) => {
    try {
      setSplitConfirmbtn(true)
      handleLoadingBarOnDriver(driverHandelardata.driver_id, true);     
      const payload = {
        movement_id: routeData?.movementData?.load_id,
        split_location_id: locationcodeData?.code,
        terminal_id: terminalHandelardata ? terminalHandelardata?.terminal_id : defaultterminal[0]?.terminal_id
      }
      if(warningDropdownValue){
        payload['option']=warningDropdownValue
       }

     let response= await createSplit(payload);
     if(response?.data?.status=='warning'){
      let str=data?.data?.errors?.map((el)=>`${el}`)?.join(",") ?? "";
      setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}   warningMasterData={response?.data?.options ?? []} parentCallback={splitAssignDriverviewclick} errormssg={str}/></div>)});
     return
    }else if(response?.data?.status=='failure' && response?.data?.errors && response?.data?.errors?.length>0 ){
      let str=response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
      errormessageDisplay(str);
     }else{
       setSuccessResponseMssg("Spilt order has been created successfully.");
     }
    setWarningModalOpen(null);
      await context.refreshDataFromAPI(context.payloadForRefreshData);
      setdriverviewmodal(false);
      setlocationViewModal(false);
      setsplitviewmodal(false);
      setSplitConfirmbtn(false);
      setIsSplitButton(null); 
    } catch (error) {
      setWarningModalOpen(null);
      handleLoadingBarOnDriver(driverHandelardata.driver_id, false);  
      setSplitConfirmbtn(false)   
      if(error?.response?.data && error?.response?.data?.errors?.length>0){
        let str=error?.response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
        errormessageDisplay(str); 
      }else{
      toast.error("Something Went Wrong While unsplit", { autoClose: false }); 
      }
    }
  };

  const msToTime = (duration) => {
    if (duration) {
      let seconds = Math.floor((duration / 1000) % 60);
      let minutes = Math.floor((duration / (1000 * 60)) % 60);
      let hours = Math.floor(duration / (1000 * 60 * 60));

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return null;
    }
  };

  /* It is for removing complete order from planning board */
  const deleteOrdersplanningBoard = async (it, driver_id) => {
    try {
      let remove_payload = [
        ...driverState
          .filter((dri) => dri.driverId === driver_id)[0]
          ?.movements?.filter(
            (mvmt) =>
              mvmt.orderId2 == it.orderId2 &&
              mvmt.movementData.movement_type != "L"
          )
          .map((mvmt) => ({
            load_id: mvmt.move_id,
          })),
        ...context.movementData.data
          .filter(
            (mvmt) => it.orderId2 == mvmt.order_id2 && mvmt.movement_type != "L"
          )
          .map((mvmt) => ({
            load_id: mvmt.load_id,
          })),
      ];

      await removemultiplemovements(remove_payload);

      await updatemultiplemovements([
        // TODO: Needs to be unscheduled
        ...driverState
          .filter((dri) => dri.driverId === driver_id)[0]
          ?.movements?.filter(
            (mvmt) =>
              mvmt.orderId2 == it.orderId2 &&
              mvmt.movementData.movement_type == "L"
          )
          .map((mvmt) => ({
            load_id: mvmt.move_id,
          })),
        ...context.movementData.data
          .filter(
            (mvmt) => it.orderId2 == mvmt.order_id2 && mvmt.movement_type == "L"
          )
          .map((mvmt) => ({
            load_id: mvmt.load_id,
          })),
      ]);

      NotificationManager.success(
        "The Order has been removed from the Planning Board",
        "Success"
      );

      //await context. refreshDataFromAPI(payloadForPlanningBoard);
    } catch (error) {
      NotificationManager.error(
        "OOPS! Something went wrong , contact your adminstrator",
        "Error"
      );
    }
  };

  const handleSearchInput = (e) => {
    setSearchLocationData(e.target.value);
  };

  const searchbylatlong = async (e, searchText) => {
    e.preventDefault();
    try {
      if (searchText && selectedLocationLongitude && selectedLocationLatitude) {
        setIsLocationLoading(true);
        let payload = {
          longitude: selectedLocationLongitude,
          latitude: selectedLocationLatitude,
          searchtext: searchText,
          distance: selectedDistance
        };
        const latlanglocation = await getTerminalbylatlong(payload);

        setLocationsDataFromMap(latlanglocation);
        setIsLocationLoading(false);
      } else if (selectedLocationLongitude && selectedLocationLatitude) {
        setIsLocationLoading(true);
        let payload = {
          longitude: selectedLocationLongitude,
          latitude: selectedLocationLatitude,
          searchtext: searchLocationData,
          distance: selectedDistance
        };
        const latlanglocation = await getTerminalbylatlong(payload);
        setLocationsDataFromMap(latlanglocation);
        setIsLocationLoading(false);
      }

    } catch (error) {
      toast.error("There is a problem in retrieving the location data.", { autoClose: false })
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchbylatlong(e);
    }
  };

  const filterLocationByDist = async (e) => {
    const value = Number(e.target.value);
    setSelectedDistance(value);
    try {
      if (selectedLocationLongitude && selectedLocationLatitude) {
        setIsLocationLoading(true);
        let payload = {
          longitude: selectedLocationLongitude,
          latitude: selectedLocationLatitude,
          searchtext: searchLocationData,
          distance: value
        };
        
        const latlanglocation = await getTerminalbylatlong(payload);

        setLocationsDataFromMap(latlanglocation);
        setIsLocationLoading(false);
      }
    } catch (error) {
      toast.error("There is a problem in retrieving the location data.", { autoClose: false })
    }
  };

  const removetrailerfrommovement = async (i, removeTrailerBlock, driver_id, selectedOrderForTrailer, driver) => {
    // For handling loading bar on driver row
    handleLoadingBarOnDriver(driver_id, true);    
    const orderId = selectedOrderForTrailer.length > 0 ? selectedOrderForTrailer[0].value : null;

    const allMovs = [];
    let planId = null;

    try {
      if (selectedOrderForTrailer.length > 0) {
        for (let i = 0; i < selectedOrderForTrailer.length; i++) {
          const allMovsBtwTrailerStartToEnd = driver.movements.filter((mov) => {
            return (
              mov.stopData === null && (mov.movementData.pickup.planned_arrive_utc >= removeTrailerBlock.trailerStartTime || mov.movementData.pickup.planned_depart_utc >= removeTrailerBlock.trailerStartTime) && (mov.movementData.delivery.planned_arrive_utc <= removeTrailerBlock.trailerEndTime || mov.movementData.delivery.planned_depart_utc <= removeTrailerBlock.trailerEndTime) && (mov.movementData.order_id2 === selectedOrderForTrailer[i].value)
            )
          });

          allMovs.push(allMovsBtwTrailerStartToEnd);
        }
      } else {
        const allMovsBtwTrailerStartToEnd = driver.movements.filter((mov) => {
          return (
            mov.stopData === null && (mov.movementData.pickup.planned_arrive_utc >= removeTrailerBlock.trailerStartTime || mov.movementData.pickup.planned_depart_utc >= removeTrailerBlock.trailerStartTime) && (mov.movementData.delivery.planned_arrive_utc <= removeTrailerBlock.trailerEndTime || mov.movementData.delivery.planned_depart_utc <= removeTrailerBlock.trailerEndTime) && (mov.movementData.order_id2 === orderId)
          )
        });
        
        if (allMovsBtwTrailerStartToEnd.length > 0) {
          planId = allMovsBtwTrailerStartToEnd[0].movementData.pickup.plan_id;
        }

        allMovs.push(allMovsBtwTrailerStartToEnd);
      }
      
      const flattenedArr = allMovs.flat();
      if (flattenedArr.length > 0) {
    planId = flattenedArr[0].movementData.pickup.plan_id;
      }
      
      const allMovsIDs = flattenedArr.map((move) => move.load_id);
          
      const payload = {
        movement_id: [...new Set(allMovsIDs)]
      }; 

      const response = await removeTrailer(driver_id, planId, payload);
      await context.refreshDataFromAPI(context.payloadForRefreshData);
      setOpenTrailerDialog(false);
      toast.success("Trailer unassigned successfully.");
    } catch (err) {
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        let str = err?.response?.data?.errors?.map((el) => `${el}`);
        toast.error(`${str[0].charAt(0).toUpperCase() + str[0].slice(1)}.`, { autoClose: false });
      } else {
        toast.error(`${err?.response?.data}` ?? "Trailer cannot be unassigned. Please try again.", { autoClose: false });
      }  

      handleLoadingBarOnDriver(driver_id, false); 
    }
  };

  const orderremoveModal = async (i, driver, order) => {
    setorderremovemodal(true);
    setdriverdetails(driver);
    setorderdetails(order);
  };

  const extraMovedelete=async(warningDropdownValue)=>{
    handleLoadingBarOnDriver(driverdetails.driverId, true);

    const driverId = driverdetails.driverId;
    const planId = extraMoveDetails?.movementData?.pickup?.plan_id;
    const movementId = extraMoveDetails.move_id;
    setdeletmovconfirmbtn(true);
    let payload
    if(warningDropdownValue){
      payload['option']=warningDropdownValue
     }

    try {
      const response = await deleteMovementFromPlan(driverId, planId, movementId,payload);
      if(response?.data?.status=='warning'){
        let str=data?.data?.errors?.map((el)=>`${el}`)?.join(",") ?? "";
        setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}   warningMasterData={response?.data?.options ?? []} parentCallback={extraMovedelete} errormssg={str}/></div>)});
       return
      }else if(response?.data?.status=='failure' && response?.data?.errors && response?.data?.errors?.length>0 ){
        let str=response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
        errormessageDisplay(str);
       }else{
         setSuccessResponseMssg("Movement deleted successfully.");          
        }
      setWarningModalOpen(null);
      setdeletmovconfirmbtn(false)
      await context.refreshDataFromAPI(context.payloadForRefreshData);
    } catch (err) {
      setWarningModalOpen(null);
      setdeletmovconfirmbtn(false)
      if(err?.response?.data && err?.response?.data?.errors?.length>0){
        let str=err?.response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
        errormessageDisplay(str); 
      }else{
        toast.error("Something Went Wrong While Deleting Movements", { autoClose: false }); 
      }

      handleLoadingBarOnDriver(driverdetails.driverId, false);      
    }
    setExtraMoveModal(false);
    setExtramoveDetails(null);
  }

  const removeOrder = async (warningDropdownValue) => {
    handleLoadingBarOnDriver(driverdetails.driverId, true);
    

    const driverId = driverdetails.driverId;
    const planId = orderdetails.plan_id;
    const movementId = orderdetails.move_id;
    setdeletmovconfirmbtn(true);
    let payload
    if(warningDropdownValue){
      payload['option']=warningDropdownValue
     }
    try {
      const response = await deleteMovementFromPlan(driverId, planId, movementId,payload);
      if(response?.data?.status=='warning'){
        let str=data?.data?.errors?.map((el)=>`${el}`)?.join(",") ?? "";
        setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}   warningMasterData={response?.data?.options ?? []} parentCallback={removeOrder} errormssg={str}/></div>)});
       return
      }else if(response?.data?.status=='failure' && response?.data?.errors && response?.data?.errors?.length>0 ){
        let str=response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
        errormessageDisplay(str);
       }else{
         setSuccessResponseMssg("Order deleted successfully."); 
       }
      setWarningModalOpen(null);
      setdeletmovconfirmbtn(false)
      await context.refreshDataFromAPI(context.payloadForRefreshData);
    } catch (err) {
      setdeletmovconfirmbtn(false);
      setWarningModalOpen(null);
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        let str = err?.response?.data?.errors?.map((el) => `${el}`);
         toast.error(`${str[0].charAt(0).toUpperCase() + str[0].slice(1)}.`, { autoClose: false });
      } else {
         toast.error(`${err?.response?.data}` ?? "Something Went Wrong. Please try again.", { autoClose: false });        
      } 
      handleLoadingBarOnDriver(driverdetails.driverId, false);      
    }

    setorderremovemodal(false);
  }

  // This function will be called when clicking on Add movement button
  const movementModalOpen = async (driver) => {    
    handleLoadingBarOnDriver(driver.driverId, true);
    setLocationsDataFromMap([]);
    setIsAddMovTrimbleVisible(true);
    setSelectedDriverData(driver);
    setStartTime(movementTime(selectedTimezoneDate, context.plannerTimezone));
    setSelectedLocationLatitude(driver?.terminal?.bpaDriverParking?.location?.latitude ?? driver?.terminal?.latitude);
    setSelectedLocationLongitude(driver?.terminal?.bpaDriverParking?.location?.longitude ?? driver?.terminal?.longitude);
    // Getting all trailers for selected terminal
    await getAllTrailers();   
  };

  // This function will be called when clicking on Save button after selecting a location
  const addmovementchangeClick = async (i, terminalname, destinationadd, latlonlocationdata, selectedDriverData) => {
    setDestiLocation(latlonlocationdata);
    setmovementforlocmap(latlonlocationdata);
    setIsAddMovTrimbleVisible(false)
    setModalShow(true);
  };

  const resetCreateMovementStates = async () => {
    setModalShow(false);
    await context.refreshDataFromAPI(context.payloadForRefreshData);
    setSelectedTrailer(null);
    toast.success(
      "Movement created successfully.",
      "Success"
    );
  };

  // For creating bobtail or empty movement
  const createStops = async (warningDropdownValue) => {
    try {
      setaddmovconfirmbtn(true);
      const allMovementsOfSelectedDriver = selectedDriverData.movements;
      let lastEndShiftOfDriver = {};
      let planId = "";

      // Finding if any pre scheduled orders or not.
      if (allMovementsOfSelectedDriver.length > 0) {
        // All end shifts of driver
        const allEndShiftsOfDriver = allMovementsOfSelectedDriver.filter((mov) => mov.movementData.delivery.stop_type === "ES" && mov.stopData === null && mov.movementData.delivery.lm_location_id !== "UNKNOWN").sort((a, b) => a.planned_arrive_utc - b.planned_arrive_utc);

        // Getting exact last end shift
        lastEndShiftOfDriver = allEndShiftsOfDriver[allEndShiftsOfDriver.length - 1];
        planId = lastEndShiftOfDriver.movementData.delivery.plan_id;
      };

      const selectedTerminalMillisToUTC = DateTime.fromMillis(parseInt(selectedTimezoneDate)).toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");

      const payload = {
        plan_date: selectedTerminalMillisToUTC,
        movement_type: selectedMoveType?.Type === "E" ? "empty" : "bobtail",
        trailer_id: selectedTrailer?.Trailer_ID || "",
        destination_location_id: destiLocation?.code || "",
      };

      if (["", undefined, null].includes(selectedMoveType)) {
        setaddmovconfirmbtn(false);
        return toast.error("Please select movement type.", { autoClose: false })
      }

      if(warningDropdownValue){
        payload['option']=warningDropdownValue
       }
      const response = await createBobtailOrEmptyMovements(selectedDriverData.driverId, payload);
      if(response?.data?.status=='warning'){
        let str=data?.data?.errors?.map((el)=>`${el}`)?.join(",") ?? "";
        setWarningModalOpen(()=>{return(<div><WarningModal setWarningModalOpen={setWarningModalOpen}   warningMasterData={response?.data?.options ?? []} parentCallback={createStops} errormssg={str} /></div>)});
       return
      }else if(response?.data?.status=='failure' && response?.data?.errors && response?.data?.errors?.length>0 ){
        let str=response?.data?.errors?.map((el)=>`${el}\n`)?.join(",");
        errormessageDisplay(str);
       }else{
         setSuccessResponseMssg("Movements created successfully")
       }
      setWarningModalOpen(null);
      setaddmovconfirmbtn(false);
      await resetCreateMovementStates();
      await context.refreshDataFromAPI(context.payloadForRefreshData);
    } catch (err) {
      setWarningModalOpen(null);
      setaddmovconfirmbtn(false);
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        let str = err?.response?.data?.errors?.map((el) => `${el}`);
        toast.error(`${str[0].charAt(0).toUpperCase() + str[0].slice(1)}.`, { autoClose: false });
      } else {
        toast.error(`${err?.response?.data}` ?? "Movements cannot be created successfully. Please try again.", { autoClose: false });
      }      
    };
  };

  // For closing add movement modal
  const onModalClose = () => {
    setModalShow(false);
    setLocationsDataFromMap([]);    
    handleLoadingBarOnDriver(selectedDriverData.driverId, false);
    setSelectedDistance(5);
    setaddmovconfirmbtn(false)
  };

  const handleRightClickOnDriver = (e, driver) => {
    e.preventDefault();
    setSelectedDriverData(driver)
    if (showButtons) {
      setShowButtons(null)
    } else {
      if (driver.schedule.offduty.length) {
        let startTime = driver?.schedule?.offduty[0]?.logEndTime;
        setStartTime(movementTime(startTime, context.plannerTimezone))
      } else {
        const date = DateTime.local().setZone(context.plannerTimezone);

        // Set the time to 12 AM in the target timezone
        const twelveAm = date.startOf("day");

        // Get the epoch time in milliseconds
        const epochTime = twelveAm.toMillis();
        setStartTime(movementTime(epochTime, context.plannerTimezone))
      }
      setAddMovements(e.currentTarget);
    }
  }

  const handleMovetype = (e, value) => {
    setSelectedMoveType(value);
    setIsTrailerHistoryNeeded(false);
  }

    const trailerHandler = (e, value) => {
      if (value) {
        setSelectedTrailer(value);
      } else {
        setSelectedTrailer({});
        setIsTrailerHistoryNeeded(true);
      }
    };

  // ETA //
  const handledriveridChange = (e) => {
    setdriverid(e.target.value);
  };

  const handlelatitudeChange = (e) => {
    setlatitude(e.target.value);
  };

  const handlelonguitudeChange = (e) => {
    setlonguitude(e.target.value);
  };

  const clearStateValues = () => {
    setdriverid();
    setlatitude();
    setlonguitude();
  };

  const openModal = (driverId) => {
    setIsModalOpen([true, driverId]);
    setdriverid(driverId);
  };

  const updateETAdata = async (e) => {
    const payload = {
      driver_id: driverid,
      latitude: latitude,
      longitude: longuitude

    };
    if (payload.driver_id && payload.latitude && payload.longitude) {
      try {
        const updatedata = await updateETA(payload);

        if (updatedata.status === 200) {
          toast.success("ETA updated successfully", "Success");
          clearStateValues();
          setIsModalOpen(false);
        } else {
          toast.error("Please fill all fields.", { autoClose: false });
        }
      } catch (err) {
        toast.error("Something went Wrong, Please try again later.", { autoClose: false });
      }
    } else {
      toast.error("Please fill all fields.", { autoClose: false });
    }
  };

  const checkMovementOnCurrentDay = function (driver) {
    const movementOnCurrentDay = driver.movements.find((mov) => mov.movementData.pickup.planned_arrive_utc >= selectedTimezoneDate || mov.movementData.pickup.planned_depart_utc >= selectedTimezoneDate || mov.movementData.delivery.planned_arrive_utc >= selectedTimezoneDate || mov.movementData.delivery.planned_depart_utc >= selectedTimezoneDate);   

    return movementOnCurrentDay === undefined;
  }

  const handleRightClickTrailerRow = (e, driver, trailer) => {
    e.preventDefault();
    const movements = driver?.movements?.find((o) => o?.orderId2 === trailer?.order_id2  && o.stopData === null);

    if (Object.keys(movements).length > 0 && movements?.movementData?.order?.order_commodity.length > 0) {
      let commodity = movements?.movementData?.order?.order_commodity[0].CG?.commodityid.split("-");
      let commodityId = `${commodity[1].trim()} (${commodity[0].trim()})`;

      setCommodityGroupId(commodityId);
    } else {
      setCommodityGroupId("");
    }    
    setIsFindTrailerButtonVisible(e.currentTarget);
  };

  const handleFindTrailers = () => {
    context.setSelectedCommodityGroup(commodityGroupId);
    context.setFindTrailersBasedOnCommodity(true);
    context.settabSelected("trailers");
    setIsFindTrailerButtonVisible(null);                                
  };

  const handleCloseOrderRow = () => {
    setIsFindTrailerButtonVisible(null);
  };

  const handleDriverClick=async(driver)=>{
  try{
      if(driver?.samsara_id){
        const res=await getDriverHosClock(driver.samsara_id);
         if(res){
            driver.hosclock=res
           setSelectedDriverHosData(driver);;
           setDriverToolTipModal(true);
          }else{
            toast.error("Something Went Wrong", { autoClose: false })
          }
      }else{
    toast.error("Driver's Samsara ID Is Null", { autoClose: false })
  }
  }
  catch(err){
    toast.error("Something Went Wrong", { autoClose: false })
  }
  
  }

  const renderTrailer = (driver) => {
    let pickupStops = [];
    let delstops = [];
    let arr = [];

    let drivermoves = driver.filter((e) => e.stopData === null);

    if (drivermoves.length > 0) {
      // for (let i = 0; i < drivermoves.length; i++) {
      //   pickupStops.push(drivermoves[i].movementData.pickup);
      //   delstops.push(drivermoves[i].movementData.delivery);
      // };

      // let allstops = [...pickupStops, ...delstops];

      // let uniqueStops = [...new Set(allstops.map(item => item.lm_stop_id))].map(lm_stop_id => allstops.find(item => item.lm_stop_id === lm_stop_id));


      // console.log(uniqueStops, "uniqueStops");

      // let stopsInAscOrder = uniqueStops.sort((a, b) => a.planned_arrive_utc - b.planned_arrive_utc)
      // console.log("stopsInAscOrder", stopsInAscOrder)

      // for (let i = 0; i < stopsInAscOrder.length; i++) {
      //   let obj = {}
      //   let trailerOfTheStops = stopsInAscOrder[i]?.trailerDetails.map((e) => e.trailer_id).filter((e) => e !== null);

      //   let tarilerEnd = (index, tarilerId) => {
      //     let endtime = 0
      //     if (tarilerId) {
      //       let trailerOfTheNextStops = stopsInAscOrder[i + 1]?.trailerDetails.map((e) => e.trailer_id).filter((e) => e != null)
      //       if (trailerOfTheNextStops?.length === 1 && trailerOfTheNextStops[0] === tarilerId) {
      //         i++
      //         return tarilerEnd(i, tarilerId)
      //       }
      //       if (trailerOfTheNextStops?.length === 2 && trailerOfTheNextStops[0].includes(tarilerId)) {
      //         //endtime = (stopsInAscOrder[i+1].planned_arrive_utc + stopsInAscOrder[i+1].planned_depart_utc) / 2
      //         return ((stopsInAscOrder[i + 1].planned_arrive_utc + stopsInAscOrder[i + 1].planned_depart_utc) / 2)
      //       }
      //       if (trailerOfTheNextStops?.length === 0) {
      //         //endtime = stopsInAscOrder[index].planned_depart_utc
      //         return (stopsInAscOrder[index].planned_depart_utc)
      //       }
      //       if (trailerOfTheNextStops === undefined) {
      //         return (stopsInAscOrder[i].planned_arrive_utc)
      //       }

      //       // return endtime
      //     }
      //     else {
      //       if (["PU", "SP", "SD", "DEL", "DH", "DT", "HT", "SS"].includes(stopsInAscOrder[i].stop_type)) {
      //         let trailerOfTheNextStops = stopsInAscOrder[i + 1]?.trailerDetails.map((e) => e.trailer_id).filter((e) => e != null);

      //         if (trailerOfTheNextStops !== undefined) {
      //           if (trailerOfTheNextStops.length === 1) {
      //             return (stopsInAscOrder[i + 1].planned_arrive_utc);
      //           }
      //           if (trailerOfTheNextStops.length === 0) {
      //             if (stopsInAscOrder[i].stop_type === "DT" && stopsInAscOrder[i + 1].stop_type === "PU") {
      //               return (stopsInAscOrder[i].planned_depart_utc);
      //             } else {
      //               i++
      //               return tarilerEnd(i, trailerOfTheNextStops[0])
      //             }
      //           }
      //         }

      //       } else {
      //         // endtime = stopsInAscOrder[i - 1].planned_depart_utc
      //         return (stopsInAscOrder[i - 1].planned_depart_utc)
      //       }
      //     }
      //     //return endtime
      //   }

      //   if (trailerOfTheStops.length === 0) {
      //     if (["PU", "SP", "SD", "DEL", "DH", "DT", "HT", "SS"].includes(stopsInAscOrder[i].stop_type)) {
      //       obj.trailer_id = "",
      //         obj.order_id2 = stopsInAscOrder[i].trailerDetails[0].order_id2

      //       if (stopsInAscOrder[i].stop_type === "DT" && stopsInAscOrder[i - 1]?.trailerDetails[1]?.trailer_id == null) {
      //         obj.trailerStartTime = stopsInAscOrder[i - 1]?.planned_depart_utc;
      //         obj.trailerEndTime =stopsInAscOrder[i ].planned_depart_utc;
      //         arr.push(obj)
      //       }
      //       if (stopsInAscOrder[i].stop_type === "PU" && stopsInAscOrder[i -1]?.stop_type === "DT") {
      //         obj.trailerStartTime = stopsInAscOrder[i].planned_arrive_utc;
      //         obj.trailerEndTime = tarilerEnd(i, '')
      //         arr.push(obj)
      //       }
      //       else if (stopsInAscOrder[i].stop_type === "SS" && stopsInAscOrder[i + 1].stop_type === "PU") {
      //         obj.trailerStartTime = stopsInAscOrder[i].planned_depart_utc;
      //         obj.trailerEndTime = tarilerEnd(i, '')
      //         arr.push(obj)
      //       }
      //       // else {
      //       //   obj.trailerStartTime = stopsInAscOrder[i].planned_arrive_utc;
      //       // }

      //       // obj.trailerStartTime = ["PU", "SP", "SD", "DEL", "DH", "DT", "HT"].includes(stopsInAscOrder[i - 1].stop_type) ? stopsInAscOrder[i - 1].planned_depart_utc : stopsInAscOrder[i].planned_arrive_utc

      //     }
      //   }
      //   if (trailerOfTheStops.length === 1) {
      //     obj.trailer_id = trailerOfTheStops[0]
      //     obj.order_id2 = stopsInAscOrder[i].trailerDetails[0].order_id2
      //     obj.trailerStartTime = stopsInAscOrder[i].planned_arrive_utc
      //     obj.trailerEndTime = tarilerEnd(i, trailerOfTheStops[0])
      //     arr.push(obj)
      //   }

      //   if (trailerOfTheStops.length === 2) {
      //     obj.trailer_id = trailerOfTheStops[1]
      //     obj.order_id2 = stopsInAscOrder[i].trailerDetails[1].order_id2
      //     obj.trailerStartTime = ((stopsInAscOrder[i].planned_arrive_utc + stopsInAscOrder[i].planned_depart_utc) / 2)
      //     obj.trailerEndTime = tarilerEnd(i, trailerOfTheStops[1])
      //     arr.push(obj)
      //   }
      // }
      let trailer = null;
      for (let i = 0; i < drivermoves.length; i++) {
        // const element = array[i];
        let move = drivermoves[i];
        if (move.movementData.movement_type === "BT") {
          if (trailer?.trailerStartTime) {
            trailer.trailerEndTime = move.movementData.pickup.planned_arrive_utc
            arr.push(trailer)
            trailer = null
          }
        }
        else if (move.movementData.movement_type === "E") {
          if (!trailer) {
            trailer = {
              trailerStartTime: move.movementData.pickup.planned_depart_utc,
              trailer_id: move.movementData.trailer_id,
              order_id2: move.movementData.order_id2
            }
          }
          let nextmove = i + 1 < drivermoves.length ? drivermoves[i + 1] : null;

          if (move.movementData.pickup.stop_type === "DEL" && move.movementData.delivery.stop_type === "DT") {
            trailer.trailerEndTime = move.movementData.delivery.planned_depart_utc
            arr.push(trailer)
            trailer = null
          }
          else if (move.movementData.delivery.stop_type === "HT") {
            trailer.trailerEndTime = move.movementData.delivery.planned_arrive_utc
            arr.push(trailer)
            trailer = null
          }
          else if (move.movementData.delivery.stop_type === "DH") {
            trailer.trailerEndTime = Math.ceil((move.movementData.delivery.planned_arrive_utc + move.movementData.delivery.planned_depart_utc) / 2)
            arr.push(trailer)
            trailer = null
          }
          else if (move.movementData.pickup.stop_type === "DH" && move.movementData.delivery.stop_type === "DT") {
            trailer.trailerStartTime = Math.ceil((move.movementData.pickup.planned_arrive_utc + move.movementData.pickup.planned_depart_utc) / 2)
            trailer.trailerEndTime = move.movementData.delivery.planned_depart_utc;
            arr.push(trailer)
            trailer = null    
          }
          else if (nextmove && move.movementData.order_id2 !== nextmove.movementData.order_id2) {
            trailer.trailerEndTime = move.movementData.delivery.planned_arrive_utc
            arr.push(trailer)
            trailer = null
          }
          else if (["N ", "NB"].includes(move.movementData.delivery.driver_load_unload)) {
            trailer.trailerEndTime = move.movementData.delivery.planned_arrive_utc
            arr.push(trailer)
            trailer = null
          }
        }
        else if (move.movementData.movement_type === "L") {
          if (!trailer) {
            trailer = {
              trailerStartTime: move.movementData.pickup.planned_arrive_utc,
              trailer_id: move.movementData.trailer_id,
              order_id2: move.movementData.order_id2
            }
            if (["N ", "NB"].includes(move.movementData.delivery.driver_load_unload)) {
              trailer.trailerEndTime = move.movementData.delivery.planned_depart_utc
              arr.push(trailer)
              trailer = null
            }

          } else {
            if (["N ", "NB"].includes(move.movementData.delivery.driver_load_unload)) {
              trailer.trailerEndTime = move.movementData.delivery.planned_depart_utc
              arr.push(trailer)
              trailer = null
            }

          }

        }
      }
    }

    return arr;

  };

  // Driver DOM
  useEffect(async () => {
    setDriverDOM(await Promise.all(driverState?.map(async (driver, idx) => {
      return (
        <div key={idx} class='planner_main_container_outer'>
          <div className='planner_main_container_outer_image_container_right_text_wrapper'>
            <div class='planner_main_container_outer_image_container'
              aria-controls='customized-menu'
              aria-haspopup='true'
              variant='contained'
              color='primary'
              onContextMenu={(e) => {
                handleRightClickOnDriver(
                  e,
                  driver
                );
              }}
            >
              <div className='plannerflex'>
                <RvHookupIcon
                  onClick={() => {
                    if (checkMovementOnCurrentDay(driver) && isEditPermission) {
                      movementModalOpen(driver);
                    }
                  }}            
                  style={checkMovementOnCurrentDay(driver) ? {color:"#3b3838",cursor:"pointer"} : {color:"grey"}}
                />

                <MoreTimeIcon onClick={() => openModal(driver.driverId)} disabled={!isEditPermission} className="hand-pointer" style={{color:"#3b3838"}} />
              </div>
              {/* <PlannerBoardTooltip tooltip={<div><SamsaraPBToolTip driver={driver} /></div>}> */}
                <div onClick={()=>handleDriverClick(driver)} style={{cursor:"pointer"}}>
                <img src={BaselineShiping} alt='Baseline-Shiping' className='planner_left_image' />
                </div>
              {/* </PlannerBoardTooltip> */}

              <p className='planner_main_container_outer_image_container_icon_text'>
                {driver.driverId}
              </p>
            </div>
            <div className='planner_main_container_outer_image_container_right_text'>
              <p>Order</p>
              <p>Trailer</p>
              <p>Segment</p>
            </div>
          </div>

          <div class='planner_dragable_container'>
            {driver.isloading ? (
              <Stack spacing={1}>
                <div className='loader_skeleton'>
                  <Skeleton variant='rectangular' />
                </div>
              </Stack>
            ) : (
              <></>
            )}
            {driver?.schedule?.offduty

              ?.map((log, index) => {
                if (
                  log.logEndTime >= selectedTimezoneDate ||
                  log.logStartTime <
                  selectedTimezoneDate + dayToMillis
                ) {
                  const parentWidth = 100;

                  return (
                    <div
                      key={index}
                      class='planner_hos_log1'
                      style={{
                        position: "absolute",
                        left:
                          log.logStartTime >= selectedTimezoneDate
                            ? ((log.logStartTime - selectedTimezoneDate) /
                              dayToMillis) *
                            100 +
                            "%"
                            : "0%",
                        width: `${(((log.logEndTime <
                          selectedTimezoneDate + dayToMillis
                          ? log.logEndTime
                          : selectedTimezoneDate + dayToMillis) -
                          (log.logStartTime >= selectedTimezoneDate
                            ? log.logStartTime
                            : selectedTimezoneDate)) /
                          dayToMillis) *
                          100
                          }%`,
                        display:
                          (((log.logEndTime <
                            selectedTimezoneDate + dayToMillis
                            ? log.logEndTime
                            : selectedTimezoneDate + dayToMillis) -
                            (log.logStartTime >= selectedTimezoneDate
                              ? log.logStartTime
                              : selectedTimezoneDate)) /
                            dayToMillis) *
                            100 >
                            0
                            ? "block"
                            : "none",
                        backgroundColor: '#545b71'
                      }}
                    ></div>
                  )
                }
                return null
              }
              )}

            {
              driver?.ScheduleOverrideList.map((scheduleData) => (
                scheduleData?.override?.length > 0 ?
                (scheduleData?.override?.map((schedule, index) => {
                  if (driver?.ScheduleOverrideList.length > 0) {
                    return (
                      <div
                        key={index}
                        class='planner_hos_log2'
                        style={{
                          position: "absolute",
                          left: schedule.start_time >= 60
                            ? ((schedule.start_time * 60 * 1000) /
                              dayToMillis) *
                            100 +
                            "%"
                            : "0%",
                          width: schedule.block_type === "Available" ? (((schedule.duration) * 60 * 1000) /
                            dayToMillis) *
                            100 + "%" : ((schedule.duration * 60 * 1000) /
                            dayToMillis) *
                            100 + "%",
                          display: "block",
                          backgroundColor: `${schedule.block_type === "Available" ? '#BDEFD4' : '#E2ADB0'}`

                        }}
                      ></div>)
                  }

                  return null;
                })) : (driver?.ScheduleOverrideList.map((schedule, index) => {
                  if (driver?.ScheduleOverrideList.length > 0) {
                    return (
                      <div
                        key={index}
                        class='planner_hos_log2'
                        style={{
                          position: "absolute",
                          left: schedule.start_time >= 60
                            ? ((schedule.start_time * 60 * 1000) /
                              dayToMillis) *
                            100 +
                            "%"
                            : "0%",
                          width: ((schedule.duration * 60 * 1000) /
                            dayToMillis) *
                            100 + "%",
                          display: "block",
                          backgroundColor: `${schedule.block_type === "Available" ? '#BDEFD4' : '#E2ADB0'}`

                        }}
                      ></div>)
                  }
                  return null;
                }))
              ))                
            }

            <div className='planner_tick_container'>
              {dragset.map((it2, index) => (
                <div
                  key={index}
                  onDrop={(e) => {
                    if (!(context.dragData.dragFrom === "orders" && context.plannedOrUnplanned === "left")) {
                     handleLoadingBarOnDriver(driver.driverId, true);
                    }

                    // driver.isloading = true;
                    if (context.dragData.dragFrom == ("movements" || "Shift"))
                      driverState.filter(
                        (dri) =>
                          dri.driverId ===
                          context.dragData.dragDataItem.driverId
                      )[0].isloading = true;
                    e.preventDefault();
                    e.stopPropagation();
                    context.setDropData({
                      drop_to: "planner_tick_container",
                      driver_id: driver.driverId,
                      index: index,
                    });
                    //
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                  className='planner_dragable_inner'
                ></div>
              ))}
            </div>

            <div class='planner_dragable_main_block'>
              {driver?.shifts
                ?.filter(
                  (shift) =>
                    shift.shiftEndTime >= selectedTimezoneDate ||
                    shift.shiftStartTime <
                    selectedTimezoneDate + dayToMillis
                )
                .map((shift, index) => (
                  <div
                    key={index}
                    class={
                      shift.shiftType === "start" && shift.movementData.pickup.location.code === "UNKNOWN"
                        ? "planner_shift_color"
                        : shift.shiftType === "end" && shift.movementData.delivery.location.code === "UNKNOWN"
                          ? "planner_shift_color"
                          : "planner_shift"
                    } onContextMenu={(e) => {
                      handleRightClickOnShifts(
                        e,
                        shift, driver
                      );
                    }}
                    style={{
                      position: "absolute",
                      left:
                        shift.shiftStartTime >= selectedTimezoneDate
                          ? ((shift.shiftStartTime -
                            selectedTimezoneDate) /
                            dayToMillis) *
                          100 +
                          "%"
                          : "0%",
                      width: `${(((shift.shiftEndTime <
                        selectedTimezoneDate + dayToMillis
                        ? shift.shiftEndTime
                        : selectedTimezoneDate + dayToMillis) -
                        (shift.shiftStartTime >=
                          selectedTimezoneDate
                          ? shift.shiftStartTime
                          : selectedTimezoneDate)) /
                        dayToMillis) *
                        100
                        }%`,
                      display:
                        (((shift.shiftEndTime <
                          selectedTimezoneDate + dayToMillis
                          ? shift.shiftEndTime
                          : selectedTimezoneDate + dayToMillis) -
                          (shift.shiftStartTime >=
                            selectedTimezoneDate
                            ? shift.shiftStartTime
                            : selectedTimezoneDate)) /
                          dayToMillis) *
                          100 >
                          0
                          ? "block"
                          : "none",

                    }}
                    draggable
                    onDrop={(e) => {
                      if (context.dragData?.dragFrom == "Shift" &&
                        shift?.movementData?.delivery?.stop_type == "ES") {
                          handleLoadingBarOnDriver(driver.driverId, true);
                        e.preventDefault();
                        e.stopPropagation();
                        context.setDropData({
                          drop_to: "Shift",
                          driver_id: driver.driverId,
                          movement: shift,
                        });
                      }
                    }}
                    onDragStart={(e) => {
                      // alert("hi")
                      context.setDragData({
                        dragFrom: "Shift",
                        dragDataItem: {
                          movement: shift,
                          driverId: driver.driverId,
                        },
                      });
                    }}
                    onDragOver={(e) => e.preventDefault()}
                  >

                    {shift.shiftType == "start" ||
                      shift.shiftType == "end" ? (
                      <PlannerBoardTooltip
                        tooltip={
                          <ShiftToolTip it={shift} />
                        }
                      >
                        <div className="tooltip_row_block">
                          {shift.shiftType == "start" ? "SS" : "ES"}
                        </div>
                      </PlannerBoardTooltip>


                    ) : (
                      ""
                    )}
                    {/* These buttons will be shown after right clicking on shift, stops and movs based on conditions */}
                    <div className="audit_log_modal">
                      <StyledMenu
                        id='customized-menu'
                        anchorEl={showButtons}
                        // className='audit_log_modal'
                        open={Boolean(showButtons)}
                        onClose={handlerightClose4}
                      >
                        <ListItemText>
                          {/* Audit log will be visible for every stops and movs on right clicking on them*/}
                          <Button
                            className='btn-blue_schedule margin_right'
                            onClick={(i) => auditLogModalOpenHandler()}
                            disabled={!isEditPermission}
                          >
                            Audit Log
                          </Button>
                          {/* Edit Actuals will be visible on clicking on Start and End shift */}
                          {editActualSSandES ? (<Button
                            className='btn-blue_schedule margin_right'
                            onClick={(i) =>
                              actualeditModal(
                                i, actualeditdatamodal
                              )
                            }
                            disabled={!isEditPermission}
                          >
                            Edit Stops
                          </Button>) : <></>}
                          {/* This button will be shown on right clicking on every stops except Start and End Shift */}
                          {stopClickFlag ?
                            <Button
                              className='btn-blue_schedule margin_right'
                              style={stop_and_movs_buttons}
                              onClick={(i) =>
                                Stopsmodal_open(
                                  i,
                                  actualeditstopsdata,
                                )
                              }
                              disabled={!isEditPermission}
                            >
                              Edit Stops
                            </Button> : <></>
                          }
                          {/* This button will be shown on right clicking on every movement*/}
                          {
                changeMoveTypeOnEmptyMov ?
                  (
                      <Button
                        className='btn-blue_schedule margin_right'
                        onClick={(i) =>
                          Movementformodaldata(
                            i, terminalname
                          )
                        }
                        disabled={!isEditPermission}
                      >
                        Change Move Type
                      </Button>
                  ) : <></>
              }
              {
                changeMoveTypeOnBobtailMov ? (
                    <Button
                      className='btn-blue_schedule margin_right'
                      onClick={(i) =>
                        Movementformodaldata(
                          i, terminalname
                        )
                      }
                      disabled={!isEditPermission}
                    >
                      Change Move Type
                    </Button>
                ) : <></>
              }


                          {/* This button will be shown on right clicking on DT if next stop don't have actuals */}

                          {
                             destChangeDHandDT ?
                             (<Button
                               className='btn-blue_schedule margin_right'
                               style={stop_and_movs_buttons}
                               onClick={() => destinationviewModalopen()}
                               disabled={!isEditPermission}
                             >
                              Change Destination
                             </Button>) : <></>
                          }
                          {
                            addEmptyMovement ?
                              (<Button
                                className='btn-blue_schedule margin_right'
                                style={stop_and_movs_buttons}
                                onClick={() => movementModalOpenforDT()}
                                disabled={!isEditPermission}
                              >
                                Add Empty Movement
                              </Button>) : <></>
                          }
                         {
                             isSplitButton ?
                                   <>
                                      {(selectedTimezoneDate >= (currentDate - 24 * 60 * 60 * 1000)) && handlesplitButton ? (
                                     <Button
                                       className='btn-blue_schedule'
                                       onClick={() =>
                                         Blockmodalopen(                            
                                           terminalname,
                                           driverIddropdown,
                                           movementlocationname
                                                        )
                                       }
                                       disabled={!isEditPermission}
                                     >
                                       Split
                                     </Button>
                                     ) : <></>}
                                  </> : <></>
                             }
                          {
                            isUnsplitButton ? (
                              <>
                                {
                                  (actualeditstopsdata.type === "SP" || actualeditstopsdata.type === "SD") && loadedduration ?
                                    (<Button
                                      className='btn-blue_schedule margin_right'
                                      style={{ display: 'inline-block' }}
                                      onClick={() =>
                                        actualeditstopsdata.type === "SP"
                                          ? unsplitpuhandler()
                                          : unsplitdelhandler()
                                      }
                                      disabled={!isEditPermission}
                                    >
                                      Unsplit
                                    </Button>) : ""
                                }
                              </>
                            ) : ""
                          }
                          {
                            unsplitModaldel ?
                              (<Button
                                className='btn-blue_schedule margin_right'
                                onClick={()=>unsplitdelhandler()}
                                disabled={!isEditPermission}
                              >
                                Unsplit
                              </Button>) : <></>
                          }
                          {
                            unsplitModalpu ?
                              (<Button
                                className='btn-blue_schedule margin_right'
                                onClick={()=>unsplitpuhandler()}
                                disabled={!isEditPermission}
                              >
                                Unsplit
                              </Button>) : <></>
                          }
                        </ListItemText>
                      </StyledMenu>
                    </div>
                  </div>
                ))}

              <div class='planner_dragable_inner_main_block'>
                <div class='planner_draggable_main_block_row'>
                  {driver?.orders
                    ?.map((order, index) => {
                      if (
                        order.orderEndTime >= selectedTimezoneDate ||
                        order.orderStartTime <
                        selectedTimezoneDate + dayToMillis
                      ) {
                        let next
                        if (index < driver?.orders.length - 1) {
                          next = driver?.orders[index + 1];
                        }
                        let endTime
                        if (next != undefined) {
                          endTime = Math.min(order?.orderEndTime, next?.orderStartTime)
                        }
                        else {
                          endTime = order?.orderEndTime
                        }
                        return (

                          <div
                            key={index}
                            style={{
                              ...order.style,
                              position: "absolute",
                              left:
                                order.orderStartTime >=
                                  selectedTimezoneDate
                                  ? ((order.orderStartTime -
                                    selectedTimezoneDate) /
                                    dayToMillis) *
                                  100 +
                                  "%"
                                  : "0%",
                              width: `${(((endTime <
                                selectedTimezoneDate + dayToMillis
                                ? endTime
                                : selectedTimezoneDate +
                                dayToMillis) -
                                (order.orderStartTime >=
                                  selectedTimezoneDate
                                  ? order.orderStartTime
                                  : selectedTimezoneDate)) /
                                dayToMillis) *
                                100
                                }%`,
                              display:
                                (((endTime <
                                  selectedTimezoneDate + dayToMillis
                                  ? endTime
                                  : selectedTimezoneDate +
                                  dayToMillis) -
                                  (order.orderStartTime >=
                                    selectedTimezoneDate
                                    ? order.orderStartTime
                                    : selectedTimezoneDate)) /
                                  dayToMillis) *
                                  100 >
                                  0
                                  ? "block"
                                  : "none",
                              pointerEvents: (context.dragData ? "all" : context.dragData.dragFrom != "Shift" ? "all" : "")
                            }}
                            className='planner_draggable_main_block_row_block planner_draggable_main_block_top_row_block'
                          >
                            <PlannerBoardTooltip
                              tooltip={<OrderTooltip it={driver?.movements?.find(o => o.orderId2 === order.orderId && o.type == "L") ?? driver?.movements?.find(o => o.orderId2 === order.orderId)} />}
                            >
                              <div className='tooltip_row_block'>
                                {order.orderId}
                              </div>
                            </PlannerBoardTooltip>
                            {(order.mvmtType === "PU" || "SP") && order.actual_arrival_utc === 0 &&
                              order.actual_departure_utc === 0 && order.actual_arrival_utc1 === 0 &&
                              order.actual_departure_utc1 === 0 ? (
                              <div className='close_planning_outer'>
                                <div
                                  className='close_planning orderidfix'
                                  onClick={(i) => {
                                    if (isEditPermission) {
                                      orderremoveModal(i, driver, order)
                                    }
                                    else {
                                      return NotificationManager.info("Sorry,You don't have the necessary permissions to perform this action.", "Information", 3000)
                                    }
                                  }
                                  }
                                />
                              </div>
                            ) : (
                              <>

                              </>
                            )}
                          </div>
                        );
                      }
                      return null
                    })}
                     
                    
                </div>

                <div class='planner_draggable_main_block_row planner_draggable_main_block_middle_row'>
                  {
                    // driver.driverId === "89" ?
                    renderTrailer(driver.movements)
                      ?.map((trailer, index) => {
                        if (
                          trailer.trailerEndTime >
                          selectedTimezoneDate ||
                          trailer.trailerStartTime <
                          selectedTimezoneDate + dayToMillis
                        ) {
                          let next
                          if (index < driver?.trailers.length - 1) {
                            next = driver?.trailers[index + 1];
                          }
                          let endTime
                          if (next != undefined) {
                            endTime = Math.min(trailer?.trailerEndTime, next?.trailerEndTime)
                          }
                          else {
                            endTime = trailer?.trailerEndTime
                          }
                          return (
                            <div
                              key={index}
                              style={{
                                position: "absolute",
                                left:
                                  trailer.trailerStartTime >=
                                    selectedTimezoneDate
                                    ? ((trailer.trailerStartTime -
                                      selectedTimezoneDate) /
                                      dayToMillis) *
                                    100 +
                                    "%"
                                    : "0%",
                                width: `${(((endTime <
                                  selectedTimezoneDate + dayToMillis
                                  ? endTime
                                  : selectedTimezoneDate +
                                  dayToMillis) -
                                  (trailer.trailerStartTime >=
                                    selectedTimezoneDate
                                    ? trailer.trailerStartTime
                                    : selectedTimezoneDate)) /
                                  dayToMillis) *
                                  100
                                  }%`,
                                display:
                                  (((endTime <
                                    selectedTimezoneDate + dayToMillis
                                    ? endTime
                                    : selectedTimezoneDate +
                                    dayToMillis) -
                                    (trailer.trailerStartTime >=
                                      selectedTimezoneDate
                                      ? trailer.trailerStartTime
                                      : selectedTimezoneDate)) /
                                    dayToMillis) *
                                    100 >
                                    0
                                    ? "block"
                                    : "none",
                                  pointerEvents: (context.dragData.dragFrom != "Shift" ? "all" : "")
                              }}
                              onContextMenu={(e) => handleRightClickTrailerRow(e, driver, trailer)}
                              className='planner_draggable_main_block_row_block planner_draggable_main_block_middle_row_block'
                              onDrop={(e) => {
                                if (context.dragData.dragFrom !== "Shift") {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleLoadingBarOnDriver(driver.driverId, true);

                                  context.setDropData({
                                    drop_to: "trailer_row",
                                    driver_id: driver.driverId,
                                    trailer: trailer,
                                  });
                                }
                              }}
                              onDragOver={(e) => {
                                e.preventDefault();
                              }}
                            >
                              {
                                <div>
                                  <StyledMenu
                                    id='customized-menu'
                                    anchorEl={isFindTrailerButtonVisible}
                                    open={Boolean(isFindTrailerButtonVisible)}
                                    onClose={handleCloseOrderRow}
                                  >
                                    <ListItemText>
                                      <Button
                                        className='btn-blue_schedule margin_right'
                                        onClick={(e) => {
                                          handleFindTrailers();
                                        }}
                                        disabled={false}
                                      >
                                        Find Trailers
                                      </Button>
                                    </ListItemText>
                                  </StyledMenu>
                                </div>
                              }
                              <PlannerBoardTooltip
                                tooltip={<TrailerPBToolTip it={trailer} />}
                              >
                                <div className='tooltip_row_block'>
                                  {trailer?.trailer_id}
                                </div>
                              </PlannerBoardTooltip>
                              <div>
                                {(trailer.trailer_id && (
                                  driver?.movements.some((mv) => (
                                    trailer.order_id2 === mv.movementData.order_id2 &&
                                    mv.stopData === null &&
                                    (
                                      (mv.movementData.pickup.stop_type === 'PU'
                                        &&
                                        (
                                          ((mv.movementData.pickup.actual_arrival_utc === null || mv.movementData.pickup.actual_arrival_utc === undefined || mv.movementData.pickup.actual_arrival_utc === 0))
                                          ||
                                          (mv.movementData.delivery.actual_departure_utc === null || mv.movementData.delivery.actual_departure_utc === 0 || mv.movementData.delivery.actual_departure_utc === undefined))
                                      )
                                    )
                                  ))
                                )) && (
                                    <div className='close_planning_outer'>
                                      {isEditPermission ? (
                                        <div
                                          className='close_planning orderidfix'
                                          onClick={() => {
                                            handleOpenDialogTrailer(trailer, driver.driverId, driver);
                                          }}
                                        />
                                      ) : (
                                        <div
                                          className='close_planning orderidfix'
                                          onClick={() => {
                                            NotificationManager.info("Sorry,You don't have the necessary permissions to perform this action.", "Information", 3000);
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}
                              </div>
                            </div>
                          );

                        }
                        return null

                      })
                    // : ""
                  }
                  
                  {
                    driver?.newEmptyMoveByPlanner?.map((movement, index) => {
                      if (
                        movement.endTime >= selectedTimezoneDate ||
                        movement.startTime <
                        selectedTimezoneDate + dayToMillis
                      ) {
                        let next
                        if (index < driver?.newEmptyMoveByPlanner.length - 1) {
                          next = driver?.newEmptyMoveByPlanner[index + 1];
                        }
                        let endTime
                        if (next != undefined) {
                          endTime = Math.min(movement?.endTime, next?.startTime)
                        }
                        else {
                          endTime = movement?.endTime
                        }
                        return <div
                          key={index}
                          style={{
                            position: "absolute",
                            left: movement.startTime >=
                              selectedTimezoneDate
                              ? ((movement.startTime -
                                selectedTimezoneDate) /
                                dayToMillis) *
                              100 +
                              "%"
                              : "0%",
                            width: `${(((endTime <
                              selectedTimezoneDate + dayToMillis
                              ? endTime
                              : selectedTimezoneDate +
                              dayToMillis) -
                              (movement.startTime >=
                                selectedTimezoneDate
                                ? movement.startTime
                                : selectedTimezoneDate)) /
                              dayToMillis) *
                              100
                              }%`,
                            display:
                              (((endTime <
                                selectedTimezoneDate + dayToMillis
                                ? endTime
                                : selectedTimezoneDate +
                                dayToMillis) -
                                (movement.startTime >=
                                  selectedTimezoneDate
                                  ? movement.startTime
                                  : selectedTimezoneDate)) /
                                dayToMillis) *
                                100 >
                                0
                                ? "block"
                                : "none",
                          }}
                          className='planner_draggable_main_block_row_block_cross planner_draggable_main_block_top_row_block_cross'
                        >

                          <div className='close_planning_outer'>
                            <div
                              className='close_planning orderidfix'
                              onClick={(i) => {
                                if (isEditPermission) {
                                  setdriverdetails(driver);
                                  setExtramoveDetails(movement);
                                  setExtraMoveModal(true);
                                }
                                else {
                                  return NotificationManager.info("Sorry,You don't have the necessary permissions to perform this action.", "Information", 3000)
                                }
                              }
                              }
                            />
                          </div>
                        </div>

                      }
                      return null

                    })
                  }
                </div>

                <div class='planner_draggable_main_block_row'>
                  {driver?.movements

                    ?.map((movement, index) => {
                      if (
                        movement.endTime >= selectedTimezoneDate ||
                        movement.startTime <
                        selectedTimezoneDate + dayToMillis
                      ) {
                        let next
                        if (index < driver?.movements.length - 1) {
                          next = driver?.movements[index + 1];
                        }
                        let endTime
                        if (next != undefined) {
                          endTime = Math.min(movement?.endTime, next?.startTime)
                        }
                        else {
                          endTime = movement?.endTime
                        }
                        return (
                          <div
                            key={index}
                            style={{
                              ...movement.style,
                              position: "absolute",
                              left:
                                movement.startTime >=
                                  selectedTimezoneDate
                                  ? ((movement.startTime -
                                    selectedTimezoneDate) /
                                    dayToMillis) *
                                  100 +
                                  "%"
                                  : "0%",
                              width: `${(((endTime <
                                selectedTimezoneDate + dayToMillis
                                ? endTime
                                : selectedTimezoneDate +
                                dayToMillis) -
                                (movement.startTime >=
                                  selectedTimezoneDate
                                  ? movement.startTime
                                  : selectedTimezoneDate)) /
                                dayToMillis) *
                                100
                                }%`,
                              display:
                                (((endTime <
                                  selectedTimezoneDate + dayToMillis
                                  ? endTime
                                  : selectedTimezoneDate +
                                  dayToMillis) -
                                  (movement.startTime >=
                                    selectedTimezoneDate
                                    ? movement.startTime
                                    : selectedTimezoneDate)) /
                                  dayToMillis) *
                                  100 >
                                  0
                                  ? "block"
                                  : "none",
                            }}
                            className='planner_draggable_main_block_row_block planner_draggable_main_block_bottom_row_block'
                            aria-controls='customized-menu'
                            aria-haspopup='true'
                            variant='contained'
                            color='primary'
                            onContextMenu={(e) => {
                              let order_id = movement.orderId;
                              let order = driver?.orders?.filter(
                                (order) => {
                                  return order.orderId === order_id;
                                }
                              )[0];
                              handleRightClickOnMovements(
                                e,
                                movement.type,
                                driver.driverId,
                                order,
                                driver,
                                movement
                              );
                              setSelectedDriverOnMovement(driver);
                              setterminalname(movement);
                              setmovementlocationName(movement);
                              setactualeditmovedata(movement);
                            }}                      
                            onClick={(e) =>
                              impactviewclick(
                                e,
                                movement.type,
                                movement
                              )
                            }         
                            onDragStart={(e) => {
                              handleDragStart();
                              context.setDragData((prevDragData) => {
                                const updatedDragData = {
                                  ...prevDragData,
                                  dragFrom: "movements",
                                  dragDataItem: {
                                    movement: movement,
                                    driverId: driver.driverId,
                                  },
                                };

                                return updatedDragData;
                              });
                            }}
                            onDragEnd={(e) => {
                              handleDragEnd();
                              context.setDragData((prevDragData) => {
                                const updatedDragData = {
                                  ...prevDragData,
                                  dragFrom: "movements",
                                  dragDataItem: {
                                    movement: movement,
                                    driverId: driver.driverId,
                                  },
                                };

                                return updatedDragData;
                              });
                            }}          
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onDrop={(e) => {
                              if ((
                                context.dragData?.dragFrom == "movements" &&
                                movement.type == "SP" &&
                                context.dragData?.dragDataItem?.movement?.type == "PU"
                              ) || (
                                  context.dragData?.dragFrom == "movements" &&
                                  movement.type == "SD" &&
                                  context.dragData?.dragDataItem?.movement?.type == "DEL"
                                )) {
                                // driver.isloading = true;
                                handleLoadingBarOnDriver(driver.driverId, true);
                                driverState.filter(
                                  (dri) =>
                                    dri.driverId ===
                                    context.dragData.dragDataItem.driverId
                                )[0].isloading = true;
                                e.preventDefault();
                                e.stopPropagation();
                                context.setDropData({
                                  drop_to: "movements",
                                  driver_id: driver.driverId,
                                  movement: movement,
                                });
                              }
                            }}
                            onDragOver={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {movement.type === "L" ||
                              movement.type === "E" || movement.type === "BT" ? (
                              <PlannerBoardTooltip
                                tooltip={
                                  <Order2PBToolTip it={movement} />
                                }
                              >
                                <div className='tooltip_row_block'>
                                  {movement.type}
                                </div>
                              </PlannerBoardTooltip>
                            ) : (
                              ""
                            )}

                            {movement.type === "DEL" ||
                              movement.type === "DT" ||
                              movement.type === "DH" ||
                              movement.type === "HT" ||
                              movement.type === "SD" ||
                              movement.type === "PU" ||
                              movement.type === "SP" ? (
                              renderTooltip(movement)
                            ) : (
                              null
                            )
                            }
                          </div>
                        );
                      }
                      return null

                    })}
                </div>
              </div>
            </div>
          </div>
          <div class='planner_pm_section forum-icon-container'>
            <SendIcon color={isEditPermission ? "primary" : 'disabled'} className="planner_right_send_icon" onClick={() => sendToXmit(driver)} />
            <div>
              <div className={driver?.messages?.filter((el) => ((el.is_read_by_planner == false || el.is_read_by_planner == null) && el.source != "BPA")).length > 0 && "planner_message_alert"} >
                {
                  driver?.messages?.filter((el) => ((el.is_read_by_planner == false || el.is_read_by_planner == null) && el.source != "BPA")).length > 0 && driver?.messages?.filter((el) => ((el.is_read_by_planner == false || el.is_read_by_planner == null) && el.source != "BPA")).length
                }
              </div>
              <ForumIcon className="planner_right_message_icon" onClick={() => { driverChatModalHandler(driver) }} />
            </div>
          </div>
        </div>
      )
    })));
  }, [driverState, selectedTimezoneDate, actualeditdatamodal, durationsettime, terminalname, driverIddropdown, driverIddropdown, movementlocationname, context.dragData,showButtons, isFindTrailerButtonVisible
  ]);

  const handleCloseEditMovement = () => {
    setMOvementsEditModal(false);
    setChangeMoveTypeOnEmptyMov(null);
    setChangeMoveTypeOnBobtailMov(null);
    setdestinationViewModal(false);
    setmovementformodal(false);
    setChangemovetypeconfirmbtn(false)
  };

  const convertdatetime1 = (duration) => {
    if (duration) {
      const inputDate = DateTime.fromISO(duration, { zone: 'utc' });
      const formattedDate = inputDate.toFormat('yyyy-MM-dd hh:mm');

      return formattedDate
    } else {
      return "No Data";
    }
  };

  return (
    <>
      {/* <ToastContainer position='top-right' autoClose={2000} theme='colored' /> */}
      <div key={refreshComponent ? "main_planner_wrapper_refresh" : "main_planner_wrapper"}>
        <div class='planner_main_container_outer'>
          <div className='planner_am_section'>HRS</div>
          <div className='planner_main_container'>
            {hourArr.map((item, key) => (
              <div key={key} className='planner_main_inner_container'>
                <div className='hrs_wrapper'>
                  <p className='hrs_wrapper_time_text'>
                    {item == "0"
                      ? `12  A.M`
                      : item == "12"
                        ? `12  P.M`
                        : item > 11
                          ? `${item - 12}  P.M`
                          : `${item}  A.M`}
                  </p>
                  <div className='planner_day_time_inner'>
                    <div className='vl_plannertankwash'></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div class='planner_pm_section'>HRS</div>
        </div>
        <div className='scroll_plannerboard_inner'>
          {driverDOM}
        </div>

        <div className="pagination_adjust_driver">
          {driverData.length >
            0 ? (
            <Stack spacing={2}>
              <Pagination
                onChange={handeDriverViewPagination}
                count={Math.ceil(
                  allDrivers?.length / 25
                )}
                page={context.driverPageNumber}
                color='primary'
              />
            </Stack>
          ) : (
            ""
          )}
        </div>
      </div>

{/* modal will open if api resonse is warning */}

      {
          warningModalOpen ? warningModalOpen : <></>
      }

      {/* actualedit modal */}
      <Modal
        show={actualeditData}
        onHide={ss_es_modalclosehandelar}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>Edit Stops</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label for="typeHeadCommodityGroup">
            Actual Arrival
          </label>
          <div className="meterial_autocomplete">
            <form noValidate>
              <TextField
                id="input_time"
                type="datetime-local"
                fullWidth
                variant="outlined"
                onChange={(e) =>
                  handleactualDateChange(e)
                }
                value={selectedactualDate} 
                inputProps={{
                  min: DateTime.now().toISODate()
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </div>
          <label for="typeHeadCommodityGroup">
            Actual Depart
          </label>
          <div className="meterial_autocomplete">
            <form noValidate>
              <TextField
                id="input_time"
                type="datetime-local"
                fullWidth
                variant="outlined"
                onChange={(e) =>
                  handledepartDateChange(e)
                }
                value={selecteddepartDate}
                inputProps={{
                  min: DateTime.now().toISODate()
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ss_es_modalclosehandelar}>Close</Button>
          {
            !editstopconfirmbtn ?
          <Button
            onClick={(i) =>
              saveDate()
            }
            disabled={editStopChangeflag}
          > Save
          </Button> :
          <div className="spinner_modal_footer_md">
          <CircularProgress />
          </div>
        }

        </Modal.Footer>
      </Modal>
      <Modal
        show={stopsviewmodal}
        onHide={() => stopclosehandeler()}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>Edit Stops</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {
            loadunloadflag ?
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Driver Load Unload</label>
                <div className='mt_10'>
                  <Autocomplete
                    id="combo-box-demo"
                    options={['Y', 'N', 'TL', 'NB']}
                    getOptionLabel={(option) => option}
                    value={selectedOption}
                    onChange={(e, option) => {setSelectedOption(option);setEditStopChangeFlag(false)}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Change Load Unload Flag"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div> : <></>
          }
          {
            editActualsExceptSSandES ? <>
              <label for="typeHeadCommodityGroup">
                Actual Arrival
              </label>
              <div className="meterial_autocomplete">
                <form noValidate>
                  <TextField
                    id="input_time"
                    type="datetime-local"
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                      handleactual_stops_DateChange(e)
                    }
                    value={selectedarrival_stops_Date}
                    inputProps={{
                      min: DateTime.now().toISODate()
                    }}

                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </form>
              </div>
              <label for="typeHeadCommodityGroup">
                Actual Depart
              </label>
              <div className="meterial_autocomplete">
                <form noValidate>
                  <TextField
                    id="input_time"
                    type="datetime-local"
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                      handledepart_stops_DateChange(e)
                    }
                    value={selecteddepart_stops_Date}

                    inputProps={{
                      min: DateTime.now().toISODate()
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </form>
              </div>
            </> : <></>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => stopclosehandeler()}>Close</Button>
        {
            !editstopconfirmbtn ?
          <Button
            onClick={(i) =>
              stop_ChangeClick()
            }
             disabled={editStopChangeflag}
          >
            Save
          </Button>:
           <div className="spinner_modal_footer_md">
           <CircularProgress />
         </div>
        }
        </Modal.Footer>
      </Modal>
      {/* //end */}

      {/* Change movement modal for E */}
      <Modal
        show={movementformodal}
        onHide={() =>handleCloseEditMovement()}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>Change Movement Type</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", paddingTop: "20px", fontSize: "18px" }}>
          <p>Do You Want To Change The Movement Type From {`${movementChangeTypeText}`}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => handleCloseEditMovement()}>Close</Button>
        {
            !changemovetypeconfirmbtn ? 
          <Button
            onClick={(i) =>
              movement_E_ChangeClick()
            }
            disabled={!isEditPermission}
          >
            Confirm
          </Button>:
          <div className="spinner_modal_footer">
            <CircularProgress />
          </div>
        }
        </Modal.Footer>
      </Modal>
      {/* //end */}

      {/* /// ORDER MODAL OPEN CLICK ON CLOSE ICON/// */}
      <Modal
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        show={orderremovemodal}
        onHide={() => {setorderremovemodal(false); setdeletmovconfirmbtn(false)}}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Confirmation
            {/* <h5 style={{ paddingTop: "5px" }}>Are you sure want to delete movement ?</h5> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to unschedule the order?</Modal.Body>
        <Modal.Footer>
          <Button

            onClick={() => {setorderremovemodal(false); setdeletmovconfirmbtn(false)}}
          >
            Close
          </Button>
          {!deletmovconfirmbtn ?
          <Button onClick={(i) =>
            removeOrder()
          }
            disabled={!isEditPermission}
          > Unschedule
          </Button>:
          <div className="spinner_modal_footer">
          <CircularProgress />
        </div>
          }
        </Modal.Footer>
      </Modal>
      {/* //// END/// */}

       {/* /// Extra Move MODAL OPEN CLICK ON CLOSE ICON/// */}
      <Modal
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        show={extraMoveModal}
        onHide={() => {setExtraMoveModal(false);setdeletmovconfirmbtn(false)}}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Do You want To Delete this Move?</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {setExtraMoveModal(false);setdeletmovconfirmbtn(false)}}
          >
            Close
          </Button>
        {!deletmovconfirmbtn ?
          <Button onClick={(i) =>
           extraMovedelete()
          }
            disabled={!isEditPermission}
          > Delete
          </Button>:
          <div className="spinner_modal_footer">
          <CircularProgress />
        </div>
          }
        </Modal.Footer>
      </Modal>      

      {/* ///  Destination modal for location*/}

      <Modal
        show={destinationViewModal}
        onHide={() => {
          setdestinationViewModal(false);
          resetMapRelatedData();
        }}
        size='lg'
        dialogClassName="modal-width"
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>Change Destination</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='split_view_container'>
            <div className='split_view_left'>
              <div className='search_section'>
                <div className='search_field_tankwash'>
                  <form class='example'>
                    <input
                      type='text'
                      placeholder='Search..'
                      name='search2'
                      onChange={(e) => handleSearchInput(e)}
                      onKeyUp={handleKeyPress}
                    />
                    <button onClick={(e) => searchbylatlong(e, searchLocationData)}>
                      <i class='fa fa-search'></i>
                    </button>
                  </form>
                </div>
                <div className='split_popup_dropdown'>
                  <form>
                    <select
                      name='cars'
                      id='cars'
                      value={selectedDistance}
                      onChange={(e) => filterLocationByDist(e)}
                    >
                      <option value="5" selected>
                        5
                      </option>
                      {miles.map((mile, k) => (
                        <option key={k}>{mile}</option>
                      ))}
                    </select>
                  </form>
                </div>
              </div>
              <p className='nearby_text'>
                Nearby Locations:{locationsDataFromMap.length}
              </p>
              <div className='location_section_wrapper'>
                {isLocationLoading ? (
                  <Stack spacing={1}>
                    <div className='loader_skeleton'>
                      <Skeleton variant='rectangular' />
                    </div>
                  </Stack>
                ) : (
                  <></>
                )}
                {locationsDataFromMap.map((location, index) => (
                  <div className='location_section'>
                     {
                        routeData?.movementData?.pickup?.lm_location_id === location.code ? <div className="split_pickup_location_icon mt_8"></div> :
                          routeData?.movementData?.delivery?.lm_location_id === location.code ? <div className="split_delivery_location_icon mt_8"></div> : <></>
                      }
                    <div className='location_section_left' style={routeData?.movementData?.pickup?.lm_location_id !== location.code && routeData?.movementData?.delivery?.lm_location_id !== location.code ? { marginLeft: "25px" } : {}}>
                      <div className='location_name'>
                        {location.code}-{location.location_name}
                        <br />
                        {location.address1}
                        <br />
                        {location.city},{location.state},{location.zip}
                      </div>
                      <div className='location_name'>
                        Distance: {location.distance} miles
                      </div>
                      <div className='location_section_right'></div>
                      <button
                        type='button'
                        class='btn-blue_schedule'
                        onClick={(i) => destinationchangeClick(location)}
                        disabled={changedestconfirmbtn}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='split_view_right_map'>
            <div id='map' className="split_view_right_map__map" style={{ height: "400px", width: "100%" }}></div>

            </div>
            <div className="split_view_right_map__guide">
              <p>To change destination on the movement, drag the red pin and drop where you would like the split along the route. Then pick a location from the list for location</p>
            <div className="split_pickup_location">
             <div className="split_pickup_location_icon"></div>
              <div>Pickup</div>
            </div>
            <div className='location_name ml_20'>
             {routeData?.movementData?.pickup?.lm_location_id}-{routeData?.movementData?.pickup?.lm_location_name}
             <br />
              {routeData?.movementData?.pickup?.address}
             <br />
              {routeData?.movementData?.pickup?.city},{routeData?.movementData?.pickup?.state},{routeData?.movementData?.pickup?.zip}
            </div>
            <div className="split_pickup_location">
              <div className="split_delivery_location_icon"></div>
              <div>Delivery</div>
            </div>
            <div className='location_name ml_20'>
              {routeData?.movementData?.delivery?.lm_location_id}-{routeData?.movementData?.delivery?.lm_location_name}
              <br />
              {routeData?.movementData?.delivery?.address}
             <br />
             {routeData?.movementData?.delivery?.city},{routeData?.movementData?.delivery?.state},{routeData?.movementData?.delivery?.zip}
           </div>
           </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              setdestinationViewModal(false);
              resetMapRelatedData(); 
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isAddMovTrimbleVisible}
        onHide={() => handleAddMovementViewModal()}
        size='lg'
        dialogClassName="modal-width"
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>Add Location</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='split_view_container'>
            <div className='split_view_left'>
              <div className='search_section'>
                <div className='search_field_tankwash'>
                  <form class='example'>
                    <input
                      type='text'
                      placeholder='Search..'
                      name='search2'
                      onChange={(e) => handleSearchInput(e)}
                      onKeyUp={handleKeyPress}
                    />
                    <button onClick={(e) => searchbylatlong(e, searchLocationData)}>
                      <i class='fa fa-search'></i>
                    </button>
                  </form>
                </div>
                <div className='split_popup_dropdown'>
                  <form>
                    <select
                      name='cars'
                      id='cars'
                      value={selectedDistance}
                      onChange={(e) => filterLocationByDist(e)}
                    >
                      <option value='5'>
                        5
                      </option>
                      {miles.map((mile, k) => (
                        <option key={k}>{mile}</option>
                      ))}
                    </select>
                  </form>
                </div>
              </div>
              <p className='nearby_text'>
                Nearby Locations:{locationsDataFromMap.length}
              </p>
              <div className='location_section_wrapper'>
                {isLocationLoading ? (
                  <Stack spacing={1}>
                    <div className='loader_skeleton'>
                      <Skeleton variant='rectangular' />
                    </div>
                  </Stack>
                ) : (
                  <></>
                )}
                {locationsDataFromMap.map((location, index) => (
                  <div className='location_section'>
                    <div className='location_section_left'>
                      <div className='location_name'>
                        {location.code}-{location.location_name}
                        <br />
                        {location.address1}
                        <br />
                        {location.city},{location.state},{location.zip}
                      </div>
                      <div className='location_name'>
                        Distance: {location.distance} miles
                      </div>
                      <div className='location_section_right'></div>
                      <button
                        type='button'
                        class='btn-blue_schedule'
                        onClick={(i) =>
                          addmovementchangeClick(
                            i,
                            terminalname,
                            destinationadd,
                            location,
                            selectedDriverData
                          )
                        }
                      >
                        Save
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='split_view_right_map'>
              <div id='map' className="split_view_right_map__map" style={{ height: "400px", width: "100%" }}>
              </div>
            </div>
            <div className="split_view_right_map__guide">
              <p>To change destination on the movement, drag the red pin and drop where you would like the split along the route. Then pick a location from the list for location</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => handleAddMovementViewModal()}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* //end */}
      {/* Movement Dialog Box */}
      {openMovementDialog && (
        <Modal
          size='sm'
          aria-labelledby='contained-modal-title-vcenter'
          show={openMovementDialog}
          onHide={() => setOpenMovementDialog(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Confirmation
              {/* <h5 style={{ paddingTop: "5px" }}>Are you sure want to delete movement ?</h5> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure want to delete movement ?</Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => setOpenMovementDialog(false)}
            >
              Cancel
            </Button>
            <Button onClick={() => handleCloseDialogMovement("save")} disabled={!isEditPermission}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Trailer Dialog Box */}
      {openTrailerDialog && (
        <Modal
          //  size="lg"
          aria-labelledby='contained-modal-title-vcenter'
          show={openTrailerDialog}
          onHide={() => setOpenTrailerDialog(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Remove Trailer
              <h5 style={{ color: "rgba(0, 0, 0, 0.87)" }}>
                Select the orders to unassign trailer
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ width: '60%' }}>
            <Picky
              options={trailerRemoveOptions}
              labelKey="label"
              valueKey="value"
              placeholder="Orders"
              multiple={true}
              includeFilter
              includeSelectAll
              value={selectedOrderForTrailer}
              numberDisplayed={1}
              manySelectedPlaceholder="Orders: %s"
              allSelectedPlaceholder="Orders: All"
              onChange={setSelectedOrderForTrailer}
              renderSelectAll={({ filtered, tabIndex, toggleSelectAll, multiple }) => {
                // Don't show if single select or items have been filtered.
                if (multiple && !filtered) {
                  return (
                    <div className="select_multiple_container">
                      <div
                        tabIndex={tabIndex}
                        role="option"
                        className="option"
                        onClick={toggleSelectAll}
                      >
                        <button className="selectall_btn">Select all</button>
                      </div>
                      <div
                        tabIndex={tabIndex}
                        role="option"
                        className="option"
                        onClick={() => {
                          setSelectedOrderForTrailer([]);
                        }}
                      >
                        <button className="selectall_btn">Clear All</button>
                      </div>
                    </div>
                  );
                }
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={(e) => setOpenTrailerDialog(false)}
            >
              {" "}
              Cancel
            </Button>
            <Button onClick={(i) =>
              removetrailerfrommovement(i, removeTrailerBlock, driverID, selectedOrderForTrailer, selectedDriverOnMovement)
            }
              disabled={!isEditPermission}
            > Unassign
            </Button>
          </Modal.Footer>
        </Modal>
      )}


      {driverChatMessageOpen &&
        <Modal
          //  size="lg"
          aria-labelledby='contained-modal-title-vcenter'
          show={driverChatMessageOpen}
          onHide={() => {
            setDriverChatMessageOpen(false);
            resetURLAfterClosingMessageModal();
          }}
          centered
          className="driver_chat_modal"
        >

          <Modal.Header closeButton className="message-modal-header">
            <Modal.Title id='contained-modal-title-vcenter' >{`${driverToSendMessage.driverId}-${driverToSendMessage?.driver_full_name.trim()}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {
              driverMessage?.data?.length > 0 &&
              driverMessage?.data
                ?.sort((a, b) => DateTime.fromISO(a.sent_ts) - DateTime.fromISO(b.sent_ts))
                .map((item) => {
                  let isBPA = item.source === 'BPA';
                  return (
                    <div className={isBPA ? 'chat-right' : 'chat-left'} key={item.id}>
                      <div className="chip-container sentmsg_date">
                        <Chip label={item.message_text} className="label1" />
                        <Chip label={convertdatetime1(item.sent_ts)} className="sentmsg_date" />
                      </div>
                    </div>
                  );
                })
            }

          </Modal.Body>
          <Modal.Footer>
            <div className="message_footer">
              <TextField
                variant="outlined"
                placeholder="Type a message"
                value={driverEnteredMessage}
                onChange={(e) => { setDriverEnteredMessage(e.target.value) }}

              />
              {driverEnteredMessage ? (
                <Button variant="contained" className="sendbutton" onClick={() => { sendDriverChatMessageHandler() }} >
                  Send
                </Button>
              ) : (
                <Button variant="contained" className="sendbutton" disabled>
                  Send
                </Button>
              )

              }

            </div>
          </Modal.Footer>
        </Modal>
      }

      {openXmitDialog && (
        <Modal
          //  size="lg"
          aria-labelledby='contained-modal-title-vcenter'
          show={openXmitDialog}
          onHide={() => setOpenXmitDialog(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Confirm Action
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>You are about to send the xmit for all orders for the selected driver.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={(e) => setOpenXmitDialog(false)}
            >
              {" "}
              Cancel
            </Button>
            <Button onClick={() => handleCloseDialogXmit("send", xmitDriver)} disabled={!isEditPermission}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}


      <div className='split_view_modal'>
        <Modal
          className='split_view_modal'
          show={locationViewModal}
          onHide={() => {
            setIsSplitButton(false)
            setlocationViewModal(false);        
            resetMapRelatedData();
          }}
          dialogClassName="modal-width"
          // size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              <p className='tank_mapview_header'>Map View</p>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className='split_view_container'>
              <div className='split_view_left'>
                <div className='search_section'>
                  <div className='search_field_tankwash'>
                    <form class='example'>
                      <input
                        type='text'
                        placeholder='Search..'
                        name='search2'
                        onChange={(e) => handleSearchInput(e)}
                        onKeyUp={handleKeyPress}
                      />
                      <button onClick={(e) => searchbylatlong(e, searchLocationData)}>
                        <i class='fa fa-search'></i>
                      </button>
                    </form>
                  </div>
                  <div className='split_popup_dropdown'>
                    <form>
                      <select
                        name='cars'
                        id='cars'
                        value={selectedDistance}
                        onChange={(e) => filterLocationByDist(e)}
                      >
                        <option value='5'>
                          5
                        </option>
                        {miles.map((mile, k) => (
                          <option key={k}>{mile}</option>
                        ))}
                      </select>
                    </form>
                  </div>
                </div>
                <p className='nearby_text'>
                  Nearby Locations:{locationsDataFromMap.length}
                </p>
                <div className='location_section_wrapper'>
                  {isLocationLoading ? (
                    <Stack spacing={1}>
                      <div className='loader_skeleton'>
                        <Skeleton variant='rectangular' />
                      </div>
                    </Stack>
                  ) : (
                    <></>
                  )}
                  {locationsDataFromMap.map((location, index) => (
                    <div className='location_section'>
                      {
                        routeData?.movementData?.pickup?.lm_location_id === location.code ? <div className="split_pickup_location_icon mt_8"></div> :
                          routeData?.movementData?.delivery?.lm_location_id === location.code ? <div className="split_delivery_location_icon mt_8"></div> : <></>
                      }
                      <div className='location_section_left' style={routeData?.movementData?.pickup?.lm_location_id !== location.code && routeData?.movementData?.delivery?.lm_location_id !== location.code ? { marginLeft: "25px" } : {}}>
                        <div className='location_name'>
                          {location.code}-{location.location_name}
                          <br />
                          {location.address1}
                          <br />
                          {location.city},{location.state},{location.zip}
                        </div>
                        <div className='location_name'>
                          Distance: {location.distance} miles
                        </div>
                        <div className='location_section_right'></div>
                        <button
                          type='button'
                          class='btn-blue_schedule'
                          onClick={(e) =>
                            splitviewclick(
                              e,
                              terminalname,
                              location,
                              driverIddropdown
                            )
                          }
                        >
                          Split
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='split_view_right_map'>
                <div id='map' className="split_view_right_map__map" style={{ height: "400px", width: "100%" }}></div>
              </div>
              <div className="split_view_right_map__guide">
                <p>To do a split on the movement, drag the red pin and drop where you would like the split along the route. Then pick a location from the list for split</p>
                <div>
                  <div className="split_pickup_location">
                    <div className="split_pickup_location_icon"></div>
                    <div>Pickup</div>
                  </div>
                  <div className='location_name ml_20'>
                    {routeData?.movementData?.pickup?.lm_location_id}-{routeData?.movementData?.pickup?.lm_location_name}
                    <br />
                    {routeData?.movementData?.pickup?.address}
                    <br />
                    {routeData?.movementData?.pickup?.city},{routeData?.movementData?.pickup?.state},{routeData?.movementData?.pickup?.zip}
                  </div>
                  <div className="split_pickup_location">
                    <div className="split_delivery_location_icon"></div>
                    <div>Delivery</div>
                  </div>
                  <div className='location_name ml_20'>
                    {routeData?.movementData?.delivery?.lm_location_id}-{routeData?.movementData?.delivery?.lm_location_name}
                    <br />
                    {routeData?.movementData?.delivery?.address}
                    <br />
                    {routeData?.movementData?.delivery?.city},{routeData?.movementData?.delivery?.state},{routeData?.movementData?.delivery?.zip}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={(e) => {
              setlocationViewModal(false);
              setIsSplitButton(false)
              resetMapRelatedData(); 
            }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ImpactviewModal Kendo Table */}
        <Modal
          show={impactViewModal}
          onHide={() => setImpactViewModal(false)}
          size='xl'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Impact View
            </Modal.Title>
          </Modal.Header>
          {dataLoaded ? (
            <Modal.Body>
              <Grid
                filter={dataState.filter}
                filterable={true}
                sort={dataState.sort}
                sortable={true}
                pageable={{
                  buttonCount: 10,
                  info: true,
                  previousNext: true,
                  pageSizes: true,
                }}
                resizable={true}
                skip={dataState.skip}
                take={dataState.take}
                data={dataResult}
                onDataStateChange={dataStateChange}
              >
                {columnorder?.slice(1)?.map((it) => (
                  <GridColumn
                    key={it.id}
                    field={it.name}
                    title={it.title}
                    width={it.width}
                  />
                ))}

              </Grid>

            </Modal.Body>
          ) : (
            <div className="progress_impact">
              <ProgressBar animated now={100} />
              <div className='middle loader--text1'> </div>
            </div>
          )}
          <Modal.Footer>
            <Button onClick={(e) => setImpactViewModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
        {/* end  */}

        {/* Audit log compponent */}
        <AuditLogModalComponent ref={auditLogComponentRef} />

        {/* Audit Log Modal and Kendo Table End*/}

        {/* SPLIT TERMINAL VIEW MODAL  */}
        <Modal
          show={splitviewmodal}
          onHide={() => setsplitviewmodal(false)}
          size='md'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              <p className='textcolorchange'>WARNING!</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Assign to another Terminal?
            <div className='meterial_autocomplete'>
              <label for='exampleFormControlInput1'>Select Terminal:</label>
              <Autocomplete
                id='combo-box-demo'
                options={terminalData}
                getOptionLabel={(option) => `${option.terminal_id} - ${option.name}`}
                onChange={terminalIdHandler}
                defaultValue={defaultterminal[0]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Choose a Terminal...'
                    variant='outlined'
                  />
                )}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {
              !splitConfirmBtn ?
            <Button
            disabled={!isEditPermission}
              onClick={(e) =>
                splitAssignDriverviewclick()
              }
            >
              Confirm
            </Button> :
              <div className="spinner_modal_footer">
              <CircularProgress />
            </div>
            }
          </Modal.Footer>
        </Modal>
        {/* END */}

        {/* MODAL FOR DRIVER DROPDOWN VIEW */}
        <Modal
          show={driverviewmodal}
          onHide={() => setdriverviewmodal(false)}
          size='md'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              <p className='textcolorchange'>WARNING!</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Assign to another Driver?
            <div className='meterial_autocomplete'>
              <label for='exampleFormControlInput1'>Select Driver:</label>
              <Autocomplete
                id='combo-box-demo'
                options={driverdropdownData}
                getOptionLabel={(option) =>
                  `${option.driver_id} - ${option.driverfullName}`
                }
                onChange={driverIdHandler}
                defaultValue={defaultdriver[0]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Choose a Driver...'
                    variant='outlined'
                  />
                )}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        {/* This modal will be opened for adding empty/bobtail after clicking on driver icon. */}
        <Modal
          show={modalShow}
          onHide={onModalClose}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          anchorEl={addMovements}
          open={Boolean(addMovements)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Movement
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label for="typeHeadCommodityGroup">
              Movement Type *
            </label>
            <div className="meterial_autocomplete">
              <Autocomplete
                id="combo-box-demo"
                options={movementType}
                getOptionLabel={(option) => `${option?.movement}`}
                // getOptionSelected={(option, value) => option.movement === value.movement}
                onChange={handleMovetype}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose a movement type..."
                    variant="outlined"
                    required={true}
                  />
                )}
              />
            </div>
            {selectedMoveType?.Type === "E" ?
              <>
                <label for="typeHeadCommodityGroup">
                  Trailer
                </label>
                <div className="meterial_autocomplete">
                  <Autocomplete
                    id="combo-box-demo"
                    options={allTrailers}
                    disabled={selectedMoveType?.Type == "BT" ? true : false}
                    getOptionLabel={(option) => `${option?.Trailer_ID}`}
                    onChange={trailerHandler}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose a Trailer..."
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </>
              : ""
            }
            <label for="typeHeadCommodityGroup">
              Driver
            </label>
            <div className="meterial_autocomplete">
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                defaultValue={selectedDriverData?.driverId}
              />
            </div>
            <label for="typeHeadCommodityGroup">
              Origin
            </label>
            <div className="meterial_autocomplete">
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                defaultValue={selectedDriverData?.terminal?.terminal_fullName}
              />
            </div>
            <label for="typeHeadCommodityGroup">
              Destination
            </label>
            <div className="meterial_autocomplete">
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                defaultValue={`${movementforlocmap?.code}-${movementforlocmap?.location_name}`}
                required={true}
              />
            </div>
            <label for="typeHeadCommodityGroup">
              Start Time
            </label>
            <div className="meterial_autocomplete">
              <form noValidate>
                <TextField
                  id="input_time"
                  type="datetime-local"
                  fullWidth
                  value={startTime}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
            </div>

          </Modal.Body>
          <Modal.Footer>
          { !addmovconfirmbtn ?
            <Button
              onClick={()=>createStops()}
              disabled={!isEditPermission && !isTrailerHistoryNeeded}
            >
              Create
            </Button>: <div className="spinner_modal_footer">
            <CircularProgress />
          </div>
            }
          </Modal.Footer>
        </Modal>
      </div>


      <Modal
        show={isDropTrailerTrimbleVisible}
        onHide={() => handleAddMovementViewModalDropTrailer()}
        size='lg'
        dialogClassName="modal-width"
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>Add New Location</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='split_view_container'>
            <div className='split_view_left'>
              <div className='search_section'>
                <div className='search_field_tankwash'>
                  <form class='example'>
                    <input
                      type='text'
                      placeholder='Search..'
                      name='search2'
                      onChange={(e) => handleSearchInput(e)}
                      onKeyUp={handleKeyPress}
                    />
                    <button onClick={(e) => searchbylatlong(e, searchLocationData)}>
                      <i class='fa fa-search'></i>
                    </button>
                  </form>
                </div>
                <div className='split_popup_dropdown'>
                  <form>
                    <select
                      name='cars'
                      id='cars'
                      value={selectedDistance}
                      onChange={(e) => filterLocationByDist(e)}
                    >
                      <option value='5'>
                        5
                      </option>
                      {miles.map((mile, k) => (
                        <option key={k}>{mile}</option>
                      ))}
                    </select>
                  </form>
                </div>
              </div>
              <p className='nearby_text'>
                Nearby Locations:{locationsDataFromMap.length}
              </p>
              <div className='location_section_wrapper'>
                {isLocationLoading ? (
                  <Stack spacing={1}>
                    <div className='loader_skeleton'>
                      <Skeleton variant='rectangular' />
                    </div>
                  </Stack>
                ) : (
                  <></>
                )}
                {locationsDataFromMap.map((location, index) => (
                  <div className='location_section'>
                    <div className='location_section_left'>
                      <div className='location_name'>
                        {location.code}-{location.location_name}
                        <br />
                        {location.address1}
                        <br />
                        {location.city},{location.state},{location.zip}
                      </div>
                      <div className='location_name'>
                        Distance: {location.distance} miles
                      </div>
                      <div className='location_section_right'></div>
                      <button
                        type='button'
                        class='btn-blue_schedule'
                        onClick={(i) =>
                          openDroptrailerModal(location)
                        }
                      >
                        Save
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className='split_view_right_map'>
              <div id='map' className="split_view_right_map__map" style={{ height: "400px", width: "100%" }}>

              </div>

            </div>
            <div className="split_view_right_map__guide">
              <p>To change destination on the movement, drag the red pin and drop where you would like the split along the route. Then pick a location from the list for location</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => handleAddMovementViewModalDropTrailer()}>Close</Button>
        </Modal.Footer>
      </Modal>

      {/* For Drop Trailer location change */}
      <Modal
        show={modalShowDropTrailer}
        onHide={onModalCloseDropTrailer}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Movement
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label for="typeHeadCommodityGroup">
            Movement Type
          </label>
          <div className="meterial_autocomplete">
            <TextField
              id="outlined-basic"
              variant="outlined"
              disabled={true}
              fullWidth
              defaultValue={"Empty"}
            />
          </div>
          <>
            <label for="typeHeadCommodityGroup">
              Trailer
            </label>
            <div className="meterial_autocomplete">
              <Autocomplete
                id="combo-box-demo"
                options={allTrailers}
                getOptionLabel={(option) => `${option?.Trailer_ID}`}
                onChange={handleDropTrailerDropTrailer}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose a Trailer..."
                    variant="outlined"
                  />
                )}
              />
            </div>
          </>
          <label for="typeHeadCommodityGroup">
            Driver
          </label>
          <div className="meterial_autocomplete">
            <TextField
              id="outlined-basic"
              variant="outlined"
              disabled={true}
              fullWidth
              defaultValue={selectedDriverOnMovement?.driverId}
            />
          </div>
          <label for="typeHeadCommodityGroup">
            Origin
          </label>
          <div className="meterial_autocomplete">
            <TextField
              id="outlined-basic"
              variant="outlined"
              disabled={true}
              fullWidth
              defaultValue={dropTrailerData?.movementData?.pickup?.location_name && dropTrailerData?.movementData?.pickup?.city ? (`${dropTrailerData?.movementData?.pickup?.location_name}-${dropTrailerData?.movementData?.pickup?.city}`) : (`${dropTrailerData?.movementData?.pickup?.city}`)}
            />
          </div>
          <label for="typeHeadCommodityGroup">
            Destination
          </label>
          <div className="meterial_autocomplete">
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              defaultValue={`${movementforlocmapForDropTrailer?.code}-${movementforlocmapForDropTrailer?.location_name}`}
            />
          </div>
          <label for="typeHeadCommodityGroup">
            Start Time
          </label>
          <div className="meterial_autocomplete">
            <form noValidate>
              <TextField
                id="input_time"
                type="datetime-local"
                fullWidth
                value={startTimeDropTrailer}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </div>
          <label for="typeHeadCommodityGroup">
            End Time
          </label>
          <div className="meterial_autocomplete">
            <form noValidate>
              <TextField
                id="input_time"
                type="datetime-local"
                fullWidth
                value={EndTimeDropTrailer}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {
            !addmovconfirmbtn ?
          <Button
            onClick={()=>dropTrailerHandler()}
            disabled={!isEditPermission}
          >
            Create
          </Button>: <div className="spinner_modal_footer">
            <CircularProgress />
          </div>
          }
        </Modal.Footer>
      </Modal>

      {/* eta */}
      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            ETA
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <label htmlFor='tankwashcode'>driver id</label>
          <div className='meterial_autocomplete'>
            <TextField
              fullWidth
              variant='outlined'
              type='number'
              name='minutes'
              placeholder='Enter driver id'
              size='small'
              value={driverid}
              onChange={handledriveridChange}
              disabled
            />
          </div>
          <label htmlFor='tankwashcode'>latitude</label>
          <div className='meterial_autocomplete'>
            <TextField
              fullWidth
              variant='outlined'
              type='number'
              name='minutes'
              placeholder='Enter latitude'
              size='small'
              onChange={handlelatitudeChange}
            />
          </div>
          <label htmlFor='tankwashcode'>longuitude</label>
          <div className='meterial_autocomplete'>
            <TextField
              fullWidth
              variant='outlined'
              type='number'
              name='minutes'
              placeholder='Enter longuitude'
              size='small'
              onChange={handlelonguitudeChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
          <Button type='submit' onClick={updateETAdata} >Save</Button>

        </Modal.Footer>
      </Modal>


      <Modal
          show={driverToolTipModal}
          onHide={() => {setDriverToolTipModal(false); setSelectedDriverHosData(null)}}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
        <Modal.Header closeButton  style={{backgroundColor: "#4267B2",color:"wheat"}}>
          <Modal.Title style={{color:"wheat",fontSize:"22px"}}>
            Driver Details
          </Modal.Title>
        </Modal.Header>
          <Modal.Body style={{ 
             maxWidth: 'none',
             backgroundColor: "#4267B2",
             borderColor: "#2C4F95",
             padding: "12px",
             color:"wheat"
            }} 
         >
          <div className='tooltip-adjust'>
      <div className='main_tooltip_section'>
        <div className='tooltip_left_driver'>
          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Driver :
            </div>
            <div className='tooltip_text_right'>
              {
                selectedDriverHosData?.driverId
              }
              -
              {selectedDriverHosData?.driver_full_name
                ??  "No Data"}
            </div>
          </div>

          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Terminal :
            </div>
            <div className='tooltip_text_right'>
              {(selectedDriverHosData?.terminal?.terminal_fullName || selectedDriverHosData?.terminal_city) ?? "No Data"}
            </div>
          </div>

          <div className='tooltip_single_sec pt_20'>
            <div className='driver_special_text'>
              Samsara Data:
            </div>
            <div className="tooltip_text_right">{selectedDriverHosData?.samsara_id ? selectedDriverHosData.samsara_id : "No Data"}</div>
          </div>

          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Vehicle :
            </div>
            <div className='tooltip_text_right'>
              {(selectedDriverHosData?.hosclock?.currentVehicle?.name) ?? "No Data"}
            </div>
          </div>
          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Duty Status
              :
            </div>
            <div className='tooltip_text_right'>
              {(selectedDriverHosData?.hosclock?.currentDutyStatus?.hosStatusType) ?? "No Data"}
            </div>
          </div>

          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Drive
              Remaining :
            </div>
            <div className='tooltip_text_right'>
              {selectedDriverHosData?.hosclock?.clocks?.drive?.driveRemainingDurationMs
                ? msToTime(selectedDriverHosData.hosclock.clocks.drive.driveRemainingDurationMs)
                : "No Data"}
            </div>
          </div>
          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Shift
              Remaining :
            </div>
            <div className='tooltip_text_right'>
              {selectedDriverHosData?.hosclock?.clocks?.shift?.shiftRemainingDurationMs
                ? msToTime(selectedDriverHosData.hosclock.clocks.shift.shiftRemainingDurationMs)
                : "No Data"}
            </div>
          </div>
          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Cycle
              Remaining :
            </div>
            <div className='tooltip_text_right'>
              {selectedDriverHosData?.hosclock?.clocks?.cycle?.cycleRemainingDurationMs
                ? msToTime(selectedDriverHosData.hosclock.clocks.cycle.cycleRemainingDurationMs)
                : "No Data"}
            </div>
          </div>
        </div>

        <div className='tooltip_middle_driver'>
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Time in
              Status :
            </div>
            <div className='tooltip_text_right'>
              {"No Data"}
            </div>
          </div>
        </div>

        <div className='tooltip_rightPu'>
          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Type :
            </div>
            <div className='tooltip_text_right'>
              {selectedDriverHosData?.driver_type_class
                ? selectedDriverHosData.driver_type_class
                : "No Data"}
            </div>
          </div>
          <div className='tooltip_single_sec'>
            <div className='tooltip_text_left'>
              Hired:
            </div>
            <div className='tooltip_text_right'>
              {selectedDriverHosData?.hire_date
                ? selectedDriverHosData.hire_date
                : "No Data"}
            </div>
          </div>
        </div>
      </div>
          </div>
          </Modal.Body>
         
        </Modal>
    </>
  );
};

export default PlannerBoardDriversection;