import { useState, useEffect, useRef, useContext } from "react";
import { UpdateOverrideSchedule, addOverrideEditSchedule } from "../../services/driverService";
import { DateTime } from "luxon";
import { ContextData } from "../appsession";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import ProgressBar from "react-bootstrap/ProgressBar";
import { NotificationContainer, NotificationManager } from "react-notifications";

gsap.registerPlugin(Draggable);


const OverrideUpdateSchedule = (props) => {
  const { overrideeditdata } = props;
  const [userData] = useContext(ContextData);
  const [gridWidth, setGridWidth] = useState();
  const [gridHeight, setGridHeight] = useState()
  const [scheduleGrid, setScheduleGrid] = useState();
  const [scheduleditdata, setscheduleditdata] = useState([]);
  const [overrideEditdate, setoverridEeditdate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropInCells, setdropInCells] = useState([]);
  const unavailableBoxRef = useRef();
  const availableBoxRef = useRef();
  const scheduleContainerGridRef = useRef();
  const gridProperties = { gridRows: 24, gridColumns: 2 };

  function handleFromDateChange(value) {
    setoverridEeditdate((prevState) => ({
      ...prevState,
      from_date: value,
    }));
  }

  function handleToDateChange(value) {
    setoverridEeditdate((prevState) => ({
      ...prevState,
      to_date: value,
    }));
  };

  function dragElement(ev, block_type, block_position, isNew) {
    let driverPermission = userData.roles.rolespermission.find(it => it?.permissiondetail?.code === "Drivers");
    if (!driverPermission.can_edit) {
      return NotificationManager.error(
        "You are not allowed to drag schedule", Error
      );
    } else {
      ev.dataTransfer.setData("dragAttribute_targetId", ev.target.id);
      ev.dataTransfer.setData("dragAttribute_blockType", block_type);
      ev.dataTransfer.setData("dragAttribute_isNew", isNew);
      ev.dataTransfer.setData("dragAttribute_block_position", block_position);

      if (isNew) {
        ev.dataTransfer.dropEffect = "copy";
      } else {
        let grid_cell = ev.target.id.split("_")[0];
        ev.dataTransfer.dropEffect = "move";
        ev.dataTransfer.setData("dragAttribute_drag_cell", grid_cell);
      }
    }
  };

  function allowDrop(ev) {
    ev.preventDefault(); 
  };

  const updateOverrideSchedule = async (requestData) => {
    try {
      await timeout(500);
      setLoading(true);
      const addOverrideResponse = await UpdateOverrideSchedule(requestData)
      setLoading(false);
    }
    catch (error) {
    }

  };

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const OverrideaddEditSchedule = async (requestData) => {
    try {
      await timeout(500);
      setLoading(true);

      const addScheduleResponse = await addOverrideEditSchedule(requestData)
      setLoading(false);

    } catch (error) {
    }
  };

  function getBlockTypeClassName(data) {
    if (data.block_type == "Available") {
      return "availablebutton-schedule";
    } else if (data.block_type == "Unavailable") {
      return "unavailablebutton-schedule";
    }
  };

  useEffect(async () => {
    // This UseEffect Colors Between the Blocks Of the Object , Modify this to change the color of the intermediate blocks , Not Start and End

    if (scheduleditdata) {
      // Clear All Divs From Draw
      for (
        let i = 0;
        i < gridProperties.gridRows * gridProperties.gridColumns;
        i++
      ) {
        try {
          document.getElementById(i + "_gridcell_override").style.backgroundColor =
            "white";
        } catch {
          // Do Nothing
        }
      }

      let filledCellArray = [];

      for (let index in scheduleditdata) {
        // Color All Middle Cells Between Available and Drop
        let start_of_paint = scheduleditdata[index].grid_cell_start_no;
        let end_of_paint = scheduleditdata[index].grid_cell_end_no;      

        // Color the Extension Cells
        for (let i = start_of_paint; i <= end_of_paint; i++) {
          if (i % 2 !== 0) {
            // Push Into The Validation Array If There Is a Match
            filledCellArray.push(i);
            if (scheduleditdata[index].block_type == "Available") {
              document.getElementById(i + "_gridcell_override").style.backgroundColor =
                "#77d6a2";
            } else if (scheduleditdata[index].block_type == "Unavailable") {
              document.getElementById(i + "_gridcell_override").style.backgroundColor =
                "#fa737c";
            }
          }
        }
      }
      setdropInCells(filledCellArray);
    }
  }, [scheduleditdata, loading]);

  const dropElement = (ev) => {
    ev.preventDefault();

    let dragAttribute_blockType = ev.dataTransfer.getData("dragAttribute_blockType");
    let dragAttribute_isNew = ev.dataTransfer.getData("dragAttribute_isNew");
    let dragAttribute_block_position = ev.dataTransfer.getData("dragAttribute_block_position");
    let overridesDataArray = []
    overridesDataArray = [...scheduleditdata];


    let drop_grid_cell = parseInt(ev.target.id.split("_")[0]);

    if (dragAttribute_isNew === "true") {
      let notallowedDrop = dropInCells.includes(drop_grid_cell);

      if (notallowedDrop) {
        NotificationManager.error("You Cannot Drag Drop On An Already Existing Schedule", "Error");
      } else {
        let start_time = (Math.floor(drop_grid_cell / gridProperties.gridColumns)) * 60;
        let end_time = (Math.floor(drop_grid_cell / gridProperties.gridColumns) + 1) * 60;

        let newBlock = {
          "start_time": start_time,
          "end_time": end_time,
          "block_type": dragAttribute_blockType,
          "grid_cell_start_no": drop_grid_cell,
          "grid_cell_end_no": drop_grid_cell + gridProperties.gridColumns,
        };

        let saveBlockObject = {
          "start_time": start_time,
          "end_time": end_time,
          "block_type": dragAttribute_blockType,
          "override_list_id": overrideeditdata[0]?.id,
          "duration": end_time - start_time,
        };

        overridesDataArray.push(newBlock);
        OverrideaddEditSchedule(saveBlockObject);
        setscheduleditdata(overridesDataArray);
      }

    } else if (dragAttribute_block_position == "end" || dragAttribute_block_position == "start") {

      let dragAttribute_drag_cell = parseInt(ev.dataTransfer.getData("dragAttribute_drag_cell"));
      let index;
      let updateOverrideObject = {};

      if (dragAttribute_block_position == "end") {
        if (loading == false) {
          index = overridesDataArray.findIndex(x => parseInt(x.grid_cell_end_no) === dragAttribute_drag_cell);
          let end_time = (Math.floor(drop_grid_cell / gridProperties.gridColumns) + 1) * 60;

          if (end_time <= overridesDataArray[index].start_time) {
            NotificationManager.error("End time cannot be less than Start time", "Error");
          } else {
            overridesDataArray[index].end_time = end_time;
            overridesDataArray[index].grid_cell_end_no = drop_grid_cell;
            updateOverrideObject.id = overridesDataArray[index].id;
            updateOverrideObject.end_time = end_time;
            updateOverrideObject.start_time = overridesDataArray[index].start_time;
            updateOverrideObject.duration = end_time - updateOverrideObject.start_time;
          }
        }
      } else if (dragAttribute_block_position == "start") {
        if (loading == false) {
          index = overridesDataArray.findIndex(x => parseInt(x.grid_cell_start_no) === dragAttribute_drag_cell);
          let start_time = (Math.floor(drop_grid_cell / gridProperties.gridColumns)) * 60;

          if (start_time >= overridesDataArray[index].end_time) {
            NotificationManager.error("Start time cannot be greater than End time", "Error");
          } else {
            overridesDataArray[index].grid_cell_start_no = drop_grid_cell;
            overridesDataArray[index].start_time = start_time;
            updateOverrideObject.id = overridesDataArray[index].id;
            updateOverrideObject.start_time = start_time;
            updateOverrideObject.end_time = overridesDataArray[index].end_time;
            updateOverrideObject.duration = overridesDataArray[index].end_time - start_time;
          }
        }
      }

      if (Object.keys(updateOverrideObject).length > 0) {
        updateOverrideSchedule(updateOverrideObject);
      }
    }

    setscheduleditdata(overridesDataArray);
  };

  const scheduleoverridedata = () => {
    let scheduleDataArray = [];

    if (overrideeditdata[0]?.override?.length > 0) {
      for (let index in overrideeditdata[0].override) {
        let scheduleObject = overrideeditdata[0].override[index];

        scheduleObject.grid_cell_start_no = ((((overrideeditdata[0].override[index].start_time / 60)) * gridProperties.gridColumns) + 1);
        scheduleObject.grid_cell_end_no = ((((overrideeditdata[0].override[index].end_time / 60)) * gridProperties.gridColumns) - 1);
        scheduleDataArray.push(scheduleObject)
      }

      setscheduleditdata(scheduleDataArray);
    }
  };

  useEffect(async () => {
    let gridArray = [];
    let gridWidth = window.innerWidth / gridProperties.gridColumns;

    setGridWidth(gridWidth);
    setGridHeight(30);
    let gridHeight = 30;
    let x, y;
    for (let i = 0; i < gridProperties.gridRows * gridProperties.gridColumns; i++) {
      y = Math.floor(i / gridProperties.gridColumns) * gridHeight;
      x = (i * gridWidth) % (gridProperties.gridColumns * gridWidth);
      gridArray.push({
        i: i,
        x: x,
        y: y
      })
    }
    setScheduleGrid(gridArray);
    scheduleoverridedata();
  }, []);

  const convertdate = (date) => {
    return DateTime.fromMillis(date * 1000);
  };

  return (
    <>
      <NotificationContainer />
      <div className="form-group">
        <div className="meterial_autocomplete">
          <div className="add_override_header">
            <div className="header-title1">Edit Overrides</div>
          </div>
          <div className="override-date-container">
            <div className="override-date-container__secondary">
              <label for="txtEffectiveDate" className="override-date-container__secondary-text">From Date </label>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  disableToolbar
                  inputVariant="outlined"
                  variant="outlined"
                  disabled={true}
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  placeholder="MM-dd-yyyy"
                  value={convertdate(overrideeditdata[0]?.from_date)}
                  onChange={handleFromDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </LocalizationProvider>
            </div>

            <div className="override-date-container__secondary">
              <label for="txtEffectiveDate" className="override-date-container__secondary-text">To Date </label>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  disableToolbar
                  inputVariant="outlined"
                  variant="outlined"
                  format="MM/dd/yyyy"
                  disabled={true}
                  placeholder="MM-dd-yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  value={convertdate(overrideeditdata[0]?.to_date)}
                  onChange={handleToDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div >
      </div >
      <div className="row">
        <div className="col-xl-12">
          <div className="card card_shadow1">
            <div className="card-body ">
              <div className="override-availability-container">
                <div className="planner_date_text1">Schedule:</div>
                <div
                  id="available"
                  draggable="true"
                  onDragStart={(e) => dragElement(e, "Available", "header", true)}
                  className="indicator_inner ml_20"
                >
                  <div
                    className="available_box"
                    ref={availableBoxRef}
                  ></div>
                  <div className="indicator_text">Available</div>
                </div>
                <div
                  id="unavailable"
                  draggable="true"
                  onDragStart={(e) => dragElement(e, "Unavailable", "header", true)}
                  className="indicator_inner ml_20">
                  <div
                    className="unavailable_box"
                    ref={unavailableBoxRef}
                  ></div>
                  <div className="indicator_text">Unavailable</div>
                </div>
              </div>
              <div className="table-responsive">
                <div className="calerder_total_section">
                  <div className="appointment-popup">
                    <div className="appointment-calendar">
                      <div className="calendar-wrapper">
                        <div
                          className="calendar-week"
                          style={{ display: "inherit" }}
                        >
                        </div>
                        <div className="calendar-week">
                        </div>
                        <div
                          ref={scheduleContainerGridRef}
                        >
                          {
                            scheduleGrid
                              ? scheduleGrid.map((gridElement) => (
                                gridElement.i % 2 === 0 ? (
                                  <div
                                    id={gridElement.i + "_gridcell_override"}
                                    className="schedule-grid-popup"
                                    style={{
                                      top: gridElement.y,
                                      left: gridElement.x,
                                      width: gridWidth - 1,
                                      height: gridHeight - 1,
                                    }}
                                  >
                                    { // Gridelement is genererated from I , there is a minus 1 as the number of rows are 25 and not 24
                                      gridElement.i / 2 + ":00"
                                    }

                                  </div>
                                ) : (
                                  <>
                                    <div
                                      id={gridElement.i + "_gridcell_override"}
                                      key={gridElement.i + "_gridcell_override"}
                                      className="schedule-grid-popup"
                                      style={{
                                        top: gridElement.y,
                                        left: gridElement.x,
                                        width: gridWidth - 1,
                                        height: gridHeight - 1,
                                      }}
                                      onDrop={(e) => dropElement(e)}
                                      onDragOver={(e) => allowDrop(e)}
                                    >

                                      {scheduleditdata.length > 0 ? (
                                        scheduleditdata.map((data) =>
                                          data.grid_cell_start_no === gridElement.i ? (
                                            <>
                                              {loading ? (<div className={getBlockTypeClassName(data)}>{data.block_type == "Available" ? (<ProgressBar animated variant="success" now={100} />) : (<ProgressBar animated variant="danger" now={100} />)}</div>
                                              ) : (
                                                <div className={getBlockTypeClassName(data)}
                                                  draggable="true"
                                                  onDragStart={(e) => dragElement(e, data.block_type, "start", false)}
                                                  id={`${data.grid_cell_start_no}_gridData`}
                                                  key={data.grid_cell_start_no + "_gridData"}>{data.block_type}</div>
                                              )
                                              }
                                            </>
                                          )
                                            : null
                                        )
                                      ) : null}
                                      {scheduleditdata.length > 0 ? (
                                        scheduleditdata.map((data) =>
                                          data.grid_cell_end_no === gridElement.i ? (
                                            <>
                                              {loading ? (<div className={getBlockTypeClassName(data)}>{data.block_type == "Available" ? (<ProgressBar animated variant="success" now={100} />) : (<ProgressBar animated variant="danger" now={100} />)}</div>
                                              ) : (<div className={getBlockTypeClassName(data)}
                                                draggable="true"
                                                onDragStart={(e) => dragElement(e, data.block_type, "end", false)}
                                                id={`${data.grid_cell_end_no}_gridData`}
                                                key={data.grid_cell_end_no + "_gridData"}>End</div>)}
                                            </>
                                          )
                                            : null
                                        )
                                      ) : null}
                                    </div>
                                  </>
                                )
                              ))
                              : null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverrideUpdateSchedule;