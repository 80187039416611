import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";
import App from "./App.js";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import axios from 'axios';
import { msalConfig, loginRequest } from "./authConfig";




/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

axios.interceptors.request.use(
    async (response) => {
        const account = msalInstance.getAllAccounts()[0];
        if (account) {
            const msalResponse = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account,
            });
            response.headers.common.Authorization = `Bearer ${msalResponse.accessToken}`;
            return response;

        } else {
            const msalResponse = await msalInstance.acquireTokenRedirect({
                ...loginRequest,
                account: account
            }).then((response) => {
                localStorage.setItem("accesstoken", response.accessToken);
                response.headers.common.Authorization = `Bearer ${msalResponse.accessToken}`;
                return response;

            })
        }

    },
    (err) => {
        return Promise.reject(err);
    }
);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
