import { Autocomplete, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import {
  addTrailer,
  changePlanTimebystartshift,
  scheduleSingleOrder,
  scheduleStackedOrders
} from "../services/planningBoardService";
import { DropContext } from "../pages/planner/planner";
import CircularProgress from '@mui/material/CircularProgress';

 const WarningModal = (props) => {
 const { setWarningModalOpen,warningMasterData,payload,driver,ApiName ,parentCallback,planId,setSuccessResponseMssg,setFailureResponseMssg, errormssg} = props;
 const [warningDropdownopen,setWarningDropdownopen]= useState(true);
 const [WarningModalcnfbtn,setWarningModalCnfBtn] = useState(false);
 const context = React.useContext(DropContext);

 const handleSubmit=async(newValue)=>{
   setWarningModalCnfBtn(true);
   if(parentCallback){
    if(payload){
      parentCallback(payload,newValue);
    }else{
      parentCallback(newValue);
    }
   }else if(ApiName && ApiName=='scheduleSingleOrder'){
    let payload1={...payload,['option']:newValue}
    try{
      let res= await scheduleSingleOrder(driver.driverId, payload1);
      setSuccessResponseMssg("Order scheduled successfully.")
    }
    catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        let str = err?.response?.data?.errors?.map((el) => `${el}`);
        setFailureResponseMssg(`${str[0].charAt(0).toUpperCase() + str[0].slice(1)}.`)
      }
    }
   }else if(ApiName && ApiName=='scheduleStackedOrders'){
    let payload1={...payload,['option']:newValue}
    try{
      let res= await scheduleStackedOrders(driver.driverId, planId,payload1);
      setSuccessResponseMssg("Order scheduled successfully.")
    }
    catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        let str = err?.response?.data?.errors?.map((el) => `${el}`);
        setFailureResponseMssg(`${str[0].charAt(0).toUpperCase() + str[0].slice(1)}.`)
      }
    }
   }else if(ApiName && ApiName=='changePlanTimebystartshift'){
    try{
      let res= await changePlanTimebystartshift(payload, planId,{"option":newValue});
      setSuccessResponseMssg("Change Plan Time successfull.");  
    }
    catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        let str = err?.response?.data?.errors?.map((el) => `${el}`);
        setFailureResponseMssg(`${str[0].charAt(0).toUpperCase() + str[0].slice(1)}.`)
      }
    }
   }else if(ApiName && ApiName=='addTrailer'){
    let payload1={...payload,['option']:newValue}
    try{
      let res= await addTrailer(driver.driverId, planId,payload1);
      setSuccessResponseMssg("Trailer assigned successfully.");   
    }
    catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        let str = err?.response?.data?.errors?.map((el) => `${el}`);
        setFailureResponseMssg(`${str[0].charAt(0).toUpperCase() + str[0].slice(1)}.`)
      }
    }
   }
   setWarningModalCnfBtn(false)
   setWarningModalOpen(null);
   setWarningDropdownopen(false);
   context.refreshDataFromAPI(context.payloadForRefreshData);
 }
const handleClose=()=>{
  context.refreshDataFromAPI(context.payloadForRefreshData);
  setWarningModalOpen(null);
  setWarningDropdownopen(false);
}
  return (
   <div>
     <Modal
          show={warningDropdownopen}
          onHide={()=>handleClose()}
          size='md'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              <p className='textcolorchange'>WARNING!</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errormssg ?? ""} Please click a button to proceed.
            <div className='warningModal_options_container'>
              {
                !WarningModalcnfbtn ?  warningMasterData?.map((el)=>{
                  return (
                    <Button
                    onClick={()=>handleSubmit(el)}
                    >
                      {el}
                    </Button>
                  )
                }) :
                <div className="spinner_modal_footer" style={{margin:"auto"}}>
                <CircularProgress />
                </div>
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
          <Button onClick={()=>handleClose()}>
            Cancel
          </Button>
          </Modal.Footer>
        </Modal>
   </div>
  )
}

export default WarningModal