import React, { useState, useEffect, useContext } from "react";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
  IntlService,
} from "@progress/kendo-react-intl";
import { process } from "@progress/kendo-data-query";
import { ContextData } from "../../components/appsession";
import {getUser, updatePUser, updateUser} from "../../services/userService";
import ProgressBar from "react-bootstrap/ProgressBar";
import getRowDraggable from "../rowDraggable/rowDraggable";
import { DateTime, Duration } from "luxon";
import "./ordersTable.css";
const DATE_FORMAT = "MM-dd-yyyy HH:mm a ZZZZ ";

const DetailComponent = (props) => {
  const dataItem = props.dataItem;
  return null;
  {
  }
};
const OrdersTable = (props) => {
  const [columnorder, setcolumnorder] = useState([])
  const { allOrdersArray,alignment,daysOut,loading } = props;
  console.log(allOrdersArray)
  const [isDataloaded, setIsDataLoaded] = useState(false);
  const [userData, setuserData, getUserInfoMSGraph] = useContext(ContextData);

   
  
  const locales = [
    {
      language: "en-US",
      locale: "en",
    },
    {
      language: "es-ES",
      locale: "es",
    },
  ];

  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 20,
    sort: [
      {
        field: "orderDate",
        dir: "desc",
      },
    ],
    // group: [{
    //   field: 'customerID'
    // }]
  });
  const [currentLocale, setCurrentLocale] = React.useState(locales[0]);
  const [orders, setorders] = useState([]);
  const [dataResult, setDataResult] = React.useState(
    process(orders, dataState)
  );

  let columns = [
    { id: 0, name: "expanded", title: " " },
    { id: 1, name: "terminal_fullname", title: "Terminal", width: "280px" },
    { id: 2, name: "Order_ID", title: "Order ID", width: "200px" },
    { id: 3, name: "Order_Status", title: "Order Status", width: "200px" },
    { id: 4, name: "Move_ID", title: "Move ID", width: "200px" },
    { id: 21, name: "pcmiler_distance", title: "Distance", width: "200px" },

    { id: 5, name: "Move_Status", title: "Move Status", width: "200px" },
    { id: 6, name: "pcmilerduration", title: 'Duration', width: '200px' },

    { id: 7, name: "Customer", title: "Customer", width: "280px" },
    { id: 8, name: "BOL", title: "BOL", width: "200px" },
    { id: 9, name: "Consignee_Ref", title: "Consignee Ref", width: "200px" },
    { id: 10, name: "PO_Lot", title: "PO Lot", width: "280px" },
    {
      id: 11,
      name: "Commodity_Group",
      title: "Commodity Group",
      width: "280px",
    },
    { id: 12, name: "Commodity", title: "Commodity", width: "280px" },
    { id: 13, name: "Product", title: "Product", width: "280px" },
    { id: 14, name: "HazMat", title: "HazMat", width: "200px" },
    { id: 15, name: "HazMat_Code", title: "HazMat Code", width: "200px" },
    { id: 16, name: "Ordered_Wgt", title: "Ordered Weight", width: "200px" },
    { id: 17, name: "ActualWgt", title: "Actual Weight", width: "200px" },
    { id: 18, name: "Driver_ID", title: "Driver ID", width: "200px" },
    { id: 19, name: "Trailer_ID", title: "Trailer ID", width: "200px" },
    { id: 20, name: "Tractor_ID", title: "Tractor ID", width: "200px" },
    { id: 22, name: "Sent_To_Driver", title: "Sent to Driver", width: "200px" },
    { id: 23, name: "Tankwash_WO", title: "Tankwash WO", width: "200px" },

    { id: 24, name: "Driver_Load", title: "Driver Load", width: "200px" },
    { id: 25, name: "Pickup_Type", title: "Pickup Type", width: "200px" },
    { id: 26, name: "Pickup_Name", title: "Pickup Location", width: "280px" },
    { id: 27, name: "Pickup_Address", title: "Pickup Address", width: "280px" },

    { id: 28, name: "Pickup_City", title: "Pickup City", width: "280px" },
    { id: 29, name: "Pickup_State", title: "Pickup State", width: "280px" },
    { id: 30, name: "Pickup_Zip", title: "Pickup Zip", width: "200px" },

    {
      id: 31,
      name: "Pickup_Timezone",
      title: "Pickup Timezone",
      width: "280px",
    },
    { id: 32, name: "Driver_Unload", title: "Driver Unload", width: "200px" },
    { id: 33, name: "Delivery_Type", title: "Delivery Type", width: "200px" },
    { id: 34, name: "Delivery_Name", title: "Delivery Location", width: "280px" },
    {
      id: 35,
      name: "Delivery_Address",
      title: "Delivery Address",
      width: "280px",
    },
    { id: 36, name: "Delivery_City", title: "Delivery City", width: "280px" },
    { id: 37, name: "Delivery_State", title: "Delivery State", width: "280px" },
    {
      id: 38,
      name: "sched_arrive_late_utc  ",
      title: "Late Pickup Appt",
      width: "280px",
    },
    { id: 39, name: "Delivery_Zip", title: "Delivery Zip", width: "200px" },
    {
      id: 40,
      name: "Delivery_Timezone",
      title: "Delivery Timezone",
      width: "280px",
    },
    { id: 41, name: "Drop_Flag", title: "Drop Flag", width: "200px" },
    {
      id: 42,
      name: "Early_Pickup_Appt",
      title: "Early Pickup Appt",
      width: "280px",
    },

    {
      id: 43,
      name: "Late_Pickup_Appt",
      title: "Late Pickup Appt",
      width: "280px",
    },
    {
      id: 44,
      name: "Pickup_Planned_Arrive",
      title: "Pickup Planned Arrive",
      width: "280px",
    },
    {
      id: 45,
      name: "Pickup_Planned_Depart",
      title: "Pickup Planned Depart",
      width: "280px",
    },

    { id: 46, name: "Pickup_ETA", title: "Pickup ETA", width: "280px" },
    {
      id: 47,
      name: "Pickup_Actual_Arrive",
      title: "Pickup Actual Arrive",
      width: "280px",
    },
    {
      id: 48,
      name: "Pickup_Actual_Depart",
      title: "Pickup Actual Depart",
      width: "280px",
    },

    {
      id: 49,
      name: "Early_Delivery_Appt",
      title: "Early Delivery Appt",
      width: "280px",
    },
    {
      id: 50,
      name: "Late_Delivery_Appt",
      title: "Late Delivery Appt",
      width: "280px",
    },
    {
      id: 51,
      name: "Delivery_Planned_Arrive",
      title: "Delivery Planned Arrive",
      width: "280px",
    },
    {
      id: 52,
      name: "Delivery_Planned_Depart",
      title: "Delivery Planned Depart",
      width: "280px",
    },
    { id: 53, name: "Delivery_ETA", title: "Delivery ETA", width: "280px" },
    {
      id: 54,
      name: "Delivery_Actual_Arrive",
      title: "Delivery Actual Arrive",
      width: "280px",
    },
    {
      id: 55,
      name: "Delivery_Actual_Depart",
      title: "Delivery Actual Depart",
      width: "280px",
    },
    { id: 56, name: "On_Hold", title: "On Hold", width: "280px" },
    { id: 57, name: "On_Hold_Reason", title: "On Hold Reason", width: "280px" },
    { id: 58, name: "planningstatus", title: "Planning Status", width: "200px" },

  ];

  

  if (userData.hasOwnProperty("orderColumns")) {
    columns = userData.orderColumns;
  }

  const columnReorderChange =async function (event) {
    console.log("running on coloumn change")
    console.log("event.target.columns",event.target.columns)
    const newOrders = event.target._columns.map((it) => {
      return {
        id: it.index,
        name: it.field,
        title: it.title,
        width: it.width,
      };
    });
    let payload = userData;
    payload.orderstabsequence = newOrders;

    const updateOrdersResponse = await updatePUser(userData); 

    const usrData = await getUser(userData.id);
    
  };

  const dataStateChange = (event) => {
    setDataResult(process(orders, event.dataState));
    setDataState(event.dataState);
  };

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setDataResult({
      ...dataResult,
    });
  };

  let _pdfExport;

  const exportExcel = () => {
    _export.save();
  };

  let _export;

  const exportPDF = () => {
    _pdfExport.save();
  };

  

  useEffect(() => {

      if (userData?.userpreferences?.orderstabsequence) {
        console.log(userData)
        setcolumnorder(userData?.userpreferences?.orderstabsequence)
      } else {
        setcolumnorder(columns)
      }
  }, [userData,alignment,daysOut])

  useEffect(() => {
   
    var arrayOfObj = [];
    let newOrderArr = allOrdersArray
    if(alignment == "center"){
      newOrderArr = allOrdersArray.filter(order => order.planningstatus === "Unplanned")
    }
    else {
      newOrderArr = allOrdersArray.filter(order => order.planningstatus === "Planned")
    }
    if (newOrderArr && Object.keys(newOrderArr)) { 
      arrayOfObj = Object.keys(newOrderArr).map((i) => newOrderArr[i]);
      let newDataValue = [];
      for (let index = 0; index < arrayOfObj.length; index++) {
        const temp = arrayOfObj[index];
        const duration = temp.pcmilerduration;

        if (!duration || isNaN(duration)) {
          temp.pcmilerduration = "";
        } else {
          const durationFormatted = Duration.fromObject({ hours: duration }).toFormat("hh:mm:ss");
          temp.pcmilerduration = durationFormatted;
        }        

        if (
          temp.Delivery_Actual_Arrive === null ||
          isNaN(temp.Delivery_Actual_Arrive)
        ) {
          temp.Delivery_Actual_Arrive;
        } else {
          const millis_Delivery_Actual_Arrive = parseInt(
            temp.Delivery_Actual_Arrive * 1000
          );

          const Timezone_Delivery_Actual_Arrive = temp.Delivery_Timezone;

          temp.Delivery_Actual_Arrive = DateTime.fromMillis(
            millis_Delivery_Actual_Arrive,
            {
              zone: Timezone_Delivery_Actual_Arrive,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Delivery_Actual_Depart === null ||
          isNaN(temp.Delivery_Actual_Depart)
        ) {
          temp.Delivery_Actual_Depart;
        } else {
          const millis_Delivery_Actual_Depart = parseInt(
            temp.Delivery_Actual_Depart * 1000
          );
          const Timezone_Delivery_Actual_Depart = temp.Delivery_Timezone;

          temp.Delivery_Actual_Depart = DateTime.fromMillis(
            millis_Delivery_Actual_Depart,
            {
              zone: Timezone_Delivery_Actual_Depart,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Delivery_Planned_Arrive === null ||
          isNaN(temp.Delivery_Planned_Arrive)
        ) {
          temp.Delivery_Planned_Arrive;
        } else {
          const millis_Delivery_Planned_Arrive = parseInt(
            temp.Delivery_Planned_Arrive * 1000
          );
          const Timezone_Delivery_Planned_Arrive = temp.Delivery_Timezone;

          temp.Delivery_Planned_Arrive = DateTime.fromMillis(
            millis_Delivery_Planned_Arrive,
            {
              zone: Timezone_Delivery_Planned_Arrive,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Delivery_Planned_Depart === null ||
          isNaN(temp.Delivery_Planned_Depart)
        ) {
          temp.Delivery_Planned_Depart;
        } else {
          const millis_Delivery_Planned_Depart = parseInt(
            temp.Delivery_Planned_Depart * 1000
          );
          const Timezone_Delivery_Planned_Depart = temp.Delivery_Timezone;

          temp.Delivery_Planned_Depart = DateTime.fromMillis(
            millis_Delivery_Planned_Depart,
            {
              zone: Timezone_Delivery_Planned_Depart,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Early_Delivery_Appt === null ||
          isNaN(temp.Early_Delivery_Appt)
        ) {
          temp.Early_Delivery_Appt;
        } else {
          const millis_Early_Delivery_Appt = parseInt(
            temp.Early_Delivery_Appt * 1000
          );
          const Timezone_Early_Delivery_Appt = temp.Delivery_Timezone;

          temp.Early_Delivery_Appt = DateTime.fromMillis(
            millis_Early_Delivery_Appt,
            {
              zone: Timezone_Early_Delivery_Appt,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (temp.Early_Pickup_Appt === null || isNaN(temp.Early_Pickup_Appt)) {
          temp.Early_Pickup_Appt;
        } else {
          const millis_Early_Pickup_Appt = parseInt(
            temp.Early_Pickup_Appt * 1000
          );
          const Timezone_Early_Pickup_Appt = temp.Delivery_Timezone;

          temp.Early_Pickup_Appt = DateTime.fromMillis(
            millis_Early_Pickup_Appt,
            {
              zone: Timezone_Early_Pickup_Appt,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Late_Delivery_Appt === null ||
          isNaN(temp.Late_Delivery_Appt)
        ) {
          temp.Late_Delivery_Appt;
        } else {
          const millis_Late_Delivery_Appt = parseInt(
            temp.Late_Delivery_Appt * 1000
          );
          const Timezone_Late_Delivery_Appt = temp.Delivery_Timezone;

          temp.Late_Delivery_Appt = DateTime.fromMillis(
            millis_Late_Delivery_Appt,
            {
              zone: Timezone_Late_Delivery_Appt,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (temp.Late_Pickup_Appt === null || isNaN(temp.Late_Pickup_Appt)) {
          temp.Late_Pickup_Appt;
        } else {
          const millis_movementpickupactual_departure = parseInt(
            temp.movementpickupactual_departure * 1000
          );
          const Timezone_movementpickupactual_departure =
            temp.movementpickuptimezone;
          const millis_Late_Pickup_Appt = parseInt(
            temp.Late_Pickup_Appt * 1000
          );
          const Timezone_Late_Pickup_Appt = temp.Pickup_Timezone;

          temp.movementpickupactual_departure = DateTime.fromMillis(
            millis_movementpickupactual_departure,
            {
              zone: Timezone_movementpickupactual_departure,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);

          temp.Late_Pickup_Appt = DateTime.fromMillis(millis_Late_Pickup_Appt, {
            zone: Timezone_Late_Pickup_Appt,
            setZone: "yes",
            locale: "en-US",
            outputCalendar: "gregory",
            numberingSystem: "buddhist",
          }).toFormat(DATE_FORMAT);
        }

        if (
          temp.movementpickupsched_arrive_early === null ||
          isNaN(temp.movementpickupsched_arrive_early)
        ) {
          temp.movementpickupsched_arrive_early;
        } else {
          const millis_movementpickupsched_arrive_early = parseInt(
            temp.movementpickupsched_arrive_early * 1000
          );
          const Timezone_movementpickupsched_arrive_early =
            temp.movementpickuptimezone;

          temp.movementpickupsched_arrive_early = DateTime.fromMillis(
            millis_movementpickupsched_arrive_early,
            {
              zone: Timezone_movementpickupsched_arrive_early,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Pickup_Actual_Arrive === null ||
          isNaN(temp.Pickup_Actual_Arrive)
        ) {
          temp.Pickup_Actual_Arrive;
        } else {
          const millis_Pickup_Actual_Arrive = parseInt(
            temp.Pickup_Actual_Arrive * 1000
          );
          const Timezone_Pickup_Actual_Arrive = temp.Pickup_Timezone;

          temp.Pickup_Actual_Arrive = DateTime.fromMillis(
            millis_Pickup_Actual_Arrive,
            {
              zone: Timezone_Pickup_Actual_Arrive,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Pickup_Actual_Depart === null ||
          isNaN(temp.Pickup_Actual_Depart)
        ) {
          temp.Pickup_Actual_Depart;
        } else {
          const millis_Pickup_Actual_Depart = parseInt(
            temp.Pickup_Actual_Depart * 1000
          );
          const Timezone_Pickup_Actual_Depart = temp.Pickup_Timezone;

          temp.Pickup_Actual_Depart = DateTime.fromMillis(
            millis_Pickup_Actual_Depart,
            {
              zone: Timezone_Pickup_Actual_Depart,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (temp.Pickup_ETA === null || isNaN(temp.Pickup_ETA)) {
          temp.Pickup_ETA;
        } else {
          const millis_Pickup_ETA = parseInt(temp.Pickup_ETA * 1000);
          const Timezone_Pickup_ETA = temp.Pickup_Timezone;

          temp.Pickup_ETA = DateTime.fromMillis(millis_Pickup_ETA, {
            zone: Timezone_Pickup_ETA,
            setZone: "yes",
            locale: "en-US",
            outputCalendar: "gregory",
            numberingSystem: "buddhist",
          }).toFormat(DATE_FORMAT);
        }

        if (temp.Delivery_ETA === null || isNaN(temp.Delivery_ETA)) {
          temp.Delivery_ETA;
        } else {
          const millis_Delivery_ETA = parseInt(temp.Delivery_ETA * 1000);
          const Timezone_Delivery_ETA = temp.Delivery_Timezone;

          temp.Delivery_ETA = DateTime.fromMillis(millis_Delivery_ETA, {
            zone: Timezone_Delivery_ETA,
            setZone: "yes",
            locale: "en-US",
            outputCalendar: "gregory",
            numberingSystem: "buddhist",
          }).toFormat(DATE_FORMAT);
        }

        if (
          temp.Pickup_Planned_Arrive === null ||
          isNaN(temp.Pickup_Planned_Arrive)
        ) {
          temp.Pickup_Planned_Arrive;
        } else {
          const millis_Pickup_Planned_Arrive = parseInt(
            temp.Pickup_Planned_Arrive * 1000
          );
          const Timezone_Pickup_Planned_Arrive = temp.Pickup_Timezone;

          temp.Pickup_Planned_Arrive = DateTime.fromMillis(
            millis_Pickup_Planned_Arrive,
            {
              zone: Timezone_Pickup_Planned_Arrive,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Pickup_Planned_Depart === null ||
          isNaN(temp.Pickup_Planned_Depart)
        ) {
          temp.Pickup_Planned_Depart;
        } else {
          const millis_Pickup_Planned_Depart = parseInt(
            temp.Pickup_Planned_Depart * 1000
          );
          const Timezone_Pickup_Planned_Depart = temp.Pickup_Timezone;

          temp.Pickup_Planned_Depart = DateTime.fromMillis(
            millis_Pickup_Planned_Depart,
            {
              zone: Timezone_Pickup_Planned_Depart,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        newDataValue.push(temp);
      }
      setorders(newDataValue);
      setIsDataLoaded(true);
    }
    
   
    if (!userData.hasOwnProperty("orderDataState")) {
      setDataResult(process(arrayOfObj, dataState));
    } else {
      // userData.orderDataState.skip = 0;
      // setDataResult(process(arrayOfObj, userData.orderDataState));
      setDataResult(process(arrayOfObj))
    }
  }, [userData,allOrdersArray,alignment,daysOut]);

  return (
    <div className="tab_inside_section">
      <div className="tab_content">
        {
          !loading? <LocalizationProvider language={currentLocale.language}>
          <IntlProvider locale={currentLocale.locale}>
            <ExcelExport
              data={orders}
              ref={(exporter) => {
                _export = exporter;
              }}
            >
              <Grid
                sortable={true}
                filterable={true}
                groupable={true}
                reorderable={true}
                position={top}
                pageable={{
                  buttonCount: 4,
                  pageSizes: true,
                  
                }}
                resizable={true}
                data={dataResult}
                {...dataState}
                onDataStateChange={dataStateChange}
                // filter={dataState.filter}
                // filterable={true}
                // sort={dataState.sort}
                // sortable={true}
                // pageable={{
                //   pageSizes: [5, 10, 20, 25, 50, 100],
                //   info: true,
                //   previousNext: true,
                //   buttonCount: 10,
                // }}
                // resizable={true}
                // skip={dataState.skip}
                // take={dataState.take}
                // data={dataResult && dataResult}
                // onDataStateChange={dataStateChange}
                detail={DetailComponent}
                onExpandChange={expandChange}
                onColumnReorder={columnReorderChange}
              
                expandField="expanded"
              >
                <GridToolbar></GridToolbar>
                {columnorder.slice(1).map((it) => (
                  <GridColumn
                    key={it.id}
                    field={it.name}
                    title={it.title}
                    width={it.width}
                  />
                ))}
              </Grid>
            </ExcelExport>
          </IntlProvider>
        </LocalizationProvider>:<ProgressBar animated now={100} />
        }
     

          
      </div>
    </div>
  );
};

export default OrdersTable;
