import React, { useEffect, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { makeStyles } from "@mui/styles";
import ProgressBar from "react-bootstrap/ProgressBar";
import {useLocation} from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";
import { useContext } from "react";
import { ContextData } from "../../components/appsession";
import { getAllAlert, updateAlert, getAllReasonCodes } from "../../services/alertService";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Autocomplete } from "@mui/lab";
import { TextField} from "@mui/material";

const AlertsTable = (props) => {

const {allAlertData,title,isPageLoading,parentCallback}=props;

  const [modalShow, setModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [views, setviews] = useState(false);

  const [userData, setuserData] = useContext(ContextData);
  const [isLoading, setIsLoading] = useState(false);
  const [alertDataToBeUpdated,setAlertDataToBeUpdated]=useState({})
  const [selectedReasonCode, setSelectedReasonCode] = useState({});
  const [allLateReasonCodeOptions, setAllLateReasonCode] = useState([]);

  const location=useLocation()
  const useStyles = makeStyles((theme) => ({
    statuscolor: {
      textAlign: "center!important",
      fontWeight: "bold",
      fontSize: 15
    },
  }));

  const classes = useStyles();

 useEffect(()=>{
setIsLoading(isPageLoading)
 },[isPageLoading])



  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    
  });

  const [dataResult, setDataResult] = useState(
    process(allAlertData, dataState)
  );

  const dataStateChange = (event) => {
    setDataResult(process(allAlertData, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {
    setDataResult(process(allAlertData, dataState)); 
  }, [allAlertData]);

  useEffect(() => {
    // Get all late reason code
    (async () => {
      try {
        const data = await getAllReasonCodes();
        setAllLateReasonCode(data);
      } catch (err) {
        toast.error("Something went wrong. Please try again.");
      }
    })();
  }, []);

  const modelOpenHandler=(it) => {
   setConfirmModalShow(true);
   setAlertDataToBeUpdated((pre)=>{
    return {
      ...pre,
      ...it
    }
   })
  }

  const rowClickHandler = async (e) => {
    setAlertDataToBeUpdated((prevState) => {
      return {
        ...prevState,
        ...e.dataItem
      }
    });  
    setModalShow(true);
  };

  const handleOptionChange = (e, value) => {  
    setSelectedReasonCode(value);
  }; 

  const  clearState=()=>{
    setSelectedReasonCode({});
  }

  const updateAlertStatus = async (type) => {
    let status=""
    if(type=='approved'){
      status="Completed"
    }else if(type=='rejected'){
      status='Rejected'
    }else if(type=='dismissed'){
      status='Dismissed'
    }

    let alert_payload={
      status:status,
      alert_comments:alertDataToBeUpdated.comments,      
      reason_code_id: selectedReasonCode.id
    }
    const data = await updateAlert(alertDataToBeUpdated.id, alert_payload);
  
   setIsLoading(false)
   if(data.status==200){
    
    setModalShow(false);
    setConfirmModalShow(false)
    parentCallback(true)
    
   return NotificationManager.success(`Alert  ${status}`, "Success",3000);
   }
    
   
  }

  const commenetChangeHandler = (e) => {
    setAlertDataToBeUpdated(prevState => {
      return { ...prevState, comments: e.target.value }
    })
    
  }

  return (
    <>
      <ToastContainer position='top-right' theme='colored' />
      <div className='row special_row_flex'>
        <div className='col-xl-12'>
          <div className='card card_shadow'>
            <div className='card-body'>


              <div className='table-responsive'>
                {allAlertData?.length > 0 ? (

                  <Grid
                    filter={dataState.filter}
                    filterable={true}
                    //filterOperators={filterOperators}
                    sort={dataState.sort}
                    sortable={true}
                    pageable={{
                      pageSizes: [ 25, 50, 100],
                      info: true,
                      previousNext: true,
                      buttonCount: 10,
                    }}
                    resizable={true}
                    skip={dataState.skip}
                    take={dataState.take}
                    data={dataResult}
                    
                    onDataStateChange={dataStateChange}
                  onRowClick={rowClickHandler}
                  >
                    <GridColumn
                      title="Action"
                      filterable={false}
                      width="200px"
                      cell={(it) => {
                        return (
                          <td>
                            <Button
                              className="btn_blue_sm btn-blue ml_10"
                              variant="primary"
                              onClick={() => {modelOpenHandler(it.dataItem) }}
                            >
                              Dismiss
                            </Button>
                          </td>


                        );
                      }}
                    />
                    
                       <GridColumn
                      field='text'
                      title='Alert Description'
                      filterable={true}
                      width="250px"
                    />
                    <GridColumn
                      field='alert_type'
                      title='Alert Type'
                      filterable={true}
                      width='200px'
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.alert_type ? e.dataItem.alert_type : "No Data"}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='status'
                      title='Alert Status'
                      filterable={true}
                      width='200px'
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.status ? e.dataItem.status : "No Data"}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='terminal.terminal_fullName'
                      title='Terminal'
                      filterable={true}
                      width="200px"
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.terminal.terminal_fullName ? e.dataItem.terminal.terminal_fullName : ""}
                          </td>
                        );
                      }}
                    />
                   
                    <GridColumn
                      field='lm_order_id'
                      title='Order Id'
                      filterable={true}
                      width="200px"
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.lm_order_id ? e.dataItem.lm_order_id : "No Data"}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='load_id'
                      title='Movement Id'
                      filterable={true}
                      width="150px"
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.load_id ? e.dataItem.load_id : "No Data"}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='shipper'
                      title='Shipper'
                      filterable={true}
                      width="150px"
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.shipper ? e.dataItem.shipper : ""}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='consignee'
                      title='Consignee '
                      filterable={true}
                      width="150px"
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.consignee ? e.dataItem.consignee : ""}
                          </td>
                        );
                      }}
                    />
                     <GridColumn
                      field='alert_comments'
                      title='Comments '
                      filterable={true}
                      width="250px"
                      
                    />

                  </Grid>

                ) : isLoading ? (
                  <div>
                    <ProgressBar animated now={100} />
                    <div className='middle loader--text1'> </div>
                  </div>
                ) : (
                  <div className='text-center'>No data found</div>
                )}
              </div>
            </div>
          </div>
          
       {/* Confirmation Modal for transfer */}
       <NotificationContainer />
        </div>
      
      </div>
      
     
       {/* Confirmation Modal */}
      <Modal show={confirmModalShow}
        onHide={() => { setConfirmModalShow(false) }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="p_text">Are you sure ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setConfirmModalShow(false) }}>
            Cancel
          </Button>
          <Button variant="primary"  onClick={() => { updateAlertStatus("dismissed") }}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

       {/* Alert Action Modal */}
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false)
          clearState()
        
        }}
        onClose={()=>{setModalShow(false)}}
        dialogClassName="alert_modal_width"
        aria-labelledby='contained-modal-title-vcenter'
        centered
        
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="authorize_pay">
            <div className="driverpay_info_general">
              <div className="driverpay_info_genera_right">
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Id :</span>
                  <span className="general_info_value">{alertDataToBeUpdated.id ? alertDataToBeUpdated.id : "No Data"}</span>
                </p>
                
              </div>
              <div className="driverpay_info_genera_left">
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Terminal :</span>
                  <span className="general_info_value">{alertDataToBeUpdated.terminal_id ? alertDataToBeUpdated.terminal.terminal_fullName : "No Data"}</span>
                </p>

                {/* <p className="driverpay_info_item2">
                  <span className="general_info_label">Created By :</span>
                  <span className="general_info_value">{authorizeData?.created_by ? authorizeData.created_by : "No Data"}</span>
                </p> */}

              </div>
              <div className="driverpay_info_genera_right">
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Order Id:</span>
                  <span className="general_info_value">{alertDataToBeUpdated.lm_order_id ? alertDataToBeUpdated.lm_order_id : "No Data"}</span>
                </p>
                
              </div>
              <div className="driverpay_info_genera_left">
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Movement Id :</span>
                  <span className="general_info_value">{alertDataToBeUpdated.load_id ? alertDataToBeUpdated.load_id : "No Data"}</span>
                </p>
              </div>
              <div className="driverpay_info_genera_left">
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Consignee :</span>
                  <span className="general_info_value">{alertDataToBeUpdated.consignee ? alertDataToBeUpdated.consignee : "No Data"}</span>
                </p>
              </div>
              <div className="driverpay_info_genera_left">
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Shipper :</span>
                  <span className="general_info_value">{alertDataToBeUpdated.shipper ? alertDataToBeUpdated.shipper : "No Data"}</span>
                </p>
              </div>

             

            </div>
            <div >
                <p className="driverpay_info_item2">
                  <span className="general_info_label"> Alert Description:</span>
                  <span className="general_info_value">{alertDataToBeUpdated.text ? alertDataToBeUpdated.text : "No Data"}</span>
                </p>
              </div>

            <div className="driverpay_authorize">
              <label htmlFor="authorize_comment" className="authorize_label">Late reason code</label>
              <div className="meterial_autocomplete">
                <Autocomplete
                  id="combo-box-demo"
                  options={allLateReasonCodeOptions}
                  getOptionLabel={(option) => `${option?.description}`}
                  onChange={handleOptionChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose a late reason code"
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
            <div className="driverpay_authorize">
              <label htmlFor="authorize_comment" className="authorize_label">Comments</label>
              <textarea id="authorize_comment" className="authorize_comment" rows={5} defaultValue={alertDataToBeUpdated.alert_comments} onChange={commenetChangeHandler}></textarea>
            </div>
            
          </div>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={() => { updateAlertStatus("approved") }}>
            Approve
          </Button>
        </Modal.Footer>

      </Modal>
    </>
  );
};

export default AlertsTable;
