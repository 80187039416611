import React, { useEffect, useState } from "react";
import { Grid as MGrid } from '@mui/material';
import { ToggleButtonGroup, ToggleButton } from '@mui/lab';

const OrderHeader = (props) => {
  const { setAlignment, alignment } = props;
  const daysOut = [1, 2, 3, 4, 5, 6, 7]

  const handleDaysout = (event) => {
    props.parentCallback(event.target.value)
  };

  const handleChange = (event, newAlignment) => {

    if (newAlignment === null) return;
    setAlignment(newAlignment);
  };
  return (
    <div className="row df  adjustrow mt_30">
      <div className="col-md-6 col-xl-12">
        <div className="card special_bg">
          <div className="card-body">
            <div className="row top_adjust">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div className="col-md-2">
                <h2 className="text-light">Orders</h2>
              </div>
              <div className="tab_inside_section_top_right">
                <div className='ordertabdropdown'>
                  <form>
                    <p className="ordertabdropdown_label">Days out</p>
                    <select onChange={(e) => handleDaysout(e)}>
                      {daysOut.map(day => (
                        <option>{day}</option>
                      ))}
                    </select>
                  </form>
                </div>
                <div className="planning_board_tab_toggle">
                  <MGrid
                    container
                    spacing={2}
                    direction='column'
                    alignItems='right'
                  >
                    <MGrid item>
                      <ToggleButtonGroup
                        size='small'
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                      >
                        <ToggleButton value='left'>PLANNED</ToggleButton>
                        <ToggleButton value='center'>UNPLANNED</ToggleButton>
                      </ToggleButtonGroup>
                    </MGrid>
                  </MGrid>
                </div>
              </div>
           </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHeader;
