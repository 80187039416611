
import { DateTime } from "luxon";
import * as fflate from "fflate";

// Application Specific
import PlanningBoard from "../models/planningBoardModel";
import { BaseConfig } from "../baseConfig";
import Driver from "../models/driverModel";
import Trailer from "../models/trailerModel";
import Config from '../Config.json';
import axios from 'axios';
export const getDriverbyTerminalIDs = async (request_data) => {
  try {
    let data = {
      terminal_id: request_data.terminals,
      date: request_data.date,
    };

    const url = BaseConfig.ApiEndPoint + "/planningboarddrivers";

    const driverApiRes = await axios.post(url, data);
    const driverApiResponse = atob(driverApiRes.data);

    // Convert binary string to character-number array
    const charData = driverApiResponse.split("").map((x) => {
      return x.charCodeAt(0);
    });
    // Turn number array into byte-array
    const binData = new Uint8Array(charData);
    // Use this Code to Decode the String

    //Uncompress API Call//
    let driverApiDataUnCompressed = fflate.unzlibSync(binData);
    let driverApiDataUnCompressedString = fflate.strFromU8(
      driverApiDataUnCompressed
    );
    const driverApiDecompressedObject = JSON.parse(
      driverApiDataUnCompressedString
    );

    const driver = new Driver();

    return driverApiDecompressedObject.data;
  } catch (err) {
    throw err;
  }
};

export const getPlanningBoardData = async (searchData) => {
  const planningBoard = new PlanningBoard();
  let planningDecompressedObject;
  // let planningObjByDriver = {};
  try {
    let data = searchData;
    data.date = searchData?.date;
    data.cgs = searchData?.cgs === undefined ? [] : searchData?.cgs;
    // data.terminals= searchData?.terminal === undefined ? [] : searchData?.terminal
    data.search = searchData?.search === undefined ? "" : searchData?.search;
    // plannerterminals: searchData.userplanners,

    // let data = {};
    // // if (reload) {
    // if (searchData.view === "carrier") {
    //   data = {
    //     date: new Date(searchData.date).getTime().toString(),
    //     // search: searchData.search === undefined ? "" : searchData.search,
    //   };
    // } else {
    //   data = {
    //     date: new Date(searchData.date).getTime().toString(),
    //     cgs: searchData.cgs === undefined ? [] : searchData.cgs,
    //     terminals: searchData.terminal === undefined ? [] : searchData.terminal,
    //     search: searchData.search === undefined ? "" : searchData.search,
    //     // plannerterminals: searchData.userplanners,
    //   };
    // }
    const url = BaseConfig.ApiEndPoint + "/planningboard";
    // const planningApiData = await axios.post(url, data);

    const planningApiResponse = await axios.post(url, data);
    const planningApiData = await planningApiResponse.data;

    //locationApiData = zlib.inflateSync(Buffer.from(locationApiData.data, 'base64')).toString();
    const strData = atob(planningApiData);
    // Convert binary string to character-number array
    const charData = strData.split("").map((x) => {
      return x.charCodeAt(0);
    });
    // Turn number array into byte-array
    const binData = new Uint8Array(charData);
    // Use this Code to Decode the String

    //Uncompress API Call//
    let planningApiDataUnCompressed = fflate.unzlibSync(binData);
    let planningApiDataUnCompressedString = fflate.strFromU8(
      planningApiDataUnCompressed
    );
    planningDecompressedObject = JSON.parse(planningApiDataUnCompressedString);
  } catch (err) {
    throw err;
  }
  return planningDecompressedObject;
};

export const getBoardByDriverFormatted = async (
  movementData,
  trailerData,
  driverData,
  plannedMode = true
) => {
  

  let planners = [];


  try {
    planners = await Promise.all(
      driverData.map(async (dri_data) => {
        const block = {};
        const driver_movements = movementData?.data?.filter(
          (mvmt) => mvmt?.driver_id === dri_data?.driver_id
        );

        block.driverId = dri_data.driver_id;
        block.driver_full_name = dri_data.driver_full_name;
        block.samsara_id = dri_data.samsara_id;
        block.driver_type_class = dri_data.driver_type_class;
        block.hire_date = dri_data.hire_date;
        block.terminal = dri_data.terminal;
        block.terminal_city = dri_data.termina_full_name;
        block.schedule = {}
        block.hosclock = dri_data.hosclock ? dri_data.hosclock : {};
        block.utilization = 0;
        block.messages=dri_data.messages;
        block.ScheduleOverrideList = dri_data.ScheduleOverrideList;


        try {
          const modifiedMovements = [];

          let movementtsInAscOrder = driver_movements.sort((a, b) => a.pickup.planned_arrive_utc - b.pickup.planned_arrive_utc);

          for (let i = 0; i < movementtsInAscOrder.length; i++) {
            let currentMovement = driver_movements[i];
            let trailerArr = [];

            if (![null, undefined, ""].includes(currentMovement.trailer_id) || ["L", "E", "BT"].includes(currentMovement.movement_type)) {

              let trailerObj = {
                order_id2: currentMovement.order_id2
              };

              trailerArr.push({ ...trailerObj, ...currentMovement.trailer });

              if (i !== 0 && driver_movements[i - 1].delivery?.trailerDetails?.length > 0) {
                let previousMovTrailerDetailsOfDel = driver_movements[i - 1].delivery.trailerDetails;

                if (previousMovTrailerDetailsOfDel[previousMovTrailerDetailsOfDel.length - 1].trailer_id !== currentMovement.trailer_id) {
                  // If current movement trailer id is different from previous movement trailer id
                  driver_movements[i - 1].delivery.trailerDetails = [...previousMovTrailerDetailsOfDel, ...trailerArr];
                  currentMovement.pickup.trailerDetails = driver_movements[i - 1].delivery.trailerDetails;
                } else {
                  // If current movement trailer id is same as previous movement trailer id
                  currentMovement.pickup.trailerDetails = previousMovTrailerDetailsOfDel;
                }
                // if (currentMovement.delivery.stop_type !== "ES") {
                currentMovement.delivery.trailerDetails = trailerArr;
                // }
              } else {
                currentMovement.pickup.trailerDetails = [...trailerArr];
                currentMovement.delivery.trailerDetails = [...trailerArr];
              }
            }

            modifiedMovements.push(currentMovement);
          };

          block.movements = getPlanningBoardDriverMovements({
            driver_movements: modifiedMovements,
            plannedMode: plannedMode,
          });

          block.movements.sort((a, b) => {
            return a.startTime - b.startTime;
          });

          // trim mvmt/stop blocks
          block.movements = block.movements
            .map((mvmt, idx) => {
              if (idx >= block.movements.length - 1) return mvmt;
              let next = idx;
              while (
                next < block.movements.length - 1 &&
                block.movements[next].startTime ==
                  block.movements[idx].startTime
              )
                next += 1;
              return {
                ...mvmt,
                endTime: Math.min(
                  mvmt.endTime,
                  block.movements[next]?.startTime
                ),
              };
            })
            .filter((mvmt) => mvmt.startTime <= mvmt.endTime);


          // dedupe
          block.movements = [
            ...block.movements.filter((it) => it?.stopData == null),
            ...[
              ...new Set(
                block.movements
                  .filter((it) => it?.stopData != null)
                  .map((it) => it?.stopData?.lm_stop_id)
              ),
            ].map((stop_id) => {
              let stops = block.movements.filter(
                (it) => it?.stopData?.lm_stop_id == stop_id
              );
              if (stops.length == 1) return stops[0];
              return stops.filter(
                (stop) => stop.movementData.pickup.lm_stop_id == stop_id
              )[0];
            }),
          ].filter((m) => m);

          block.movements.sort((a, b) => {
            return a.startTime - b.startTime;
          });

          // Add linked mvmts to stops
          block.movements = block.movements.map((mvmt, idx) => {
            if (mvmt.stopData) {
              let linkedMvmts = [
                idx > 0
                  ? {
                      move_id: block.movements[idx - 1]?.move_id,
                      type: block.movements[idx - 1]?.movementData
                        ?.movement_type,
                        status:block.movements[idx-1].movementData?.status
                    }
                  : null,
                idx < block.movements.length - 1
                  ? {
                      move_id: block.movements[idx + 1]?.move_id,
                      type: block.movements[idx + 1]?.movementData
                        ?.movement_type,
                        status:block.movements[idx+1].movementData?.status
                    }
                  : null,
              ].filter((i) => i);

              return {
                ...mvmt,
                stopData: {
                  ...mvmt.stopData,
                  linkedMvmts,
                },
              };
            }
            return mvmt;
          });

          let orders = [];
          let trailers = [];
          let final_shifts = [];

          for (let i = 0; i < block.movements.length; i++) {
            let it = block.movements[i];
            if (
              !(
                [null, "", undefined].includes(
                  ["PU", "DEL", "SP", "SD", "L"].includes(it.type) &&
                    ["", null, undefined].includes(it.orderId2)
                    ? it.orderId
                    : it.orderId2
                ) || ["SS", "ES", "BT", "E", "HT", "DT"].includes(it.type)
              )
            ) {
              let order = {};
              let movementData = it.movementData;

              order.orderId =
                ["PU", "DEL", "SP", "SD", "L"].includes(it.type) &&
                  ["", null, undefined].includes(it.orderId2)
                  ? it.orderId
                  : it.orderId2;
              order.mvmtType = it.type;
              order.terminal_id = it.terminal_id;
              order.orderStartTime = it.startTime;
              order.orderEndTime = it.endTime;
              order.commodity = it.commodity;
              order.status = it.status;
              order.bol_received = it.bol;
              order.ordered_wt = it.weight;
              order.pickup_location = movementData.pickup.location;
              order.delivery_location = movementData.delivery.location;
              order.terminalId = movementData.driver.terminal_id;
              order.order_status = it.status;
              order.location_name = movementData.pickup.location.location_name;
              order.address1 = movementData.pickup.location.address1;
              order.city = movementData.pickup.location.city;
              order.state = movementData.pickup.location.state;
              order.zip = movementData.pickup.location.zip;
              order.move_id = movementData.load_id;
              order.company_location_id1 =
                movementData.delivery.location.company_location_id;
              order.location_name1 =
                movementData.delivery.location.location_name;
              order.address11 = movementData.delivery.location.address1;
              order.city1 = movementData.delivery.location.city;
              order.plan_id = movementData.delivery.plan_id;
              order.state1 = movementData.delivery.location.state;
              order.zip1 = movementData.delivery.location.zip;
              order.delivery_driver_load_unload =
                movementData.delivery.driver_load_unload;
              order.pickup_driver_load_unload =
                movementData.pickup.driver_load_unload;
              order.pickup_appt_required = movementData.pickup.appt_required;
              order.picup_confirmed = movementData.pickup.confirmed;
              order.pickup_sched_arrive_early_utc =
                movementData.pickup.sched_arrive_early_utc;
              order.pickup_sched_arrive_late_utc =
                movementData.pickup.sched_arrive_late_utc;
              order.delivery_sched_arrive_early_utc =
                movementData.delivery.sched_arrive_early_utc;
              order.delivery_sched_arrive_late_utc =
                movementData.delivery.sched_arrive_late_utc;
              order.distance = movementData.distance;
              order.pickup_timezone = movementData.pickup.pickup_timezone;
              order.delivery_timezone = movementData.delivery.delivery_timezone;
              order.commoditygroupid = it.order_commodity?.commoditygroupid
                ? it.order_commodity?.commoditygroupid
                : "";
              order.commoditygroup_description = it.order_commodity?.description
                ? it.order_commodity?.description
                : "";
              order.actual_arrival_utc = movementData.pickup.actual_arrival_utc;
              order.actual_departure_utc =
                movementData.pickup.actual_departure_utc;
              order.actual_arrival_utc1 =
                movementData.delivery.actual_arrival_utc;
              order.actual_departure_utc1 =
                movementData.delivery.actual_departure_utc;
              order.style = {};

              if ([0, null, undefined, ""].includes(movementData.xmitted2driver_utc))
                order.style = { backgroundColor: "white", color: "grey" };
              else {
                if (
                  [0, null, undefined, ""].includes(movementData.xmit_accepted)
                )
                  order.style = { backgroundColor: "orange", color: "white" };
                else
                  order.style = { backgroundColor: "green", color: "white" };
              }

              orders.push(order);
            }
            if (it.trailer_id !== null) {
              //console.log("trailerDatas1",it)


              const trailerDatas = trailerData && trailerData?.find(t => t.Trailer_ID == it.trailer_id)
//console.log("trailerDatas",trailerData)
              let trailer = {
               /// order_id: it.orderId,
                order_id2: it.orderId2,
                trailer_id: it.trailer_id,
                mvmt_load_ids: it.move_id,
                trailerStartTime: it.startTime,
                trailerEndTime: it.endTime,
                license_plate: it.trailerData?.license_plate,
                license_state: it.trailerData?.license_state,
                stat: it.trailerData?.stat,
                make: it.trailerData?.make,
                model: it.trailerData?.model,
                model_year: it.trailerData?.model_year,
                tare_weight: it.trailerData?.tare_weight,
                pm_due_date_utc: it.trailerData?.pm_due_date_utc,
                commodity_group: it.trailerData?.commodity_group,
                commoditygroup_description:
                  it.trailerData?.commoditygroup?.description,
                terminal_id: it.terminal_id,
                // terminalname:it.stopData?.location?.city ,
                // commodityid:it.movementData?.order?.order_commodity[0]?.CG?.commodityid,
                // commodity_Id:it.movementData?.order?.order_commodity[0]?.commodity_id,
                equip_types: trailerDatas?.Equip_Type,
                mvmt_type: it.type,
                Last_Wash_WO : trailerDatas?.['Last_Wash_W/O'],
                Loads_to_Next_Wash : trailerDatas?.Loads_to_Next_Wash,
                Days_to_Next_Wash : trailerDatas?.Days_to_Next_Wash,
                Last_Order : trailerDatas?.Last_Order,
                Last_Commodity : trailerDatas?.Last_Commodity,
                Last_Product : trailerDatas?.Last_Product,
                Last_Washed : trailerDatas?.Last_Washed,
                commodity_group : trailerDatas?.Commodity_Group,
                Loads_Since_Last_Wash : trailerDatas?.Loads_Since_Last_Wash,
                Days_Since_Last_Wash : trailerDatas?.Days_Since_Last_Wash,
                Length : trailerDatas?.Length,
                Width : trailerDatas?.Width,
                Height : trailerDatas?.Height,
                Volume : trailerDatas?.Volume,
                dedicated : trailerDatas?.Dedicated,
                terminal : trailerDatas?.Terminal,
                shipper_pool : trailerDatas?.Shipper_Pool,
                State : trailerDatas?.State,
              };

              trailers.push(trailer);
            }

            if (["SS", "ES"].includes(it.type))
              final_shifts.push({
                shiftType: it.type === "SS" ? "start" : "end",
                shiftStartTime: it.startTime,
                shiftEndTime: it.endTime,
                move_id: it.move_id,
                movementData: it.movementData,
              });
          }

          let final_orders = [];
          let final_trailers = [];

          let idxs = [];
          orders.sort((a, b) => a.startTime - b.startTime);
          orders.forEach((it, idx) => {
            if (idxs.includes(idx)) return;
            let order = { ...it };
            let obstacle = false;

            orders.slice(idx + 1).forEach((it2, idx2) => {
              if (
                obstacle ||
                ((it2.orderId != order.orderId &&
                  it2.orderStartTime != order.orderStartTime) ||
                (["SS", "ES", "BT", "E", "HT", "DT"].includes(it2.mvmtType) &&
                    Math.abs(it2.orderEndTime - it2.orderStartTime) > 0)) &&
                !["DEL", "SD"].includes(it2.mvmtType) ||
                order.orderEndTime < it2.orderStartTime
              ) {
                obstacle = true;
                return;
              } else {
                idxs.push(idx2 + idx + 1);
                if ([null, undefined, ""].includes(order.orderId)) order.orderId = it2.orderId;
                order.orderEndTime = it2.orderEndTime;
              }
            });

            final_orders.push(order);
            idxs.push(idx);
          });

          idxs = [];

          trailers.sort((a, b) => a.trailerStartTime - b.trailerStartTime);
          trailers.forEach((it, idx) => {
            if (idxs.includes(idx)) return;
            let trailer = {
              ...it,
              mvmt_load_ids: [],
              all_orders: [],
            };
            let obstacle = false;
            trailers.slice(idx).forEach((it2, idx2) => {
              if (
                (obstacle ||
                  (((it2.trailer_id != it.trailer_id &&
                    it2.order_id2 != it.order_id2) ||
                    (["SS", "ES", "BT"].includes(it2.mvmt_type) &&
                      Math.abs(it2.trailerEndTime - it2.trailerStartTime) >
                      0) ||
                    trailer.trailerEndTime < it2.trailerStartTime ||
                  (it2.order_id2 != it.order_id2 &&
                    (it.trailer_id == "" || it2.trailer_id == ""))) &&
                    !["DT", "DEL", "SD"].includes(it2.mvmt_type))) &&
                it2.trailerStartTime != it.trailerStartTime
              ) {
                obstacle = true;
                return;
              } else {
                if (["", null, undefined].includes(trailer.trailer_id))
                  trailer.trailer_id = it2.trailer_id;
                idxs.push(idx2 + idx);
                trailer.mvmt_load_ids.push(it2.mvmt_load_ids);
                trailer.all_orders.push(it2.order_id);
                trailer.trailerEndTime = it2.trailerEndTime;
              }
            });
            trailer.mvmt_load_ids = [...new Set(trailer.mvmt_load_ids)];
            trailer.all_orders = [...new Set(trailer.all_orders)];
            final_trailers.push(trailer);
            idxs.push(idx);
          });
           
          let newEmptyMoveByPlanner=[];
          block.movements?.forEach((movement)=>{
            if(movement?.type=='E' && movement?.movementData?.is_planner_inserted){
              newEmptyMoveByPlanner.push(movement)
            }
          })
          block.orders = final_orders;
          block.trailers = final_trailers;
          block.shifts = final_shifts;
          block.newEmptyMoveByPlanner=newEmptyMoveByPlanner;

          /* block.orders = getPlanningBoardDriverOrders({
            movements: block.movements,
            plannedMode,
          });
          block.trailers = getPlanningBoardDriverTrailers({
            movements: block.movements,
          });
          block.shifts = getPlanningBoardDriverShifts({
            movements: block.movements,
          }); */

          block.movements = block.movements.filter(
            (mvmt) => !["SS", "ES"].includes(mvmt.type)
          );
          let driverOffduty=[];
          let endShits=block.movements?.filter((mov) => mov.movementData.delivery.stop_type === "ES" && mov.stopData === null).sort((a, b) => a.planned_arrive_utc - b.planned_arrive_utc);
          endShits.length>0 && endShits?.forEach((el)=>{
            let obj={
              logStartTime:el.movementData.delivery.planned_depart_utc,
              logEndTime:el.movementData.delivery.planned_depart_utc + 10 * 60 * 60 * 1000
            }
            driverOffduty.push(obj)
          })
          block.schedule.offduty=driverOffduty
          block.isloading = false;
        } catch (err) {
          console.log(err);
        }
        return block;
      })
    );

  
  } catch (err) {
    console.log({err});
    return [];
  }
  return planners;
};
;
export const getPlanningBoardDriverBobtails = async ({
  driverData,
  planningBoardData,
}) => {
  let bobtails = {};
  let driverIds = [];
  let bobtailReqs = [];

  if (!driverData || !planningBoardData) return bobtails;

  try {
    let filteredPlanningArray = [];
    if (planningBoardData?.data.length > 0) {
      filteredPlanningArray = planningBoardData?.data?.filter(
        (order) => order.movement.brokerage == "N"
      );
    }

    let planningObjByDriver = filteredPlanningArray.reduce((r, a) => {
      r[a.movement.driver.driver_id] = [
        ...(r[a.movement.driver.driver_id] || []),
        a,
      ];
      return r;
    }, {});

    for (let loop = 0; loop < driverData.length; loop++) {
      let planningObj = planningObjByDriver[driverData[loop].driver_id];

      bobtailReqs.push(
        getPlanningBoardOrderBobtails({ driverPlanningObj: planningObj }).catch(
          (err) => {
            return {};
          }
        )
      );
      driverIds.push(driverData[loop].driver_id);
    }

    let bobtailRes = await Promise.all(bobtailReqs);
    for (let loop = 0; loop < driverIds.length; loop++) {
      bobtails[driverIds[loop]] = bobtailRes[loop];
    }

    return bobtails;
  } catch (err) {
    return {};
  }
};

export const getPlanningBoardOrderBobtails = async ({ driverPlanningObj }) => {
  let bobtailReqs = [];
  let bobtailIds = [];
  let bobtails = {};
  if (!driverPlanningObj) return bobtails;

  for (let i = 0; i < driverPlanningObj.length; i++) {
    let it = driverPlanningObj[i];
    let order_id = it.lm_order_id;

    var pcm_data = JSON.stringify({
      ReportRoutes: [
        {
          RouteId: "",
          Stops: [
            {
              Coords: {
                Lat: it.movement.driver.terminal.latitude,
                Lon: it.movement.driver.terminal.longitude,
              },
            },
            {
              Coords: {
                Lat: it.movement.pickup.latitude,
                Lon: it.movement.pickup.longitude,
              },
            },
          ],
          ReportTypes: [
            {
              __type: Config.dev.pcMilerApiType,
              THoursWithSeconds: true,
            },
          ],
        },
      ],
    });

    bobtailReqs.push(
      pcmilerRouteReports(pcm_data).catch((err) => {
        return {
          data: [
            { ReportLines: [{ THours: "00:00:00" }, { THours: "00:00:00" }] },
          ],
        };
      })
    );
    bobtailIds.push(order_id);
  }

  await Promise.all(bobtailReqs)
    .then((pcmilerRes) =>
      pcmilerRes.map((res, idx) => {
        var travel_time = res.data[0].ReportLines[1].THours.split(":");
        bobtails[bobtailIds[idx]] =
          (parseInt(travel_time[0]) * 60 * 60 +
            parseInt(travel_time[1]) * 60 +
            parseInt(travel_time[2])) *
          1000;
      })
    )
    .catch((err) => console.log(err));

  return bobtails;
};

export const getPlanningBoardDriverMovements = ({
  driver_movements,
  plannedMode = true,
}) => {
  try {
    let movements = [];
    if (!driver_movements) return movements;

    for (let i = 0; i < driver_movements.length; i++) {
      let mvmt = driver_movements[i];

      let pu_arrive_utc = plannedMode
        ? mvmt.pickup.planned_arrive_utc
        : mvmt.pickup.actual_arrival_utc;
      let pu_depart_utc = plannedMode
        ? mvmt.pickup.planned_depart_utc
        : mvmt.pickup.actual_departure_utc;
      let del_arrive_utc = plannedMode
        ? mvmt.delivery.planned_arrive_utc
        : mvmt.delivery.actual_arrival_utc;
      let del_depart_utc = plannedMode
        ? mvmt.delivery.planned_depart_utc
        : mvmt.delivery.actual_departure_utc;

      if (
        [0, null, undefined].includes(pu_arrive_utc) ||
        [0, null, undefined].includes(pu_depart_utc) ||
        [0, null, undefined].includes(del_arrive_utc) ||
        [0, null, undefined].includes(del_depart_utc)
      )
        continue;
      let pickup_style = {};
      
      if (mvmt.pickup.eta_utc > mvmt.pickup.sched_arrive_late_utc) {

        if (!mvmt.pickup.sched_arrive_late_utc) {
          pickup_style = {
            background: "#5eab5e",
            color: "white",
          }
        } else {
          pickup_style = {
            background: "#b53329",
            color: "white",
          }
        }
      } else if ([0, null, undefined, ""].includes(mvmt.pickup.eta_utc)) {
        pickup_style = {
          background: "#5eab5e",
          color: "white",
        };
      } else if (
        mvmt.pickup.eta_utc > pu_arrive_utc &&
        mvmt.pickup.eta_utc <= mvmt.pickup.sched_arrive_late_utc
      ) {
        pickup_style = { background: "#c9af31", color: "white" };
      } else if (mvmt.pickup.eta_utc <= pu_arrive_utc) {
        pickup_style = { background: "#5eab5e", color: "white" };
      }

      let pu = {};
      // if(i-1>= 0){
      //   pu.associated_move_ids = [driver_movements[i-1]?.load_id,mvmt.load_id]
      // }

      pu.id = mvmt.pickup.lm_stop_id;

      pu.orderId = mvmt.order_id;
      pu.orderId2 = mvmt.order_id2;

      pu.move_id = mvmt.load_id;
      pu.load_id = mvmt.load_id;
      pu.movement_type = mvmt.movement_type;
      pu.movementData = mvmt;
      pu.stopData = {
        ...mvmt.pickup,
      };
      pu.type =
        mvmt.pickup.stop_type == "split pickup"
          ? "SP"
          : mvmt.pickup.stop_type == "split delivery"
            ? "SD"
            : mvmt.pickup.stop_type;
      pu.stop_pickup_type = mvmt.pickup.stop_type;
      pu.trailer_id = ["SS", "ES", "BT"].includes(mvmt.pickup.stop_type)
        ? null
        : mvmt.trailer.trailer_id ?? "";
      pu.orig_trailer_id = mvmt.trailer.trailer_id;
      pu.movement_status = mvmt.status;
      pu.trailerData = mvmt.trailer;
      pu.terminal_id = mvmt.terminal_id;
      pu.startTime = pu_arrive_utc;
      pu.endTime = pu_depart_utc;
      pu.pickup_timezone = mvmt.pickup.pickup_timezone;
      pu.pickup_planned_arrive = mvmt.pickup.planned_arrive_utc;
      pu.pickup_planned_depart = mvmt.pickup.planned_depart_utc;
      pu.pickup_sched_arrive_early_utc = mvmt.pickup.sched_arrive_early_utc;
      pu.pickup_sched_arrive_late_utc = mvmt.pickup.sched_arrive_late_utc;
      pu.pickup_actual_arrive = mvmt.pickup.actual_arrival_utc;
      pu.pickup_actual_depart = mvmt.pickup.actual_departure_utc;
      pu.pickup_eta_utc = mvmt.pickup.eta_utc;
      pu.driverId = mvmt.driver_id;
      pu.latitude = mvmt.pickup?.latitude;
      pu.longitude = mvmt.pickup.longitude;
      pu.pickup_location = mvmt.pickup.location?.location_name;
      pu.delivery_location = mvmt.delivery.location?.location_name;
      pu.company_location_id = mvmt.pickup.location?.company_location_id;
      pu.location_name = mvmt.pickup.location?.location_name;
      pu.address1 = mvmt.pickup.location?.address1;
      pu.city = mvmt.pickup.location?.city;
      pu.state = mvmt.pickup.location?.state;
      pu.zip = mvmt.pickup.location?.zip;
      pu.style = pickup_style;
      pu.company_location_id1 = mvmt.delivery.location?.company_location_id;
      pu.location_name1 = mvmt.delivery.location?.location_name;
      pu.address11 = mvmt.delivery.location?.address1;
      pu.city1 = mvmt.delivery.location?.city;
      pu.state1 = mvmt.delivery.location?.state;
      pu.zip1 = mvmt.delivery.location?.zip;
      pu.trailerDetails = mvmt?.pickup?.trailerDetails;

      // let prev_move_id=driver_movements?.find(x=>x.dlv_lm_stop_id=== mvmt.pickup.lm_stop_id )
      // // pu.associated_move_ids=[ mvmt.load_id,prev_move_id?.load_id]

      movements.push(pu);

      let drive_to_del = {};
      drive_to_del.id = mvmt.load_id;
      drive_to_del.orderId = mvmt.order_id;
      drive_to_del.orderId2 = mvmt.order_id2;
      drive_to_del.type = mvmt.movement_type;
      drive_to_del.stop_pickup_type = mvmt.pickup.stop_type;
      drive_to_del.trailer_id = ["SS", "ES", "BT"].includes(mvmt.movement_type)
        ? null
        : mvmt.trailer.trailer_id
          ? mvmt.trailer.trailer_id
          : "";
      drive_to_del.orig_trailer_id = mvmt.trailer.trailer_id;
      drive_to_del.move_id = mvmt.load_id;
      drive_to_del.load_id = mvmt.load_id;
      drive_to_del.distance = mvmt.distance;
      drive_to_del.movement_status = mvmt.status;
      drive_to_del.trailerData = mvmt.trailer;
      drive_to_del.movementData = mvmt;
      drive_to_del.stopData = null;
      drive_to_del.terminal_id = mvmt.terminal_id;
      drive_to_del.startTime = pu_depart_utc;
      drive_to_del.endTime = del_arrive_utc;
      drive_to_del.eta_utc = mvmt.pickup.eta_utc;
      drive_to_del.driverId = mvmt.driver_id;
      drive_to_del.pickup_timezone = mvmt.pickup.pickup_timezone;
      drive_to_del.planned_arrive_utc = pu_arrive_utc;
      drive_to_del.planned_depart_utc = pu_depart_utc;
      drive_to_del.actual_arrival_utc = mvmt.pickup.actual_arrival_utc;
      drive_to_del.actual_departure_utc = mvmt.pickup.actual_departure_utc;
      drive_to_del.latitude = mvmt.pickup?.latitude;
      drive_to_del.longitude = mvmt.pickup.longitude;
      drive_to_del.pickup_location = mvmt.pickup.location?.location_name;
      drive_to_del.delivery_location = mvmt.delivery.location?.location_name;
      drive_to_del.move_id = mvmt.load_id;
      drive_to_del.brokerage = mvmt.brokerage;
      drive_to_del.company_location_id =
        mvmt.pickup.location?.company_location_id;
      drive_to_del.location_name = mvmt.pickup.location?.location_name;
      drive_to_del.address1 = mvmt.pickup.location?.address1;
      drive_to_del.city = mvmt.pickup.location?.city;
      drive_to_del.state = mvmt.pickup.location?.state;
      drive_to_del.zip = mvmt.pickup.location?.zip;
      drive_to_del.company_location_id1 =
        mvmt.delivery.location?.company_location_id;
      drive_to_del.location_name1 = mvmt.delivery.location?.location_name;
      drive_to_del.address11 = mvmt.delivery.location?.address1;
      drive_to_del.city1 = mvmt.delivery.location?.city;
      drive_to_del.state1 = mvmt.delivery.location?.state;
      drive_to_del.zip1 = mvmt.delivery.location?.zip;

      drive_to_del.style = {};

      movements.push(drive_to_del);

      let delivery_style = {};
      if (mvmt.delivery.eta_utc > mvmt.delivery.sched_arrive_late_utc) {
        delivery_style = {
          background: "#b53329",
          color: "white",
        };
      }
      
      else if(mvmt.delivery.eta_utc===0||mvmt.delivery.eta_utc===null||mvmt.delivery.eta_utc===undefined){
        delivery_style = {
          background: "#5eab5e",
          color: "white",
        };
      }
      
      else if (
        mvmt.delivery.eta_utc > del_arrive_utc &&
        mvmt.delivery.eta_utc <= mvmt.delivery.sched_arrive_late_utc
      ) {
        delivery_style = { background: "#c9af31", color: "white" };
      } else if (mvmt.delivery.eta_utc <= del_arrive_utc) {
        delivery_style = { background: "#5eab5e", color: "white" };
      }

      let del = {};
      del.id = mvmt.delivery.id;
      del.orderId = mvmt.order_id;
      del.orderId2 = mvmt.order_id2;
      del.move_id = mvmt.load_id;
      del.load_id = mvmt.load_id;
      del.type =
        mvmt.delivery.stop_type == "split delivery"
          ? "SD"
          : mvmt.delivery.stop_type == "split pickup"
            ? "SP"
            : mvmt.delivery.stop_type;
      del.stop_delivery_type = mvmt.delivery.stop_type;
      del.movement_status = mvmt.status;
      del.trailer_id = ["SS", "ES", "BT"].includes(mvmt.delivery.stop_type)
        ? null
        : mvmt.trailer.trailer_id
          ? mvmt.trailer.trailer_id
          : "";
      del.orig_trailer_id = mvmt.trailer.trailer_id;
      del.mvmt_load_id = mvmt.load_id;
      del.movementData = mvmt;
      del.stopData = {
        ...mvmt.delivery,
      };
      del.trailerData = mvmt.trailer;
      del.terminal_id = mvmt.terminal_id;
      del.startTime = del_arrive_utc;
      del.endTime = del_depart_utc;
      del.delivery_planned_arrive = mvmt.delivery.planned_arrive_utc;
      del.delivery_planned_depart = mvmt.delivery.planned_depart_utc;
      del.delivery_sched_arrive_early_utc =
        mvmt.delivery.sched_arrive_early_utc;
      del.delivery_sched_arrive_late_utc = mvmt.delivery.sched_arrive_late_utc;
      del.delivery_eta_utc = mvmt.delivery.eta_utc;
      del.delivery_actual_arrive = mvmt.delivery.actual_arrival_utc;
      del.delivery_actual_depart = mvmt.delivery.actual_departure_utc;
      del.stop_delivery_type = mvmt.delivery.stop_type;
      del.delivery_timezone = mvmt.delivery.delivery_timezone;
      del.driverId = mvmt.driver_id;
      del.latitude = mvmt.delivery?.latitude;
      del.longitude = mvmt.delivery?.longitude;
      del.pickup_location = mvmt.pickup.location?.location_name;
      del.delivery_location = mvmt.delivery.location?.location_name;
      del.style = delivery_style;
      del.company_location_id1 = mvmt.delivery.location?.company_location_id;
      del.location_name1 = mvmt.delivery.location?.location_name;
      del.address11 = mvmt.delivery.location?.address1;
      del.city1 = mvmt.delivery.location?.city;
      del.state1 = mvmt.delivery.location?.state;
      del.zip1 = mvmt.delivery.location?.zip;
      del.eta_utc = mvmt.pickup.eta_utc;
      del.trailerDetails = mvmt?.delivery?.trailerDetails;

      movements.push(del);
    }

    return movements;
  } catch (err) {
    return [];
  }
};

export const getPlanningBoardDriverMovementsOld = ({
  driverPlanningObj,
  bobtails,
  plannedMode,
}) => {
  try {
    let movements = [];
    if (!driverPlanningObj) return movements;

    for (let i = 0; i < driverPlanningObj.length; i++) {
      let it = driverPlanningObj[i];

      let drive_to_pu_duration = 0;
      let hooking_duration = 30 * 60 * 1000;
      let bobtail_duration =
        typeof bobtails !== "undefined" && it.lm_order_id in bobtails
          ? bobtails[it.lm_order_id]
          : 0;

      let pu_arrive_utc = plannedMode
        ? it.movement.pickup.planned_arrive_utc
        : it.movement.pickup.actual_arrival_utc;
      let pu_depart_utc = plannedMode
        ? it.movement.pickup.planned_depart_utc
        : it.movement.pickup.actual_departure_utc;
      let del_arrive_utc = plannedMode
        ? it.movement.delivery.planned_arrive_utc
        : it.movement.delivery.actual_arrival_utc;
      let del_depart_utc = plannedMode
        ? it.movement.delivery.planned_depart_utc
        : it.movement.delivery.actual_departure_utc;
      if (
        pu_arrive_utc === 0 ||
        pu_depart_utc === 0 ||
        del_arrive_utc === 0 ||
        del_depart_utc === 0
      )
        continue;

      let old_trailers = movements
        .map((it) => it.trailer_id)
        .filter((it) => it !== null);
      let last_trailer_id =
        old_trailers.length < 1 ? null : old_trailers[old_trailers.length - 1];

      if (
        movements.length == 0 ||
        movements[movements.length - 1].type == "BT"
      ) {
        let bobtail1 = {};
        bobtail1.id = null;
        bobtail1.orderId = it.lm_order_id;
        bobtail1.type = "BT";
        bobtail1.trailer_id = null;
        bobtail1.terminal_id = it.terminal_id;
        bobtail1.mvmt_load_id = it.movement.load_id;
        bobtail1.startTime =
          pu_arrive_utc -
          bobtail_duration -
          drive_to_pu_duration -
          hooking_duration;
        bobtail1.endTime =
          pu_arrive_utc - drive_to_pu_duration - hooking_duration;
        bobtail1.style = {};
        bobtail1.pickup_location = it.movement.pickup.location.location_name; // TODO: Remove once using order data for splits
        bobtail1.delivery_location =
          it.movement.delivery.location.location_name;
        movements.push(bobtail1);
      }

      if (
        last_trailer_id == it.movement.trailer.trailer_id &&
        last_trailer_id !== null
      ) {
        movements.pop(); // remove B
        movements.pop(); // remove UH
        movements.pop(); // remove E

        let last_movement = movements[movements.length - 1];

        let drive_to_pu = {};
        drive_to_pu.id = null;
        drive_to_pu.orderId = it.lm_order_id;
        drive_to_pu.type = "E";
        drive_to_pu.stop_delivery_type = it.movement.delivery.type;
        drive_to_pu.order_status = it.status;
        drive_to_pu.movement_status = it.movement.status;
        drive_to_pu.stop_delivery_type = it.movement.delivery.type;
        drive_to_pu.order_status = it.status;
        drive_to_pu.movement_status = it.movement.status;
        drive_to_pu.trailer_id = it.movement.trailer.trailer_id
          ? it.movement.trailer.trailer_id
          : "";
        drive_to_pu.mvmt_load_id = it.movement.load_id;
        drive_to_pu.trailerData = it.movement.trailer;
        drive_to_pu.movementData = it.movement;
        drive_to_pu.terminal_id = it.terminal_id;
        drive_to_pu.startTime = last_movement.endTime;
        drive_to_pu.endTime = pu_arrive_utc;
        drive_to_pu.pickup_location = it.movement.pickup.location.location_name;
        drive_to_pu.delivery_location =
          it.movement.delivery.location.location_name;
        drive_to_pu.move_id = it.movement.load_id;
        drive_to_pu.movement_type = it.movement.movement_type;
        drive_to_pu.type = it.movement.pickup.stop_type;
        drive_to_pu.eta_utc = it.movement.pickup.eta_utc;
        drive_to_pu.eta_utc = it.movement.pickup.eta_utc;
        drive_to_pu.pickup_timezone = it.movement.pickup.pickup_timezone;
        drive_to_pu.planned_arrive_utc = pu_arrive_utc;
        drive_to_pu.planned_depart_utc = pu_depart_utc;
        drive_to_pu.actual_arrival_utc = it.movement.pickup.actual_arrival_utc;
        drive_to_pu.actual_departure_utc =
          it.movement.pickup.actual_departure_utc;
        drive_to_pu.latitude = it.movement.pickup?.latitude;
        drive_to_pu.longitude = it.movement.pickup.longitude;
        drive_to_pu.brokerage = it.movement.brokerage;
        drive_to_pu.company_location_id =
          it.movement.pickup.location.company_location_id;
        drive_to_pu.location_name = it.movement.pickup.location.location_name;
        drive_to_pu.address1 = it.movement.pickup.location.address1;
        drive_to_pu.city = it.movement.pickup.location.city;
        drive_to_pu.state = it.movement.pickup.location.state;
        drive_to_pu.zip = it.movement.pickup.location.zip;
        drive_to_pu.company_location_id1 =
          it.movement.delivery.location.company_location_id;
        drive_to_pu.location_name1 =
          it.movement.delivery.location.location_name;
        drive_to_pu.address11 = it.movement.delivery.location.address1;
        drive_to_pu.city1 = it.movement.delivery.location.city;
        drive_to_pu.state1 = it.movement.delivery.location.state;
        drive_to_pu.zip1 = it.movement.delivery.location.zip;
        drive_to_pu.location_timezone = it.movement.pickup.location.timezone;

        drive_to_pu.style = {};
        movements.push(drive_to_pu);
      } else {
        let hooking = {};
        hooking.id = null;
        hooking.orderId = it.lm_order_id;
        hooking.type = "H";
        hooking.trailer_id = it.movement.trailer.trailer_id
          ? it.movement.trailer.trailer_id
          : "";
        hooking.mvmt_load_id = it.movement.load_id;
        hooking.trailerData = it.movement.trailer;
        hooking.movementData = it.movement;
        hooking.terminal_id = it.terminal_id;
        hooking.startTime =
          pu_arrive_utc - drive_to_pu_duration - hooking_duration;
        hooking.endTime = pu_arrive_utc - drive_to_pu_duration;
        hooking.pickup_location = it.movement.pickup.location.location_name;
        hooking.delivery_location = it.movement.delivery.location.location_name;

        hooking.style = {};
        movements.push(hooking);

        if (drive_to_pu_duration > 0) {
          let drive_to_pu = {};
          drive_to_pu.id = null;
          drive_to_pu.orderId = it.lm_order_id;
          drive_to_pu.type = "E";
          drive_to_pu.move_id = it.movement.load_id;
          drive_to_pu.stop_delivery_type = it.movement.delivery.type;
          drive_to_pu.order_status = it.status;
          drive_to_pu.movement_status = it.movement.status;
          drive_to_pu.trailer_id = it.movement.trailer.trailer_id
            ? it.movement.trailer.trailer_id
            : "";
          drive_to_pu.trailerData = it.movement.trailer;
          drive_to_pu.movementData = it.movement;
          drive_to_pu.terminal_id = it.terminal_id;
          drive_to_pu.startTime = pu_arrive_utc - drive_to_pu_duration;
          drive_to_pu.endTime = pu_arrive_utc;
          drive_to_pu.pickup_location =
            it.movement.pickup.location.location_name;
          drive_to_pu.delivery_location =
            it.movement.delivery.location.location_name;
          drive_to_pu.eta_utc = it.movement.pickup.eta_utc;
          drive_to_pu.pickup_timezone = it.movement.pickup.pickup_timezone;
          drive_to_pu.planned_arrive_utc = pu_arrive_utc;
          drive_to_pu.planned_depart_utc = pu_depart_utc;
          drive_to_pu.actual_arrival_utc =
            it.movement.pickup.actual_arrival_utc;
          drive_to_pu.actual_departure_utc =
            it.movement.pickup.actual_departure_utc;
          drive_to_pu.latitude = it.movement.pickup?.latitude;
          drive_to_pu.longitude = it.movement.pickup.longitude;
          drive_to_pu.brokerage = it.movement.brokerage;
          drive_to_pu.company_location_id =
            it.movement.pickup.location.company_location_id;
          drive_to_pu.location_name = it.movement.pickup.location.location_name;
          drive_to_pu.address1 = it.movement.pickup.location.address1;
          drive_to_pu.city = it.movement.pickup.location.city;
          drive_to_pu.state = it.movement.pickup.location.state;
          drive_to_pu.zip = it.movement.pickup.location.zip;
          drive_to_pu.company_location_id1 =
            it.movement.delivery.location.company_location_id;
          drive_to_pu.location_name1 =
            it.movement.delivery.location.location_name;
          drive_to_pu.address11 = it.movement.delivery.location.address1;
          drive_to_pu.city1 = it.movement.delivery.location.city;
          drive_to_pu.state1 = it.movement.delivery.location.state;
          drive_to_pu.zip1 = it.movement.delivery.location.zip;
          drive_to_pu.location_timezone = it.movement.pickup.location.timezone;

          drive_to_pu.style = {};
          movements.push(drive_to_pu);
        }
      }

      let pickup_style = {};
      if (
        it.movement.pickup.eta_utc > it.movement.pickup.sched_arrive_late_utc
      ) {
        pickup_style = {
          background: "#b53329",
          color: "white",
        };
      } else if (
        it.movement.pickup.eta_utc > pu_arrive_utc &&
        it.movement.pickup.eta_utc <= it.movement.pickup.sched_arrive_late_utc
      ) {
        pickup_style = { background: "#c9af31", color: "white" };
      } else if (it.movement.pickup.eta_utc <= pu_arrive_utc) {
        pickup_style = { background: "#5eab5e", color: "white" };
      }

      let pu = {};
      pu.id = it.movement.pickup.id;
      pu.orderId = it.lm_order_id;
      pu.move_id = it.movement.load_id;
      pu.movement_type = it.movement.movement_type;
      pu.type =
        it.movement.pickup.stop_type == "split pickup"
          ? "SP"
          : it.movement.pickup.stop_type;
      pu.stop_pickup_type = it.movement.pickup.stop_type;
      pu.trailer_id = it.movement.trailer.trailer_id
        ? it.movement.trailer.trailer_id
        : "";
      pu.order_status = it.status;
      pu.movement_status = it.movement.status;
      pu.trailerData = it.movement.trailer;
      pu.terminal_id = it.terminal_id;
      pu.startTime = pu_arrive_utc;
      pu.endTime = pu_depart_utc;
      pu.pickup_timezone = it.movement.pickup.pickup_timezone;
      pu.pickup_planned_arrive = it.movement.pickup.planned_arrive_utc;
      pu.pickup_planned_depart = it.movement.pickup.planned_depart_utc;
      pu.pickup_sched_arrive_early_utc =
        it.movement.pickup.sched_arrive_early_utc;
      pu.pickup_sched_arrive_late_utc =
        it.movement.pickup.sched_arrive_late_utc;
      pu.pickup_actual_arrive = it.movement.pickup.actual_arrival_utc;
      pu.pickup_actual_depart = it.movement.pickup.actual_departure_utc;
      pu.pickup_eta_utc = it.movement.pickup.eta_utc;
      pu.pickup_status = it.pickup_status;
      pu.driverId = it.driver_id;
      pu.latitude = it.movement.pickup?.latitude;
      pu.longitude = it.movement.pickup.longitude;
      pu.pickup_location = it.movement.pickup.location.location_name;
      pu.delivery_location = it.movement.delivery.location.location_name;
      pu.pickup_location = it.movement.pickup.location.location_name;
      pu.delivery_location = it.movement.delivery.location.location_name;
      pu.movement_type = it.movement_type;
      pu.company_location_id = it.movement.pickup.location.company_location_id;
      pu.location_name = it.movement.pickup.location.location_name;
      pu.address1 = it.movement.pickup.location.address1;
      pu.city = it.movement.pickup.location.city;
      pu.state = it.movement.pickup.location.state;
      pu.zip = it.movement.pickup.location.zip;
      pu.style = pickup_style;
      pu.company_location_id1 =
        it.movement.delivery.location.company_location_id;
      pu.location_name1 = it.movement.delivery.location.location_name;
      pu.address11 = it.movement.delivery.location.address1;
      pu.city1 = it.movement.delivery.location.city;
      pu.state1 = it.movement.delivery.location.state;
      pu.zip1 = it.movement.delivery.location.zip;
      pu.location_timezone = it.movement.pickup.location.timezone;

      movements.push(pu);

      let drive_to_del = {};
      drive_to_del.id = it.movement.load_id;
      drive_to_del.orderId = it.lm_order_id;
      drive_to_del.type = "L";
      drive_to_del.stop_pickup_type = it.movement.pickup.stop_type;
      drive_to_del.trailer_id = it.movement.trailer.trailer_id
        ? it.movement.trailer.trailer_id
        : "";
      drive_to_del.move_id = it.movement.load_id;
      drive_to_del.order_status = it.status;
      drive_to_del.movement_status = it.movement.status;
      drive_to_del.trailerData = it.movement.trailer;
      drive_to_del.movementData = it.movement;
      drive_to_del.terminal_id = it.terminal_id;
      drive_to_del.startTime = pu_depart_utc;
      drive_to_del.endTime = del_arrive_utc;
      drive_to_del.eta_utc = it.movement.pickup.eta_utc;
      drive_to_del.pickup_timezone = it.movement.pickup.pickup_timezone;
      drive_to_del.planned_arrive_utc = pu_arrive_utc;
      drive_to_del.planned_depart_utc = pu_depart_utc;
      drive_to_del.actual_arrival_utc = it.movement.pickup.actual_arrival_utc;
      drive_to_del.actual_departure_utc =
        it.movement.pickup.actual_departure_utc;
      drive_to_del.latitude = it.movement.pickup?.latitude;
      drive_to_del.longitude = it.movement.pickup.longitude;
      drive_to_del.pickup_location = it.movement.pickup.location.location_name;
      drive_to_del.delivery_location =
        it.movement.delivery.location.location_name;
      drive_to_del.move_id = it.movement.load_id;
      drive_to_del.brokerage = it.movement.brokerage;
      drive_to_del.company_location_id =
        it.movement.pickup.location.company_location_id;
      drive_to_del.location_name = it.movement.pickup.location.location_name;
      drive_to_del.address1 = it.movement.pickup.location.address1;
      drive_to_del.city = it.movement.pickup.location.city;
      drive_to_del.state = it.movement.pickup.location.state;
      drive_to_del.zip = it.movement.pickup.location.zip;
      drive_to_del.company_location_id1 =
        it.movement.delivery.location.company_location_id;
      drive_to_del.location_name1 = it.movement.delivery.location.location_name;
      drive_to_del.address11 = it.movement.delivery.location.address1;
      drive_to_del.city1 = it.movement.delivery.location.city;
      drive_to_del.state1 = it.movement.delivery.location.state;
      drive_to_del.zip1 = it.movement.delivery.location.zip;
      drive_to_del.location_timezone = it.movement.pickup.location.timezone;

      drive_to_del.style = {};

      movements.push(drive_to_del);

      let delivery_style = {};
      if (
        it.movement.delivery.eta_utc >
        it.movement.delivery.sched_arrive_late_utc
      ) {
        delivery_style = {
          background: "#b53329",
          color: "white",
        };
      } else if (
        it.movement.delivery.eta_utc > del_arrive_utc &&
        it.movement.delivery.eta_utc <=
        it.movement.delivery.sched_arrive_late_utc
      ) {
        delivery_style = { background: "#c9af31", color: "white" };
      } else if (it.movement.delivery.eta_utc <= del_arrive_utc) {
        delivery_style = { background: "#5eab5e", color: "white" };
      }

      let del = {};
      del.id = it.movement.delivery.id;
      del.orderId = it.lm_order_id;
      del.move_id = it.movement.load_id;
      del.type =
        it.movement.delivery.stop_type == "split delivery"
          ? "SD"
          : it.movement.delivery.stop_type;
      // del.stop_delivery_type = it.movement.delivery.type;
      del.order_status = it.status;
      del.movement_status = it.movement.status;
      del.trailer_id = it.movement.trailer.trailer_id
        ? it.movement.trailer.trailer_id
        : "";
      del.mvmt_load_id = it.movement.load_id;
      del.trailerData = it.movement.trailer;
      del.movementData = it.movement;
      del.terminal_id = it.terminal_id;
      del.startTime = del_arrive_utc;
      del.endTime = del_depart_utc;
      del.delivery_planned_arrive = it.movement.delivery.planned_arrive_utc;
      del.delivery_planned_depart = it.movement.delivery.planned_depart_utc;
      del.delivery_sched_arrive_early_utc =
        it.movement.delivery.sched_arrive_early_utc;
      del.delivery_sched_arrive_late_utc =
        it.movement.delivery.sched_arrive_late_utc;
      del.delivery_eta_utc = it.movement.delivery.eta_utc;
      del.delivery_actual_arrive = it.movement.delivery.actual_arrival_utc;
      del.delivery_actual_depart = it.movement.delivery.actual_departure_utc;
      del.stop_delivery_type = it.movement.delivery.stop_type;
      del.delivery_timezone = it.movement.delivery.delivery_timezone;
      del.delivery_status = it.delivery_status;
      del.driverId = it.driver_id;
      del.latitude = it.movement.delivery?.latitude;
      del.longitude = it.movement.delivery.longitude;
      del.pickup_location = it.movement.pickup.location.location_name;
      del.delivery_location = it.movement.delivery.location.location_name;
      del.style = delivery_style;
      del.company_location_id1 =
        it.movement.delivery.location.company_location_id;
      del.location_name1 = it.movement.delivery.location.location_name;
      del.address11 = it.movement.delivery.location.address1;
      del.city1 = it.movement.delivery.location.city;
      del.state1 = it.movement.delivery.location.state;
      del.zip1 = it.movement.delivery.location.zip;
      del.eta_utc = it.movement.pickup.eta_utc;
      del.location_timezone = it.movement.delivery.location.timezone;

      movements.push(del);

      let drive_to_terminal = {};
      drive_to_terminal.id = null;
      drive_to_terminal.orderId = it.lm_order_id;
      drive_to_terminal.type = "E";
      drive_to_terminal.move_id = it.movement.load_id;
      drive_to_terminal.stop_delivery_type = it.movement.delivery.type;
      drive_to_terminal.order_status = it.status;
      drive_to_terminal.movement_status = it.movement.status;
      drive_to_terminal.trailer_id = it.movement.trailer.trailer_id
        ? it.movement.trailer.trailer_id
        : "";
      drive_to_terminal.mvmt_load_id = it.movement.load_id;
      drive_to_terminal.terminal_id = it.terminal_id;
      drive_to_terminal.startTime = del_depart_utc;
      drive_to_terminal.endTime =
        del_depart_utc + del_arrive_utc - pu_depart_utc;
      drive_to_terminal.latitude = it.movement.pickup?.latitude;
      drive_to_terminal.longitude = it.movement.pickup.longitude;
      drive_to_terminal.pickup_location =
        it.movement.pickup.location.location_name;
      drive_to_terminal.delivery_location =
        it.movement.delivery.location.location_name;
      drive_to_terminal.eta_utc = it.movement.pickup.eta_utc;
      drive_to_terminal.pickup_timezone = it.movement.pickup.pickup_timezone;
      drive_to_terminal.planned_arrive_utc = pu_arrive_utc;
      drive_to_terminal.planned_depart_utc = pu_depart_utc;
      drive_to_terminal.actual_arrival_utc =
        it.movement.pickup.actual_arrival_utc;
      drive_to_terminal.actual_departure_utc =
        it.movement.pickup.actual_departure_utc;
      drive_to_terminal.latitude = it.movement.pickup?.latitude;
      drive_to_terminal.longitude = it.movement.pickup.longitude;
      drive_to_terminal.brokerage = it.movement.brokerage;
      drive_to_terminal.company_location_id =
        it.movement.pickup.location.company_location_id;
      drive_to_terminal.location_name =
        it.movement.pickup.location.location_name;
      drive_to_terminal.address1 = it.movement.pickup.location.address1;
      drive_to_terminal.city = it.movement.pickup.location.city;
      drive_to_terminal.state = it.movement.pickup.location.state;
      drive_to_terminal.zip = it.movement.pickup.location.zip;
      drive_to_terminal.company_location_id1 =
        it.movement.delivery.location.company_location_id;
      drive_to_terminal.location_name1 =
        it.movement.delivery.location.location_name;
      drive_to_terminal.address11 = it.movement.delivery.location.address1;
      drive_to_terminal.city1 = it.movement.delivery.location.city;
      drive_to_terminal.state1 = it.movement.delivery.location.state;
      drive_to_terminal.zip1 = it.movement.delivery.location.zip;
      drive_to_terminal.location_timezone =
        it.movement.pickup.location.timezone;

      drive_to_terminal.style = {};
      movements.push(drive_to_terminal);

      let unhooking = {};
      unhooking.id = null;
      unhooking.orderId = it.lm_order_id;
      unhooking.type = "UH";
      unhooking.trailer_id = it.movement.trailer.trailer_id
        ? it.movement.trailer.trailer_id
        : "";
      unhooking.mvmt_load_id = it.movement.load_id;
      unhooking.trailerData = it.movement.trailer;
      unhooking.movementData = it.movement;
      unhooking.terminal_id = it.terminal_id;
      unhooking.startTime = del_depart_utc + del_arrive_utc - pu_depart_utc;
      unhooking.endTime =
        del_depart_utc + del_arrive_utc - pu_depart_utc + hooking_duration;
      unhooking.pickup_location = it.movement.pickup.location.location_name;
      unhooking.delivery_location = it.movement.delivery.location.location_name;
      unhooking.style = {};
      movements.push(unhooking);

      let bobtail2 = {};
      bobtail2.id = null;
      bobtail2.orderId = it.lm_order_id;
      bobtail2.type = "BT";
      bobtail2.trailer_id = null;
      bobtail2.terminal_id = it.terminal_id;
      bobtail2.mvmt_load_id = it.movement.load_id;
      bobtail2.startTime =
        del_depart_utc + del_arrive_utc - pu_depart_utc + hooking_duration;
      bobtail2.endTime =
        del_depart_utc +
        del_arrive_utc -
        pu_depart_utc +
        hooking_duration +
        bobtail_duration;
      bobtail2.pickup_location = it.movement.pickup.location.location_name;
      bobtail2.delivery_location = it.movement.delivery.location.location_name;
      bobtail2.style = {};
      movements.push(bobtail2);
    }
    return movements;
  } catch (err) {
    return [];
  }
};

export const getPlanningBoardDriverShifts = ({ movements }) => {
  try {
    let shifts = [];
    if (movements.length === 0) return shifts;

    let shift_mvmts = movements.filter((mvmt) =>
      ["SS", "ES"].includes(mvmt.type)
    );

    shifts = shift_mvmts.map((it) => ({
      shiftType: it.type === "SS" ? "start" : "end",
      shiftStartTime: it.startTime,
      shiftEndTime: it.endTime,
      move_id: it.move_id,
      movementData: it.movementData,
    }));

    return shifts;
  } catch (err) {
    return [];
  }
};

export const getPlanningBoardDriverOrdersOld = ({
  driverPlanningObj,
  plannedMode,
}) => {
  try {
    let orders = [];
    if (!driverPlanningObj) return orders;

    for (let i = 0; i < driverPlanningObj.length; i++) {
      let it = driverPlanningObj[i];
      let order = {};

      let pu_arrive_utc = plannedMode
        ? it.movement.pickup.planned_arrive_utc
        : it.movement.pickup.actual_arrival_utc;
      let pu_depart_utc = plannedMode
        ? it.movement.pickup.planned_depart_utc
        : it.movement.pickup.actual_departure_utc;
      let del_arrive_utc = plannedMode
        ? it.movement.delivery.planned_arrive_utc
        : it.movement.delivery.actual_arrival_utc;
      let del_depart_utc = plannedMode
        ? it.movement.delivery.planned_depart_utc
        : it.movement.delivery.actual_departure_utc;

      if (
        pu_arrive_utc === 0 ||
        pu_depart_utc === 0 ||
        del_arrive_utc === 0 ||
        del_depart_utc === 0
      )
        continue;

      order.orderId = it.lm_order_id;
      order.terminal_id = it.terminal_id;
      order.orderStartTime = pu_arrive_utc;
      order.orderEndTime = del_depart_utc;
      order.commodity = it.commodity;
      order.commodity_id = it.commodity_id;
      order.status = it.status;
      order.customer_name = it.customer_name;
      order.bol_received = it.bol_received;
      order.on_hold = it.on_hold;
      order.consignee_refno = it.consignee_refno;
      order.ordered_wt = it.ordered_wt;
      order.order_type_id = it.order_type_id;
      order.commodity_desc = it.commodity_desc;
      order.blnum = it.blnum;
      order.pickup_location = it.movement.pickup.location;
      order.delivery_location = it.movement.delivery.location;
      (order.terminalId = it.movement.driver.terminal.terminal_id),
        (order.terminal_city = it.movement.driver.terminal.city),
        (order.order_status = it.status),
        (order.company_location_id =
          it.movement.pickup.location.company_location_id);
      order.location_name = it.movement.pickup.location.location_name;
      order.address1 = it.movement.pickup.location.address1;
      order.city = it.movement.pickup.location.city;
      order.state = it.movement.pickup.location.state;
      order.zip = it.movement.pickup.location.zip;
      order.move_id = it.movement.load_id;
      order.company_location_id1 =
        it.movement.delivery.location.company_location_id;
      order.location_name1 = it.movement.delivery.location.location_name;
      order.address11 = it.movement.delivery.location.address1;
      order.city1 = it.movement.delivery.location.city;
      order.state1 = it.movement.delivery.location.state;
      order.zip1 = it.movement.delivery.location.zip;
      order.delivery_driver_load_unload =
        it.movement.delivery.driver_load_unload;
      order.pickup_driver_load_unload = it.movement.pickup.driver_load_unload;
      order.pickup_appt_required = it.movement.pickup.appt_required;
      order.picup_confirmed = it.movement.pickup.confirmed;
      order.pickup_sched_arrive_early_utc =
        it.movement.pickup.sched_arrive_early_utc;
      order.pickup_sched_arrive_late_utc =
        it.movement.pickup.sched_arrive_late_utc;
      order.delivery_sched_arrive_early_utc =
        it.movement.delivery.sched_arrive_early_utc;
      order.delivery_sched_arrive_late_utc =
        it.movement.delivery.sched_arrive_late_utc;
      order.distance = it.movement.distance;
      order.pickup_timezone = it.movement.pickup.pickup_timezone;
      order.delivery_timezone = it.movement.delivery.delivery_timezone;
      order.commoditygroupid = it.order_commodity?.commoditygroupid
        ? it.order_commodity?.commoditygroupid
        : "";
      order.commoditygroup_description = it.order_commodity?.description
        ? it.order_commodity?.description
        : "";

      if (it.movement.xmit_accepted == "Y")
        order.style = { background: "#5eab5e", color: "white" };
      else if ([0, null, undefined, ""].includes(it.movement.xmitted2driver) && [0, null, undefined, "", "N"].includes(it.movement.xmit_accepted))
        order.style = { background: "d17c21", color: "white" };

      orders.push(order);
    }

    return orders;
  } catch (err) {
    return [];
  }
};

export const getPlanningBoardDriverOrders = ({ movements, plannedMode }) => {
  try {
    let orders = [];
    if (!movements) return orders;

    for (let i = 0; i < movements.length; i++) {
      let it = movements[i];
      if (
        [null, "", undefined].includes(
          ["PU", "DEL", "SP", "SD", "L"].includes(it.type) &&
            ["", null, undefined].includes(it.orderId2)
            ? it.orderId
            : it.orderId2
        ) ||
        ["SS", "ES", "BT", "E", "HT", "DT"].includes(it.type)
      )
        continue;
      let order = {};
      let movementData = it.movementData;

      order.orderId =
        ["PU", "DEL", "SP", "SD", "L"].includes(it.type) &&
          ["", null, undefined].includes(it.orderId2)
          ? it.orderId
          : it.orderId2;
      order.mvmtType = it.type;
      order.terminal_id = it.terminal_id;
      order.orderStartTime = it.startTime;
      order.orderEndTime = it.endTime;
      order.commodity = it.commodity;
      order.status = it.status;
      order.bol_received = it.bol;
      order.ordered_wt = it.weight;
      order.pickup_location = movementData.pickup.location;
      order.delivery_location = movementData.delivery.location;
      order.terminalId = movementData.driver.terminal_id;
      order.order_status = it.status;
      order.location_name = movementData.pickup.location.location_name;
      order.address1 = movementData.pickup.location.address1;
      order.city = movementData.pickup.location.city;
      order.state = movementData.pickup.location.state;
      order.zip = movementData.pickup.location.zip;
      order.move_id = movementData.load_id;
      order.company_location_id1 =
        movementData.delivery.location.company_location_id;
      order.location_name1 = movementData.delivery.location.location_name;
      order.address11 = movementData.delivery.location.address1;
      order.city1 = movementData.delivery.location.city;
      order.state1 = movementData.delivery.location.state;
      order.zip1 = movementData.delivery.location.zip;
      order.delivery_driver_load_unload =
        movementData.delivery.driver_load_unload;
      order.pickup_driver_load_unload = movementData.pickup.driver_load_unload;
      order.pickup_appt_required = movementData.pickup.appt_required;
      order.picup_confirmed = movementData.pickup.confirmed;
      order.pickup_sched_arrive_early_utc =
        movementData.pickup.sched_arrive_early_utc;
      order.pickup_sched_arrive_late_utc =
        movementData.pickup.sched_arrive_late_utc;
      order.delivery_sched_arrive_early_utc =
        movementData.delivery.sched_arrive_early_utc;
      order.delivery_sched_arrive_late_utc =
        movementData.delivery.sched_arrive_late_utc;
      order.distance = movementData.distance;
      order.pickup_timezone = movementData.pickup.pickup_timezone;
      order.delivery_timezone = movementData.delivery.delivery_timezone;
      order.commoditygroupid = it.order_commodity?.commoditygroupid
        ? it.order_commodity?.commoditygroupid
        : "";
      order.commoditygroup_description = it.order_commodity?.description
        ? it.order_commodity?.description
        : "";

      if (it.movement.xmit_accepted == "Y")
        order.style = { background: "#5eab5e", color: "white" };
      else if ([0, null, undefined, ""].includes(it.movement.xmitted2driver) && [0, null, undefined, "", "N"].includes(it.movement.xmit_accepted))
        order.style = { background: "d17c21", color: "white" };

      orders.push(order);
    }

    const final_orders = [];
    const idxs = [];
    orders.sort((a, b) => a.startTime - b.startTime);

    orders.forEach((it, idx) => {
      if (idxs.includes(idx)) return;
      let order = { ...it };
      let obstacle = false;

      orders.slice(idx + 1).forEach((it2, idx2) => {
        if (
          obstacle ||
          (it2.orderId != order.orderId &&
            it2.orderStartTime != order.orderStartTime) ||
          (["SS", "ES", "BT", "E", "HT", "DT"].includes(it2.mvmtType) &&
            Math.abs(it2.orderEndTime - it2.orderStartTime) > 0) ||
          order.orderEndTime < it2.orderStartTime
        ) {
          obstacle = true;
          return;
        } else {
          idxs.push(idx2 + idx + 1);
          order.orderId = it2.orderId;
          order.orderEndTime = it2.orderEndTime;
        }
      });

      final_orders.push(order);
      idxs.push(idx);
    });

    return final_orders;
  } catch (err) {
    return [];
  }
};

export const getPlanningBoardDriverTrailers = ({ movements }) => {

  try {
    let trailers = [];
    let all_trailers = movements
      .filter((it) => it.trailer_id !== null)
      .map((it) => ({
        order_id: it.orderId,
        order_id2: it.orderId2,
        trailer_id: it.trailer_id,
        mvmt_load_ids: it.move_id,
        trailerStartTime: it.startTime,
        trailerEndTime: it.endTime,
        license_plate: it.trailerData?.license_plate,
        license_state: it.trailerData?.license_state,
        stat: it.trailerData?.stat,
        make: it.trailerData?.make,
        model: it.trailerData?.model,
        model_year: it.trailerData?.model_year,
        tare_weight: it.trailerData?.tare_weight,
        pm_due_date_utc: it.trailerData?.pm_due_date_utc,
        commodity_group: it.trailerData?.commodity_group,
        commoditygroup_description: it.trailerData?.commoditygroup?.description,
        terminal_id: it.terminal_id,
        equip_types: it.equip_type,
        mvmt_type: it.type,
      }));

    let idxs = [];

    all_trailers.sort((a, b) => a.trailerStartTime - b.trailerStartTime);
    all_trailers.forEach((it, idx) => {
      if (idxs.includes(idx)) return;
      let trailer = {
        ...it,
        mvmt_load_ids: [],
        all_orders: [],
      };
      let obstacle = false;
      all_trailers.slice(idx).forEach((it2, idx2) => {
        if (
          (obstacle ||
            (((it2.trailer_id != it.trailer_id &&
              it2.order_id2 != it.order_id2) ||
              (["SS", "ES", "BT"].includes(it2.mvmt_type) &&
                Math.abs(it2.trailerEndTime - it2.trailerStartTime) > 0) ||
              trailer.trailerEndTime < it2.trailerStartTime ||
              it2.order_id2 != it.order_id2) &&
              it2.mvmt_type != "DT")) &&
          it2.trailerStartTime != it.trailerStartTime
        ) {
          obstacle = true;
          return;
        } else {
          if (["", null, undefined].includes(trailer.trailer_id))
            trailer.trailer_id = it2.trailer_id;
          idxs.push(idx2 + idx);
          trailer.mvmt_load_ids.push(it2.mvmt_load_ids);
          trailer.all_orders.push(it2.order_id);
          trailer.trailerEndTime = it2.trailerEndTime;
        }
      });
      trailer.mvmt_load_ids = [...new Set(trailer.mvmt_load_ids)];
      trailer.all_orders = [...new Set(trailer.all_orders)];
      trailers.push(trailer);
      idxs.push(idx);
    });
    return trailers;
  } catch (err) {
    return [];
  }
};

export const getBoardByTrailerFormatted = async (
  movementData,
  driverData,
  trailers,
  plannedMode = true
) => {
  const planningBoard = new PlanningBoard();
  // let planningObj = [];
  let planners = [];
  try {
    // let planningObjByTrailer = planningBoardData.data.reduce((r, a) => {
    //   r[a.movement.trailer_id] = [...(r[a.movement.trailer_id] || []), a];
    //   return r;
    // }, {});
    // planningObj = planningBoardData.data.map((it) =>
    //   planningBoard.parseApiPlanningBoardObject(it)
    // );
    // if (planningObj === null) return planners;

    
    for (let loop = 0; loop < trailers.length; loop++) {
      const movementdata_arr = [];
      const block = {};
      const trailers_data = trailers[loop];
      // const trailers_movements =
      movementData
        ?.data
        ?.filter(
          (mvmt) => {
            // mvmt?.trailer_id === trailers_data.Trailer_ID

            // mvmt
            // ?.trailer_id === trailers_data
            // ?.trailer_id
            if (mvmt && mvmt.trailer_id) {
              if (mvmt.trailer_id && trailers_data.Trailer_ID) {
                if (mvmt.trailer_id === trailers_data.Trailer_ID) {

                  movementdata_arr.push(mvmt)
                }
              }
            }
          }

        );



      const trailers_movements = movementdata_arr;

      block.trailer_id = trailers_data.Trailer_ID;
      block.type = trailers_data.Equip_Type;
      block.terminal = trailers_data.Terminal;
      block.terminal_city = trailers_data.City;
      // block.license_plate = trailers_data?.license_plate;
      block.year = trailers_data.Year;
      block.state = trailers_data.State;
      block.make = trailers_data.Make;
      block.model = trailers_data.Model;
      block.license = trailers_data.License_Plate;
      block.stat = trailers_data.Status;
      // block.shipper_pool = trailers_data.code;
      block.dedicated = trailers_data.code;
      block.movement_status = trailers_data.Status;
      block.tare_weight = trailers_data.Tare_Weight;
      block.pm_due_date_utc = trailers_data.PM_Due_Date;
      block.Last_Wash_WO = trailers_data['Last_Wash_W/O'];
      block.Loads_to_Next_Wash = trailers_data.Loads_to_Next_Wash;
      block.Days_to_Next_Wash = trailers_data.Days_to_Next_Wash
      block.Last_Order = trailers_data.Last_Order;
      block.Last_Commodity = trailers_data.Last_Commodity;
      block.Last_Product = trailers_data.Last_Product;
      block.Last_Washed = trailers_data.Last_Washed;
      block.commodity_group = trailers_data.Commodity_Group;
      block.Loads_Since_Last_Wash = trailers_data.Loads_Since_Last_Wash;
      block.Days_Since_Last_Wash = trailers_data.Days_Since_Last_Wash;
      block.Length = trailers_data.Length;
      block.Width = trailers_data.Width;
      block.Height = trailers_data.Height;
      block.Volume = trailers_data.Volume;

      try {
        block.movements = getPlanningBoardDriverMovements({
          driver_movements: trailers_movements,
          plannedMode: plannedMode,
        });
        block.movements.sort((a, b) => {
          return a.startTime - b.startTime;
        });

        // trim mvmt/stop blocks
        block.movements = block.movements
            .map((mvmt, idx) => {
              if (idx >= block.movements.length - 1) return mvmt;
              let next = idx;
              while (
                next < block.movements.length - 1 &&
                block.movements[next].startTime ==
                  block.movements[idx].startTime
              )
                next += 1;
              return {
                ...mvmt,
                endTime: Math.min(
                  mvmt.endTime,
                  block.movements[next]?.startTime
                ),
              };
            })
          .filter((mvmt) => mvmt.startTime <= mvmt.endTime);

// dedupe
          block.movements = [
            ...block.movements.filter((it) => it?.stopData == null),
            ...[
              ...new Set(
                block.movements
                  .filter((it) => it?.stopData != null)
                  .map((it) => it?.stopData?.lm_stop_id)
              ),
            ].map((stop_id) => {
              let stops = block.movements.filter(
                (it) => it?.stopData?.lm_stop_id == stop_id
              );
              if (stops.length == 1) return stops[0];
              return stops.filter(
                (stop) => stop.movementData.pickup.lm_stop_id == stop_id
              )[0];
            }),
          ].filter((m) => m);

          block.movements.sort((a, b) => {
            return a.startTime - b.startTime;
          });

// Add linked mvmts to stops
          block.movements = block.movements.map((mvmt, idx) => {
            if (mvmt.stopData) {
              let linkedMvmts = [
                idx > 0
                  ? {
                      move_id: block.movements[idx - 1]?.move_id,
                      type: block.movements[idx - 1]?.movementData
                        ?.movement_type,
                    }
                  : null,
                idx < block.movements.length - 1
                  ? {
                      move_id: block.movements[idx + 1]?.move_id,
                      type: block.movements[idx + 1]?.movementData
                        ?.movement_type,
                    }
                  : null,
              ].filter((i) => i);

              return {
                ...mvmt,
                stopData: {
                  ...mvmt.stopData,
                  linkedMvmts,
                },
              };
            }
            return mvmt;
          });
        
        let orders = [];
        let drivers = [];
        let final_shifts = [];

        for (let i = 0; i < block.movements.length; i++) {
          let it = block.movements[i];
            if (
              !(
                [null, "", undefined].includes(
                  ["PU", "DEL", "SP", "SD", "L"].includes(it.type) &&
                    ["", null, undefined].includes(it.orderId2)
                    ? it.orderId
                    : it.orderId2
                ) || ["SS", "ES", "BT", "E", "HT", "DT"].includes(it.type)
              )
            ) {
              let order = {};
              let movementData = it.movementData;

              order.orderId =
                ["PU", "DEL", "SP", "SD", "L"].includes(it.type) &&
                  ["", null, undefined].includes(it.orderId2)
                  ? it.orderId
                  : it.orderId2;
            order.mvmtType = it.type;
            order.terminal_id = it.terminal_id;
            order.orderStartTime = it.startTime;
            order.orderEndTime = it.endTime;
            order.commodity = it.commodity;
            order.status = it.status;
            order.bol_received = it.bol;
            order.ordered_wt = it.weight;
            order.pickup_location = movementData.pickup.location;
            order.delivery_location = movementData.delivery.location;
            order.terminalId = movementData.driver.terminal_id;
            order.order_status = it.status;
            order.location_name = movementData.pickup.location.location_name;
            order.address1 = movementData.pickup.location.address1;
            order.city = movementData.pickup.location.city;
            order.state = movementData.pickup.location.state;
            order.zip = movementData.pickup.location.zip;
            order.move_id = movementData.load_id;
            order.company_location_id1 =
              movementData.delivery.location.company_location_id;
            order.location_name1 = movementData.delivery.location.location_name;
            order.address11 = movementData.delivery.location.address1;
            order.city1 = movementData.delivery.location.city;
            order.state1 = movementData.delivery.location.state;
            order.zip1 = movementData.delivery.location.zip;
            order.delivery_driver_load_unload =
              movementData.delivery.driver_load_unload;
            order.pickup_driver_load_unload =
              movementData.pickup.driver_load_unload;
            order.pickup_appt_required = movementData.pickup.appt_required;
            order.picup_confirmed = movementData.pickup.confirmed;
            order.pickup_sched_arrive_early_utc =
              movementData.pickup.sched_arrive_early_utc;
            order.pickup_sched_arrive_late_utc =
              movementData.pickup.sched_arrive_late_utc;
            order.delivery_sched_arrive_early_utc =
              movementData.delivery.sched_arrive_early_utc;
            order.delivery_sched_arrive_late_utc =
              movementData.delivery.sched_arrive_late_utc;
            order.distance = movementData.distance;
            order.pickup_timezone = movementData.pickup.pickup_timezone;
            order.delivery_timezone = movementData.delivery.delivery_timezone;
            order.commoditygroupid = it.order_commodity?.commoditygroupid
              ? it.order_commodity?.commoditygroupid
              : "";
            order.commoditygroup_description = it.order_commodity?.description
              ? it.order_commodity?.description
              : "";

              if (it.movementData.xmit_accepted == "Y")
                order.style = { background: "#5eab5e", color: "white" };
              else if ([0, null, undefined, ""].includes(it.movementData.xmitted2driver) && [0, null, undefined, "", "N"].includes(it.movementData.xmit_accepted))
                order.style = { background: "d17c21", color: "white" };

            if(!["", null, undefined].includes(order.orderId))
            orders.push(order);
          }

          
          if (it.driverId !== null) {
            const driData = driverData.find(d=>d.driver_id == it.driverId);

            if (driData) {
              let driver = {
                driverId: it.driverId,
                driver_full_name: it.movementData.driver.driver_full_name,
                samsara_id: it.movementData.driver.samsara_id,
                driver_type_class: it.movementData.driver.driver_type_class,
                hire_date: it.movementData.driver.hire_date,
                terminal: it.movementData.driver.terminal_id,
                terminal_city: driData.terminal.name,
                schedule: driData.schedule,
                hosclock: driData.hosclock ?? {},
                driverStartTime: it.startTime,
                driverEndTime: it.endTime
              };
              if (!["SS", "ES"].includes(it.type))
                drivers.push(driver);
            }
          }

          if (["SS", "ES"].includes(it.type))
            final_shifts.push({
              shiftType: it.type === "SS" ? "start" : "end",
              shiftStartTime: it.startTime,
              shiftEndTime: it.endTime,
              move_id: it.move_id,
              movementData: it.movementData,
            });
        }

        const final_orders = [];
        const final_drivers = [];

        let idxs = [];
        orders.sort((a, b) => a.startTime - b.startTime);
        orders.forEach((it, idx) => {
          if (idxs.includes(idx)) return;
          let order = { ...it };
          let obstacle = false;

          /** Confusion */
          orders.slice(idx + 1).forEach((it2, idx2) => {
            if (
              obstacle ||
              (it2.orderId != order.orderId &&
                it2.orderStartTime != order.orderStartTime) ||
              (["SS", "ES", "BT", "E", "HT", "DT"].includes(it2.mvmtType) &&
                Math.abs(it2.orderEndTime - it2.orderStartTime) > 0) ||
              order.orderEndTime < it2.orderStartTime
            ) {
              obstacle = true;
              return;
            } else {
              idxs.push(idx2 + idx + 1);
              order.orderId = it2.orderId;
              order.orderEndTime = it2.orderEndTime;
            }
          });
          final_orders.push(order);
          idxs.push(idx);
        });

        idxs = [];

        drivers.sort((a, b) => a.driverStartTime - b.driverStartTime);
        drivers.forEach((it, idx) => {
          if (idxs.includes(idx)) return;
          let driver = {
            ...it,
            mvmt_load_ids: [],
            all_orders: [],
          };
          let obstacle = false;
          drivers.slice(idx).forEach((it2, idx2) => {

            if (
              obstacle ||
              (it2.driverId != it.driverId  &&
                it2.driverEndTime - it2.driverStartTime > 0) ||
                driver.driverEndTime < it2.driverStartTime
            ) {
              obstacle = true;
              return;
            } else {
              idxs.push(idx2 + idx);
              driver.mvmt_load_ids.push(it2.mvmt_load_ids);
              driver.all_orders.push(it2.order_id);
              driver.driverEndTime = it2.driverEndTime;
            }
          });
          driver.mvmt_load_ids = [...new Set(driver.mvmt_load_ids)];
          driver.all_orders = [...new Set(driver.all_orders)];
          final_drivers.push(driver);
          idxs.push(idx);
        });

        block.orders = final_orders;
        block.drivers = final_drivers;
        block.shifts = final_shifts;

        block.movements = block.movements.filter(
          (mvmt) => !["SS", "ES"].includes(mvmt.type)
        );

        let mvmt_map_vals = block.movements.map((v) => [
          JSON.stringify([v.startTime, v.endTime]),
          v,
        ]);

        [
          ...mvmt_map_vals
            .filter((it) => it[1]?.stopData != null)
            .map((it) => it[1]?.stopData?.lm_stop_id),
        ].forEach((key) => {
          mvmt_map_vals = mvmt_map_vals.map((mvmt) => {
            if (mvmt[1]?.stopData?.lm_stop_id == key) {
              let linkedMvmts = [
                ...new Set(
                  mvmt_map_vals
                    .filter((it) => it[1]?.stopData?.lm_stop_id == key)
                    .map((it) => ({
                      move_id: it[1]?.move_id,
                      type: it[1].movementData.movement_type,
                      status:it[1].movementData?.status,
                    }))
                ),
              ];
              return [
                mvmt[0],
                {
                  ...mvmt[1],
                  stopData: {
                    ...mvmt[1].stopData,
                    linkedMvmts,
                  },
                },
              ];
            }
            return mvmt;
          });
        });

        block.movements = [...new Map(mvmt_map_vals).values()];

        block.movements.sort((a, b) => {
          return a.endTime - b.endTime;
        });

        block.isloading = false;
      } catch (err) {
        console.trace(err);
      }
      // const data = await TrailerViewBlocksInMinutes(
      //   planningObjByTrailer[block.trailerId],
      //   new Date(searchData.date)
      // );
      // block.planner = data;
      // block.planner = {};
      planners.push(block);
    }
    return Promise.resolve(planners);
  } catch (err) {
    return Promise.reject(
      "There is a problem on retrieving planning board data. Please try again!"
    );
  }
};

export const getBoardByCarrierFormatted = async (
  planningBoardData,
  searchData
) => {
  const planningBoard = new PlanningBoard();
  let planningObj = [];
  let filteredPlanningArray = [];
  let planningObjByMovement;
  // let planningObjByDriver = {};
  let planners = [];
  try {
    // if (reload) {
    const data = {
      date: new Date(searchData.date).getTime().toString(),
      // terminals: searchData.userterminals,
      // plannerterminals: searchData.userplanners,
    };

    if (planningBoardData?.data.length > 0) {
      filteredPlanningArray = planningBoardData?.data?.filter(
        (order) => order.movement.brokerage == "Y"
      );
    }
    if (filteredPlanningArray?.length > 0) {
      planningObjByMovement = filteredPlanningArray.reduce((r, a) => {
        r[a.movement.load_id] = [...(r[a.movement.load_id] || []), a];
        return r;
      }, {});

      for (
        let loop = 0;
        loop < Object.keys(planningObjByMovement).length;
        loop++
      ) {
        const block = {};
        block.movementId = Object.keys(planningObjByMovement)[loop];
        const data = await CarrierViewBlocksInMinutes(
          planningObjByMovement[block.movementId],
          new Date(searchData.date)
        );
        block.driverId = "Bulkmatic Solutions";
        block.planner = data;
        planners.push(block);
      }
    }
  } catch (err) {
    return "";
  }

  return planners;
};

export const DriverViewBlocksInMinutes = async (orders, planningDate) => {
  let blockData = [];
  // { start: 0, length: 1, type: "", orderid: "", trailerid: "", driverid: "", hasOnTime: true, actionValue: ""}
  try {
    planningDate = new Date(
      planningDate.getFullYear(),
      planningDate.getMonth(),
      planningDate.getDate()
    );
    [];
    const planningTs = planningDate.getTime();
    const blocks = [];

    for (let loop = 0; loop < orders.length; loop++) {
      let order = orders[loop];
      let pickup_type, delivery_type;
      let pickup_eta_utc = order.movement.pickup.eta_utc;
      let delivery_eta_utc = order.movement.delivery.eta_utc;
      let pickup_status,
        delivery_status,
        has_delivery_on_time,
        has_unloaded_on_time;
      let move_type, zmitStatus;
      let pickup_planned_depart = order.movement.pickup.planned_depart_utc;
      let pickup_planned_arrive = order.movement.pickup.planned_arrive_utc;
      let pickup_timezone = order.movement.pickup.pickup_timezone;
      let pickup_actual_depart = order.movement.pickup.actual_departure_utc;
      let pickup_actual_arrive = order.movement.pickup.actual_arrival_utc;
      let delivery_timezone = order.movement.delivery.delivery_timezone;
      let delivery_planned_arrive = order.movement.delivery.planned_arrive_utc;
      let delivery_planned_depart = order.movement.delivery.planned_depart_utc;
      let delivery_actual_depart = order.movement.delivery.actual_departure_utc;
      let delivery_actual_arrive = order.movement.delivery.actual_arrival_utc;
      let pickup_sched_arrive_early_utc =
        order.movement.pickup.sched_arrive_early_utc;
      let pickup_sched_arrive_late_utc =
        order.movement.pickup.sched_arrive_late_utc;
      let delivery_sched_arrive_early_utc =
        order.movement.delivery.sched_arrive_early_utc;
      let delivery_sched_arrive_late_utc =
        order.movement.delivery.sched_arrive_late_utc;
      let distance = order.movement.distance;
      let brokerage = order.movement.brokerage;
      let movement_type = order.movement.movement_type;
      let movement_status = order.movement.status;
      let stop_pickup__type = order.movement.pickup.type;
      let stop_delivery_type = order.movement.delivery.type;
      let company_location_id =
        order.movement.pickup.location.company_location_id;
      let location_name = order.movement.pickup.location.location_name;
      let address1 = order.movement.pickup.location.address1;
      let city = order.movement.pickup.location.city;
      let state = order.movement.pickup.location.state;
      let zip = order.movement.pickup.location.zip;
      let move_id = order.movement.load_id;
      let company_location_id1 =
        order.movement.delivery.location.company_location_id;
      let location_name1 = order.movement.delivery.location.location_name;
      let address11 = order.movement.delivery.location.address1;
      let city1 = order.movement.delivery.location.city;
      let state1 = order.movement.delivery.location.state;
      let zip1 = order.movement.delivery.location.zip;
      let order_status = order.status;
      let bol_received = order.bol_received;
      let customer_name = order.customer_name;
      let consignee_refno = order.consignee_refno;
      let ordered_wt = order.ordered_wt;
      let on_hold = order.on_hold;
      let delivery_driver_load_unload =
        order.movement.delivery.driver_load_unload;
      let pickup_driver_load_unload = order.movement.pickup.driver_load_unload;
      let pickup_appt_required = order.movement.pickup.appt_required;
      let picup_confirmed = order.movement.pickup.confirmed;

      if (order.movement.pickup.stop_type !== null) {
        pickup_type = order.movement.pickup.stop_type;
      } else {
        if (order.movement?.pickup?.type === "pickup") {
          pickup_type = "PU";
        } else if (order.movement?.pickup?.type === "split pickup") {
          pickup_type = "SP";
        }
      }

      if (order.movement.delivery.stop_type !== null) {
        delivery_type = order.movement.delivery.stop_type;
      } else {
        if (order.movement?.delivery?.type === "delivery") {
          delivery_type = "DEL";
        }
        if (order.movement?.delivery?.type === "split delivery") {
          delivery_type = "SD";
        }
      }

      // color coding for pickup block
      if (pickup_eta_utc > order.movement.pickup.sched_arrive_late_utc) {
        pickup_status = "red";
      } else if (
        pickup_eta_utc > pickup_planned_arrive &&
        pickup_eta_utc <= order.movement.pickup.sched_arrive_late_utc
      ) {
        pickup_status = "yellow";
      } else if (pickup_eta_utc <= pickup_planned_arrive) {
        pickup_status = "green";
      }

      // color coding for delivery block
      if (delivery_eta_utc > order.movement.delivery.sched_arrive_late_utc) {
        delivery_status = "red";
      } else if (
        delivery_eta_utc > delivery_planned_arrive &&
        delivery_eta_utc <= order.movement.delivery.sched_arrive_late_utc
      ) {
        delivery_status = "yellow";
      } else if (delivery_eta_utc <= delivery_planned_arrive) {
        delivery_status = "green";
      }
      if (order.movement.xmit_accepted == "Y") {
        zmitStatus = "green";
      } else if (order.movement.xmitted2driver != null) {
        if (
          order.movement.xmit_accepted == "N" ||
          order.movement.xmit_accepted == null
        ) {
          zmitStatus = "orange";
        }
      } else {
        zmitStatus = "white";
      }
      //Movetype logic.

      if (order.movement.movement_type !== null) {
        move_type = order.movement.movement_type;
      } else {
        // Handles returns, redeliveries, load, empty
        if (order.movement.is_return) {
          move_type = "R";
        } else if (order.movement.is_redelivery) {
          move_type = "RD";
        } else if (order.movement.loaded) {
          move_type = "L";
        } else {
          move_type = "E";
        }
      }

      let obj = {
        start: 0,
        drawStart: 0,
        isBlock: 0,
        PU: 0,
        DEL: 0,
        MV: 0,
        OF: 0,
        LEN: 0,
        orderid: order.lm_order_id,
        commodity: order.commodity,
        trailerid: order.movement.trailer_id,
        driverid: order.movement.driver.driver_id,
        hasReachedOnTime: false,
        has_pickup_on_time:
          pickup_actual_depart <= pickup_actual_depart ? true : false,
        pickup_status: pickup_status,
        delivery_status: delivery_status,
        zmitStatus: zmitStatus,
        has_departed_on_time: false,
        has_unloaded_on_time: false,
        has_delivery_on_time:
          delivery_actual_arrive <= delivery_planned_arrive ? true : false,
        pickup_type: pickup_type,
        delivery_type: delivery_type,
        loaded: order.movement.loaded,
        move_type: move_type,
        pickup_planned_arrive: pickup_planned_arrive,
        pickup_timezone: pickup_timezone,
        pickup_planned_depart: pickup_planned_depart,
        pickup_actual_arrive: pickup_actual_arrive,
        pickup_actual_depart: pickup_actual_depart,
        delivery_timezone: delivery_timezone,
        delivery_planned_arrive: delivery_planned_arrive,
        delivery_planned_depart: delivery_planned_depart,
        delivery_actual_arrive: delivery_actual_arrive,
        delivery_actual_depart: delivery_actual_depart,
        pickup_eta_utc: pickup_eta_utc,
        delivery_eta_utc: delivery_eta_utc,
        pickup_sched_arrive_early_utc: pickup_sched_arrive_early_utc,
        pickup_sched_arrive_late_utc: pickup_sched_arrive_late_utc,
        delivery_sched_arrive_early_utc: delivery_sched_arrive_early_utc,
        delivery_sched_arrive_late_utc: delivery_sched_arrive_late_utc,
        distance: distance,
        brokerage: brokerage,
        movement_type: movement_type,
        movement_status: movement_status,
        stop_pickup__type: stop_pickup__type,
        stop_delivery_type: stop_delivery_type,
        company_location_id: company_location_id,
        location_name: location_name,
        address1: address1,
        city: city,
        state: state,
        zip: zip,
        move_id: move_id,
        company_location_id1: company_location_id1,
        location_name1: location_name1,
        address11: address11,
        city1: city1,
        state1: state1,
        zip1: zip1,
        terminalId: order.movement.driver.terminal.terminal_id,
        terminal_city: order.movement.driver.terminal.city,
        commodity_Id: order.commodity_id,
        order_status: order_status,
        bol_received: bol_received,
        customer_name: customer_name,
        consignee_refno: consignee_refno,
        ordered_wt: ordered_wt,
        commoditygroupid: order.order_commodity.commoditygroupid,
        commoditygroup_description: order.order_commodity.description,
        on_hold: on_hold,
        commodity_id: order.order_commodity.commodity_id,
        delivery_driver_load_unload: delivery_driver_load_unload,
        pickup_driver_load_unload: pickup_driver_load_unload,
        pickup_appt_required: pickup_appt_required,
        picup_confirmed: picup_confirmed,
        commodity: order.commodity,
        license_plates: order.movement.trailer.license_plate,
        equip_types: order.movement.trailer.equip_type,
        license_state: order.movement.trailer.license_state,
        makes: order.movement.trailer.make,
        models: order.movement.trailer.model,
        model_year: order.movement.trailer.model_year,
        tare_weights: order.movement.trailer.tare_weight,
        state: state,
        terminalId: order.movement.driver.terminal.terminal_id,
        terminal_city: order.movement.driver.terminal.city,
        shipper_pool: order.movement.pickup.location.code,
        dedicated: order.movement.pickup.location.code,
        pm_due_date_utc: order.movement.trailer.pm_due_date_utc,
        order_type_id: order.order_type_id,
        commodity_desc: order.commodity_desc,
        blnum: order.blnum,
        load_id_arr: orders.map((e) => e.movement.load_id),
      };

      let nextStartTs = 0;
      const dispatch_ts = new Date(order.pickup_dispatch_ts);
      const dispatch_ts_utc = new Date(
        Date.UTC(
          dispatch_ts.getFullYear(),
          dispatch_ts.getMonth(),
          dispatch_ts.getDate(),
          dispatch_ts.getHours(),
          dispatch_ts.getMinutes(),
          dispatch_ts.getSeconds()
        )
      );

      // If block starts previous day
      if (pickup_planned_arrive < planningDate.getTime()) {
        let previousStartTs = DateTime.fromMillis(planningTs)
          .minus({ days: 1 })
          .toMillis();
        let previousStart = parseInt(
          (pickup_planned_arrive - previousStartTs) / 60000
        );
        let start_pk_ts = parseInt(
          (pickup_planned_arrive - planningTs) / 60000
        );
        obj.isBlock = 1;
        obj.start = start_pk_ts;
        obj.drawStart = start_pk_ts;
        obj.PU = (pickup_planned_depart - pickup_planned_arrive) / 60000;
        obj.MV = (delivery_planned_arrive - pickup_planned_depart) / 60000;
        obj.DEL = (delivery_planned_depart - delivery_planned_arrive) / 60000;
        obj.LEN = obj.PU + obj.MV + obj.DEL;

        if (previousStart + obj.LEN > 1440) {
          obj.start = 0;
          obj.drawStart = 0;
          let new_DEL, new_MV, new_PU;
          if (previousStart + obj.PU > 1440) {
            let previousPU = 1440 - previousStart;
            new_PU = obj.PU - previousPU;
            new_MV = obj.MV;
            new_DEL = obj.DEL;
          } else if (previousStart + obj.PU + obj.MV > 1440) {
            let previous_MV = 1440 - (start_pk_ts + obj.PU);
            new_PU = 0;
            new_MV = obj.MV - previous_MV;
            new_DEL = obj.DEL;
          } else if (previousStart + obj.PU + obj.MV + obj.DEL > 1440) {
            new_PU = 0;
            new_MV = 0;
            let previous_DEL = 1440 - (start_pk_ts + obj.PU + obj.MV);
            new_DEL = obj.DEL - previous_DEL;
          }
          obj.PU = new_PU;
          obj.MV = new_MV;
          obj.DEL = new_DEL;
          obj.LEN = obj.PU + obj.MV + obj.DEL;
          blocks.push(obj);
        }
        let driver;
      } else {
        // In case the block starts in the same day and continue till next day

        let start_pk_ts = parseInt(
          (pickup_planned_arrive - planningTs) / 60000
        );
        obj.isBlock = 1;
        obj.start = start_pk_ts;
        obj.drawStart = start_pk_ts;
        obj.PU = (pickup_planned_depart - pickup_planned_arrive) / 60000;
        obj.MV = (delivery_planned_arrive - pickup_planned_depart) / 60000;
        obj.DEL = (delivery_planned_depart - delivery_planned_arrive) / 60000;
        obj.LEN = obj.PU + obj.MV + obj.DEL;

        if (start_pk_ts + obj.LEN > 1440) {
          if (start_pk_ts + obj.PU > 1440) {
            obj.PU = 1440 - start_pk_ts;
            obj.MV = 0;
            obj.DEL = 0;
            obj.LEN = 1440 - start_pk_ts;
            obj.start = 0;
          } else if (start_pk_ts + obj.PU + obj.MV > 1440) {
            obj.MV = 1440 - (start_pk_ts + obj.PU);
            obj.DEL = 0;
            obj.LEN = 1440 - start_pk_ts;
          } else if (start_pk_ts + obj.PU + obj.MV + obj.DEL > 1440) {
            obj.DEL = 1440 - (start_pk_ts + obj.PU + obj.MV);
            obj.LEN = 1440 - start_pk_ts;
          }
        }
        let driver;
        blocks.push(obj);
      }
    }
    blockData = blocks;

    //
  } catch (err) { }

  return blockData;
};

export const DriversWithHosLogs = async (driver, planningDate) => {
  let blockData = [];
  // { start: 0, length: 1, type: "", orderid: "", trailerid: "", driverid: "", hasOnTime: true, actionValue: ""}
  try {
    planningDate = new Date(
      planningDate.getFullYear(),
      planningDate.getMonth(),
      planningDate.getDate()
    );
    [];
    const planningTs = planningDate.getTime();
    const blocks = [];
    if (driver) {
      if (driver.schedule) {
        if (driver.schedule.offduty) {
          for (let index = 0; index < driver.schedule.offduty.length; index++) {
            let obj1 = {
              start: 0,
              drawStart: 0,
              isBlock: 0,
              PU: 0,
              DEL: 0,
              MV: 0,
              OF: 0,
              LEN: 0,
              orderid: "",
              trailerid: "",
              driverid: driver.driver_id,
              hasReachedOnTime: false,
              has_pickup_on_time: false,
              has_departed_on_time: false,
              has_delivery_on_time: false,
              has_unloaded_on_time: false,
              pickup_type: "",
              delivery_type: "",
              move_type: "",
            };

            try {
              let startOffdutyUtc = driver.schedule.offduty[index].logStartTime;
              let startOffDutyTime = parseInt(
                (startOffdutyUtc - planningTs) / 60000
              );
              let endOffdutyUtc = driver.schedule.offduty[index].logEndTime;
              let offDutyDuration = parseInt(endOffdutyUtc - startOffdutyUtc);
              let endOffDutyTime = parseInt(offDutyDuration / 60000);
              obj1.OF =
                endOffDutyTime + (startOffDutyTime < 0 ? startOffDutyTime : 0);
              obj1.LEN =
                endOffDutyTime + (startOffDutyTime < 0 ? startOffDutyTime : 0);
              obj1.isBlock = 1;
              obj1.start = startOffDutyTime < 0 ? 0 : startOffDutyTime;
              obj1.drawStart = startOffDutyTime < 0 ? 0 : startOffDutyTime;
              if (startOffDutyTime < 1440 && obj1.LEN > 0) blocks.push(obj1);
            } catch (error) { }
          }
        }
      }
    }

    blockData = blocks;

    //
  } catch (err) { }

  return blockData;
};

export const TrailerViewBlocksInMinutes = async (orders, planningDate) => {
  let blockData = [];
  // { start: 0, length: 1, type: "", orderid: "", trailerid: "", driverid: "", hasOnTime: true, actionValue: ""}
  try {
    planningDate = new Date(
      planningDate.getFullYear(),
      planningDate.getMonth(),
      planningDate.getDate()
    );
    [];
    const planningTs = planningDate.getTime();
    const blocks = [];

    if (orders) {
      if (orders.length > 0) {
        if (orders[0].movement.trailer?.maintworkorder) {
          let maintworkorder = orders[0]?.movement.trailer?.maintworkorder;

          let planningTs_end = DateTime.fromMillis(planningTs)
            .plus({ days: 1 })
            .toMillis();
          try {
            let in_service_ts_millis = maintworkorder.in_service_ts_utc * 1000;
            let out_of_service_ts_millis =
              maintworkorder.out_of_service_ts_utc * 1000;
            if (
              in_service_ts_millis > planningTs &&
              in_service_ts_millis < planningTs_end
            ) {
              let obj1 = {
                start: 0,
                drawStart: 0,
                isBlock: 1,
                PU: 0,
                DEL: 0,
                MV: 0,
                OF: 0,
                LEN: 0,
                orderid: "",
                trailerid: maintworkorder.unit_code,
                driverid: "",
                hasReachedOnTime: false,
                has_pickup_on_time: false,
                has_departed_on_time: false,
                has_delivery_on_time: false,
                has_unloaded_on_time: false,
                pickup_type: "",
                delivery_type: "",
                move_type: "MNT: " + maintworkorder.work_order_nbr,
              };
              let start_out_of_service = parseInt(
                (out_of_service_ts_millis - planningTs) / 60000
              );
              let service_duration = parseInt(
                in_service_ts_millis - out_of_service_ts_millis
              );
              let service_duration_minutes = parseInt(service_duration / 60000);
              obj1.OF = service_duration_minutes;
              obj1.LEN = service_duration_minutes;
              obj1.start = start_out_of_service;
              obj1.drawStart = start_out_of_service;
              blocks.push(obj1);
            }
          } catch (error) { }
        }
        if (orders[0].movement.trailer?.trailerwashwo) {
          let trailer_wash_wo = orders[0].movement.trailer?.trailerwashwo;
          let planningTs_end = DateTime.fromMillis(planningTs)
            .plus({ days: 1 })
            .toMillis();
          try {
            let in_date_utc = trailer_wash_wo.in_date_utc * 1000;
            let out_date_utc = trailer_wash_wo.out_date_utc * 1000;
            if (in_date_utc > planningTs && in_date_utc < planningTs_end) {
              let obj2 = {
                start: 0,
                drawStart: 0,
                isBlock: 1,
                PU: 0,
                DEL: 0,
                MV: 0,
                OF: 0,
                LEN: 0,
                orderid: "",
                trailerid: "",
                driverid: "",
                hasReachedOnTime: false,
                has_pickup_on_time: false,
                has_departed_on_time: false,
                has_delivery_on_time: false,
                has_unloaded_on_time: false,
                pickup_type: "",
                delivery_type: "",
                move_type: "TW: " + trailer_wash_wo.wash_id,
              };
              let start_in_date = parseInt((in_date_utc - planningTs) / 60000);
              let wash_duration = parseInt(out_date_utc - in_date_utc);
              let wash_duration_minutes = parseInt(wash_duration / 60000);
              obj2.OF = wash_duration_minutes;
              obj2.LEN = wash_duration_minutes;
              obj2.start = start_in_date;
              obj2.drawStart = start_in_date;
              blocks.push(obj2);
            }
          } catch (error) { }
        }
      }
    }
    for (let loop = 0; loop < orders.length; loop++) {
      let order = orders[loop];
      let pickup_type, delivery_type;
      let pickup_eta_utc = order.movement.pickup.eta_utc;
      let delivery_eta_utc = order.movement.delivery.eta_utc;
      let pickup_status,
        delivery_status,
        has_delivery_on_time,
        has_unloaded_on_time;
      let pickup_planned_depart = order.movement.pickup.planned_depart_utc;
      let pickup_planned_arrive = order.movement.pickup.planned_arrive_utc;
      let pickup_actual_depart = order.movement.pickup.actual_departure_utc;
      let pickup_actual_arrive = order.movement.pickup.actual_arrival_utc;
      let delivery_planned_arrive = order.movement.delivery.planned_arrive_utc;
      let delivery_planned_depart = order.movement.delivery.planned_depart_utc;
      let delivery_actual_depart = order.movement.delivery.actual_departure_utc;
      let delivery_actual_arrive = order.movement.delivery.actual_arrival_utc;
      let pickup_sched_arrive_early_utc =
        order.movement.pickup.sched_arrive_early_utc;
      let pickup_sched_arrive_late_utc =
        order.movement.pickup.sched_arrive_late_utc;
      let delivery_sched_arrive_early_utc =
        order.movement.delivery.sched_arrive_early_utc;
      let delivery_sched_arrive_late_utc =
        order.movement.delivery.sched_arrive_late_utc;
      let pickup_timezone = order.movement.pickup.pickup_timezone;
      let delivery_timezone = order.movement.delivery.delivery_timezone;
      let load_id = order.movement.load_id;

      let move_type, zmitStatus;

      if (order.movement?.pickup?.type === "pickup") {
        pickup_type = "PU";
      } else if (order.movement?.pickup?.type === "split pickup") {
        pickup_type = "SP";
      }
      if (order.movement?.delivery?.type === "delivery") {
        delivery_type = "DEL";
      }
      if (order.movement?.delivery?.type === "split delivery") {
        delivery_type = "SD";
      }
      // color coding for pickup block

      if (pickup_eta_utc > order.movement.pickup.sched_arrive_late_utc) {
        pickup_status = "red";
      } else if (
        pickup_eta_utc > pickup_planned_arrive &&
        pickup_eta_utc <= order.movement.pickup.sched_arrive_late_utc
      ) {
        pickup_status = "yellow";
      } else if (pickup_eta_utc <= pickup_planned_arrive) {
        pickup_status = "green";
      }

      // color coding for delivery block
      if (delivery_eta_utc > order.movement.delivery.sched_arrive_late_utc) {
        delivery_status = "red";
      } else if (
        delivery_eta_utc > delivery_planned_arrive &&
        delivery_eta_utc <= order.movement.delivery.sched_arrive_late_utc
      ) {
        delivery_status = "yellow";
      } else if (delivery_eta_utc <= delivery_planned_arrive) {
        delivery_status = "green";
      }

      if (order.movement.xmit_accepted == "Y") {
        zmitStatus = "green";
      } else if (order.movement.xmitted2driver != null) {
        if (
          order.movement.xmit_accepted == "N" ||
          order.movement.xmit_accepted == null
        ) {
          zmitStatus = "orange";
        }
      } else {
        zmitStatus = "white";
      }

      //Movetype logic. Handles returns, redeliveries, load, empty
      if (order.movement.is_return) {
        move_type = "R";
      } else if (order.movement.is_redelivery) {
        move_type = "RD";
      } else if (order.movement.loaded) {
        move_type = "L";
      } else {
        move_type = "E";
      }

      let obj = {
        start: 0,
        drawStart: 0,
        isBlock: 0,
        PU: 0,
        DEL: 0,
        MV: 0,
        OF: 0,
        LEN: 0,
        orderid: order.lm_order_id,
        trailerid: order.movement.trailer_id,
        driverid: order.movement.driver.driver_id,
        hasReachedOnTime: false,
        has_pickup_on_time:
          pickup_actual_depart <= pickup_actual_depart ? true : false,
        pickup_status: pickup_status,
        delivery_status: delivery_status,
        has_departed_on_time: false,
        has_unloaded_on_time: false,
        has_delivery_on_time:
          delivery_actual_arrive <= delivery_planned_arrive ? true : false,
        pickup_type: pickup_type,
        delivery_type: delivery_type,
        loaded: order.movement.loaded,
        zmitStatus: zmitStatus,
        move_type: move_type,
        stop_pickup__type: order.movement.pickup.type,
        stop_delivery_type: order.movement.delivery.type,
        pickup_planned_arrive: pickup_planned_arrive,
        pickup_planned_depart: pickup_planned_depart,
        pickup_actual_arrive: pickup_actual_arrive,
        pickup_actual_depart: pickup_actual_depart,
        delivery_planned_arrive: delivery_planned_arrive,
        delivery_planned_depart: delivery_planned_depart,
        delivery_actual_arrive: delivery_actual_arrive,
        delivery_actual_depart: delivery_actual_depart,
        pickup_eta_utc: pickup_eta_utc,
        delivery_eta_utc: delivery_eta_utc,
        pickup_sched_arrive_early_utc: pickup_sched_arrive_early_utc,
        pickup_sched_arrive_late_utc: pickup_sched_arrive_late_utc,
        delivery_sched_arrive_early_utc: delivery_sched_arrive_early_utc,
        delivery_sched_arrive_late_utc: delivery_sched_arrive_late_utc,
        pickup_timezone: pickup_timezone,
        delivery_timezone: delivery_timezone,
        driver_name: order.movement.driver.driver_full_name,
        terminalId: order.movement.driver.terminal.terminal_id,
        terminal_city: order.movement.driver.terminal.city,
        samseraid: order.movement.driver.samsara_id,
        Hiredate: order.movement.driver.hire_date,
        drivertypeclass: order.movement.driver.driver_type_class,
        customer_name: order.customer_name,
        order_status: order.status,
        bol_received: order.bol_received,
        consignee_refno: order.consignee_refno,
        ordered_wt: order.ordered_wt,
        on_hold: order.on_hold,
        delivery_driver_load_unload: order.movement.delivery.driver_load_unload,
        pickup_driver_load_unload: order.movement.pickup.driver_load_unload,
        pickup_appt_required: order.movement.pickup.appt_required,
        picup_confirmed: order.movement.pickup.confirmed,
        move_id: order.movement.load_id,
        company_location_id: order.movement.pickup.location.company_location_id,
        location_name: order.movement.pickup.location.location_name,
        address1: order.movement.pickup.location.address1,
        city: order.movement.pickup.location.city,
        state: order.movement.pickup.location.state,
        zip: order.movement.pickup.location.zip,
        company_location_id1:
          order.movement.delivery.location.company_location_id,
        location_name1: order.movement.delivery.location.location_name,
        address11: order.movement.delivery.location.address1,
        city1: order.movement.delivery.location.city,
        state1: order.movement.delivery.location.state,
        zip1: order.movement.delivery.location.zip,
        brokerage: order.movement.brokerage,
        order_type_id: order.order_type_id,
        commodity_desc: order.commodity_desc,
        blnum: order.blnum,
        load_id: load_id,
      };

      // If block starts previous day
      if (pickup_planned_arrive < planningDate.getTime()) {
        let previousStartTs = DateTime.fromMillis(planningTs)
          .minus({ days: 1 })
          .toMillis();
        let previousStart = parseInt(
          (pickup_planned_arrive - previousStartTs) / 60000
        );
        let start_pk_ts = parseInt(
          (pickup_planned_arrive - planningTs) / 60000
        );
        obj.isBlock = 1;
        obj.start = start_pk_ts;
        obj.drawStart = start_pk_ts;
        obj.PU = (pickup_planned_depart - pickup_planned_arrive) / 60000;
        obj.MV = (delivery_planned_arrive - pickup_planned_depart) / 60000;
        obj.DEL = (delivery_planned_depart - delivery_planned_arrive) / 60000;
        obj.LEN = obj.PU + obj.MV + obj.DEL;

        if (previousStart + obj.LEN > 1440) {
          obj.start = 0;
          obj.drawStart = 0;
          let new_DEL, new_MV, new_PU;
          if (previousStart + obj.PU > 1440) {
            let previousPU = 1440 - previousStart;
            new_PU = obj.PU - previousPU;
            new_MV = obj.MV;
            new_DEL = obj.DEL;
          } else if (previousStart + obj.PU + obj.MV > 1440) {
            let previous_MV = 1440 - (start_pk_ts + obj.PU);
            new_PU = 0;
            new_MV = obj.MV - previous_MV;
            new_DEL = obj.DEL;
          } else if (previousStart + obj.PU + obj.MV + obj.DEL > 1440) {
            new_PU = 0;
            new_MV = 0;
            let previous_DEL = 1440 - (start_pk_ts + obj.PU + obj.MV);
            new_DEL = obj.DEL - previous_DEL;
          }
          obj.PU = new_PU;
          obj.MV = new_MV;
          obj.DEL = new_DEL;
          obj.LEN = obj.PU + obj.MV + obj.DEL;
          blocks.push(obj);
        }
        let driver;
      } else {
        // In case the block starts in the same day and continue till next day
        let start_pk_ts = parseInt(
          (pickup_planned_arrive - planningTs) / 60000
        );
        obj.isBlock = 1;
        obj.start = start_pk_ts;
        obj.drawStart = start_pk_ts;
        obj.PU = (pickup_planned_depart - pickup_planned_arrive) / 60000;
        obj.MV = (delivery_planned_arrive - pickup_planned_depart) / 60000;
        obj.DEL = (delivery_planned_depart - delivery_planned_arrive) / 60000;
        obj.LEN = obj.PU + obj.MV + obj.DEL;

        if (start_pk_ts + obj.LEN > 1440) {
          if (start_pk_ts + obj.PU > 1440) {
            obj.PU = 1440 - start_pk_ts;
            obj.MV = 0;
            obj.DEL = 0;
            obj.LEN = 1440 - start_pk_ts;
            obj.start = 0;
          } else if (start_pk_ts + obj.PU + obj.MV > 1440) {
            obj.MV = 1440 - (start_pk_ts + obj.PU);
            obj.DEL = 0;
            obj.LEN = 1440 - start_pk_ts;
          } else if (start_pk_ts + obj.PU + obj.MV + obj.DEL > 1440) {
            obj.DEL = 1440 - (start_pk_ts + obj.PU + obj.MV);
            obj.LEN = 1440 - start_pk_ts;
          }
        }
        let driver;
        blocks.push(obj);
      }
    }
    blockData = blocks;
  } catch (err) { }

  return blockData;
};

export const CarrierViewBlocksInMinutes = async (orders, planningDate) => {
  let blockData = [];
  // { start: 0, length: 1, type: "", orderid: "", trailerid: "", driverid: "", hasOnTime: true, actionValue: ""}
  try {
    planningDate = new Date(
      planningDate.getFullYear(),
      planningDate.getMonth(),
      planningDate.getDate()
    );
    [];
    const planningTs = planningDate.getTime();
    const blocks = [];

    for (let loop = 0; loop < orders.length; loop++) {
      let order = orders[loop];
      let pickup_type, delivery_type;
      let pickup_eta_utc = order.movement.pickup.eta_utc;
      let delivery_eta_utc = order.movement.delivery.eta_utc;
      let pickup_status,
        delivery_status,
        has_delivery_on_time,
        has_unloaded_on_time;
      let pickup_planned_depart = order.movement.pickup.planned_depart_utc;
      let pickup_planned_arrive = order.movement.pickup.planned_arrive_utc;
      let pickup_actual_depart = order.movement.pickup.actual_departure_utc;
      let delivery_planned_arrive = order.movement.delivery.planned_arrive_utc;
      let delivery_planned_depart = order.movement.delivery.planned_depart_utc;
      let delivery_actual_arrive = order.movement.delivery.actual_arrival_utc;
      if (order.movement?.pickup?.type === "pickup") {
        pickup_type = "PU";
      } else if (order.movement?.pickup?.type === "split pickup") {
        pickup_type = "SP";
      }
      if (order.movement?.delivery?.type === "delivery") {
        delivery_type = "DEL";
      }
      if (order.movement?.delivery?.type === "split delivery") {
        delivery_type = "SD";
      }
      // color coding for pickup block
      if (pickup_eta_utc > order.movement.pickup.sched_arrive_late_utc) {
        pickup_status = "red";
      } else if (
        pickup_eta_utc > pickup_planned_arrive &&
        pickup_eta_utc <= order.movement.pickup.sched_arrive_late_utc
      ) {
        pickup_status = "yellow";
      } else if (pickup_eta_utc <= pickup_planned_arrive) {
        pickup_status = "green";
      }

      // color coding for delivery block
      if (delivery_eta_utc > order.movement.delivery.sched_arrive_late_utc) {
        delivery_status = "red";
      } else if (
        delivery_eta_utc > delivery_planned_arrive &&
        delivery_eta_utc <= order.movement.delivery.sched_arrive_late_utc
      ) {
        delivery_status = "yellow";
      } else if (delivery_eta_utc <= delivery_planned_arrive) {
        delivery_status = "green";
      }
      let obj = {
        start: 0,
        drawStart: 0,
        isBlock: 0,
        PU: 0,
        DEL: 0,
        MV: 0,
        OF: 0,
        LEN: 0,
        orderid: order.lm_order_id,
        trailerid: order.movement.trailer_id,
        driverid: "Bulkmatic Solutions",
        hasReachedOnTime: false,
        has_pickup_on_time:
          pickup_actual_depart <= pickup_actual_depart ? true : false,
        pickup_status: pickup_status,
        delivery_status: delivery_status,
        has_departed_on_time: false,
        has_unloaded_on_time: false,
        has_delivery_on_time:
          delivery_actual_arrive <= delivery_planned_arrive ? true : false,
        pickup_type: pickup_type,
        delivery_type: delivery_type,
        // loaded: order.movement.loaded,
        // zmitStatus: "carrierView"
      };

      // If block starts previous day
      let time = planningDate.getTime();
      if (pickup_planned_arrive < planningDate.getTime()) {
        let previousStartTs = DateTime.fromMillis(planningTs)
          .minus({ days: 1 })
          .toMillis();
        let previousStart = parseInt(
          (pickup_planned_arrive - previousStartTs) / 60000
        );
        let start_pk_ts = parseInt(
          (pickup_planned_arrive - planningTs) / 60000
        );
        obj.isBlock = 1;
        obj.start = start_pk_ts;
        obj.drawStart = start_pk_ts;
        obj.PU = (pickup_planned_depart - pickup_planned_arrive) / 60000;
        obj.MV = (delivery_planned_arrive - pickup_planned_depart) / 60000;
        obj.DEL = (delivery_planned_depart - delivery_planned_arrive) / 60000;
        obj.LEN = obj.PU + obj.MV + obj.DEL;

        if (previousStart + obj.LEN > 1440) {
          obj.start = 0;
          obj.drawStart = 0;
          let new_DEL, new_MV, new_PU;
          if (previousStart + obj.PU > 1440) {
            let previousPU = 1440 - previousStart;
            new_PU = obj.PU - previousPU;
            new_MV = obj.MV;
            new_DEL = obj.DEL;
          } else if (previousStart + obj.PU + obj.MV > 1440) {
            let previous_MV = 1440 - (start_pk_ts + obj.PU);
            new_PU = 0;
            new_MV = obj.MV - previous_MV;
            new_DEL = obj.DEL;
          } else if (previousStart + obj.PU + obj.MV + obj.DEL > 1440) {
            new_PU = 0;
            new_MV = 0;
            let previous_DEL = 1440 - (start_pk_ts + obj.PU + obj.MV);
            new_DEL = obj.DEL - previous_DEL;
          }
          obj.PU = new_PU;
          obj.MV = new_MV;
          obj.DEL = new_DEL;
          obj.LEN = obj.PU + obj.MV + obj.DEL;
          blocks.push(obj);
        }
        let driver;
      } else {
        // In case the block starts in the same day and continue till next day

        let start_pk_ts = parseInt(
          (pickup_planned_arrive - planningTs) / 60000
        );
        obj.isBlock = 1;
        obj.start = start_pk_ts;
        obj.drawStart = start_pk_ts;
        obj.PU = (pickup_planned_depart - pickup_planned_arrive) / 60000;
        obj.MV = (delivery_planned_arrive - pickup_planned_depart) / 60000;
        obj.DEL = (delivery_planned_depart - delivery_planned_arrive) / 60000;
        obj.LEN = obj.PU + obj.MV + obj.DEL;

        if (start_pk_ts + obj.LEN > 1440) {
          if (start_pk_ts + obj.PU > 1440) {
            obj.PU = 1440 - start_pk_ts;
            obj.MV = 0;
            obj.DEL = 0;
            obj.LEN = 1440 - start_pk_ts;
            obj.start = 0;
          } else if (start_pk_ts + obj.PU + obj.MV > 1440) {
            obj.MV = 1440 - (start_pk_ts + obj.PU);
            obj.DEL = 0;
            obj.LEN = 1440 - start_pk_ts;
          } else if (start_pk_ts + obj.PU + obj.MV + obj.DEL > 1440) {
            obj.DEL = 1440 - (start_pk_ts + obj.PU + obj.MV);
            obj.LEN = 1440 - start_pk_ts;
          }
        }
        let driver;

        blocks.push(obj);
      }
    }
    blockData = blocks;

    //
  } catch (err) { }
  return blockData;
};
export const getOfffDutyBlocks = async (orders, date) => {
  let obj = {
    start: 0,
    drawStart: 0,
    isBlock: 0,
    PU: 0,
    DEL: 0,
    MV: 0,
    LEN: 0,
    orderid: "",
    trailerid: "",
    driverid: "",
    hasReachedOnTime: false,
    has_pickup_on_time: false,
    has_departed_on_time: false,
    has_delivery_on_time: false,
    has_unloaded_on_time: false,
    pickup_type: "",
    delivery_type: "",
  };

  return obj;
};

export const getAlerts = async () => {
  try {
    const url = BaseConfig.ApiEndPoint + "/alerts";
    const alertsResponse = await axios.get(url);
    return alertsResponse;
  } catch (err) {
    throw err;
  }
};

export const dismissAlert = async (alert_id) => {
  try {
    const url = BaseConfig.ApiEndPoint + "/dismissalert";
    const requestBody = {
      alert_id: alert_id,
    };
    const alertsResponse = await axios.post(url, requestBody);
    return alertsResponse;
  } catch (err) {
    throw err;
  }
};

export const getOrderById = async (id) => {
  try {
    const url = BaseConfig.ApiEndPoint + "/orders/" + id;
    const orderResponse = await axios.get(url);
    return orderResponse;
  } catch (error) {
    throw error;
  }
};

export const createemptymove = async (obj) => {
  let response = {};
  try {
    const url = BaseConfig.ApiEndPoint + "/createemptymove";
    response = await axios.post(url, obj);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatemovementbyloadid = async (move_id, obj) => {
  try {
    let config = {
      method: "put",
      url: BaseConfig.ApiEndPoint + "/updatemovementbyloadid/" + move_id,
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const pcmilerRouteReports = async (obj) => {
  try {
    var config = {
      method: "post",
      url: `${Config.dev.pcMilerApiUrl}/Service.svc/route/routeReports?dataVersion=Current`,
      headers: {
        Authorization: `${Config.dev.pcmilerkey}`,
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createmovement = async (obj) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/createmovement",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createmultiplemovements = async (obj) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/createmultiplemovements",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatemultiplemovements = async (obj) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/updatemultiplemovements",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const updatemultiplemovementsforxmitted2driver_utc = async (obj) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/updatemultiplemovementsforxmitted2driver_utc",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


export const updatestopbylmstopid = async (obj) => {
  try {
    var config = {
      method: "put",
      url: BaseConfig.ApiEndPoint + "/updatestopbylmstopid",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatemultiplestops = async (obj) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/updatemultiplestops",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const removemultiplestops = async (obj) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/removemultiplestops",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createstop = async (obj) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/createstop",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createmultiplestops = async (obj) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/createmultiplestops",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getmovementstopbyterminalid = async (searchData,timeZone) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/getmovementstopbyterminalid",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        date: searchData.date,
        // terminals:Array.isArray(terminals)? terminals:['011', '025', '027'],
        terminals: searchData.terminals,
        timeZone
      },
    };

    let response = await axios(config);
    const mvmvtStopApiResponse = atob(response.data);

    // Convert binary string to character-number array
    const charData = mvmvtStopApiResponse.split("").map((x) => {
      return x.charCodeAt(0);
    });
    // Turn number array into byte-array
    const binData = new Uint8Array(charData);
    // Use this Code to Decode the String

    //Uncompress API Call//
    let mvmvtStopApiDataUnCompressed = fflate.unzlibSync(binData);
    let mvmvtStopApiDataUnCompressedString = fflate.strFromU8(
      mvmvtStopApiDataUnCompressed
    );
    const mvmvtStopApiDecompressedObject = JSON.parse(
      mvmvtStopApiDataUnCompressedString
    );

    return Promise.resolve(mvmvtStopApiDecompressedObject);
  } catch (error) {
    return Promise.reject(error);
  }
};

// export const deleteordesbyloadid = async (data) => {

//   let payload = {
//       "id": data
//   }
//   const url = BaseConfig.ApiEndPoint + "/";
//   const deleteApiData = await axios.post(url, payload);
//   return Promise.resolve(deleteApiData.data.data);
// }

export const removeMovementByloadId = async (payLoad) => {
  let orderobject = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/removemovementbyloadid";
    const deleteApiData = await axios.post(url, payLoad);
    orderobject = deleteApiData.data;
  } catch (error) {
    return Promise.reject("failed");
  }
  return Promise.resolve(orderobject);
};

export const removemultiplemovements = async (payLoad) => {
  let orderobject = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/removemultiplemovements";
    const deleteApiData = await axios.post(url, payLoad);
    orderobject = deleteApiData.data;
  } catch (error) {
    return Promise.reject("failed");
  }
  return Promise.resolve(orderobject);
};
export const getStopInfo = async (ids) => {
  let stopobj = {};
  try {
    const url = BaseConfig.ApiEndPoint + "/getstopinfo";
    const getStopData = await axios.post(url, ids);
    stopobj = getStopData.data.data;
  } catch (error) {
    return Promise.reject("failed");
  }
  return Promise.resolve(stopobj);
};

export const getTerminalbylatlong = async (data) => {
  let locationobject = [];
  try {
    let payLoad = {
      longitude: data?.longitude,
      latitude: data?.latitude,
      searchtext: data?.searchtext,
      distance: data?.distance ?? 20,
    };
    const url = BaseConfig.ApiEndPoint + "/getlocationbylatlong";
    const latlngApiData = await axios.post(url, payLoad);
    locationobject = latlngApiData.data.data;
  } catch (error) {
    return Promise.reject("failed");
  }
  return Promise.resolve(locationobject);
};
export const getImapctView = async (input_date, driver_id, trailer_id) => {
  let impactViewApiData = [];
  try {
    let data = {
      inputDate: input_date / 1000,
      driver_id: driver_id,
      trailer_id: trailer_id,
    };
    const url = BaseConfig.ApiEndPoint + "/getimpactview";
    const impactApiData = await axios.post(url, data);
    impactViewApiData = impactApiData.data;
  } catch (error) {
    return Promise.reject("failed");
  }
  return await Promise.resolve(impactViewApiData);
};

export const getAllTerminals = async () => {
  let terminalObject = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/terminals";
    const terminalApiData = await axios.get(url);
    terminalObject = terminalApiData.data.data;
  } catch (err) {
    return Promise.reject(
      "Failure: Unable to retrieve terminal list. Please try refreshing again!"
    ); //"There is a problem on retrieving terminal data. Please try again!");
  }

  return Promise.resolve(terminalObject);
};

export const CreateSplitOrder = async (data) => {
  let orderobject = [];
  try {
    let payLoad = {
      location_id: data.location_id,
      order_id: data.order_id,
      driver_id: data.driver_id,
      terminal_id: data.terminal_id,
    };
    const url = BaseConfig.ApiEndPoint + "/createsplitorder";
    const splitApiData = await axios.post(url, payLoad);
    orderobject = splitApiData.data.data;
  } catch (error) {
    return Promise.reject("failed");
  }
  return Promise.resolve(orderobject);
};
export const unscheduleMovement = async (payload) => {
  let orderobject = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/unschedulemovement";
    const unscheduleMovementData = await axios.post(url, payload);

    return Promise.resolve(unscheduleMovementData);
  } catch (error) {
    return Promise.reject("failed");
  }
};

export const createEmptyMove = async (payload) => {
  let emptyMove = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/createemptymove";
    emptyMove = await axios.post(url, payload);

    return Promise.resolve(emptyMove);
  } catch (error) {
    return Promise.reject("failed");
  }
};

export const updatetrailerterminal = async (payload) => {
  let emptyMove = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/updatetrailerterminal";
    emptyMove = await axios.post(url, payload);

    return Promise.resolve(emptyMove);
  } catch (error) {
    return Promise.reject("failed");
  }
};

export const getallcarrierview = async (searchData) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/getcarrierview",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        date: searchData.date,
        terminals: searchData.terminals,
      },
    };

    let response = await axios(config);
    const mvmvtStopApiResponse = atob(response.data);

    // Convert binary string to character-number array
    const charData = mvmvtStopApiResponse.split("").map((x) => {
      return x.charCodeAt(0);
    });
    // Turn number array into byte-array
    const binData = new Uint8Array(charData);
    // Use this Code to Decode the String

    //Uncompress API Call//
    let mvmvtStopApiDataUnCompressed = fflate.unzlibSync(binData);
    let mvmvtStopApiDataUnCompressedString = fflate.strFromU8(
      mvmvtStopApiDataUnCompressed
    );
    const mvmvtStopApiDecompressedObject = JSON.parse(
      mvmvtStopApiDataUnCompressedString
    );

    return Promise.resolve(mvmvtStopApiDecompressedObject);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createsplitstopdata = async (payload) => {
  let splitStop = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/createsplitstopdata";
    splitStop = await axios.post(url, payload);

    return Promise.resolve(splitStop);
  } catch (error) {
    return Promise.reject("failed");
  }
};

export const updatesplitstopdata = async (where, data) => {
  let splitStop = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/updatesplitstopdata";
    splitStop = await axios.post(url, { where, data });

    return Promise.resolve(splitStop);
  } catch (error) {
    return Promise.reject("failed");
  }
};

export const getsplitstopdata = async (payload) => {
  let splitStop = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/getsplitstopdata";
    splitStop = await axios.post(url, payload);

    return Promise.resolve(splitStop);
  } catch (error) {
    return Promise.reject("failed");
  }
};

export const deletesplitstopdata = async (payload) => {
  let splitStop = [];
  try {
    const url = BaseConfig.ApiEndPoint + `/deletesplitstopdata/${payload}`;
    splitStop = await axios.delete(url);

    return Promise.resolve(splitStop);
  } catch (error) {
    return Promise.reject("failed");
  }
};
export const getMoveByid= async (payload) => {

  let orderObj = [];
  try {
    // if (reload) {
    // API object call.
    const url = BaseConfig.ApiEndPoint + "/getmovementbymoveid";
    const orderApiData = await axios.post(url, payload);
    orderObj = orderApiData.data.data;

  } catch (err) {
    return Promise.reject(
      "There is a problem on retrieving Move data. Please try again!"
    );
  }

  return Promise.resolve(orderObj);
}

export const unassignTrailer = async (obj) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/unassignedtrailer",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const unsplit = async (obj) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/unsplit",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};



export const updateETA = async (payload) => {
  try {
      const url = BaseConfig.ApiEndPoint + "/bpadriverseta"
      const ConfigData = await axios.post(url, payload);
      return ConfigData
  } catch (err) {
      return err;
  }
}

export const getmovementtrailer = async (payload) => {

  let getmovetrailerObj = [];
  try {
    // if (reload) {
    // API object call.
    const url = BaseConfig.ApiEndPoint + "/getmovementtrailer";
    const apiData = await axios.post(url, payload);
    getmovetrailerObj = apiData.data.data;

  } catch (err) {
    return Promise.reject(
      "There is a problem on retrieving Move data. Please try again!"
    );
  }

  return Promise.resolve(getmovetrailerObj);
}

// Driverparking
export const createLocation = async (payload) => {
  try {
      const url = BaseConfig.ApiEndPoint + "/createdriverparking"
      const ConfigData = await axios.post(url, payload);
      return ConfigData
  } catch (err) {
      return err;
  }
}

// If there is no pre order scheduled for a driver
export const scheduleSingleOrder = async (driver_id, payload) => {
  try {
    const url = `${BaseConfig.PlanningApiEndPoint}/driver/${driver_id}`;
    const responseData = await axios.post(url, payload);
    return responseData;
  } catch (err) {
    throw err;
  }
};

// If there are orders scheduled for a driver
export const scheduleStackedOrders = async (driver_id, plan_id, payload) => {
  try {
    const url = `${BaseConfig.PlanningApiEndPoint}/driver/${driver_id}/plan/${plan_id}`;
    const responseData = await axios.patch(url, payload);
    return responseData;
  } catch (err) {
    throw err;
  };
};

// For creating bobtail or empty movements
export const deleteMovementFromPlan = async (driverId, planId, movementId,data) => {  
  try {
    const url = `${BaseConfig.PlanningApiEndPoint}/remove-move/driver/${driverId}/plan/${planId}/move/${movementId}`;
    var config = {
      method: "patch",
       url,
      headers: {
        "Content-Type": "application/json",
      },
      data:data ?? {}
    };
    let response = await axios(config);
    return response;
  } catch (err) {
    throw err;
  };
};

// For creating bobtail or empty movements
export const createBobtailOrEmptyMovements = async (driver_id, payload) => {  
  try {
    const url = `${BaseConfig.PlanningApiEndPoint}/add-move/driver/${driver_id}`;
    const responseData = await axios.post(url, payload);
    return responseData;
  } catch (err) {
    throw err;
  };
};

// For adding a trailer to an order
export const addTrailer = async (driverId, planId, payload) => {  
  try {    
    const url = `${BaseConfig.PlanningApiEndPoint}/add-trailer/driver/${driverId}/plan/${planId}`;
    const responseData = await axios.patch(url, payload);
    return responseData;
  } catch (err) {
    throw err;
  };
};

// For removing a trailer from an order
export const removeTrailer = async (driverId, planId, payload) => {  
  try {
    const url = `${BaseConfig.PlanningApiEndPoint}/remove-trailer/driver/${driverId}/plan/${planId}`;
    const responseData = await axios.post(url, payload);
    return responseData;
  } catch (err) {
    throw err;
  };
};

export const createSplit = async (payload) => {
  try {
    var config = {
      method: "post",
       url: BaseConfig.PlanningApiEndPoint + "/split",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//unsplit
export const createUnSplit = async (payload) => {
  try {
    var config = {
      method: "post",
       url: BaseConfig.PlanningApiEndPoint + "/unsplit",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//change Movement
export const editMovements = async (payload,id) => {

  try {
    var config = {
      method: "patch",
       url: BaseConfig.PlanningApiEndPoint + "/movement"+ `/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }

};

//Edit Stops
export const editStops = async (payload,id) => {
  
  try {
    var config = {
      method: "patch",
       url: BaseConfig.PlanningApiEndPoint + "/stop"+ `/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }

};

//change Plan Time By Startshift

export const changePlanTimebystartshift = async (payload,id,data) => {

  try {
    var config = {
      method: "patch",
       url: BaseConfig.PlanningApiEndPoint + "/plan"+ `/${id}`+"/starttime"+`/${payload}`,
      headers: {
        "Content-Type": "application/json",
      },
      data:data ?? {}
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }

};

export const getDriverHosClock = async (id) => {
  try {
    var config = {
      method: "get",
       url: BaseConfig.ApiEndPoint + "/driverhosclock"+ `/${id}`,
      headers: {
        "Content-Type": "application/json",
      }
    };
    let response = await axios(config);
    return Promise.resolve(response?.data?.data);
  } catch (error) {
    return Promise.reject(error);
  }

};