import {React, useContext} from 'react';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
    customWidth: {
      maxWidth: 500,
    },
    customTooltip: {
      maxWidth: 'none',
      backgroundColor: "#4267B2",
      borderColor: "#2C4F95",
      borderStyle: "solid",
      borderWidth: "2px",
      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.54)",
      padding: "12px",
      borderRadius: "10px"
    },
    customArrow: {
      color: "#4267B2",
      fontSize: "20px"
    },
  }));

const PlannerBoardTrailerTooltip = (props) => {
    const classes = useStyles();

    return(
        <Tooltip
            title={
                <>
                <Typography color='inherit'>
                    {props?.tooltip}
                </Typography>
                </>
            }
            placement="top"
            classes={{
                tooltip: classes?.customTooltip,
                arrow: classes?.customArrow,
            }}
            arrow
        >
            {props?.children}
        </Tooltip>
    )
}
const formatDate = (epoch_date, tzone) => {

  if (!tzone || tzone === "Eastern Standard Time" || tzone === "EST"|| tzone === "EDT" || tzone === "America/New_York") {
    tzone = "America/New_York"
  }
  if (!tzone ||tzone === "Central Standard Time"||tzone === "CDT"||tzone === "CST" || tzone === "America/Chicago") {
    tzone = "America/Chicago"
  }
  if (epoch_date > 0) {
    return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
  }
  else {
    return ""
  }

}
const OrderPBToolTip = (props) => {
    // const context = useContext(DropContext);
    // const plannerTimezone = context.plannerTimezone;
    const it = props.it;
    const displayfororderid = (it) => {
      if (it.orderId === null) {
  
        return it.orderId2
      }
      else {
        return it.orderId
      }
    }
    return (
      <span className="tooltip-adjust">
        <div className="main_tooltip_section">
          <div className="tooltip_left">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Order: </div>
              <div className="tooltip_text_right">
  
                {(displayfororderid(it)) ?? "No Data"}
              </div>
            </div>
            {it?.stopData?.stop_type !== "HT" ? 
                <div className="tooltip_single_sec">
                <div className="tooltip_text_left"> Previous Move : </div>
                <div className="tooltip_text_right">{!it?.stopData?.linkedMvmts ? it?.move_id : it?.stopData?.linkedMvmts[0]?.move_id}</div>
              </div>
              : null
            }
            {it?.stopData?.stop_type !== "DT" ? 
                <div className="tooltip_single_sec">
                <div className="tooltip_text_left"> Next Move : </div>
                <div className="tooltip_text_right">{!it?.stopData?.linkedMvmts ? it?.move_id : it?.stopData?.linkedMvmts[1]?.move_id}</div>
                </div>
                : null
            }
          </div>
  
          <div className="tooltip_middle">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Stop Type:</div>
              <div className="tooltip_text_right">  {it?.type}</div>
            </div>
            {it?.stopData?.stop_type !== "HT" ? 
                <div className="tooltip_single_sec">
                <div className="tooltip_text_left"> Previous Move Type:</div>
                <div className="tooltip_text_right"> {!it?.stopData?.linkedMvmts ? it?.type : it?.stopData?.linkedMvmts[0]?.type} </div>
                </div>
                : null
            }
            {it?.stopData?.stop_type !== "DT" ? 
                <div className="tooltip_single_sec">
                <div className="tooltip_text_left"> Next Move Type:</div>
                <div className="tooltip_text_right"> {!it?.stopData?.linkedMvmts ? it?.type : it?.stopData?.linkedMvmts[1]?.type} </div>
                </div>
                : null
            }
          </div>
          <div className="tooltip_rightPu">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Status:</div>
              <div className="tooltip_text_right"> {it?.order_status}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Status:</div>
              <div className="tooltip_text_right"> {it?.movement_status}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Status:</div>
              <div className="tooltip_text_right"> {!it?.stopData?.linkedMvmts ? it?.type : it?.stopData?.linkedMvmts[1]?.status}</div>
            </div>
          </div>
        </div>
        <div className="main_tooltip_section tooltip_inner_gap">
          <div className="tooltip_left">
            <div className="tooltip_single_sec  ">
              <div className="tooltip_text_left fs_20">{it?.stopData?.stop_type ?? "No Data"} : </div>
            </div>
            <div className="tooltip_single_sec">
              <p className="tooltip_text_right">
                {it?.stopData?.lm_location_id ?
                  (`${it.stopData?.lm_location_id} - ${it?.stopData?.lm_location_name}`)
                  : "No Data"
                }
                <br />
                {it?.stopData?.address ?
                  (`${it?.stopData?.address}`)
                  : null
                }
  
                <br />
                {it?.stopData?.city ?
                  (`${it.stopData?.city}, ${it?.stopData?.state}, ${it?.stopData?.zip} `)
                  : null
                }
              </p>
            </div>
          </div>
          <div className="tooltip_middle">
  
          </div>
        </div>
  
        <div className="main_tooltip_section tooltip_inner_gap1">
          <div className="tooltip_left">
  
  
  
            <div className="tooltip_single_sec ">
              <div className="tooltip_text_left">Early Appt: </div>
              <div className="tooltip_text_right">  {it?.stopData?.sched_arrive_early_utc ?
                formatDate(it.stopData?.sched_arrive_early_utc, it.stopData.delivery_timezone?it.stopData.delivery_timezone:it?.stopData.pickup_timezone) : "No Data"}</div>
            </div>
            <div className="tooltip_single_sec ">
              <div className="tooltip_text_left">Late Appt: </div>
              <div className="tooltip_text_right">  {it?.stopData?.sched_arrive_late_utc ?
                formatDate(it.stopData?.sched_arrive_late_utc, it.stopData.delivery_timezone?it.stopData.delivery_timezone:it?.stopData.pickup_timezone) : "No Data"}</div>
            </div>
          </div>
          <div className="tooltip_middle">
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">  Appt Rq'd:</div>
              <div className="tooltip_text_right">  {it?.pickup_appt_required ? it.pickup_appt_required : "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">  Appt confirmed:</div>
              <div className="tooltip_text_right">  {it?.picup_confirmed ? it.picup_confirmed : "No Data"}</div>
            </div>
  
          </div>
          <div className="tooltip_rightPu">
          </div>
        </div>
        <div className="main_tooltip_section tooltip_inner_gap" >
          <div className="tooltip_left">
            <div className="tooltip_single_sec mt_5 ">
              <div className="tooltip_text_left">Planned Arrive : </div>
              <div className="tooltip_text_right">  {it?.stopData.planned_arrive_utc ? formatDate(it.stopData.planned_arrive_utc,it.stopData.delivery_timezone?it.stopData.delivery_timezone:it.stopData.pickup_timezone) : "No Data"}</div>
            </div>
  
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Planned Depart : </div>
              <div className="tooltip_text_right">  {it?.stopData.planned_depart_utc ? formatDate(it.stopData.planned_depart_utc,it.stopData.delivery_timezone?it.stopData.delivery_timezone:it.stopData.pickup_timezone) : "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">ETA: </div>
              <div className="tooltip_text_right"> {it?.stopData.eta_utc ? formatDate(it.stopData.eta_utc,it.stopData.delivery_timezone?it.stopData.delivery_timezone:it.stopData.pickup_timezone) : "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Actual Arrival : </div>
              <div className="tooltip_text_right">  {it?.stopData.actual_arrival_utc ? formatDate(it.stopData.actual_arrival_utc,it.stopData.delivery_timezone?it.stopData.delivery_timezone:it.stopData.pickup_timezone) : "No Data"}</div>
            </div>
            <div className="tooltip_single_sec">
              <div className="tooltip_text_left">Actual Depart : </div>
              <div className="tooltip_text_right">  {it?.stopData?.actual_departure_utc ? formatDate(it.stopData?.actual_departure_utc,it.stopData.delivery_timezone?it.stopData.delivery_timezone:it.stopData.pickup_timezone) : "No Data"}</div>
            </div>
          </div>
          <div className="tooltip_middle">
          </div>
          <div className="tooltip_rightPu">
          </div>
        </div>
      </span>
    );
  }

export {OrderPBToolTip};
export default PlannerBoardTrailerTooltip;