import React, { useState, useEffect } from 'react';
import { Autocomplete } from "@mui/lab";
import { TextField } from "@mui/material";
import { getTrailerHistorybyTrailerid } from "../../services/trailerService";
import Button from "react-bootstrap/Button";
import { DateTime } from "luxon";
import { NotificationContainer, NotificationManager } from "react-notifications";
import Config from '../../Config.json';

const OutsideCarrier = (props) => {
    // Props (Which is also using as options like allWashLocation, allActiveTractors, allActiveTrailers)
    const { allWashLocation, allWashCodes, createTankWash, loggedUserData, washDeatilsById, updateTankWash, setorderClicked } = props;

    // States
    const [customer, setCustomer] = useState("");
    const [equipmentType, setEquipmentType] = useState({ id: "1", name: 'Trailer', value: "L" });
    const [interiorWash, setInteriorWash] = useState({});
    const [exteriorWash, setExteriorWash] = useState({});
    const [trailerID, setTrailerID] = useState("");
    const [tractorID, setTractorID] = useState("");
    const [washLocation, setWashLocation] = useState([]);
    const [driverID, setDriverID] = useState("");
    const [defaultDate1, setDefaultDate1] = useState("");

    // Disabled for now    
    const [maxWash, setMaxWash] = useState("");
    const [interiorWashAmount, setInteriorWashAmount] = useState(0);
    const [exteriorWashAmount, setExteriorWashAmount] = useState(0);
    const [allocation, setAllocation] = useState("");
    const [chargeAllocation, setChargeAllocation] = useState("");

    // Options
    const [extrWashCodeOptions, setExtrWashCodeOptions] = useState([]);
    const [intrWashCodeOptions, setIntrWashCodeOptions] = useState([]);
    const [othersEquipment, setOthersEquipment] = useState("")

    const equipmentTypeOptions = [
        { id: "1", name: 'Trailer', value: "L" },
        { id: "2", name: 'Tractor', value: "T" },
        { id: "3", name: 'Trailer & Tractor', value: "B" },
        { id: "4", name: 'Others', value: "O" },
    ];

    const handleEquipmentType = (e, value) => {
        setEquipmentType(value);
        setTrailerID("");
        setTractorID("");
        setOthersEquipment("")        
    }

    // useEffect(() => {
    //     // Filter the wash locations based on the values of is_wash and is_terminal
    //     const filteredLocations = allWashLocation.filter(
    //         (loc) => loc.is_wash === true && loc.is_terminal === true
    //     );
    //     setWashLocation(filteredLocations);
    // }, [allWashLocation]);

    useEffect(() => {
        const extWashCodeArr = allWashCodes.filter((item) => item.type === "E" && item.is_active === true);
        setExtrWashCodeOptions(extWashCodeArr);

        const intWashCodeArr = allWashCodes.filter((item) => item.type === "I" && item.is_active === true);
        setIntrWashCodeOptions(intWashCodeArr);
    }, []);

    const handleDateChange = (e) => {
        setDefaultDate1(e.target.value)
        if (washLocation?.timezone) {
            let timestamps = DateTime.fromISO(e.target.value).setZone(`${washLocation?.timezone}`, { keepLocalTime: true }).toMillis();

        } else {
            NotificationManager.error("Choose a wash location first.", "Error", 2500);
            setDefaultDate1("");
        }
    }

    const handleLocation = (e, value) => {
        setWashLocation(value);
    }

    const handleTrailer = (e, value) => {
        setTrailerID(e.target.value);
    }

    const handleTractor = (e, value) => {
        setTractorID(e.target.value);
    }



    const handleCreateHandler = async (e, defaultDate1, tz) => {
        let timestamps1 = DateTime.fromISO(defaultDate1).setZone(`${tz}`, { keepLocalTime: true }).toMillis();

        const payload = {
            "company_id": Config.dev.companyid,
            "charge_type": "O",
            "commodity_id": "",
            "driver_id": driverID,
            "entered_user_id": loggedUserData.userName,
            "equip_type": equipmentType.value,
            "int_wash_code": interiorWash?.wash_code ? interiorWash?.wash_code : interiorWash?.int_wash_code,
            "ext_wash_code": exteriorWash?.wash_code ? exteriorWash?.wash_code : exteriorWash?.ext_wash_code,
            "intra_co_seg_code": "",
            // "ready_to_xfer": "N/A",
            "tractor_id": tractorID,
            "trailer_id": trailerID,
            "void": "N",
            "wash_location_id": washLocation?.code,
            // "trailer_wash_assigned": "N/A",
            "needed_date": timestamps1,
            "needed_date_utc": timestamps1,
            "source": "planning system",
            "other_equip": othersEquipment,
            "int_wash_amount": Number(interiorWashAmount),
            "ext_wash_amount": Number(exteriorWashAmount)
        }

        if (washDeatilsById) {
            if (washDeatilsById && (payload.needed_date_utc && payload.equip_type && payload.int_wash_code && payload.wash_location_id) && (payload.equip_type === "O" ? payload.other_equip : payload.equip_type === "L" ? payload.trailer_id : payload.equip_type === "T" ? payload.tractor_id : payload.equip_type === "B" ? (payload.tractor_id && payload.trailer_id) : false)) {
                const response = await updateTankWash(payload, washDeatilsById.wash_id);        
                if (response.status !== 500) {
                    setorderClicked(false);
                } else {
                    setorderClicked(true);
                }
            } else {
                NotificationManager.error("Please fill all mandatory fields.");
            }

        } else {          
            if ((payload.needed_date_utc && payload.equip_type && payload.int_wash_code && payload.wash_location_id) && (payload.equip_type === "O" ? payload.other_equip : payload.equip_type === "L" ? payload.trailer_id : payload.equip_type === "T" ? payload.tractor_id : payload.equip_type === "B" ? (payload.tractor_id && payload.trailer_id) : false) ) {
                createTankWash(payload);
            } else {
                NotificationManager.error("Please fill all mandatory fields.");
            }
        }
    }

    const movementTime = (epoch_date, tzone) => {      
        return DateTime.fromMillis(parseInt(epoch_date), {zone: tzone}).toISO({ suppressMilliseconds: true, suppressSeconds: true, includeOffset: false });          
    }

    useEffect(() => {

        if (washDeatilsById) {
            setExteriorWashAmount(washDeatilsById?.ext_wash_amount);
            setInteriorWashAmount(washDeatilsById?.int_wash_amount)
            setWashLocation(washDeatilsById.location);
            setInteriorWash(washDeatilsById.int_wash)
            setExteriorWash(washDeatilsById.ext_wash)
            if (washDeatilsById.equip_type == "L") {
                setEquipmentType({ name: "Trailer", value: "L" })
                setTrailerID(washDeatilsById.trailer_id);
            }
            else if (washDeatilsById.equip_type == "T") {
                setEquipmentType({ name: "Tractor", value: "T" })
                setTractorID(washDeatilsById.tractor_id)
            } else if (washDeatilsById.equip_type == "B") {
                setEquipmentType({ name: "Trailer & Tractor", value: "B" })
                setTractorID(washDeatilsById.tractor_id);
                setTrailerID(washDeatilsById.trailer_id)
            } else {
                setEquipmentType({ name: "Others", value: "O" })
                setOthersEquipment(washDeatilsById.other_equip)
            }

            setDefaultDate1(movementTime(washDeatilsById.needed_date_utc, washDeatilsById.location.timezone));
        }

    }, [washDeatilsById])

    const handleExteriorWashChange = (event, value) => {
        if (value) {
            setExteriorWash(value);
            setExteriorWashAmount(value?.amount);
        } else {
            setExteriorWash("");
            setExteriorWashAmount(0); 
        }
    };

    const handleInteriorWashChange = (event, value) => {
        if (value) {
            setInteriorWash(value);
            setInteriorWashAmount(value?.amount);
        } else {
            setInteriorWash("");
            setInteriorWashAmount(0);
        }
    };

    const extwashAmountHandler = (e) => {
        setExteriorWashAmount(Number(e.target.value));
    };

    const intwashAmountHandler = (e) => {
        setInteriorWashAmount(Number(e.target.value));
    };

    return (
        <>
            <NotificationContainer />
            <div>
                <label htmlFor="typeHeadCommodityGroup">
                    Wash Location*
                </label>
                <div className="meterial_autocomplete">
                    <Autocomplete
                        id='combo-box-demo'
                        options={allWashLocation}
                        getOptionLabel={(option) => {
                            if (option.code) {
                                return `${option.code} - ${option.location_name} - ${option.state} - ${option.city}`;
                            }
                            return ''; 
                        }}
                        onChange={(e, newValue) => handleLocation(e, newValue)}
                        value={washLocation}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder='Choose a Wash Location'
                                variant='outlined'
                            />
                        )}
                    />

                </div>
            </div>

            <div className="tankwash-dialog__container">
                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Equipment Type*
                    </label>
                    <div className="meterial_autocomplete">
                        <Autocomplete
                            options={equipmentTypeOptions}
                            getOptionLabel={option => option?.name}
                            onChange={(e, newValue) => handleEquipmentType(e, newValue)}
                            value={equipmentType}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Choose an Equipment Type"
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                </div>

                {equipmentType?.name === "Trailer" ? (
                    <div className="dialog-item">
                        <label htmlFor="typeHeadCommodityGroup">
                            Trailer*
                        </label>
                        <div className="meterial_autocomplete">
                            <TextField
                                disabled={equipmentType?.name === "Tractor"}
                                id="outlined-required"
                                className="meterial_autocomplete"
                                variant='outlined'
                                placeholder='Enter Trailer Details'
                                value={trailerID}
                                onChange={(event, newValue) => handleTrailer(event, newValue)}
                            />
                        </div>
                    </div>) : ""}

                {equipmentType?.name === "Tractor" ? (<div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Tractor*
                    </label>
                    <div className="meterial_autocomplete">
                        <TextField
                            disabled={equipmentType?.name === "Trailer"}
                            id="outlined-required"
                            className="meterial_autocomplete"
                            variant='outlined'
                            placeholder='Enter Tractor Details'
                            value={tractorID}
                            onChange={(event, newValue) => handleTractor(event, newValue)}
                        />
                    </div>
                </div>) : ""}
                {equipmentType?.name === "Trailer & Tractor" ? (
                    <>
                        <div className="dialog-item">
                            <label htmlFor="typeHeadCommodityGroup">
                                Trailer*
                            </label>
                            <div className="meterial_autocomplete">
                                <TextField
                                    disabled={equipmentType?.name === "Tractor"}
                                    id="outlined-required"
                                    className="meterial_autocomplete"
                                    variant='outlined'
                                    placeholder='Enter Trailer Details'
                                    value={trailerID}
                                    onChange={(event, newValue) => handleTrailer(event, newValue)}
                                />
                            </div>
                        </div>
                        <div className="dialog-item">
                            <label htmlFor="typeHeadCommodityGroup">
                                Tractor*
                            </label>
                            <div className="meterial_autocomplete">
                                <TextField
                                    disabled={equipmentType?.name === "Trailer"}
                                    id="outlined-required"
                                    className="meterial_autocomplete"
                                    variant='outlined'
                                    placeholder='Enter Tractor Details'
                                    value={tractorID}
                                    onChange={(event, newValue) => handleTractor(event, newValue)}
                                />
                            </div>
                        </div>
                    </>
                )
                    : ""}
                {equipmentType?.name === "Others" ? (


                    <div className="dialog-item">
                        <label htmlFor="typeHeadCommodityGroup">
                            Other Equipment*
                        </label>

                        <div className="meterial_autocomplete">
                            <TextField
                                disabled={equipmentType?.name === "Tractor"}
                                id="outlined-required"
                                className="meterial_autocomplete"
                                variant='outlined'
                                placeholder='Enter Other Equipment'
                                value={othersEquipment}
                                onChange={(e) => { setOthersEquipment(e.target.value) }}
                            />
                        </div>
                    </div>

                )
                    : ""}

                <div className="dialog-item">
                    <label htmlFor="exteriorWashInput">
                        Exterior Wash (Optional)
                    </label>
                    <div className="meterial_autocomplete">
                        <Autocomplete
                            options={extrWashCodeOptions}
                            getOptionLabel={option => {
                                if (option?.wash_code) {
                                    return `${option?.wash_code} - ${option?.description}`;
                                }
                                return ''; 
                            }}
                            onChange={handleExteriorWashChange}
                            value={exteriorWash}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id="exteriorWashInput"
                                    placeholder="Choose an Exterior Wash"
                                    variant="outlined"
                                />
                            )}
                        />

                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Exterior Wash Amount
                    </label>
                    <div className="meterial_autocomplete">
                        <TextField
                            id="exteriorWashAmount"
                            value={exteriorWashAmount}
                            placeholder="Exterior Wash Amount"
                            variant="outlined"
                            fullWidth
                            onChange={extwashAmountHandler}
                            type="number"
                        />
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="interiorWashInput">
                        Interior Wash*
                    </label>
                    <div className="meterial_autocomplete">
                        <Autocomplete
                            options={intrWashCodeOptions}
                            getOptionLabel={option => {
                                if (option?.wash_code) {
                                    return `${option?.wash_code} - ${option?.description}`;
                                }
                                return ''; 
                            }}
                            onChange={handleInteriorWashChange}
                            value={interiorWash}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id="interiorWashInput"
                                    placeholder="Choose an Interior Wash"
                                    variant="outlined"
                                />
                            )}
                        />

                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Interior Wash Amount
                    </label>
                    <div className="meterial_autocomplete">
                        <TextField
                            id="interiorWashAmount"
                            value={interiorWashAmount}
                            placeholder="Interior Wash Amount"
                            variant="outlined"
                            fullWidth
                            onChange={intwashAmountHandler}
                            type="number"
                        />
                    </div>
                </div>



                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Max Wash
                    </label>
                    <div className="meterial_autocomplete">
                        <Autocomplete
                            disabled
                            options={[]}
                            getOptionLabel={option => option}
                            value={maxWash}
                            onChange={(event, newValue) => setMaxWash(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Max Wash"
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Total Amount
                    </label>
                    <div className="meterial_autocomplete">
                        <TextField
                            fullWidth
                            variant='outlined'
                            type='number'
                            name='totalAmount'
                            placeholder='Total Amount'
                            size='small'
                            disabled
                            value={Number(interiorWashAmount) + Number(exteriorWashAmount)}
                        />
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        {`Wash Needed By* ${!(washLocation?.timezone) ? "" : `(${washLocation?.timezone})`}`}
                    </label>
                    <div className="meterial_autocomplete">
                        <form noValidate>
                            <TextField
                                variant="outlined"
                                id="datetime-local"
                                className="meterial_autocomplete"
                                type="datetime-local"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={defaultDate1}
                                onChange={handleDateChange}
                            />
                        </form>
                    </div>
                </div>

                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Allocation
                    </label>
                    <div className="meterial_autocomplete">
                        <Autocomplete
                            disabled
                            options={[]}
                            getOptionLabel={option => option}
                            value={allocation}
                            onChange={(event, newValue) => setAllocation(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Allocation"
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="dialog-item">
                    <label htmlFor="typeHeadCommodityGroup">
                        Charge Allocation
                    </label>
                    <div className="meterial_autocomplete">
                        <Autocomplete
                            disabled
                            options={[]}
                            getOptionLabel={option => option}
                            value={chargeAllocation}
                            onChange={(event, newValue) => setChargeAllocation(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Charge Allocation"
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                </div>
            </div>

            <div style={{ textAlign: "right", padding: "20px 0px 20px 0px" }}>
                <Button variant="primary" onClick={(e) => handleCreateHandler(e, defaultDate1, washLocation?.timezone)} >{washDeatilsById ? "Save" : "Create"}</Button>
            </div>
        </>)
}

export default OutsideCarrier;
