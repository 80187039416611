import { useState, useEffect, useRef, useContext } from "react";
import { addOverrideSchedule } from "../../services/driverService";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { DateTime } from "luxon";
import { ContextData } from "../appsession";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./overrideSchedule.css";

gsap.registerPlugin(Draggable);


const OverrideSchedule = ({ driver, updateOverrideModal }) => {
  const [userData, setuserData] = useContext(ContextData);
  const unavailableBoxRef = useRef();
  const availableBoxRef = useRef();
  const scheduleContainerGridRef = useRef();
  const [gridWidth, setGridWidth] = useState();
  const [gridHeight, setGridHeight] = useState()
  const [scheduleGrid, setScheduleGrid] = useState();
  const [overridesData, setOverridesData] = useState([]);
  const [overrideFromDate, setOverrideFromDate] = useState(null);
  const [overrideToDate, setOverrideToDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dropInCells, setdropInCells] = useState([]);
  const gridProperties = { gridRows: 24, gridColumns: 2 }; 

  const handleFromDateChange = (date) => {
    setOverrideFromDate(date);
  };

  const handleToDateChange = (date) => {
    setOverrideToDate(date);
  };

  function dragElement(ev, block_type, block_position, isNew) {
    let driverPermission = userData.roles.rolespermission.find(it => it?.permissiondetail?.code === "Drivers");
    if (!driverPermission.can_edit) {
      return NotificationManager.error(
        "You are not allowed to drag schedule", Error
      );
    }
    else {
      ev.dataTransfer.setData("dragAttribute_targetId", ev.target.id);
      ev.dataTransfer.setData("dragAttribute_blockType", block_type);
      ev.dataTransfer.setData("dragAttribute_isNew", isNew);
      ev.dataTransfer.setData("dragAttribute_block_position", block_position);
      if (isNew) {
        ev.dataTransfer.dropEffect = "copy";
      }
      else {
        let grid_cell = ev.target.id.split("_")[0];
        ev.dataTransfer.dropEffect = "move";
        ev.dataTransfer.setData("dragAttribute_drag_cell", grid_cell);
      }
    }
  };

  const getTimeZone = (zone) => {
    let Timezone
    if (
      zone === "Eastern Standard Time" || zone === "America/New_York"
    ) {
      Timezone = "America/New_York";
    }
    if (
      zone === "Central Standard Time" || zone === "America/Chicago"
    ) {
      Timezone = "America/Chicago";
    }
    return Timezone
  };

  function allowDrop(ev) {
    ev.preventDefault();
  };

  async function addOverride(e) {
    e.preventDefault();
    let overridesArray = [];
    const override_from_date = DateTime.fromISO(overrideFromDate).setZone(getTimeZone(driver?.terminal?.timezone),{keepLocalTime : true}).startOf("day").toMillis();
    const override_to_date = DateTime.fromISO(overrideToDate).setZone(getTimeZone(driver?.terminal?.timezone),{keepLocalTime : true}).startOf("day").toMillis();

    if (override_from_date && override_to_date && (overridesData.length > 0)) {
      for (let i = 0; i < overridesData.length; i++) {
        overridesArray.push({
          start_time: overridesData[i].start_time,
          end_time: overridesData[i].end_time,
          duration: overridesData[i].end_time - overridesData[i].start_time,
          block_type: overridesData[i].block_type,
        })
      };

      let requestData = {
        driver_id: driver.driver_id,
        from_date: override_from_date,
        to_date: override_to_date,
        overridesArray
      };

      setLoading(true);
      const addOverrideResponse = await addOverrideSchedule(requestData);
      setLoading(false);
      updateOverrideModal(false);
    } else {
      NotificationManager.error("From Date, To Date, Schedule are mandatory", "Error");
    }
  };

  useEffect(async () => {
    // This UseEffect Colors Between the Blocks Of the Object , Modify this to change the color of the intermediate blocks , Not Start and End
    if (overridesData) {
      // Clear All Divs From Draw
      for (
        let i = 0;
        i < gridProperties.gridRows * gridProperties.gridColumns;
        i++
      ) {
        try {      
          document.getElementById(i + "_gridcell_override").style.backgroundColor =
            "white";
        } catch {
          //Do Nothing
        }
      }
      let filledCellArray = [];

      for (let index in overridesData) {
        // Color All Middle Cells Between Available and Drop
        let start_of_paint = overridesData[index].grid_cell_start_no;
        let end_of_paint = overridesData[index].grid_cell_end_no;

        // Color the Extension Cells
        for (let i = start_of_paint; i <= end_of_paint; i++) {

          if ((i % 2 !== 0)) {
            // Push Into The Validation Array If There Is a Match
            filledCellArray.push(i);
            if (overridesData[index].block_type == "Available") {
              document.getElementById(i + "_gridcell_override").style.backgroundColor =
                "#77d6a2";
            } else if (overridesData[index].block_type == "Unavailable") {
              document.getElementById(i + "_gridcell_override").style.backgroundColor =
                "#fa737c";
            }
          }
        }
      }
      setdropInCells(filledCellArray);
    }
  }, [overridesData]);

  const dropElement = (ev) => {
    ev.preventDefault();
    let overridesDataArray = [];

    let dragAttribute_blockType = ev.dataTransfer.getData("dragAttribute_blockType");
    let dragAttribute_isNew = ev.dataTransfer.getData("dragAttribute_isNew");
    let dragAttribute_block_position = ev.dataTransfer.getData("dragAttribute_block_position");

    overridesDataArray = [...overridesData];

    let drop_grid_cell = parseInt(ev.target.id.split("_")[0]);
    if (dragAttribute_isNew === "true") {
      let notallowedDrop = dropInCells.includes(drop_grid_cell);

      if (notallowedDrop) {
        NotificationManager.error("You Cannot Drag Drop On An Already Existing Schedule", "Error");
      } else {
        let start_time = (Math.floor(drop_grid_cell / gridProperties.gridColumns)) * 60;
        let end_time = (Math.ceil(drop_grid_cell / gridProperties.gridColumns) + 1) * 60;

        let newBlock = {
          "start_time": start_time,
          "end_time": end_time,
          "block_type": dragAttribute_blockType,
          "grid_cell_start_no": drop_grid_cell,
          "grid_cell_end_no": drop_grid_cell + gridProperties.gridColumns,
        }

        overridesDataArray.push(newBlock);
      }
    } else if (dragAttribute_block_position == "end") {
      let dragAttribute_drag_cell = parseInt(ev.dataTransfer.getData("dragAttribute_drag_cell"));
      let index = overridesDataArray.findIndex(x => parseInt(x.grid_cell_end_no) === dragAttribute_drag_cell)
      let end_time = (Math.floor(drop_grid_cell / gridProperties.gridColumns) + 1) * 60;

      if (end_time <= overridesDataArray[index].start_time) {
        NotificationManager.error("End time cannot be less than Start time", "Error");
      } else {
        overridesDataArray[index].end_time = end_time;
        overridesDataArray[index].grid_cell_end_no = drop_grid_cell;
      }
    } else if (dragAttribute_block_position == "start") {
      let dragAttribute_drag_cell = parseInt(ev.dataTransfer.getData("dragAttribute_drag_cell"));
      let index = overridesDataArray.findIndex(x => parseInt(x.grid_cell_start_no) === dragAttribute_drag_cell)
      let start_time = (Math.floor(drop_grid_cell / gridProperties.gridColumns)) * 60;

      if (start_time >= overridesDataArray[index].end_time) {
        NotificationManager.error("Start time cannot be greater than End time", "Error");
      } else {
        overridesDataArray[index].grid_cell_start_no = drop_grid_cell;
        overridesDataArray[index].start_time = start_time;
      }
    }

    setOverridesData(overridesDataArray);
  }

  function getBlockTypeClassName(data) {
    if (data.block_type == "Available") {
      return "availablebutton-schedule";
    } else if (data.block_type == "Unavailable") {
      return "unavailablebutton-schedule";
    }
  }

  useEffect(async () => {
    let gridArray = [];
    let gridWidth = window.innerWidth / gridProperties.gridColumns;

    setGridWidth(gridWidth);
    setGridHeight(30);
    let gridHeight = 30;

    let x, y;
    for (let i = 0; i < gridProperties.gridRows * gridProperties.gridColumns; i++) {
      y = Math.floor(i / gridProperties.gridColumns) * gridHeight;
      x = (i * gridWidth) % (gridProperties.gridColumns * gridWidth);
      gridArray.push({
        i: i,
        x: x,
        y: y
      })
    }
    setScheduleGrid(gridArray);
  }, []);

  return (
    <>
      <NotificationContainer />
      <div className="form-group">
        <div className="meterial_autocomplete">
          <div className="add_override_header">
            <div className="header-title1">Add Overrides</div>
            <button
              type="button"
              className="btn_blue btn-blue_schedule_override"
              onClick={(e) => addOverride(e)}
            >
              SAVE
            </button>
          </div>
          <div className="override-date-container">
            <div className="override-date-container__secondary">
              <label for="txtEffectiveDate" className="override-date-container__secondary-text">From Date </label>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  disableToolbar
                  inputVariant="outlined"
                  variant="outlined"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  placeholder="MM-dd-yyyy"
                  disablePast
                  value={overrideFromDate}
                  onChange={handleFromDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="override-date-container__secondary">
              <label for="txtEffectiveDate" className="override-date-container__secondary-text">To Date </label>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  disableToolbar
                  inputVariant="outlined"
                  variant="outlined"
                  format="MM/dd/yyyy"
                  placeholder="MM-dd-yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  value={overrideToDate}
                  onChange={handleToDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card card_shadow1">
            <div className="card-body ">
              <div className="override-availability-container">
                <div className="planner_date_text1">Schedule:</div>
                <div
                  id="available"
                  draggable="true"
                  onDragStart={(e) => dragElement(e, "Available", "header", true)}
                  className="indicator_inner ml_20"
                >
                  <div
                    className="available_box"
                    ref={availableBoxRef}
                  ></div>
                  <div className="indicator_text">Available</div>
                </div>
                <div
                  id="unavailable"
                  draggable="true"
                  onDragStart={(e) => dragElement(e, "Unavailable", "header", true)}
                  className="indicator_inner ml_20">
                  <div
                    className="unavailable_box"
                    ref={unavailableBoxRef}
                  ></div>
                  <div className="indicator_text">Unavailable</div>
                </div>
              </div>
              <div className="table-responsive">
                <div className="calerder_total_section">
                  <div className="appointment-popup">
                    <div className="appointment-calendar">
                      <div className="calendar-wrapper">
                        <div
                          className="calendar-week"
                          style={{ display: "inherit" }}
                        >
                        </div>
                        <div className="calendar-week">
                        </div>
                        <div
                          ref={scheduleContainerGridRef}
                        >
                          {
                            scheduleGrid
                              ? scheduleGrid.map((gridElement) => (
                                gridElement.i % 2 === 0 ? (
                                  <div
                                    id={gridElement.i + "_gridcell_override"}
                                    className="schedule-grid-popup"
                                    style={{
                                      top: gridElement.y,
                                      left: gridElement.x,
                                      width: gridWidth - 1,
                                      height: gridHeight - 1,
                                    }}
                                  >
                                    { // Gridelement is genererated from I , there is a minus 1 as the number of rows are 25 and not 24
                                      gridElement.i / 2 + ":00"
                                    }
                                  </div>
                                ) : (
                                  <div
                                    id={gridElement.i + "_gridcell_override"}
                                    key={gridElement.i + "_gridcell_override"}
                                    className="schedule-grid-popup"
                                    style={{
                                      top: gridElement.y,
                                      left: gridElement.x,
                                      width: gridWidth - 1,
                                      height: gridHeight - 1,
                                    }}
                                    onDrop={(e) => dropElement(e)}
                                    onDragOver={(e) => allowDrop(e)}
                                  >
                                    {overridesData.length > 0 ? (
                                      overridesData.map((data) =>
                                        data.grid_cell_start_no === gridElement.i ? (
                                          <>
                                            {loading ? (
                                              <div className={getBlockTypeClassName(data)}>{data.block_type == "Available" ? (
                                                <ProgressBar animated variant="success" now={100} />
                                              ) : (
                                                <ProgressBar animated variant="danger" now={100} />
                                              )}
                                              </div>
                                            ) : (
                                              <div className={getBlockTypeClassName(data)}
                                                draggable="true"
                                                onDragStart={(e) => dragElement(e, data.block_type, "start", false)}
                                                id={`${data.grid_cell_start_no}_gridData`}
                                                key={data.grid_cell_start_no + "_gridData"}>{data.block_type}
                                              </div>
                                            )
                                            }
                                          </>
                                        )
                                          : null
                                      )
                                    ) : null}
                                    {overridesData.length > 0 ? (
                                      overridesData.map((data) =>
                                        data.grid_cell_end_no === gridElement.i ? (
                                          <>
                                            {loading ? (<div className={getBlockTypeClassName(data)}>{data.block_type == "Available" ? (
                                              <ProgressBar animated variant="success" now={100} />
                                            ) : (
                                              <ProgressBar animated variant="danger" now={100} />
                                            )}
                                            </div>
                                            ) : (
                                              <div className={getBlockTypeClassName(data)}
                                                draggable="true"
                                                onDragStart={(e) => dragElement(e, data.block_type, "end", false)}
                                                id={`${data.grid_cell_end_no}_gridData`}
                                                key={data.grid_cell_end_no + "_gridData"}>
                                                End
                                              </div>)}
                                          </>
                                        )
                                          : null
                                      )
                                    ) : null}
                                  </div>
                                )
                              ))
                              : null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverrideSchedule;